import React, { useContext, useMemo } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

import { DropdownIndicator, Option, MultiValue } from './selectCustomComponents'
import { selectStyles } from './styled'

/*
  Currently the component is quite usage-specific.
  Particularly, its texts and layout are appropriate
  especially for Bets History Page.
*/
export const SelectCheckbox = (props) => {
  const themeContext = useContext(ThemeContext)

  const { t } = useTranslation()
  const styles = useMemo(() => selectStyles(themeContext), [themeContext])

  const handleKeyDown = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  return (
    <Select
      {...props}
      components={{
        ClearIndicator: null,
        DropdownIndicator,
        Option,
        MultiValue
      }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      noOptionsMessage={() => t('no options message')}
      placeholder={t('all types')}
      styles={styles}
      isMulti
      onKeyDown={handleKeyDown}
    />
  )
}
