import React, { FC } from 'react'

import { IconEmblem } from 'shared/ui/Icon/General/IconEmblem'

import { EventCardButtons } from '..'
import {
  StyledEventCardCompetitor,
  StyledEventCardDate,
  StyledEventCardHeader,
  StyledEventCardHeaderWrapper,
  StyledEventCardMetaWrapper,
  StyledEventCardWrapper
} from '../../EventCard.styled'

import { TextSkeleton } from './EventCardSkeleton.styled'

export const EventCardSkeleton: FC = () => (
  <StyledEventCardWrapper>
    <StyledEventCardMetaWrapper>
      <StyledEventCardHeaderWrapper>
        <StyledEventCardHeader>
          <TextSkeleton />

          <EventCardButtons />
        </StyledEventCardHeader>

        <StyledEventCardCompetitor>
          <IconEmblem size={16} />

          <TextSkeleton />
        </StyledEventCardCompetitor>

        <StyledEventCardCompetitor>
          <IconEmblem size={16} />

          <TextSkeleton />
        </StyledEventCardCompetitor>
      </StyledEventCardHeaderWrapper>
    </StyledEventCardMetaWrapper>

    <StyledEventCardDate>
      <TextSkeleton />
    </StyledEventCardDate>
  </StyledEventCardWrapper>
)
