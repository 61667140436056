import React from 'react'

import { useInjectReducer } from 'shared/lib/@reduxjs'
import { RightContainer } from 'widgets/right-container'

import { reducer, sliceKey } from './slice'

export const RightBasketContainer = () => {
  useInjectReducer({ key: sliceKey, reducer })

  return <RightContainer />
}
