import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportCurling: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M9.9996 7.19995H15.9996C16.736 7.19995 17.333 7.91564 17.333 8.80005V10.1222H6.6662V8.80005C6.6662 7.91636 7.2632 7.19995 7.9996 7.19995H8.6662C8.6662 5.43281 9.8604 4 11.333 4H15.333V5.6001H11.333C10.5966 5.6001 9.9996 6.31627 9.9996 7.19995ZM5.7904 11.2001H18.2096C19.2884 12.0596 20 13.5291 20 15.2C20 17.8518 18.209 20 16 20H8C5.791 20 4 17.8518 4 15.2C4 13.5291 4.7124 12.0596 5.7904 11.2001Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
