import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBiathlon: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_115559)">
          <path
            clipRule="evenodd"
            d="M15.4413 12.7222C15.2851 12.7222 15.1584 12.8488 15.1584 13.005V16.5423C15.1584 17.6033 14.2953 18.4664 13.2343 18.4664H8.94927C8.09715 18.4664 7.3729 17.9096 7.12071 17.1407C7.08562 17.0337 6.97933 16.9622 6.86883 16.9837C6.70743 16.9968 6.59842 17.0023 6.52774 17.0017C6.50917 17.0055 6.49022 17.0082 6.47093 17.0098L5.20817 17.1167C4.91644 17.1414 4.66602 16.9112 4.66602 16.6185V13.5083C4.66602 13.2321 4.88987 13.0083 5.16602 13.0083H6.42877C6.46619 13.0083 6.50265 13.0124 6.53772 13.0202C6.54496 13.009 6.56295 13.003 6.59718 13.003H8.17935C8.57176 13.003 8.89104 12.6837 8.89104 12.2913V12.1432C8.89104 11.987 9.01768 11.8603 9.17389 11.8603H9.54735C9.69291 11.8603 9.8109 11.7424 9.8109 11.5968C9.8109 11.4512 9.92889 11.3333 10.0744 11.3333H10.4092C10.5547 11.3333 10.6727 11.4512 10.6727 11.5968C10.6727 11.7424 10.7907 11.8603 10.9363 11.8603H18.2484C18.394 11.8603 18.5119 11.7424 18.5119 11.5968C18.5119 11.4512 18.6299 11.3333 18.7755 11.3333H19.0909C19.2471 11.3333 19.3738 11.4599 19.3738 11.6161V12.4393C19.3738 12.5955 19.2471 12.7222 19.0909 12.7222H15.4413ZM10.97 14.8557C10.8527 14.8557 10.7503 14.9346 10.7079 15.044C10.6327 15.2384 10.7647 15.4707 10.9731 15.4707H11.3497C11.5059 15.4707 11.6325 15.3441 11.6325 15.1879V15.1385C11.6325 14.9823 11.5059 14.8557 11.3497 14.8557H10.97ZM13.2343 17.6046C13.82 17.6046 14.2966 17.128 14.2966 16.5423L14.2966 15.1384C14.2966 14.9822 14.17 14.8556 14.0138 14.8556H12.7772C12.621 14.8556 12.4944 14.9822 12.4944 15.1384V16.0496C12.4944 16.2058 12.3677 16.3325 12.2115 16.3325H9.65603C9.6081 16.3325 9.5611 16.345 9.5185 16.3669C9.31342 16.4728 9.09198 16.5509 8.85913 16.5963L8.20128 16.7243C8.04429 16.7549 7.94105 16.9125 8.01813 17.0527C8.19889 17.3813 8.54841 17.6046 8.94927 17.6046H13.2343Z"
            fill={mainColor}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M4 9.03272C4 9.48828 4.36931 9.85759 4.82487 9.85759L6.48818 9.85759C6.77042 9.85759 7.01955 9.71583 7.16827 9.49963C7.17225 9.49973 7.17624 9.49978 7.18024 9.49978L17.8121 9.49977L17.8121 9.65967C17.8121 9.93581 18.0359 10.1597 18.3121 10.1597C18.5882 10.1597 18.8121 9.93581 18.8121 9.65967L18.8121 9.49977L19.5048 9.49977C19.7809 9.49977 20.0048 9.27592 20.0048 8.99977C20.0048 8.72363 19.7809 8.49977 19.5048 8.49977L18.8121 8.49977L18.8121 8.33988C18.8121 8.06374 18.5882 7.83988 18.3121 7.83988C18.0359 7.83988 17.8121 8.06374 17.8121 8.33988L17.8121 8.49977L7.18024 8.49978C7.16011 8.49978 7.14025 8.50097 7.12073 8.50328C6.96942 8.32269 6.74221 8.20786 6.48818 8.20786L4.82487 8.20786C4.3693 8.20786 4 8.57716 4 9.03272Z"
            fill={mainColor}
            fillRule="evenodd"
          />
          <path
            d="M19.9433 6.02718C19.9434 4.68604 16.9194 4.68889 15.3145 4.68626L13.4737 4.68324L13.4657 7.3499L15.3065 7.35291C16.9114 7.35554 19.9432 7.36831 19.9433 6.02718Z"
            fill={mainColor}
          />
          <path
            d="M8.69531 4.67261L5.41421 4.66834C4.63881 4.66733 4.00634 5.11509 4.00426 5.66651L4.00175 6.33317C3.99967 6.88459 4.62877 7.33398 5.40417 7.33499L8.68527 7.33926L8.69531 4.67261Z"
            fill={mainColor}
          />
          <rect
            fill={mainColor}
            height="7.61871"
            transform="matrix(-0.00376446 0.999993 -0.999999 -0.00130023 13.9121 4.67798)"
            width="2.66667"
          />
        </g>

        <defs>
          <clipPath id="clip0_6396_115559">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
