import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import { getEvents, getTournaments } from 'astra-core/containers/EventsProvider'
import {
  getInputSearchLine,
  getSearchEventsEntries
} from 'astra-core/containers/SearchProvider'
import { getEventsBySearch, getLineTournamentsBySearch } from 'astra-core/utils'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'

import { initialState } from './slice'
import { SelectLineEventsParams } from './types'

type SelectLineTournamentsParams = {
  sportId: string
}

export const getLineTournamentsIdsBySport = (
  state: RootState,
  params: SelectLineTournamentsParams
) =>
  (state.lineTournamentsContainer.tournamentsIdsBySport ||
    initialState.tournamentsIdsBySport)[
    +params.sportId // TODO either add sportIdPrev here or add sceleton preloader to UI
  ] || []

export const getLineTournamentsFetchItems = (state: RootState) =>
  state.lineTournamentsContainer.fetchItems || initialState.fetchItems

export const getFilterIsTop = (state: RootState) =>
  state.lineTournamentsContainer.filters.isTop ?? initialState.filters.isTop

export const getLayoutIsLoading = (state: RootState) =>
  state.lineTournamentsContainer.layouts.isLoading ??
  initialState.layouts.isLoading

export const getEventsLayoutIsLoading = (state: RootState) =>
  state.lineTournamentsContainer.layouts.isEventsLoading ??
  initialState.layouts.isEventsLoading

export const getFilterPeriod = (state: RootState) =>
  state.lineTournamentsContainer.filters.period || initialState.filters.period

export const getLineFetchItems = (state: RootState) =>
  state.lineTournamentsContainer.fetchEventsItems ||
  initialState.fetchEventsItems

export const getLineTournamentsFilters = (state: RootState) =>
  state.lineTournamentsContainer.filters || initialState.filters

export const getLineEventsIdsByTournament = (
  state: RootState,
  params: SelectLineEventsParams
) =>
  (state.lineTournamentsContainer.eventsIdsByTournament ||
    initialState.eventsIdsByTournament)[
    +params.tournamentId // TODO either add tournamentIdPrev here or add skeleton preloader to UI
  ] || []

export const selectLineTournaments = createSelector(
  [
    getLineTournamentsIdsBySport,
    getTournaments,
    getInputSearchLine,
    getSearchEventsEntries
  ],
  (lineTournamentsIdsBySport, tournaments, searchText, events) =>
    getLineTournamentsBySearch({
      tournaments: getValues(lineTournamentsIdsBySport, tournaments),
      searchText,
      events
    })
)

export const selectLineTournamentsIsEmpty = createSelector(
  [getTournaments],
  (tournaments) => isEmpty(tournaments)
)

export const selectLineTournamentsFetchItems = createSelector(
  [getLineTournamentsFetchItems],
  (lineTournamentsFetchItems) => lineTournamentsFetchItems
)

export const selectFilterIsTop = createSelector(
  [getFilterIsTop],
  (isTop) => isTop
)

export const selectFilterPeriod = createSelector(
  [getFilterPeriod],
  (period) => period
)

export const selectLayoutIsLoading = createSelector(
  [getLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectEventsLayoutIsLoading = createSelector(
  [getEventsLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectLineIsEmpty = createSelector([getEvents], (events) =>
  isEmpty(events)
)

export const selectLineFetchItems = createSelector(
  [getLineFetchItems],
  (lineFetchItems) => lineFetchItems
)

export const selectLineEvents = createSelector(
  [getLineEventsIdsByTournament, getEvents, getInputSearchLine],
  (eventsIdsByTournament, events, searchText) =>
    getEventsBySearch({
      events: getValues(eventsIdsByTournament, events),
      searchText
    })
)

export const selectLineTournamentsFilters = createSelector(
  [getLineTournamentsFilters],
  (filters) => filters
)

export const selectLineTournamentsLength = createSelector(
  [
    getLineTournamentsIdsBySport,
    getTournaments,
    getInputSearchLine,
    getSearchEventsEntries
  ],
  (lineTournamentsIdsBySport, tournaments, searchText, events) =>
    getLineTournamentsBySearch({
      tournaments: getValues(lineTournamentsIdsBySport, tournaments),
      searchText,
      events
    }).length
)
