import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { columnGapPrefixes } from 'shared/lib/styled'
import { EColorsNames } from 'shared/types/theme'

export const StyledBannerScore = styled.div`
  font-size: 32px;
`

export const StyledBannerComment = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[50]};
    font-size: ${props.theme.fonts.size.xs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    text-align: center;
  `}
`

export const StyledBannerStats = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledBannerStatsContent = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 8px;
  display: inline-flex;
  grid-column-gap: 1px;
  grid-template-columns: repeat(auto-fill, 48px);
  margin: 0 auto;
  padding: 8px;
`
export const StyledBannerStatsItem = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1px;
  padding: 8px;
  position: relative;
  ${StyledIcon} {
    color: ${({ theme }) => theme.colors.default.primary[30]};
  }

  &::after {
    border: 1px solid ${({ theme }) => theme.colors.default.primary[5]};
    bottom: 0;
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`

export const StyledBannerStatsItemText = styled.div`
  color: ${({ theme }) => theme.colors.default.primary[90]};
  line-height: 16px;
  margin-top: 9px;
  white-space: nowrap;
`

export const StyledBannerCompetitors = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  display: grid;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  grid-column-gap: 16px;
  grid-template-columns: 1fr auto 1fr;
  ${() => `
    ${StyledBannerCompetitor}:first-of-type {
      text-align: right;
    }
  
  `}
`

export const StyledBannerCompetitorWrapper = styled.div<{ reverse?: boolean }>`
  display: flex;
  gap: 4px;
  justify-content: ${(p) => (p.reverse ? 'flex-start' : 'flex-end')};
  line-height: 1;
`

export const StyledBannerCompetitor = styled.div.attrs(
  (props: { isLongName?: boolean }) => props
)`
  font-size: ${({ theme, isLongName }) =>
    isLongName ? theme.fonts.size.l : theme.fonts.size.xxl};
`

export const CurrentServerFlag = styled.div<{ $isActive?: boolean }>`
  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.default.green[50] : 'transparent'};
  border-radius: 1px;
  width: 2px;
`

export const StyledBannerContent = styled.div`
  display: grid;
  grid-row-gap: 12px;
  justify-content: center;
`
export const StyledBannerContentHead = styled.div`
  display: flex;
  justify-content: center;
  ${columnGapPrefixes('8px')}
`

export const StyledEventBannerWrapper = styled.div`
  overflow: hidden;
  padding: 0 8px 8px;
`

export const StyledEventBanner = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.theme.colors.custom[EColorsNames.Yellow][1]};
  border-radius: 8px;
  padding: 32px 80px 16px;
`

export const StyledTeamLogo = styled.div`
  height: 64px;
  width: 64px;
`
