import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageCalendarLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageCalendar,
  fallback
})

export { pageCalendarPrefetch } from './page'
