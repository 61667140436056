import React, { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useResizeContainer } from 'astra-core'

import { IconServicesSponsors } from 'shared/ui/Icon/General/IconServicesSponsors'

import {
  StyledSponsorTitle,
  StyledSponsorTitleWrapper,
  StyledSponsorWrapper
} from './Sponsors.styled'
import { SponsorsLowerResolutionItems } from './SponsorsLowerResolution'
import { SponsorsHighResolutionItems } from './SponsorsHighResolution'
import { isShowingSmallBanners } from './constants'

export const Sponsors: FC = () => {
  const [t] = useTranslation()
  const sponsorsWrapperRef = useRef(null)
  const [width] = useResizeContainer(sponsorsWrapperRef)

  return (
    <StyledSponsorWrapper ref={sponsorsWrapperRef}>
      <StyledSponsorTitleWrapper>
        <IconServicesSponsors size={26} />
        <StyledSponsorTitle>{t('services')}</StyledSponsorTitle>
      </StyledSponsorTitleWrapper>

      {isShowingSmallBanners(width) ? (
        <SponsorsLowerResolutionItems />
      ) : (
        <SponsorsHighResolutionItems sponsorsWrapperWidth={width} />
      )}
    </StyledSponsorWrapper>
  )
}
