import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'

import { RootState } from 'shared/types/store'
import { Checkbox } from 'shared/ui/checkbox'

import {
  StyledSettingItem,
  StyledSettingItemTitle
} from '../../Settings.styled'

export const SettingBalance = () => {
  const [t] = useTranslation()
  const isBalanceHidden = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )
  const { onSetUserSetting } = useSetUserSetting()

  const handleDisplayBalance = useCallback(() => {
    onSetUserSetting({
      balanceHidden: !isBalanceHidden
    })
  }, [isBalanceHidden, onSetUserSetting])

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>{t('balance')}</StyledSettingItemTitle>
      <Checkbox
        checked={!!isBalanceHidden}
        label={t('hide balance')}
        onClick={handleDisplayBalance}
      />
    </StyledSettingItem>
  )
}
