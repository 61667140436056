import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { Layout } from 'widgets/Layout'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'

import { LineContainer } from './LineContainer'
import { getFetchLineReqData } from './LineContainer/utils'

export const PageLine = () => (
  <Layout>
    <LineContainer />
  </Layout>
)

export const pageLinePrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLine(
      getFetchLineReqData({ tournamentId: getRouteParamAtIndex(req.params, 2) })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
}
