import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'
import { useParams } from 'react-router-dom'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { useRedirectToSport } from 'hooks/useRedirectToSport'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'

import { liveContainerActions } from './LiveContainer/slice'
import { getFetchLiveReqData } from './LiveContainer/utils'
import { LiveParams } from './LiveContainer/types'
import { LiveContainer } from './LiveContainer'

export const PageLiveEvents = () => {
  const { sportId } = useParams<LiveParams>()
  useRedirectToSport({
    sportId: +sportId,
    currentTabMenu: ESportsMenuTabTypes.LIVE
  })

  return (
    <Layout type={ELayoutsList.SPORT}>
      <LiveContainer />
    </Layout>
  )
}

export const pageLiveEventsPrefetch = ({ store, req }) => {
  store.dispatch(
    liveContainerActions.fetchLive(
      getFetchLiveReqData({ sportId: getRouteParamAtIndex(req.params, 1) })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLiveSportCounters())
}
