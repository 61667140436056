import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutProps } from 'widgets/Layout/Layout.types'
import { Auth } from 'widgets/Auth/Auth'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { Header } from 'widgets/header'
import { ProfileMenu } from 'widgets/profile-menu'

import { StyledContentTitle } from '../Common'

import { StyledContentProfile, StyledLayoutProfile } from './Profile.styled'

export const LayoutProfile: FC<LayoutProps> = memo(
  ({ pageTitle, children }) => {
    const [t] = useTranslation()

    return (
      <StyledLayoutProfile>
        <Auth />
        <Header />

        <ProfileMenu />

        <NotificationsPanel />

        <StyledContentProfile>
          {pageTitle?.text && (
            <StyledContentTitle {...pageTitle}>
              {t(pageTitle.text)}
            </StyledContentTitle>
          )}
          {children}
        </StyledContentProfile>
      </StyledLayoutProfile>
    )
  }
)
