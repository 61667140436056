import { createContext, useContext } from 'react'

import { TOnboardingMaskProps } from './components/OnboardingMask/OnboardingMask.types'
import { EOnboardingContentPositions } from './Onboarding.types'

export type TOnboardingItemContext = Partial<{
  itemNodeRectProps: TOnboardingMaskProps
  positionContent: EOnboardingContentPositions
  onNextOnboardingItem: () => void
  onCloseOnboarding: () => void
  isHaveNext: boolean
}>

export const OnboardingItemContext = createContext<TOnboardingItemContext>({})

export const useOnboardingItemContext = () => {
  return useContext(OnboardingItemContext)
}
