import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import { EMainPageEventsList } from 'pages/page-main/Main/components/MainPageEvents/MainPageEvents.types'
import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'

import {
  Banners,
  MainPageEvents,
  OnboardingNotification,
  Search,
  Sponsors
} from './components'
import {
  StyledEventsSectionWrapper,
  StyledMainSearchBlockWrapper,
  StyledMainWrapper
} from './Main.styled'

interface Props {}

export const Main: FC<Props> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(mainPageContainerActions.fetchBanners())
    dispatch(mainPageContainerActions.fetchTopLiveEvents())
    dispatch(mainPageContainerActions.fetchTopLineEvents())
    dispatch(mainPageContainerActions.fetchUpcomingEvents())

    return () => {
      dispatch(
        rightBasketContainerState.toggleBasketOnSmallWindow({ isShow: false })
      )
    }
  }, [dispatch])

  return (
    <StyledMainWrapper>
      <StyledMainSearchBlockWrapper>
        <Search isMainPage />
        <BasketButtonSmallWindow />
      </StyledMainSearchBlockWrapper>

      <OnboardingNotification />

      <Banners />

      <Sponsors />

      <StyledEventsSectionWrapper>
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.TOP_LIVE} />
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.TOP_LINE} />
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.UPCOMING} />
      </StyledEventsSectionWrapper>
    </StyledMainWrapper>
  )
}
