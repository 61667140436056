import React from 'react'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { Main } from '../Main'

import { reducer, sliceKey } from './slice'
import { mainPageContainerSaga } from './saga'

export function MainPageContainer() {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: mainPageContainerSaga })

  return <Main />
}
