import { createSelector } from '@reduxjs/toolkit'
import { getSports } from 'astra-core/containers/CommonDataProvider'
import { getEvents } from 'astra-core/containers/EventsProvider'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getMainPageContainer = (state: RootState) =>
  state.mainPageContainer ?? initialState

/**
 * Banners
 */

const getBannersState = (state: RootState) =>
  getMainPageContainer(state).banners

const getBannerItems = (state: RootState) => {
  const currentDate = new Date()

  return getBannersState(state).items.filter((item) => {
    if (item.activeFrom) {
      const activeFrom = new Date(item.activeFrom)

      if (activeFrom > currentDate) {
        return false
      }
    }

    if (item.activeTo) {
      const activeTo = new Date(item.activeTo)

      if (activeTo < currentDate) {
        return false
      }
    }

    return true
  })
}

export const selectBannerItems = createSelector(
  [getBannerItems],
  (items) => items
)

export const selectBannersLoading = createSelector(
  [getBannersState],
  (state) => state.fetchItems.loading
)

export const selectBannersHost = createSelector(
  [getBannersState],
  (state) => state.host
)

/**
 * Live
 */

const getTopLiveEventsIdsBySport = (state: RootState) =>
  getMainPageContainer(state).topLiveEvents.eventsIdsBySport

const getTopLiveEventsCurrentSportId = (state: RootState) =>
  state.mainPageContainer.topLiveEvents.currentSportId

const getTopLiveEventsIds = (state: RootState) =>
  getTopLiveEventsIdsBySport(state)[getTopLiveEventsCurrentSportId(state)] ?? []

export const selectTopLiveEvents = createSelector(
  [getTopLiveEventsIds, getEvents],
  (topLiveEventsIds, events) =>
    topLiveEventsIds.map((eventId) => events[eventId])
)

const getTopLiveEventsLoading = (state: RootState) =>
  getMainPageContainer(state).topLiveEvents.fetchItems.loading

export const selectTopLiveEventsLoading = createSelector(
  [getTopLiveEventsLoading],
  (loading) => loading
)

export const selectTopLiveEventsSports = createSelector(
  [getTopLiveEventsIdsBySport, getSports],
  (topLiveEventsIdsBySport, sports) =>
    Object.keys(topLiveEventsIdsBySport)
      .map(Number)
      .map((sId) => sports[sId]!)
      .filter(Boolean)
)

export const selectTopLiveEventCurrentSportId = createSelector(
  [getTopLiveEventsCurrentSportId],
  (sportId) => sportId
)

/**
 * Line
 */

const getTopLineEventsIdsBySport = (state: RootState) =>
  getMainPageContainer(state).topLineEvents.eventsIdsBySport

const getTopLineEventsCurrentSportId = (state: RootState) =>
  state.mainPageContainer.topLineEvents.currentSportId

const getTopLineEventsIds = (state: RootState) =>
  getTopLineEventsIdsBySport(state)[getTopLineEventsCurrentSportId(state)] ?? []

export const selectTopLineEvents = createSelector(
  [getTopLineEventsIds, getEvents],
  (topLineEventsIds, events) =>
    topLineEventsIds.map((eventId) => events[eventId])
)

const getTopLineEventsLoading = (state: RootState) =>
  getMainPageContainer(state).topLineEvents.fetchItems.loading

export const selectTopLineEventsLoading = createSelector(
  [getTopLineEventsLoading],
  (loading) => loading
)

export const selectTopLineEventsSports = createSelector(
  [getTopLineEventsIdsBySport, getSports],
  (topLineEventsIdsBySport, sports) =>
    Object.keys(topLineEventsIdsBySport)
      .map(Number)
      .map((sId) => sports[sId]!)
      .filter(Boolean)
)

export const selectTopLineEventsCurrentSportId = createSelector(
  [getTopLineEventsCurrentSportId],
  (sportId) => sportId
)

/**
 * Upcoming
 */

const getUpcomingEventsIdsBySport = (state: RootState) =>
  getMainPageContainer(state).upcomingEvents.eventsIdsBySport

const getUpcomingEventsCurrentSportId = (state: RootState) =>
  state.mainPageContainer.upcomingEvents.currentSportId

const getUpcomingEventsIds = (state: RootState) =>
  getUpcomingEventsIdsBySport(state)[getUpcomingEventsCurrentSportId(state)] ??
  []

export const selectUpcomingEvents = createSelector(
  [getUpcomingEventsIds, getEvents],
  (upcomingEventsIds, events) =>
    upcomingEventsIds.map((eventId) => events[eventId])
)

const getUpcomingEventsLoading = (state: RootState) =>
  getMainPageContainer(state).upcomingEvents.fetchItems.loading

export const selectUpcomingEventsLoading = createSelector(
  [getUpcomingEventsLoading],
  (loading) => loading
)

export const selectUpcomingEventsSports = createSelector(
  [getUpcomingEventsIdsBySport, getSports],
  (upcomingEventsIdsBySport, sports) =>
    Object.keys(upcomingEventsIdsBySport)
      .map(Number)
      .map((sId) => sports[sId]!)
      .filter(Boolean)
)

export const selectUpcomingEventsCurrentSportId = createSelector(
  [getUpcomingEventsCurrentSportId],
  (sportId) => sportId
)
