import React, { FC, memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { filterRoutes } from 'shared/lib/routes'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { ISideMenu, ISideMenuItem } from './types'
import { StyledSideMenu, StyledSideMenuItem } from './styles'

export const SideMenu: FC<ISideMenu> = memo(({ routeList }) => {
  const [t] = useTranslation()

  const menuItems = useMemo(
    () =>
      filterRoutes({
        routeList,
        t
      }),
    [t, routeList]
  )

  return (
    <StyledSideMenu>
      {menuItems.map((item) => (
        <SideMenuItem key={item.name} route={item} {...item} icon={item.icon} />
      ))}
    </StyledSideMenu>
  )
})

const SideMenuItem: FC<ISideMenuItem> = memo(({ route, icon: Icon = null }) => {
  const { pathname } = useLocation()

  const colorProps =
    pathname === route.path
      ? {
          name: EColorsNames.Primary,
          type: EColorsTypes.DEFAULT,
          value: 90
        }
      : {
          name: EColorsNames.Primary,
          type: EColorsTypes.CUSTOM,
          value: 17
        }

  return (
    <StyledSideMenuItem
      $isActiveItem={pathname === route.path}
      exact={route.exact}
      key={route.name}
      to={route.path}
    >
      {Icon && <Icon colorProps={colorProps} />}

      {route.translatedName}
    </StyledSideMenuItem>
  )
})
