import { createSelector } from '@reduxjs/toolkit'
import {
  getOutcomesIdValues,
  selectBasketOutcomesId
} from 'astra-core/containers/BasketProvider'
import values from 'lodash/values'
import { getMarketById } from 'astra-core/containers/CommonDataProvider'
import { getBetOutcomeId } from 'astra-core'

export const selectMarket = createSelector([getMarketById], (market) => market)

export const selectIsOutcomeInBasket = createSelector(
  [getOutcomesIdValues, selectBasketOutcomesId],
  (outcomeIdValue, basketOutcomesId) => {
    const { eventId, outcomeTypeId, parameters } = outcomeIdValue

    const isEmptyOutcomeIdValue = values(outcomeIdValue).some(
      (value) => value === undefined
    )

    if (isEmptyOutcomeIdValue) {
      return false
    }

    return basketOutcomesId.includes(
      getBetOutcomeId({ eventId, outcomeTypeId, parameters })
    )
  }
)
