import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { formatDate } from 'astra-core'

import { getEventHomeAwayCompetitors, getEventLink } from 'shared/lib/events'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import {
  EEventStatisticsCodes,
  EventStatistics
} from 'widgets/event-statistics'
import { useHandleWidthTeamNames } from 'pages/page-main/Main/components/EventCard/hooks'

import { EventCardProps } from './EventCard.types'
import {
  EventCardButtons,
  EventCardCompetitor,
  EventCardProbabilities
} from './components'
import {
  StyledEventCardWrapper,
  StyledEventCardHeaderWrapper,
  StyledEventCardHeader,
  StyledEventCardMetaWrapper,
  StyledEventCardDate,
  StyledEventCardName,
  AdditionalInfoWrapper
} from './EventCard.styled'

export const EventCard: FC<EventCardProps> = ({ event }) => {
  const history = useHistory()
  const { isLongTeamName, refTeamAway, refTeamHome } = useHandleWidthTeamNames()

  const { homeCompetitors, awayCompetitors } = useMemo(
    () => getEventHomeAwayCompetitors(event),
    [event]
  )
  const eventLink = useMemo(() => getEventLink(event), [event])

  const goToEventPage = useCallback(() => {
    history.push(eventLink)
  }, [eventLink, history])

  const hintAttr = useMemo(
    () => getHintAttrNameFormatted(event.tournament.name),
    [event.tournament.name]
  )

  const currentServer = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.CurrentServer
  )

  return (
    <StyledEventCardWrapper onClick={goToEventPage}>
      <StyledEventCardMetaWrapper>
        <StyledEventCardHeaderWrapper>
          <StyledEventCardHeader {...hintAttr}>
            <StyledEventCardName>{event.tournament.name}</StyledEventCardName>

            <EventCardButtons event={event} />
          </StyledEventCardHeader>

          <EventCardCompetitor
            competitors={homeCompetitors}
            isLongTeamName={isLongTeamName}
            isServer={currentServer?.value === '1'}
            ref={refTeamHome}
          />

          <EventCardCompetitor
            competitors={awayCompetitors}
            isLongTeamName={isLongTeamName}
            isServer={currentServer?.value === '2'}
            ref={refTeamAway}
          />
        </StyledEventCardHeaderWrapper>
      </StyledEventCardMetaWrapper>

      <AdditionalInfoWrapper>
        {event.live ? (
          <EventStatistics event={event} showLiveIcon />
        ) : (
          <StyledEventCardDate>
            {formatDate(event.eventDate)}
          </StyledEventCardDate>
        )}
      </AdditionalInfoWrapper>

      <EventCardProbabilities event={event} />
    </StyledEventCardWrapper>
  )
}
