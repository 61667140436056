import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { RootState } from 'shared/types/store'
import { LiveParams } from 'pages/page-live-events/LiveContainer/types'

import { LINE_HEADER_FILTERS } from './constants'
import { ILineHeaderProps } from './LineHeader.types'
import { StyledLineHeader } from './LineHeader.styled'

export const LineHeader: FC<ILineHeaderProps> = ({ type }) => {
  const { sportId } = useParams<LiveParams>()
  const sport = useSelector((state: RootState) => selectSport(state, +sportId))

  const Icon = useMemo(
    () => (
      <SportIcon sport={sport?.code as ESportsCodes} isActive withBackground />
    ),
    [sport?.code]
  )

  const Filters = LINE_HEADER_FILTERS[type]

  return (
    <StyledLineHeader>
      <TitleLinePage iconComponent={Icon} title={sport?.name}>
        <Filters />
      </TitleLinePage>
    </StyledLineHeader>
  )
}
