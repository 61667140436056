import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageLiveEventsLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageLiveEvents,
  fallback
})

export { pageLiveEventsPrefetch } from './page'
