import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportDiving: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_116012)">
          <path
            d="M11.4053 8.37793C11.4053 7.82564 10.9576 7.37793 10.4053 7.37793H7.97097C7.5724 7.37793 7.21189 7.61461 7.05342 7.98031L3.43359 16.3335L4.01094 16.6669L7.56959 8.34795C7.6483 8.16394 7.82916 8.0446 8.02929 8.0446L10.2386 8.0446C10.5148 8.0446 10.7386 8.26845 10.7386 8.5446V9.09836L8.00198 9.09837L7.54245 10.6488H9.20396V11.3155H7.34486L7.02736 12.3867H9.20396V13.0534H6.82977L6.44204 14.3616H9.20396V15.0282H6.24444L5.81916 16.4631H9.20396V17.1298H5.62157L5.25261 18.3747L11.7556 18.3747V9.9337H17.0041C17.2348 9.9337 17.4218 9.7467 17.4218 9.51602C17.4218 9.28535 17.2348 9.09835 17.0041 9.09835L11.4053 9.09836L11.4053 8.37793Z"
            fill={mainColor}
          />
          <path
            d="M12.8869 13.3271L12.86 13.3473L12.4606 12.8135L12.4976 12.7857C12.6552 12.6674 12.8424 12.5268 13.0739 12.4189C13.3319 12.2986 13.6357 12.2219 14.0271 12.2219C14.4056 12.2219 14.7024 12.2936 14.9556 12.4075C15.1978 12.5165 15.3896 12.6604 15.5513 12.7817L15.5605 12.7886C15.7302 12.9159 15.8691 13.0186 16.0389 13.095C16.2024 13.1686 16.4056 13.2219 16.6938 13.2219C16.9827 13.2219 17.1846 13.1684 17.3406 13.0964C17.5002 13.0227 17.6278 12.9228 17.7685 12.8022C17.7898 12.7839 17.8115 12.765 17.8337 12.7457C18.0991 12.5145 18.4349 12.2219 19.0271 12.2219V12.8886C18.69 12.8886 18.5289 13.0273 18.2552 13.263L18.2024 13.3083C18.0515 13.4377 17.8666 13.5878 17.62 13.7017C17.3697 13.8172 17.0716 13.8886 16.6938 13.8886C16.3154 13.8886 16.0185 13.8169 15.7654 13.703C15.5232 13.594 15.3314 13.4502 15.1697 13.3288L15.1605 13.3219C14.9908 13.1947 14.8519 13.0919 14.682 13.0155C14.5185 12.9419 14.3154 12.8886 14.0271 12.8886C13.7296 12.8886 13.5224 12.9454 13.3555 13.0232C13.1892 13.1007 13.054 13.2019 12.8869 13.3271Z"
            fill={mainColor}
          />
          <path
            d="M12.86 15.5592L12.8869 15.5391C13.054 15.4139 13.1892 15.3126 13.3555 15.2351C13.5224 15.1573 13.7296 15.1005 14.0271 15.1005C14.3154 15.1005 14.5185 15.1538 14.682 15.2274C14.8519 15.3039 14.9908 15.4066 15.1605 15.5339L15.1697 15.5408C15.3314 15.6621 15.5232 15.8059 15.7654 15.9149C16.0185 16.0288 16.3154 16.1005 16.6938 16.1005C17.0716 16.1005 17.3697 16.0291 17.62 15.9136C17.8666 15.7998 18.0515 15.6496 18.2024 15.5203L18.2552 15.4749C18.5289 15.2392 18.69 15.1005 19.0271 15.1005V14.4339C18.4349 14.4339 18.0991 14.7264 17.8337 14.9577C17.8115 14.977 17.7898 14.9959 17.7685 15.0141C17.6278 15.1348 17.5002 15.2346 17.3406 15.3083C17.1846 15.3803 16.9827 15.4339 16.6938 15.4339C16.4056 15.4339 16.2024 15.3805 16.0389 15.307C15.8691 15.2305 15.7302 15.1278 15.5605 15.0005L15.5513 14.9936C15.3896 14.8723 15.1978 14.7285 14.9556 14.6195C14.7024 14.5055 14.4056 14.4339 14.0271 14.4339C13.6357 14.4339 13.3319 14.5106 13.0739 14.6308C12.8424 14.7387 12.6552 14.8793 12.4976 14.9977L12.4606 15.0255L12.86 15.5592Z"
            fill={mainColor}
          />
          <path
            d="M12.86 17.7922L12.8869 17.7721C13.054 17.6469 13.1892 17.5456 13.3555 17.4681C13.5224 17.3903 13.7296 17.3335 14.0271 17.3335C14.3154 17.3335 14.5185 17.3868 14.682 17.4604C14.8519 17.5369 14.9908 17.6396 15.1605 17.7669L15.1697 17.7738C15.3314 17.8951 15.5232 18.0389 15.7654 18.1479C16.0185 18.2618 16.3154 18.3335 16.6938 18.3335C17.0716 18.3335 17.3697 18.2621 17.62 18.1466C17.8666 18.0328 18.0515 17.8826 18.2024 17.7533L18.2552 17.7079C18.5289 17.4722 18.69 17.3335 19.0271 17.3335V16.6669C18.4349 16.6669 18.0991 16.9594 17.8337 17.1907C17.8115 17.21 17.7898 17.2289 17.7685 17.2471C17.6278 17.3678 17.5002 17.4676 17.3406 17.5413C17.1846 17.6133 16.9827 17.6669 16.6938 17.6669C16.4056 17.6669 16.2024 17.6136 16.0389 17.54C15.8691 17.4635 15.7302 17.3608 15.5605 17.2335L15.5513 17.2266C15.3896 17.1053 15.1978 16.9615 14.9556 16.8525C14.7024 16.7386 14.4056 16.6669 14.0271 16.6669C13.6357 16.6669 13.3319 16.7436 13.0739 16.8639C12.8424 16.9717 12.6552 17.1123 12.4976 17.2307L12.4606 17.2585L12.86 17.7922Z"
            fill={mainColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_116012">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
