import styled, { css } from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

export const StyledScoreByPeriods = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledScoreByPeriod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

interface StyledScoreByPeriodsItemProps {
  isFirst?: boolean
  isLast?: boolean
}

export const StyledScoreByPeriodsItem = styled.div<StyledScoreByPeriodsItemProps>`
  line-height: 16px;
  padding: 2px 8px;

  ${(props) => css`
    background-color: ${props.theme.colors.custom[EColorsNames.Yellow][6]};
    color: ${props.theme.colors.custom[EColorsNames.Primary][17]};
    font-size: ${props.theme.fonts.size.xs};

    ${props.isFirst &&
    css`
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    `}

    ${props.isLast &&
    css`
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    `}
  `}
`

export const StyledScoreByPeriodsDivider = styled.div`
  background-color: ${(props) =>
    props.theme.colors.custom[EColorsNames.Yellow][6]};
  position: relative;
  width: 1px;

  &::after {
    background-color: ${(props) =>
      props.theme.colors.custom[EColorsNames.Primary][41]};
    bottom: 5px;
    content: '';
    position: absolute;
    top: 5px;
    width: 1px;
  }
`
