import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLineFilled: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6C0 3.79086 1.79086 2 4 2H20C22.2091 2 24 3.79086 24 6V18C24 20.2091 22.2091 22 20 22H4C1.79086 22 0 20.2091 0 18V6Z"
        fill="#40BF40"
      />
      <path
        clipRule="evenodd"
        d="M4 8C4 7.44772 4.44772 7 5 7H17C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H5C4.44772 9 4 8.55228 4 8ZM4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12ZM5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17H12C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15H5Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
