import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBowling: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M16.8465 11.0316C16.8059 10.9683 16.7648 10.9055 16.7234 10.8432C16.7238 10.8267 16.7239 10.8101 16.724 10.7934C16.724 10.6873 16.7216 10.5897 16.717 10.4987H18.1333C18.1286 10.5897 18.1262 10.6873 18.1263 10.7934C18.1264 10.8756 18.1304 10.9549 18.1381 11.0316H16.8465Z"
          fill={mainColor}
        />
        <path
          d="M16.4817 19.0983C16.9251 18.1207 17.4912 16.6994 17.6258 15.5615C17.743 14.5698 17.7491 13.9953 17.6258 13.0044C17.5676 12.5368 17.4338 12.133 17.26 11.7649H18.3359C18.4678 12.0618 18.647 12.3263 18.8281 12.5939C19.1474 13.0654 19.473 13.5463 19.5581 14.2299C19.641 14.8958 19.6369 15.2818 19.5581 15.9482C19.4045 17.2481 18.4127 19.0983 18.4127 19.0983H16.4817Z"
          fill={mainColor}
        />
        <path
          d="M16.5523 9.47922C16.5755 9.5778 16.5976 9.67158 16.6177 9.76535H18.2325C18.2526 9.67157 18.2747 9.57782 18.2979 9.47923C18.3536 9.24291 18.4158 8.97878 18.4733 8.61985C18.5371 8.04768 18.4169 6.90389 17.4266 6.90161L17.4251 6.90161H17.4236C16.4333 6.90388 16.3131 8.04768 16.3769 8.61985C16.4344 8.97878 16.4966 9.24291 16.5523 9.47922Z"
          fill={mainColor}
        />
        <path
          clipRule="evenodd"
          d="M8.04948 19.4316C10.0745 19.4316 11.7161 17.79 11.7161 15.7649C11.7161 13.7399 10.0745 12.0983 8.04948 12.0983C6.02443 12.0983 4.38281 13.7399 4.38281 15.7649C4.38281 17.79 6.02443 19.4316 8.04948 19.4316ZM8.38281 16.0983C8.751 16.0983 9.04948 15.7998 9.04948 15.4316C9.04948 15.0634 8.751 14.7649 8.38281 14.7649C8.01462 14.7649 7.71615 15.0634 7.71615 15.4316C7.71615 15.7998 8.01462 16.0983 8.38281 16.0983ZM7.04948 14.7649C7.04948 15.1331 6.751 15.4316 6.38281 15.4316C6.01462 15.4316 5.71615 15.1331 5.71615 14.7649C5.71615 14.3967 6.01462 14.0983 6.38281 14.0983C6.751 14.0983 7.04948 14.3967 7.04948 14.7649ZM7.71615 14.0983C8.08434 14.0983 8.38281 13.7998 8.38281 13.4316C8.38281 13.0634 8.08434 12.7649 7.71615 12.7649C7.34796 12.7649 7.04948 13.0634 7.04948 13.4316C7.04948 13.7998 7.34796 14.0983 7.71615 14.0983Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          d="M11.2928 12.8911C11.3574 12.4711 11.4833 12.1029 11.6428 11.7649H10.5143C10.4644 11.8773 10.4077 11.985 10.3467 12.09C10.6994 12.3109 11.018 12.5812 11.2928 12.8911Z"
          fill={mainColor}
        />
        <path
          d="M12.0564 11.0316C12.0806 10.9938 12.105 10.9562 12.1295 10.9188C12.1274 10.8778 12.1263 10.836 12.1263 10.7934C12.1262 10.6873 12.1286 10.5897 12.1333 10.4987H10.7169C10.7216 10.5897 10.724 10.6873 10.724 10.7934C10.7239 10.8756 10.7198 10.9549 10.7122 11.0316H12.0564Z"
          fill={mainColor}
        />
        <path
          d="M10.5523 9.47922C10.4966 9.2429 10.4344 8.97878 10.3769 8.61985C10.3131 8.04768 10.4333 6.90389 11.4236 6.90161L11.4251 6.90161H11.4266C12.4169 6.90388 12.5371 8.04768 12.4733 8.61985C12.4158 8.97878 12.3536 9.24291 12.2979 9.47922C12.2747 9.57782 12.2526 9.67157 12.2325 9.76535H10.6177C10.5976 9.67159 10.5755 9.5778 10.5523 9.47922Z"
          fill={mainColor}
        />
        <path
          d="M13.1232 6.44903C13.1961 6.9039 13.2749 7.23863 13.3455 7.53812C13.3749 7.66307 13.4029 7.78188 13.4284 7.90073H15.4747C15.5002 7.78191 15.5282 7.66313 15.5576 7.53822C15.6281 7.23873 15.707 6.9039 15.7799 6.44903C15.8608 5.72392 15.7085 4.27445 14.4535 4.27149L14.4516 4.27148H14.4496C13.1946 4.27444 13.0424 5.72392 13.1232 6.44903Z"
          fill={mainColor}
        />
        <path
          d="M13.5461 8.70073C13.5572 8.85215 13.5632 9.01748 13.563 9.20362C13.563 9.27463 13.5605 9.34392 13.5559 9.41162H15.3472C15.3426 9.34392 15.3402 9.27463 15.3401 9.20362C15.3399 9.01748 15.3459 8.85215 15.357 8.70073H13.5461Z"
          fill={mainColor}
        />
        <path
          d="M12.6735 11.4853C12.9476 11.0807 13.2179 10.6815 13.3873 10.2116H15.5158C15.6852 10.6815 15.9555 11.0807 16.2296 11.4853C16.6342 12.0829 17.0469 12.6923 17.1547 13.5587C17.2598 14.4026 17.2546 14.8917 17.1547 15.7363C16.9599 17.3837 15.703 19.7284 15.703 19.7284H13.2001C13.2001 19.7284 12.5593 18.533 12.1218 17.2495C12.2907 16.7864 12.3828 16.2864 12.3828 15.7649C12.3828 14.9413 12.153 14.1713 11.754 13.5156C11.87 12.672 12.2757 12.0729 12.6735 11.4853Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
