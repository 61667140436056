import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { BANNER_HIGHT } from './constants'
import { ISponsorsItemProps } from './SponsorsLowerResolutionItems.types'

export const StyledSponsorsItemsWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const SponsorsItemWrapper = styled(Link)`
  border-radius: 8px;
  height: ${BANNER_HIGHT};
  max-width: 302px;
  min-width: 10px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &:hover #embeddedLowerSVG {
    transform: scale(1.15);
    transition: all 0.4s;
  }
`

export const SponsorsItemBG = styled.div`
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`

export const SponsorsItemLogo = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 15px;
  width: 100%;
  z-index: 10;
`

export const SponsorsItemAdditionalImage = styled.div`
  left: 0;
  position: absolute;
  top: -5px;
  z-index: 9;
`

export const SponsorsItemImage = styled.div<{
  itemStyles: ISponsorsItemProps['itemStyles']
}>`
  position: absolute;
  right: 0;
  ${({ itemStyles }) =>
    css`
      bottom: ${itemStyles?.bottomPosition ? itemStyles?.bottomPosition : 0};
    `}
  z-index: 9;
`
