import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSleigh: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M17.1796 7.8938H15.6082C15.1757 7.8938 14.8239 8.31931 14.8239 8.75251C14.8239 9.18571 15.1757 9.61123 15.6082 9.61123H17.1796C17.8707 9.61123 18.4318 10.1478 18.4318 10.8389C18.4318 10.9517 18.416 11.0617 18.386 11.1652C18.7727 11.663 19.0292 12.2681 19.1029 12.9273C19.1102 12.9205 19.1175 12.9137 19.1249 12.907L19.1249 12.907C19.1408 12.8925 19.1567 12.8781 19.1717 12.8623C19.6982 12.3369 20.0004 11.5205 20.0004 10.7753C20.0004 9.22065 18.735 7.8938 17.1796 7.8938ZM15.6116 10.5052H14.0401C13.6069 10.5052 13.2555 10.8521 13.2555 11.285C13.2555 11.7182 13.6069 12.0651 14.0401 12.0651H15.6116C16.3019 12.0651 16.8633 12.6293 16.8633 13.32C16.8633 13.6512 16.7288 13.9726 16.4947 14.2063C16.2575 14.4432 15.9424 14.5714 15.6081 14.5714H15.606H4.86412H4.86307C4.43056 14.5714 4.07945 14.9183 4.0791 15.3515C4.0784 15.784 4.42916 16.1319 4.86202 16.1323L15.6042 16.1484H15.6084C16.362 16.1484 17.0708 15.8535 17.6036 15.3214C18.1305 14.796 18.432 14.0693 18.432 13.3249C18.4316 11.7692 17.1666 10.5052 15.6116 10.5052ZM15.8971 13.7644L4.78386 13.713C4.50857 13.713 4.25318 13.5722 4.11169 13.3354C3.96985 13.0992 3.96286 12.8099 4.09213 12.5668L5.76557 9.42993C5.90147 9.1742 6.16769 9.01489 6.45696 9.01489H12.5984C12.8671 9.01489 13.1158 9.15149 13.2597 9.37752C13.3733 9.55534 13.4082 9.7688 13.3628 9.97003C13.3506 10.0249 13.3321 10.0783 13.308 10.1297L12.6666 10.6879L12.6026 11.6368L12.7745 12.0473C13.3333 12.6879 13.504 12.7659 14.0402 12.7659H15.6113C15.9194 12.7659 16.1703 13.0147 16.1703 13.3242C16.1703 13.4702 16.1088 13.6162 16.0054 13.7193C15.9771 13.748 15.9376 13.7644 15.8971 13.7644Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
