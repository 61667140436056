import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectAccountBonus } from 'astra-core/containers/AccountProvider'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { ETabTypes } from 'astra-core/containers/BalanceProvider'
import { useHistory } from 'react-router-dom'
import { formatAmountLocaleString } from 'astra-core'
import { ETestIds } from 'astra-core/utils/testIds'

import { RootState } from 'shared/types/store'
import { ERoutes } from 'shared/types/routes'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'

import { IUserAccountBalanceProps } from './user-account-bonus.types'
import { StyledUserAccountBonus } from './user-account-bonus.styles'

export const UserAccountBonus: FC<IUserAccountBalanceProps> = ({
  formatPrice,
  ...props
}) => {
  const bonus = useSelector(selectAccountBonus)!
  const history = useHistory()

  const isBalanceHidden = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  const handleRedirectList = useCallback(
    () => history.push(`${ERoutes.ProfileBalance}${ETabTypes.Bonus}`),
    [history]
  )

  if (isBalanceHidden) {
    return null
  }

  return (
    <StyledUserAccountBonus
      id={ETestIds.TestMainHeaderUserAccountBonus}
      onClick={handleRedirectList}
      {...props}
    >
      {formatAmountLocaleString({
        placeholder: '...',
        ...formatPrice,
        value: bonus
      })}
      <IconBonus />
    </StyledUserAccountBonus>
  )
}
