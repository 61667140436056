import React, { FC, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'

import { useInterval } from 'hooks'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { useIsTablet } from 'shared/lib/styled'

import { OutcomesCategories } from './Table'
import {
  DESKTOP_COLUMNS_COUNT,
  EVENT_OUTCOMES_POLLING_INTERVAL,
  TABLET_COLUMNS_COUNT
} from './constants'
import { OutcomesComponentType, OutcomesProps } from './Outcomes.types'
import {
  StyledOutcomes,
  StyledOutcomesLoaderWrap,
  StyledOutcomesTables
} from './Outcomes.styled'

export const Outcomes: FC<OutcomesProps> = memo(
  ({ eventId, type, isLoading = false, outcomesRef }) => {
    const dispatch = useDispatch()

    const fetchEventProbabilities = useCallback(() => {
      dispatch(eventsProviderActions.fetchEventProbabilities({ eventId }))
    }, [dispatch, eventId])

    /*
  Add polling event outcomes only for Events List,
  since polling full event data is already enabled on Event Page
   */
    useInterval(
      fetchEventProbabilities,
      type === OutcomesComponentType.EVENTS_LIST
        ? EVENT_OUTCOMES_POLLING_INTERVAL
        : null,
      true
    )

    const isTablet = useIsTablet()

    return (
      <StyledOutcomes>
        {isLoading ? (
          <StyledOutcomesLoaderWrap>
            <LoaderPoints size={6} />
          </StyledOutcomesLoaderWrap>
        ) : (
          <StyledOutcomesTables>
            <OutcomesCategories
              columnsCount={
                isTablet ? TABLET_COLUMNS_COUNT : DESKTOP_COLUMNS_COUNT
              }
              eventId={eventId}
              ref={outcomesRef}
            />
          </StyledOutcomesTables>
        )}
      </StyledOutcomes>
    )
  }
)
