import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useHashParam = (name: string) => {
  const location = useLocation()

  const params = useMemo(() => {
    return location.hash
      .split('#')
      .map((item) => item.split('='))
      .reduce((acc, [name, value]) => {
        acc[name] = value

        return acc
      }, {})
  }, [location.hash])

  const exists = Object.hasOwn(params, name)
  const value = params[name]

  return { exists, value }
}
