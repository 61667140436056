import styled from 'styled-components'

import { MARQUEE_COLORS } from './constants'

export const StyledTitleString = styled.div<{
  marqueeWidth?: number
  idKey: number | string
}>`
  flex: 1;
  font-size: inherit;
  overflow: hidden;

  &::after {
    ${({ theme }) => MARQUEE_COLORS[theme.typeLayoutTheme]}
    content: '';
    display: ${(props) => (props.marqueeWidth ? 'block' : 'none')};
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
  }

  > span {
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-name: ${(props) =>
      props.marqueeWidth ? `marquee-${props.idKey}` : ''};
    animation-timing-function: linear;
    display: inline-block;
    white-space: nowrap;
  }

  @keyframes marquee-${(props) => props.idKey} {
    0% {
      transform: translateX(0);
    }

    40% {
      transform: translateX(0);
    }

    90% {
      transform: translateX(-${(props) => props.marqueeWidth}px);
    }

    99% {
      transform: translateX(-${(props) => props.marqueeWidth}px);
    }

    100% {
      transform: translateX(0);
    }
  }
`
