import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import { useInterval, usePrevious } from 'hooks'

import {
  selectFilterIsTop,
  selectFilterPeriod,
  selectLayoutIsLoading
} from './selectors'
import {
  getFetchLineTournamentsReqData,
  LINE_TOURNAMENTS_POLLING_INTERVAL
} from './utils'
import { LineTournamentsParams } from './types'
import { lineTournamentsContainerActions } from './slice'

export const useLineTournamentsContainerData = () => {
  const dispatch = useDispatch()
  const { sportId } = useParams<LineTournamentsParams>()
  const sportIdPrev = usePrevious(sportId)
  const top = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(selectFilterPeriod)
  const isLayoutLoading = useSelector(selectLayoutIsLoading)

  const filters = useMemo(() => ({ top, filterPeriod }), [filterPeriod, top])
  const filtersPrev = usePrevious(filters)

  const fetchLineTournaments = useCallback(() => {
    if (sportId) {
      dispatch(
        lineTournamentsContainerActions.fetchLineTournaments(
          getFetchLineTournamentsReqData({ sportId: +sportId })
        )
      )
    }
  }, [dispatch, sportId])

  useEffect(() => {
    /* To handle fetch on changed filters */

    if (!isEqual(filtersPrev, filters)) {
      fetchLineTournaments()
    }
  }, [fetchLineTournaments, filters, filtersPrev])

  useEffect(() => {
    /* To handle fetch on sport id change */

    if (!!sportIdPrev && sportIdPrev !== sportId) {
      fetchLineTournaments()
    }
  }, [sportIdPrev, sportId, fetchLineTournaments])

  const intervalDelay = isLayoutLoading
    ? null
    : LINE_TOURNAMENTS_POLLING_INTERVAL

  useInterval(fetchLineTournaments, intervalDelay, true)
}
