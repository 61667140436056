import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportKabaddi: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M11.9993 7.3335H4.66602V9.00016M11.9993 7.3335H19.3327V9.00016M11.9993 7.3335V16.6668M11.9993 16.6668H19.3327V15.0002M11.9993 16.6668H4.66602V15.0002M4.66602 9.00016V15.0002M4.66602 9.00016L7 9M19.3327 9.00016V15.0002M19.3327 9.00016H17M19.3327 15.0002H17M4.66602 15.0002L7 15M9 9.00016V15.0002M9 9.00016L7 9M9 9.00016H15M9 15.0002L7 15M9 15.0002H15M7 9V15M17 9.00016V15.0002M17 9.00016H15M17 15.0002H15M15 9.00016V15.0002"
        stroke={mainColor}
        strokeLinecap="round"
        strokeWidth="1.3"
      />
    )}
  </SportIconWrapper>
)
