import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectTypeAuthModal } from 'astra-core/containers/AccountProvider'

import { UnauthorizedModal } from './UnauthorizedModal'
import { SuccessSignUpComponents } from './components/SuccessPages/components'

export const Auth: FC = () => {
  const typeAuthModal = useSelector(selectTypeAuthModal)

  return (
    <>
      {typeAuthModal ? <UnauthorizedModal /> : null}
      <SuccessSignUpComponents />
    </>
  )
}
