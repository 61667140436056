import React, { FC, useContext, useMemo } from 'react'
import { Competitor } from 'betweb-openapi-axios'
import {
  selectSport,
  ESportsCodes
} from 'astra-core/containers/CommonDataProvider'
import { useSelector } from 'react-redux'

import { RootState } from 'shared/types/store'
import { IconLive } from 'shared/ui/Icon/General/IconLive'
import { EColorsNames } from 'shared/types/theme'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { EventDateTime } from 'pages/page-live-events/Line/components/Event/components/EventBanner/components/EventBannerMini/components/EventDateTime'

import { useEventBannerData } from '../../hooks'

import { ActionsList } from './components'
import {
  CurrentServerFlag,
  StyledBannerMiniCompetitorWrapper,
  StyledEventBannerMini,
  StyledEventBannerMiniCompetitor,
  StyledEventBannerMiniCompetitors,
  StyledEventBannerMiniLiveWrapper,
  StyledEventBannerScore,
  StyledEventBannerScoreByPeriods,
  StyledEventBannerWhiteSpace,
  StyledScoresWrapper
} from './EventBannerMini.styled'

export const EventBannerMini: FC = () => {
  const { event } = useContext(EventContext) as IEventContext
  const { competitors, currentServer, bannerScore } = useEventBannerData()
  const sport = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const isLive = useMemo(() => event.live, [event.live])

  const scoresByPeriods = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.ScoresByPeriods
  )

  const gameScore = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.GameScore
  )

  const gameScoreValue = useMemo(() => {
    if (!gameScore?.value) {
      return null
    }
    let formattedGameScoreValue = gameScore?.value
    if (sport?.code === ESportsCodes.TENNIS) {
      formattedGameScoreValue = formattedGameScoreValue.replace(/50/g, 'A')
    }
    return formattedGameScoreValue
  }, [gameScore?.value, sport?.code])

  return (
    <StyledEventBannerMini>
      {isLive && (
        <StyledEventBannerMiniLiveWrapper>
          <IconLive
            colorProps={{ name: EColorsNames.Primary, value: 0 }}
            size={18}
          />
        </StyledEventBannerMiniLiveWrapper>
      )}

      <EventCompetitorsMiniBanner
        competitors={competitors}
        currentServer={currentServer}
      />

      <StyledEventBannerWhiteSpace>
        <StyledScoresWrapper>
          <StyledEventBannerScore>{bannerScore}</StyledEventBannerScore>
          <StyledEventBannerScoreByPeriods>
            {scoresByPeriods?.value}
            {!!gameScoreValue && ` (${gameScoreValue})`}
          </StyledEventBannerScoreByPeriods>
        </StyledScoresWrapper>
        <EventDateTime />
      </StyledEventBannerWhiteSpace>

      <ActionsList />
    </StyledEventBannerMini>
  )
}

const EventCompetitorsMiniBanner: FC<{
  competitors: {
    homeCompetitors: Competitor[]
    awayCompetitors: Competitor[]
  }
  currentServer?: any
}> = ({ competitors, currentServer }) => {
  const hasCurrentServer = !!currentServer?.value

  return (
    <StyledEventBannerMiniCompetitors>
      <StyledBannerMiniCompetitorWrapper>
        {hasCurrentServer && (
          <CurrentServerFlag $isActive={currentServer.value === '1'} />
        )}
        <StyledEventBannerMiniCompetitor>
          {competitors.homeCompetitors[0]?.name}
        </StyledEventBannerMiniCompetitor>
      </StyledBannerMiniCompetitorWrapper>
      {' - '}
      <StyledBannerMiniCompetitorWrapper>
        <StyledEventBannerMiniCompetitor>
          {competitors.awayCompetitors[0]?.name}
        </StyledEventBannerMiniCompetitor>
        {hasCurrentServer && (
          <CurrentServerFlag $isActive={currentServer.value === '2'} />
        )}
      </StyledBannerMiniCompetitorWrapper>
    </StyledEventBannerMiniCompetitors>
  )
}
