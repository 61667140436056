import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { ADVERT_INFO_COOKIE } from 'constants/cookies.constants'

import {
  StyledSuccessPageWrapper,
  StyledSuccessPage,
  StyledSuccessPageTitle,
  StyledSuccessPageSubtitle
} from '../../SuccessPage.styled'

import { ISuccessAuthComponentsProps } from './types'

export const SuccessAuthComponents = ({
  text
}: ISuccessAuthComponentsProps) => {
  const [t] = useTranslation()

  useEffect(() => {
    Cookies.remove(ADVERT_INFO_COOKIE)
  }, [])

  return (
    <StyledSuccessPageWrapper>
      <StyledSuccessPage>
        <IconSuccess size={40} />

        <StyledSuccessPageTitle>{t(text)}</StyledSuccessPageTitle>

        <StyledSuccessPageSubtitle>
          {t('place your bet')}
        </StyledSuccessPageSubtitle>
      </StyledSuccessPage>
    </StyledSuccessPageWrapper>
  )
}
