import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageLineLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageLine,
  fallback
})

export { pageLinePrefetch } from './page'
