import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  accountProviderActions,
  TTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import { EAuthPagesAndModal } from 'astra-core/constants/auth'
import {
  useGetPostAuthServiceMessage,
  EPostMessageIdEvent
} from 'astra-core/containers/AuthMessageProvider'
import { ETestIds } from 'astra-core/utils/testIds'

import { EButtonSizes, EButtonViews } from 'shared/ui/Button'
import { useHashParam } from 'hooks/useHashParams'

import {
  StyledButton,
  StyledComponentOfUnauthorized,
  StyledSignUp
} from './ComponentOfUnauthorized.styled'

export const ComponentOfUnauthorized = memo(() => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const openAuthModal = useCallback(
    (types: TTypeAuthModal) => () =>
      dispatch(accountProviderActions.setTypeAuthModal(types)),
    [dispatch]
  )

  const { exists: regmeParamExists } = useHashParam('regme')

  useEffect(() => {
    if (regmeParamExists) {
      openAuthModal(EAuthPagesAndModal.SignIn)()
    }
  }, [openAuthModal, regmeParamExists])

  useGetPostAuthServiceMessage(
    EPostMessageIdEvent.open,
    openAuthModal(EAuthPagesAndModal.SignIn)
  )

  useGetPostAuthServiceMessage(
    EPostMessageIdEvent.signUp,
    openAuthModal(EAuthPagesAndModal.FastSignUp)
  )

  useGetPostAuthServiceMessage(
    EPostMessageIdEvent.fullSignUp,
    openAuthModal(EAuthPagesAndModal.FullSignUp)
  )

  return (
    <StyledComponentOfUnauthorized>
      <StyledSignUp
        id={ETestIds.TestButtonSignUp}
        size={EButtonSizes.S}
        view={EButtonViews.SECONDARY}
        onClick={openAuthModal(EAuthPagesAndModal.FastSignUp)}
      >
        {t('sign up')}
      </StyledSignUp>
      <StyledButton
        id={ETestIds.TestButtonSignIn}
        size={EButtonSizes.S}
        onClick={openAuthModal(EAuthPagesAndModal.SignIn)}
      >
        {t('log in')}
      </StyledButton>
    </StyledComponentOfUnauthorized>
  )
})
