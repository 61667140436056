import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  createRef,
  PropsWithChildren,
  RefObject
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import * as Templates from 'astrabet-templates-kit'
import { basketProviderActions } from 'astra-core/containers/BasketProvider'
import { getEventHomeAwayCompetitors } from 'astra-core'
import {
  selectEventProbabilities,
  selectEventProbabilitiesByMarket
} from 'astra-core/containers/EventsProvider'
import {
  selectMarketCategoriesByEvent,
  selectEventTradedMarketsIds
} from 'astra-core/containers/CommonDataProvider'
import {
  CategoryMarketInfo,
  EventProbabilityTradingStatus,
  MarketCategory
} from 'betweb-openapi-axios'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { useSearchReplace } from 'astra-core/containers/SearchProvider'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { useOutcomes } from 'hooks'
import { useEventMode } from 'hooks/events'
import { isParameterValueMatching } from 'shared/lib/outcomes'
import { RootState } from 'shared/types/store'
import { ProbabilityWithOutcome } from 'shared/lib/outcomes/types'
import { selectOutcomesGroup } from 'containers/OutcomesContainer/selectors'
import { Masonry } from 'shared/ui/Masonry'
import { selectEvent } from 'widgets/EventContainer/selectors'

import {
  StyledEventOdd,
  StyledOddWrapper
} from '../ui/TableBets/TableBets.styled'
import { selectMarket } from '../../selectors'
import { ClearResultSearch } from '../ui'

import {
  OutcomesCategoriesProps,
  OutcomesCategoryProps,
  OutcomesTableProps,
  PanelProps,
  OutcomeOddProps,
  OutcomeCategoriesHandle,
  PanelHandle,
  MemoizedMasonryProps
} from './Outcomes.types'
import {
  StyledClearResultSearch,
  StyledFavoriteButton,
  StyledPanel,
  StyledPanelHead,
  StyledPanelTitle
} from './Outcomes.styled'
import { OddsTable } from './OddsTable'

const TABLES_GAP = 8
const MERGED_TEMPLATES = ['G2Template']
const TEMPLATES_WITH_PLUS_SIGN = ['H2Template', 'LH2Template']
const TEMPLATES_WITH_PARENTHESES = [
  'G2Template',
  'LG2Template',
  'H2Template',
  'LH2Template'
]

export const OutcomesCategories = forwardRef<
  OutcomeCategoriesHandle,
  OutcomesCategoriesProps
>(({ eventId, ...props }, forwardedRef) => {
  const { valueSearch, regExp, searchRegExpReplace } = useSearchReplace({
    eventId
  })

  const selectedMarketGroup = useSelector(selectOutcomesGroup)
  const marketCategories = useSelector((state: RootState) =>
    selectMarketCategoriesByEvent(state, eventId)
  )

  const probabilities = useSelector((state: RootState) =>
    selectEventProbabilities(state, eventId)
  )

  const replaceText = useCallback(
    (text: string) => (text ? text.replaceAll(/{\$.*}/g, '') : text),
    []
  )

  const filteredSearchMarket = useCallback(
    (markets: Array<CategoryMarketInfo>) => {
      const filteredMarkets: Array<CategoryMarketInfo> = []
      markets.forEach((market) => {
        if (regExp.test(replaceText(market?.title))) {
          filteredMarkets.push({
            ...market,
            title: searchRegExpReplace(market.title)
          })
        }
        return null
      })
      return filteredMarkets
    },
    [regExp, replaceText, searchRegExpReplace]
  )

  const filteredSearchCategories = useCallback(
    (categories: Array<MarketCategory>) => {
      const filteredCategories: Array<MarketCategory> = []
      categories.forEach((category) => {
        if (regExp.test(replaceText(category?.name))) {
          filteredCategories.push({
            ...category,
            name: searchRegExpReplace(category.name)
          })
        } else {
          const newOrderedMarkets = filteredSearchMarket(
            category.orderedMarkets
          )
          if (newOrderedMarkets.length)
            filteredCategories.push({
              ...category,
              orderedMarkets: newOrderedMarkets
            })
        }
        return category
      })
      return filteredCategories
    },
    [filteredSearchMarket, regExp, replaceText, searchRegExpReplace]
  )

  const isMarketCategoryNotEmpty = useCallback(
    (category: MarketCategory) =>
      probabilities?.markets
        .filter((market) =>
          category.orderedMarkets.some(
            (orderedMarket) =>
              orderedMarket.marketId === market.marketId &&
              market.probabilities.some((probability) =>
                orderedMarket.parameters.every((parameter) =>
                  probability.parameters.find((pParameter) => {
                    return isParameterValueMatching(pParameter, parameter)
                  })
                )
              )
          )
        )
        .some((market) =>
          market?.probabilities.some(
            (probability) =>
              probability.tradingStatus ===
              EventProbabilityTradingStatus.Trading
          )
        ),
    [probabilities?.markets]
  )

  const isMarketCategoryInGroup = useCallback(
    (category: MarketCategory) => {
      if (selectedMarketGroup === null) {
        return true
      }
      return selectedMarketGroup.categories.some(
        (categoryInGroup) => categoryInGroup.marketCategoryId === category.id
      )
    },
    [selectedMarketGroup]
  )

  const pruneGroupCategoryMarkets = useCallback(
    (category: MarketCategory) => {
      const groupCategory = selectedMarketGroup?.categories.find(
        (c) => c.marketCategoryId === category.id
      )

      if (!groupCategory) {
        return category
      }

      return {
        ...category,
        orderedMarkets: category.orderedMarkets.filter((orderedMarket) =>
          groupCategory.categoryMarketIds.includes(orderedMarket.id)
        )
      }
    },
    [selectedMarketGroup?.categories]
  )

  const filteredMarketCategories = useMemo(() => {
    if (!selectedMarketGroup) {
      return marketCategories.filter(isMarketCategoryNotEmpty)
    }
    return marketCategories
      .filter(isMarketCategoryNotEmpty)
      .filter(isMarketCategoryInGroup)
      .map(pruneGroupCategoryMarkets)
  }, [
    marketCategories,
    selectedMarketGroup,
    isMarketCategoryInGroup,
    isMarketCategoryNotEmpty,
    pruneGroupCategoryMarkets
  ])

  const filteredSearchMarketCategories = useMemo(
    () => filteredSearchCategories(filteredMarketCategories),
    [filteredMarketCategories, filteredSearchCategories]
  )

  if (valueSearch.length && !filteredSearchMarketCategories.length)
    return (
      <StyledClearResultSearch>
        <ClearResultSearch />
      </StyledClearResultSearch>
    )

  return (
    <MemoizedMasonry
      eventId={eventId}
      marketCategories={filteredSearchMarketCategories}
      ref={forwardedRef}
      {...props}
    />
  )
})

/**
 * This was necessary to prevent rerendering of child components
 * Closed panels used to open on each probability update
 * Memoizing array of filtered marketCategories, without reacting to probabilities update,
 * isolates changes inside each table, not the whole Masonry layout.
 * Filtering marketCategories was impossible without looking up into
 * event probabilities
 */
const MemoizedMasonry = React.memo(
  forwardRef<OutcomeCategoriesHandle, MemoizedMasonryProps>(
    ({ marketCategories, eventId, columnsCount = 2 }, forwardedRef) => {
      const [panelRefs, setPanelRefs] = useState<
        Record<string, RefObject<React.ElementRef<typeof OutcomesCategory>>>
      >({})

      useEffect(() => {
        setPanelRefs((elRefs) =>
          mapValues(
            keyBy(marketCategories, 'id'),
            (value) => elRefs[value.id] ?? createRef()
          )
        )
      }, [marketCategories])

      useImperativeHandle(
        forwardedRef,
        () => ({
          openAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.open()
            })
          },
          closeAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.close()
            })
          }
        }),
        [panelRefs]
      )

      return (
        <Masonry
          render={({ data: marketCategory }) => (
            <OutcomesCategory
              category={marketCategory}
              eventId={eventId}
              ref={panelRefs[marketCategory.id]}
            />
          )}
          columnCount={columnsCount}
          columnGutter={TABLES_GAP}
          itemKey={(marketCategory) => marketCategory.id}
          items={marketCategories}
          overscanBy={Infinity}
          rowGutter={TABLES_GAP}
          tabIndex={-1}
        />
      )
    }
  ),
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
)

export const OutcomesCategory = forwardRef<PanelHandle, OutcomesCategoryProps>(
  ({ eventId, category }, forwaredRef) => {
    const tradedMarketsIds = useSelector((state: RootState) =>
      selectEventTradedMarketsIds(
        state,
        eventId,
        category.orderedMarkets.map((orderedMarket) => orderedMarket.marketId)
      )
    )

    const filteredOrderedMarkets = useMemo(
      () =>
        category.orderedMarkets
          .filter((orderedMarket) =>
            tradedMarketsIds.includes(orderedMarket.marketId)
          )
          .map((marketInfo, index, array) => (
            <OutcomesTable
              isMerged={
                index > 0 &&
                MERGED_TEMPLATES.includes(marketInfo.templateId) &&
                MERGED_TEMPLATES.includes(array[index - 1].templateId)
              }
              eventId={eventId}
              isFirst={index === 0}
              isLast={index === array.length - 1}
              key={marketInfo.id}
              market={marketInfo}
            />
          )),
      [category.orderedMarkets, eventId, tradedMarketsIds]
    )

    return (
      <Panel ref={forwaredRef} title={category.name}>
        {filteredOrderedMarkets}
      </Panel>
    )
  }
)

export const OutcomesTable: FC<OutcomesTableProps> = ({
  eventId,
  market,
  isFirst,
  isLast,
  isMerged
}) => {
  const eventProbabilities = useSelector((state: RootState) =>
    selectEventProbabilitiesByMarket(state, eventId, market.marketId)
  )

  const { event } = useSelector((state: RootState) =>
    selectEvent(state, { eventId })
  )

  const marketData = useSelector((state: RootState) =>
    selectMarket(state, market.marketId)
  )

  const transformParamValue = useCallback(
    (value: string) => {
      let result = value
      if (TEMPLATES_WITH_PLUS_SIGN.includes(market.templateId)) {
        if (!value.startsWith('-') && Number(value) !== 0) {
          result = `+${result}`
        }
      }
      if (TEMPLATES_WITH_PARENTHESES.includes(market.templateId)) {
        result = `(${result})`
      }
      return result
    },
    [market.templateId]
  )

  const outcomes = useOutcomes(
    event,
    eventProbabilities,
    marketData?.orderedOutcomeTypes,
    market,
    transformParamValue
  )

  const { homeCompetitors, awayCompetitors } =
    getEventHomeAwayCompetitors(event)

  return React.createElement(
    // Temporary solution of unexistant IDs set in astrabet admin
    Templates[market.templateId ?? 'K1Template'] ?? Templates.K1Template,
    {
      probabilities: outcomes,
      categoryMarket: market,
      renderOdd: (outcome: ProbabilityWithOutcome) => {
        return <OutcomeOdd eventProbability={{ ...outcome, eventId }} />
      },
      withoutDivider: isFirst || isMerged,
      withoutTitle: isMerged,
      competitors: {
        homeCompetitors,
        awayCompetitors
      },
      OddsTableComponent: OddsTable,
      isLast,
      // tournamentId added for auto-tests
      tournamentId: event.tournament.id
    }
  )
}

const Panel = forwardRef<PanelHandle, PropsWithChildren<PanelProps>>(
  ({ title = '', children }, forwardedRef) => {
    const [opened, setOpened] = useState<boolean>(true)

    const toggleOpened = useCallback(() => setOpened((opened) => !opened), [])
    const open = useCallback(() => setOpened(true), [])
    const close = useCallback(() => setOpened(false), [])

    useImperativeHandle(forwardedRef, () => ({ open, close }), [close, open])

    return (
      <StyledPanel>
        <StyledPanelHead onClick={toggleOpened}>
          <StyledFavoriteButton>{/* <IconStar /> */}</StyledFavoriteButton>
          <StyledPanelTitle
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
          <IconChevronDown size={8} twist={opened ? 180 : 0} />
        </StyledPanelHead>
        {opened && children}
      </StyledPanel>
    )
  }
)

const OutcomeOdd: FC<OutcomeOddProps> = ({ eventProbability }) => {
  const dispatch = useDispatch()
  const outcomeMode = useEventMode(eventProbability)

  const handleAddOutcomeToBasket = useCallback(() => {
    dispatch(
      basketProviderActions.addOutcomeToBasket({
        eventProbability,
        eventId: eventProbability?.eventId
      })
    )
  }, [dispatch, eventProbability])

  return (
    <StyledOddWrapper $mode={outcomeMode} onClick={handleAddOutcomeToBasket}>
      <StyledEventOdd $mode={outcomeMode}>
        {eventProbability?.odd}
      </StyledEventOdd>
    </StyledOddWrapper>
  )
}
