import styled from 'styled-components'

export const StyledTableCalendarHead = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.default.primary[5]};
  border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  gap: 4px;
  line-height: ${(props) => props.theme.fonts.size.l};
  padding: 0 16px;
`

export const StyledTableCalendarTime = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`
export const StyledTableCalendarDate = styled.span`
  color: ${(props) => props.theme.colors.default.primary[50]};
`
