import styled, { css } from 'styled-components'

export const CoefficientTableCellStyled = styled.div<{
  withoutBorder?: boolean
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  grid-column-gap: 4px;
  justify-content: center;
  ${(props) =>
    !props.withoutBorder &&
    css`
      border-left: 1px solid ${props.theme.colors.custom.primary[4]};
    `}
  min-width: 40px;

  &:first-child {
    border-left: 0;
  }
`

export const StyledTableCellFirst = styled(CoefficientTableCellStyled)`
  min-width: 300px;
`

export const StyledTableRowDoubleColumn = styled(CoefficientTableCellStyled)`
  min-width: 80px;
`
