import {
  EventProbabilityParameter,
  MarketParameter,
  ParameterOperator,
  ParameterType,
  EventProbability
} from 'betweb-openapi-axios'
import get from 'lodash/get'
import { MainOutcomeTypes } from 'astra-core/containers/CommonDataProvider'

import { GetMainOutcomeProbabilityParams, OutcomeWithoutOdd } from './types'

export const getOutcomeParameterValue = ({
  parameterType,
  eventProbability
}: {
  eventProbability: EventProbability | OutcomeWithoutOdd | undefined
  parameterType: ParameterType
}) => {
  return eventProbability?.parameters?.find((p) => p.type === parameterType)
    ?.value
}

export const getMainOutcomeProbability = ({
  probs,
  mainOutcomeTypes,
  mainOutcomeType
}: GetMainOutcomeProbabilityParams) =>
  get(probs, `${mainOutcomeTypes[MainOutcomeTypes[mainOutcomeType]]}`)

export const isParameterValueMatching = (
  probabilityParameter: EventProbabilityParameter,
  marketParameter: MarketParameter
): boolean => {
  const operatorsStatus = {
    [ParameterOperator.Equal]:
      probabilityParameter.value === marketParameter.value,
    [ParameterOperator.LessThanOrEqual]:
      probabilityParameter.value <= marketParameter.value,
    [ParameterOperator.MoreThanOrEqual]:
      probabilityParameter.value >= marketParameter.value,
    [ParameterOperator.Unknown]: false,
    default: true
  }

  return probabilityParameter.type !== marketParameter.type
    ? false
    : operatorsStatus[marketParameter.operator || 'default']
}
