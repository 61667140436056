import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageMainLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageMain,
  fallback
})

export { pageMainPrefetch } from './page'
