import { EColorsNames } from 'shared/types/theme'
import { IconProps } from 'shared/ui/Icon'

export const EVENT_STATISTICS_POLLING_INTERVAL = 2000

export const ICON_ACTION_PROPS: IconProps = {
  size: 16,
  colorProps: { name: EColorsNames.Primary, value: 70 }
}

export const SIZE_LONG_NAME = 16
