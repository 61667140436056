import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportRowing: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M7.98922 15.2238C7.93563 14.9022 7.65743 14.6666 7.33151 14.6666V4.66663C7.33151 4.29846 7.03305 4 6.66488 4C6.29671 4 5.99825 4.29846 5.99825 4.66663V14.6666C5.67233 14.6666 5.39413 14.9022 5.34054 15.2238L4.67391 19.2238C4.64413 19.4166 4.69943 19.613 4.82565 19.762C4.95175 19.9109 5.13644 19.9977 5.33151 20H7.99825C8.19344 19.9977 8.37801 19.9109 8.50424 19.762C8.63033 19.613 8.68575 19.4166 8.65585 19.2238L7.98922 15.2238ZM19.1735 4.23572C19.0469 4.0863 18.861 4.00012 18.6652 4H15.9986C15.8034 4.00232 15.6188 4.08911 15.4926 4.23804C15.3664 4.38696 15.3111 4.58337 15.3409 4.77625L16.0076 8.77625C16.0611 9.09778 16.3393 9.33337 16.6652 9.33337V19.3333C16.6652 19.7015 16.9637 20 17.3319 20C17.7001 20 17.9986 19.7015 17.9986 19.3333V9.33337C18.3245 9.33337 18.6027 9.09778 18.6562 8.77625L19.3229 4.77625C19.355 4.58289 19.3004 4.38525 19.1735 4.23572ZM15.1786 11.9981C15.1786 8.87717 13.7694 6.24945 12.669 4.80212C12.3208 4.34409 11.6574 4.36165 11.3231 4.82989C10.2178 6.37769 8.77862 9.10695 8.77862 11.9981C8.77862 14.8893 10.2178 17.6185 11.3231 19.1663C11.6574 19.6346 12.3208 19.6521 12.669 19.1941C13.7694 17.7468 15.1786 15.1191 15.1786 11.9981ZM14.3789 11.9982C14.3789 13.3236 13.3044 14.3982 11.9789 14.3982C10.6534 14.3982 9.57891 13.3236 9.57891 11.9982C9.57891 10.6727 10.6534 9.59816 11.9789 9.59816C13.3044 9.59816 14.3789 10.6727 14.3789 11.9982Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
