import styled, { css } from 'styled-components'

import { OddModes } from 'pages/page-live-events/Line/Line.types'

export const StyledEventCardProbabilities = styled.div`
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 1px solid ${(p) => p.theme.colors.custom.primary[4]};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
  width: 100%;
`

export const StyledEventCardProbabilitiesItemWrapper = styled.div`
  border-right: 1px solid ${(p) => p.theme.colors.custom.primary[4]};

  &:last-of-type {
    border: none;
  }
`

export const StyledEventCardProbabilitiesItem = styled.div<{
  $mode: OddModes
}>`
  ${(props) => css`
    align-items: center;
    display: flex;
    font-size: ${(p) => p.theme.fonts.size.s};
    justify-content: space-between;
    line-height: 1.5rem;
    padding: 4px 16px;
    transition: 0.5s ease background-color, color;

    ${props.$mode === OddModes.Increase &&
    css`
      background-color: ${props.theme.colors.default.green[5]};
      transition: none;
    `}

    ${props.$mode === OddModes.Decrease &&
    css`
      background-color: ${props.theme.colors.default.red[5]};
      transition: none;
    `}
    
    ${props.$mode === OddModes.Active &&
    css`
      background-color: ${props.theme.colors.default.primary[70]};
      color: ${props.theme.colors.default.primary[0]};
      transition: none;
    `}

    &:hover {
      background-color: ${props.theme.colors.default.primary[5]};

      ${props.$mode === OddModes.Increase &&
      css`
        background-color: ${props.theme.colors.fixed.green[0]};
      `}

      ${props.$mode === OddModes.Decrease &&
      css`
        background-color: ${props.theme.colors.fixed.red[1]};
      `}

      ${props.$mode === OddModes.Active &&
      css`
        background-color: ${props.theme.colors.default.primary[80]};
      `}
    }
  `}
`

export const StyledEventCardProbabilitiesItemCode = styled.span<{
  $mode: OddModes
}>`
  color: ${({ theme, $mode }) =>
    $mode !== OddModes.Active
      ? theme.colors.default.primary[50]
      : theme.colors.default.primary[0]};
`

export const StyledEventCardProbabilitiesItemValue = styled.span<{
  $mode: OddModes
}>`
  color: ${({ theme, $mode }) =>
    $mode !== OddModes.Active
      ? theme.colors.default.primary[90]
      : theme.colors.default.primary[0]};
  font-weight: ${(p) => p.theme.fonts.weight.normal};
`
