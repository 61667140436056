import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { useInterval } from 'hooks'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'

import { SPORTS_COUNTERS_POLLING_INTERVAL } from '../constants'

export const useFetchSportsCounters = (currentTabMenu: ESportsMenuTabTypes) => {
  const dispatch = useDispatch()

  const fetchSportsCounters = useCallback(() => {
    if (currentTabMenu === ESportsMenuTabTypes.LINE) {
      dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
    } else if (currentTabMenu === ESportsMenuTabTypes.LIVE) {
      dispatch(sportCountersProviderActions.fetchLiveSportCounters())
    }
  }, [dispatch, currentTabMenu])

  useEffect(() => {
    fetchSportsCounters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentTabMenu])

  useInterval(fetchSportsCounters, SPORTS_COUNTERS_POLLING_INTERVAL)

  useEffect(() => {
    /*
        To load another tab counters after the 1st render,
        so that the data will be displayed quickly after tab changing
         */
    if (currentTabMenu === ESportsMenuTabTypes.LINE) {
      dispatch(sportCountersProviderActions.fetchLiveSportCounters())
    } else if (currentTabMenu === ESportsMenuTabTypes.LIVE) {
      dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
