import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconEmblem: FC<Omit<IconProps, 'colorProps'>> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 7.57434L18.5 3C18.5 3 19.9915 10.5804 31.8975 10.6237H32.1025C44.0085 10.5804 45.5 3 45.5 3L59 7.57434V31.2083C59 40.7941 54.4234 49.2631 47.4286 54.3606C43.0552 57.5476 32 61 32 61C32 61 20.9448 57.5476 16.5714 54.3606C9.57656 49.2631 5 40.7941 5 31.2083V7.57434Z"
        stroke="#D9D9D9"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M47.5287 7.91064C47.1414 8.45937 46.6778 9.02852 46.1239 9.59415C43.4216 12.3537 39.0009 14.5985 32.117 14.6236L32.1097 14.6236H31.8903L31.883 14.6236C24.9991 14.5985 20.5784 12.3537 17.8761 9.59415C17.3222 9.02852 16.8586 8.45937 16.4713 7.91064L9 10.4422V31.2081C9 39.5058 12.9576 46.7775 18.9272 51.1278C20.6257 52.3655 24.004 53.8625 27.35 55.1375C28.9457 55.7455 30.4145 56.2593 31.4853 56.6213C31.6694 56.6835 31.8414 56.7412 32 56.7939C32.1586 56.7412 32.3306 56.6835 32.5147 56.6213C33.5855 56.2593 35.0543 55.7455 36.65 55.1375C39.996 53.8625 43.3743 52.3655 45.0728 51.1278C51.0424 46.7775 55 39.5058 55 31.2081V10.4422L47.5287 7.91064Z"
        fill="url(#paint0_linear_10723_157010)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_10723_157010"
          x1="9"
          x2="54.0454"
          y1="56.7939"
          y2="7.04631"
        >
          <stop stopColor="#B3B3B3" />
          <stop offset="1" stopColor="#E6E6E6" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
