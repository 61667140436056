import styled, { css } from 'styled-components'

export const BasketHistoryBonusCouponWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
  height: 24px;
  margin: 0 16px;
  width: 100%;
`

export const BasketHistoryBonusCouponText = styled.div`
  line-height: 16px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.blue[26]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`
