import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportVelo: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M11.2932 5.65696L12.6633 9.75793C15.8323 9.2712 18.6667 11.71 18.6667 14.8479C18.6667 17.6906 16.3291 20 13.4582 20C10.5873 20 8.25496 17.6906 8.25496 14.8479C8.25496 12.8647 9.4002 11.1353 11.0684 10.2757L10.7912 9.44207L7.32298 13.7752C6.89437 14.3107 6.0307 14.0106 6.02652 13.3247C6.0255 13.1579 6.08177 12.9958 6.18592 12.8656L8.76927 9.63446H6.66081C6.29743 9.63446 6.00223 9.34106 6.00001 8.97768C5.99773 8.60282 6.30399 8.29773 6.68257 8.29773H9.56754L10.1637 7.54693L9.34267 5.08738C9.16487 4.55405 9.56754 4 10.1375 4H12.4437C12.9039 4 13.2804 4.37282 13.2804 4.82848C13.2804 5.28414 12.9039 5.65696 12.4437 5.65696H11.2932ZM9.93359 14.8479C9.93359 16.7741 11.5181 18.343 13.4634 18.343C15.4088 18.343 16.9933 16.7741 16.9985 14.8479C16.9985 12.8337 15.2676 11.2078 13.202 11.3631L13.5732 12.4764C14.8597 12.5282 15.8794 13.5741 15.8794 14.8583C15.8794 16.1786 14.8022 17.2453 13.4687 17.2453C12.1352 17.2453 11.0579 16.1735 11.0579 14.8583C11.0579 14.0971 11.4187 13.4188 11.9783 12.9838L11.607 11.8757C10.603 12.4919 9.93359 13.5948 9.93359 14.8479Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
