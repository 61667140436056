import { EventsApiGetEventsRequest } from 'betweb-openapi-axios'

import { LineFilterPeriod } from './types'

interface GetFetchLineReqDataParams {
  tournamentId: string | number
  top?: EventsApiGetEventsRequest['top']
  filterPeriod?: LineFilterPeriod
}

export const getFetchLineReqData = ({
  tournamentId,
  top,
  filterPeriod
}: GetFetchLineReqDataParams) => ({
  top,
  live: false,
  tournamentId: +tournamentId,
  ...filterPeriod
})

export const LINE_POLLING_INTERVAL = 5000
