import React, { FC, ReactElement } from 'react'

import {
  StyledEventTeams,
  StyledCurrentServerFlag,
  StyledCompetitor
} from './teams-names.styled'

type Team = { name: string; isActivePlayer?: boolean }

export const EventTeamsNames: FC<{
  tagComponent?: ReactElement | false
  teamsList: [Team, Team]
  teamsDivider?: string
  teamsWrapperComponent: (ReactElement) => ReactElement
}> = ({
  tagComponent,
  teamsList,
  teamsWrapperComponent,
  teamsDivider = ' – '
}) => {
  const firstTeam = teamsList[0]
  const secondTeam = teamsList[1]

  return (
    <StyledEventTeams>
      {tagComponent}

      {teamsWrapperComponent(
        <>
          <CompetitorsName
            isActivePlayer={firstTeam.isActivePlayer}
            name={firstTeam.name}
          />
          {teamsDivider}
          <CompetitorsName
            isActivePlayer={secondTeam.isActivePlayer}
            name={secondTeam.name}
          />
        </>
      )}
    </StyledEventTeams>
  )
}

const CompetitorsName: FC<Team> = ({ name, isActivePlayer }) => {
  return (
    <StyledCompetitor>
      {isActivePlayer && <StyledCurrentServerFlag />} {` ${name}`}
    </StyledCompetitor>
  )
}
