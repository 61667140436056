import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useEffect
} from 'react'
import { useDispatch } from 'react-redux'

import { IconLiveTracker } from 'shared/ui/Icon/General/IconLiveTracker'
import { IconH2H } from 'shared/ui/Icon/General/IconH2H'
import { getEventBetradarSourceId } from 'shared/lib/events'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { matchTrackerModel } from 'entities/match-tracker'

import { ICON_ACTION_PROPS } from './constants'
import {
  ActionItemProps,
  ActionLiveTrackerProps,
  ActionStatisticsProps
} from './Actions.types'
import { StyledActions, StyledActionsItem } from './Actions.styled'

export const ActionsList = memo(() => {
  const { event } = useContext(EventContext) as IEventContext

  return (
    <StyledActions>
      {/* <ActionFavourite eventId={event.id} /> */}
      {/* <ActionItem Icon={IconStatistics} /> */}

      {event.live ? (
        <>
          <ActionLiveTracker event={event} />
          {/* <ActionItem Icon={IconStreamFilled} /> */}
        </>
      ) : (
        <ActionH2H event={event} />
      )}
    </StyledActions>
  )
})

const ActionItem: FC<ActionItemProps> = ({ Icon, ...props }) => (
  <StyledActionsItem {...props}>
    <Icon {...ICON_ACTION_PROPS} />
  </StyledActionsItem>
)

const ActionH2H: FC<ActionStatisticsProps> = ({ event }) => {
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useCallback(
    () => window.open(`/headToHead/${eventSourceId}`, 'head2head'),
    [eventSourceId]
  )

  if (!eventSourceId) {
    return null
  }

  return <ActionItem Icon={IconH2H} onClick={handleClick} />
}

const ActionLiveTracker: FC<ActionLiveTrackerProps> = ({ event }) => {
  const dispatch = useDispatch()
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  useEffect(() => {
    dispatch(matchTrackerModel.liveMatchTrackerActions.clearCurrentMatch())
  }, [dispatch, event.id])

  const handleClick = useCallback(() => {
    if (eventSourceId) {
      dispatch(
        matchTrackerModel.liveMatchTrackerActions.setCurrentMatch({
          eventSourceId
        })
      )
    }
  }, [dispatch, eventSourceId])

  if (!eventSourceId) {
    return null
  }

  return <ActionItem Icon={IconLiveTracker} onClick={handleClick} />
}
