import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBalls: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M9.62435 17.3333C12.3628 17.3333 14.5827 15.1134 14.5827 12.375C14.5827 9.63659 12.3628 7.41667 9.62435 7.41667C6.88594 7.41667 4.66602 9.63659 4.66602 12.375C4.66602 12.8458 4.73165 13.3014 4.85426 13.7329C4.73026 12.8901 4.94621 12.1585 5.4914 11.8438C6.33837 11.3548 7.65925 12.0569 8.44165 13.4121C9.22405 14.7673 9.17171 16.2622 8.32473 16.7512C8.10782 16.8765 7.85983 16.9236 7.59776 16.9016C8.21646 17.179 8.90239 17.3333 9.62435 17.3333Z"
          fill={mainColor}
        />
        <path
          d="M7.39345 13.8675C7.86935 14.6918 7.83751 15.6011 7.32234 15.8986C6.80716 16.196 6.00374 15.7689 5.52784 14.9446C5.05194 14.1203 5.08378 13.211 5.59895 12.9136C6.11413 12.6161 6.91755 13.0432 7.39345 13.8675Z"
          fill={mainColor}
        />
        <path
          d="M14.5512 9.25612C15.2413 10.108 15.6548 11.1932 15.6548 12.375C15.6548 12.8533 15.5871 13.3158 15.4607 13.7534C15.6398 13.7786 15.8229 13.7917 16.0089 13.7917C18.1606 13.7917 19.9048 12.0474 19.9048 9.89583C19.9048 7.74422 18.1606 6 16.0089 6C15.6392 6 15.2816 6.0515 14.9427 6.14771C15.6044 6.05066 16.1787 6.22039 16.4258 6.6485C16.81 7.31398 16.2584 8.35181 15.1936 8.96655C14.9814 9.08903 14.765 9.18547 14.5512 9.25612Z"
          fill={mainColor}
        />
        <path
          d="M13.6121 8.36407C13.9411 8.46445 14.4034 8.39265 14.8359 8.14296C15.4835 7.76904 15.8191 7.13778 15.5854 6.733C15.3517 6.32822 14.6372 6.3032 13.9896 6.67712C13.3419 7.05104 13.0063 7.6823 13.24 8.08708C13.2503 8.10484 13.2615 8.12186 13.2735 8.13815C13.3896 8.20891 13.5025 8.2843 13.6121 8.36407Z"
          fill={mainColor}
        />
        <path
          d="M19.3327 17.3333C19.3327 18.4379 18.4373 19.3333 17.3327 19.3333C16.2281 19.3333 15.3327 18.4379 15.3327 17.3333C15.3327 16.2288 16.2281 15.3333 17.3327 15.3333C18.4373 15.3333 19.3327 16.2288 19.3327 17.3333Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
