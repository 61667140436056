import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportHorseRacing: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M14.596 6.36908C14.6157 6.4085 16.0952 9.35408 17.9229 12.1918L16.5862 13.5279L9.22738 7.37141C9.32443 7.24713 9.42279 7.12588 9.52246 7.00766C9.68421 6.42358 9.92421 5.06683 10.0087 4.55766C10.0306 4.42576 10.0917 4.30349 10.184 4.20674C10.2763 4.10999 10.3955 4.04323 10.5263 4.01513C10.657 3.98703 10.7932 3.99889 10.9171 4.04917C11.0409 4.09945 11.1469 4.18583 11.221 4.29708L12.3565 6.00016H13.9997C14.1236 6.00021 14.2449 6.03474 14.3503 6.0999C14.4556 6.16507 14.5406 6.25827 14.596 6.36908ZM4.0199 19.1717C4.08698 18.9032 5.39748 13.7361 7.74673 9.60921L14.6935 15.421L13.9222 16.1923L12.6666 15.2955V19.3334C12.6666 19.5102 12.5964 19.6798 12.4714 19.8048C12.3464 19.9298 12.1768 20.0001 12 20.0001H4.66665C4.56534 20.0001 4.46537 19.977 4.37432 19.9326C4.28327 19.8881 4.20355 19.8235 4.1412 19.7437C4.07885 19.6638 4.03552 19.5708 4.01451 19.4717C3.99349 19.3726 3.99533 19.27 4.0199 19.1717ZM19.8538 14.9168C19.7248 14.7557 19.5958 14.5896 19.4667 14.4187L16.1219 17.7635L16.2791 17.8758C16.3332 17.9143 16.3927 17.9447 16.4557 17.9657C17.1201 18.1633 17.8357 18.0906 18.4467 17.7633C19.1898 17.3549 19.7057 16.5921 19.98 15.4949C20.0049 15.3953 20.0065 15.2914 19.9846 15.1911C19.9627 15.0908 19.918 14.9969 19.8538 14.9168ZM16.6265 14.4307L18.8618 12.1953L19.8047 13.1382L15.138 17.8048L14.1952 16.862L15.68 15.3772L7.43441 8.47882L8.28999 7.45616L16.6265 14.4307Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
