import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSpeedway: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_116117)">
          <path
            d="M12.9675 8.57729L13.3333 8.33338L12.6667 6.66672L12.4273 6.82162L12.9675 8.57729Z"
            fill={mainColor}
          />
          <path
            d="M10.6822 7.9508L7 10.3334H4V12.3334V17.3334L7.33333 12.3334L11.2311 9.73486L10.6822 7.9508Z"
            fill={mainColor}
          />
          <path
            d="M4 12.3334V10.3334H7L10.6822 7.9508L11.2311 9.73486L7.33333 12.3334M4 12.3334V17.3334L7.33333 12.3334M4 12.3334H7.33333M8.33333 8.00003C8.33333 8.00003 11 6.33337 11 5.33337C11 4.33337 8.4 4.80003 7.33333 5.33336M13.3333 8.33338L12.9675 8.57729L12.4273 6.82162L12.6667 6.66672L13.3333 8.33338Z"
            stroke={mainColor}
            strokeLinejoin="round"
          />
          <path
            d="M6.99935 13.3335L7.99935 14.0002L7.66602 14.6668M7.66602 14.6668V15.3335L6.33268 17.0002L5.33268 17.3335L4.33268 16.6668H3.66602V18.3335H6.99935L8.99935 15.6668L8.66602 14.6668H7.66602Z"
            stroke={mainColor}
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M11.8 6.82065C11.7009 6.56291 11.4116 6.43433 11.1538 6.53346C10.8961 6.63259 10.7675 6.92189 10.8667 7.17962L12.0529 10.2638C10.8163 10.8798 9.87746 11.9427 9.51937 13.196C9.4435 13.4615 9.59725 13.7383 9.86277 13.8141C10.1283 13.89 10.405 13.7363 10.4809 13.4707C10.7476 12.5371 11.4578 11.7072 12.414 11.2027L12.6695 11.867C11.4724 12.5582 10.6668 13.8518 10.6668 15.3334C10.6668 17.5426 12.4577 19.3334 14.6668 19.3334C16.8759 19.3334 18.6668 17.5426 18.6668 15.3334C18.6668 13.1243 16.8759 11.3334 14.6668 11.3334C14.2944 11.3334 13.9339 11.3843 13.5919 11.4795L13.3459 10.8398C14.2882 10.5912 15.3704 10.6377 16.4637 11.1236C16.7161 11.2358 17.0115 11.1221 17.1237 10.8698C17.2359 10.6174 17.1222 10.322 16.8699 10.2098C15.5251 9.61215 14.1685 9.56577 12.9854 9.90263L11.8 6.82065ZM12.9124 12.4986L14.2 15.8463C14.2991 16.104 14.5884 16.2326 14.8462 16.1335C15.1039 16.0343 15.2325 15.745 15.1333 15.4873L13.8326 12.1053C14.0992 12.0366 14.3787 12.0001 14.6668 12.0001C16.5077 12.0001 18.0001 13.4925 18.0001 15.3334C18.0001 17.1744 16.5077 18.6668 14.6668 18.6668C12.8258 18.6668 11.3335 17.1744 11.3335 15.3334C11.3335 14.1362 11.9647 13.0864 12.9124 12.4986Z"
            fill={mainColor}
            fillRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_116117">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
