export interface LoaderPointsMiniProps {
  text?: string
  type: ELoaderPointsMiniTypesSizes
}

export enum ELoaderPointsMiniTypesSizes {
  S = 's',
  M = 'm'
}

export type StyledLoaderPointsMiniProps = LoaderPointsMiniProps
