import { EventsApiGetEventsRequest } from 'betweb-openapi-axios'

interface GetFetchLiveReqDataParams {
  sportId: string | number
  top?: EventsApiGetEventsRequest['top']
}

export const getFetchLiveReqData = ({
  sportId,
  top
}: GetFetchLiveReqDataParams) => ({
  top,
  live: true,
  sportId: +sportId
})

export const LIVE_POLLING_INTERVAL = 2000
