import { Statistics } from 'betweb-openapi-axios'

export const getBannerScore = (
  eventStatistics: Statistics,
  isLive?: boolean
) => {
  if (isLive) {
    // TODO find out what should be shown while fetching score
    return `${eventStatistics?.homeStatistics?.score ?? ''} : ${
      eventStatistics?.awayStatistics?.score ?? ''
    }`
  } else {
    return '-'
  }
}
