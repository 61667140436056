import React from 'react'
import { useSelector } from 'react-redux'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { LineTournaments } from 'pages/page-live-events/Line/components/LineTournaments'

import { selectLineTournamentsIsEmpty } from './selectors'
import { lineTournamentsContainerSaga } from './saga'
import { useLineTournamentsContainerData } from './hooks'
import { reducerLine, sliceKeyLine } from './slice'
import { LineTournamentsProps } from './types'

export function LineTournamentsContainer(_props: LineTournamentsProps) {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })
  const isEmpty = useSelector(selectLineTournamentsIsEmpty)

  useLineTournamentsContainerData()

  return isEmpty ? <LoaderSpinner /> : <LineTournaments />
}
