import React, { FC, useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectDisabledButtonExpressSystem,
  selectExpressOutcomeValue,
  selectExpressRate,
  selectMakeBetErrorsBottom,
  selectMaxBetExpressSystem
} from 'astra-core/containers/BasketProvider'
import { validateBasketInputValue } from 'astra-core'

import {
  BasketButton,
  BasketError,
  BasketInput,
  BetCardElement,
  CommonBlockClearAll,
  CustomTopLayerAboveInput
} from '../../BasketCommon/Common'
import { StyledBetCardElementBorder } from '../../BasketCommon/Common.styled'
import { BasketBonusCoupons } from '../../BasketCommon/components'

import { StyledInputWrapper } from './Express.styled'
import { ExpressProps } from './Express.types'

export const Express: FC<ExpressProps> = ({ outcomes }) => {
  const basketInputRef = useRef<HTMLInputElement>(null)
  const outcomeValue = useSelector(selectExpressOutcomeValue)
  const expressRate = useSelector(selectExpressRate)
  const maxBetExpressSystem = useSelector(selectMaxBetExpressSystem)
  const errorsBottom = useSelector(selectMakeBetErrorsBottom)
  const disabledButton = useSelector(selectDisabledButtonExpressSystem)
  const isBonusWallet = useSelector(selectBasketBonusWallet)
  const dispatch = useDispatch()

  const setOutcomeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        basketProviderActions.setExpressAmount({
          amount: validateBasketInputValue(e)
        })
      )
    },
    [dispatch]
  )

  const calcCoefficient: number = useMemo(
    () =>
      (outcomeValue !== '' && (expressRate - +isBonusWallet) * +outcomeValue) ||
      0,
    [expressRate, outcomeValue, isBonusWallet]
  )

  const makeInputFocus = useCallback(() => {
    if (basketInputRef?.current) {
      basketInputRef.current.focus()
    }
  }, [])

  return (
    <>
      <StyledBetCardElementBorder />

      {outcomes.map((outcome) => (
        <BetCardElement key={outcome.id} outcome={outcome} />
      ))}

      <CommonBlockClearAll />

      <BasketBonusCoupons />

      <StyledInputWrapper>
        <CustomTopLayerAboveInput
          makeInputFocus={makeInputFocus}
          maxBet={maxBetExpressSystem}
          stakeAmount={outcomeValue}
          winSum={calcCoefficient}
        />
        <BasketInput
          inputRef={basketInputRef}
          value={outcomeValue}
          onChange={setOutcomeAmount}
        />
      </StyledInputWrapper>

      {errorsBottom.map((error) => (
        <BasketError error={error} key={error.code} />
      ))}

      <BasketButton disabledButton={disabledButton} />
    </>
  )
}
