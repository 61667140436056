import React, { FC } from 'react'

import { StyledTableCellFirst } from 'pages/page-live-events/Line/components/ui/TableBets/components/TableBetsRow/components/TableMainRowCell/TableMainRowCell.styled'

import {
  StyledEventRowInfoCell,
  StyledTableRowOutcomes,
  StyledLineTableRowWrapper
} from './event-row.styled'
import { ITableColumnHeaderListProps } from './event-row.types'

export const EventRow: FC<ITableColumnHeaderListProps> = ({
  handleMouseEnter,
  handleMouseLeave,
  coefficientsItems,
  infoCellComponents,
  lastCell,
  message,
  expandedData,
  outcomesComponent
}) => {
  return (
    <StyledLineTableRowWrapper>
      <StyledTableRowOutcomes
        isSuspended={!!message}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {message}

        <StyledTableCellFirst>
          <StyledEventRowInfoCell>{infoCellComponents}</StyledEventRowInfoCell>
        </StyledTableCellFirst>

        {coefficientsItems}

        {lastCell}
      </StyledTableRowOutcomes>

      {expandedData}

      {outcomesComponent}
    </StyledLineTableRowWrapper>
  )
}
