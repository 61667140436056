import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resultsProviderActions } from 'astra-core/containers/ResultsProvider'
import { useTranslation } from 'react-i18next'

import { ResultsMenuList } from './components'
import {
  StyledResultsMenuTitle,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './ResultsMenu.styled'

export const ResultsMenu = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  useEffect(() => {
    dispatch(resultsProviderActions.fetchResultCounters())
  }, [dispatch])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <StyledResultsMenuTitle>{t('kind of sport')}</StyledResultsMenuTitle>
        </StyledTabsWrapper>
        <ResultsMenuList />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
