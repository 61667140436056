import styled from 'styled-components'

import { IconCalendar } from 'shared/ui/Icon/General/IconCalendar'
import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledCalendarEvents = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
`

export const StyledIconCalendar = styled(IconCalendar)`
  background: ${(props) => props.theme.colors.custom.blue[6]};
  border-radius: 4px;
  padding: 5px;
`
export const StyledDataList = styled(DataListWithReduxPagination)`
  height: 100%;
  overflow: scroll;
`
