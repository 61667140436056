import React, { FC } from 'react'
import { useGetCurrencyIcon } from 'astra-core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectLoyaltyById } from 'astra-core/containers/AccountProvider'

import { RootState } from 'shared/types/store'
import { IconBonusCoupon } from 'shared/ui/Icon/General/IconBonusCoupon'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'

import {
  BasketHistoryBonusCouponText,
  BasketHistoryBonusCouponWrapper
} from './BasketHistoryBonusCoupon.styled'
import { IBasketHistoryBonusCouponProps } from './BasketHistoryBonusCoupon.types'

export const BasketHistoryBonusCoupon: FC<IBasketHistoryBonusCouponProps> = ({
  appliedCouponId
}) => {
  const currencyIcon = useGetCurrencyIcon()
  const { t } = useTranslation()
  const couponLoyalty = useSelector((state: RootState) =>
    selectLoyaltyById(state, appliedCouponId)
  )

  if (!couponLoyalty) {
    return null
  }

  const {
    possibleAmount,
    coupon: { amount }
  } = couponLoyalty

  const couponText = getFormattedBonusCouponAmount(
    t('used coupon for'),
    possibleAmount ?? amount,
    currencyIcon
  )

  return (
    <BasketHistoryBonusCouponWrapper>
      <IconBonusCoupon />
      <BasketHistoryBonusCouponText>{couponText}</BasketHistoryBonusCouponText>
    </BasketHistoryBonusCouponWrapper>
  )
}
