import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { columnGapPrefixes, rowGapPrefixes } from 'shared/lib/styled'

import { IStyledSideMenuItem } from './types'

export const StyledSideMenu = styled.nav`
  display: grid;
  padding: 14px 16px;
  position: relative;
  ${rowGapPrefixes('4px')}

  &::before,
  &::after {
    background: ${(props) => props.theme.colors.custom.primary[4]};
    content: '';
    display: block;
    height: 1px;
    left: 16px;
    position: absolute;
    width: calc(100% - 32px);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`

export const StyledSideMenuItem = styled(NavLink)<IStyledSideMenuItem>`
  ${(props) => css`
    align-items: center;
    background: ${props.$isActiveItem
      ? props.theme.colors.custom.primary[4]
      : ''};
    border-radius: 4px;
    color: ${props.$isActiveItem
      ? props.theme.colors.default.primary[90]
      : props.theme.colors.custom.primary[17]};
    display: flex;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 20px;
    padding: 6px 5px;
    ${columnGapPrefixes('8px')}

    &:hover {
      background: ${props.theme.colors.custom.primary[4]};
    }
  `}
`
