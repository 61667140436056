import styled from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'
import { Select } from 'shared/ui/Select'

export const StyledLineHeaderFilters = styled.div`
  align-items: center;
  display: flex;
  ${columnGapPrefixes('8px')}
`

export const StyledSelectPeriod = styled(Select)`
  width: 170px;
`
