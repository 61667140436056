import styled from 'styled-components'

import { customMedia } from 'shared/lib/styled'
import { RIGHT_CONTAINER_WIDTH } from 'widgets/right-container'

export const StyledLayout = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: grid;
  grid-template-areas:
    'header header header'
    'sports-menu content right-container';
  grid-template-columns: 240px 1fr ${RIGHT_CONTAINER_WIDTH}px;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  overflow: hidden;

  ${customMedia.small} {
    grid-template-areas:
      'header header header'
      'sports-menu content content';
  }
`

export const StyledContent = styled.div`
  display: grid;
  grid-area: content;
  grid-template-rows: 1fr auto;
  overflow-x: hidden;
`
