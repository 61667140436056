import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportWindsurf: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M19.5249 16.0709H13.595V15.5073L17.3457 13.8246C17.8815 13.5842 18.2724 13.12 18.418 12.551C18.5636 11.982 18.4437 11.3871 18.0893 10.9188L13.4987 4.85506C13.3755 4.69235 13.1623 4.62657 12.9687 4.69146C12.7753 4.75644 12.6449 4.93768 12.6449 5.14178C12.6449 5.14178 12.6143 10.6521 12.6449 11.0373C12.6756 11.4226 12.6449 12.304 12.6449 12.304V16.071H4.47502C4.21268 16.071 4 16.2837 4 16.546V16.5671C4 18.2223 5.34658 19.5689 7.0018 19.5689C7.4842 19.5689 7.94649 19.5689 8.98624 19.5689H16.9982C18.6534 19.5689 20 18.2222 20 16.567V16.546C20 16.2836 19.7873 16.0709 19.5249 16.0709Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
