import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBaseball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M19.2908 7.9777L19.3143 7.95601C20.232 7.05146 20.2309 5.58074 19.3119 4.67751C18.3959 3.77714 16.9019 3.77374 15.9816 4.66991L7.08076 14.6555L7.08274 14.6575L6.4208 15.3954L5.58326 16.2186L7.56895 18.1702L8.4262 17.3277L9.14936 16.6887L9.15859 16.6977L19.2908 7.9777ZM4.43409 17.3457C4.0402 17.7328 4.0402 18.3605 4.43409 18.7476L4.99311 19.2971C5.38699 19.6842 6.02561 19.6842 6.4195 19.2971L6.89472 18.83L4.90931 16.8786L4.43409 17.3457ZM16.8899 16.389C17.9311 16.389 18.7878 15.5995 18.8744 14.5978C19.5624 15.158 20.0015 16.0037 20.0015 16.9484C20.0015 17.8933 19.5623 18.7392 18.874 19.2994C18.7848 18.3002 17.9292 17.5138 16.8898 17.5138C15.8541 17.5138 15.0008 18.2946 14.9065 19.2887C14.2256 18.7285 13.7918 17.8873 13.7918 16.9484C13.7918 16.0097 14.2254 15.1687 14.906 14.6085C14.9978 15.6052 15.8523 16.389 16.8899 16.389ZM16.8901 15.4514C17.4628 15.4514 17.9286 14.9935 17.9286 14.4307L17.9291 14.4105L17.9438 14.0754C17.6166 13.9599 17.2642 13.8967 16.897 13.8967C16.5303 13.8967 16.1785 13.9597 15.8516 14.0749V14.4307C15.8516 14.9935 16.3175 15.4514 16.8901 15.4514ZM15.8516 19.4719C15.8516 18.9091 16.3175 18.4512 16.8901 18.4512C17.4628 18.4512 17.9286 18.9091 17.9287 19.4719V19.8266C17.6057 19.9387 17.2585 20 16.897 20C16.5303 20 16.1784 19.937 15.8516 19.8218V19.4719Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
