import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { getTebleBetsHeadTestId } from 'astra-core/utils/testIds'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { RootState } from 'shared/types/store'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import {
  EButtonSizes,
  EButtonViews,
  EButtonIconPositionSides
} from 'shared/ui/Button'
import { Marquee } from 'shared/ui/Marquee'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'

import { TableBetsContext } from '../../TableBets.context'
import { ButtonBack } from '../../../LineHeader/components'

import { ETableHeadVariant, ITableHeadProps } from './TableBetsHead.types'
import {
  StyledLineTableHead,
  StyledLineTableHeadChevron,
  StyledLineTableHeadChevronButton,
  StyledSportIcon,
  StyledTableHeadColumn,
  StyledTableHeadColumnTitle,
  StyledTableHeadDoubleColumn
} from './TableBetsHead.styled'

export const TableBetsHead: FC<ITableHeadProps> = ({
  tournament,
  isShowOdds,
  isShowCount,
  isOutcomesOpened,
  setIsOutcomesOpened,
  variant = ETableHeadVariant.ONE
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { name: sportName, code: sportCode } =
    useSelector(
      (state: RootState) =>
        !!tournament && selectSport(state, tournament?.sportId)
    ) || {}

  const { tableEventHead, isLinkHead } = useContext(TableBetsContext)

  const isDisplaySportIcon =
    tableEventHead?.withSportIcon && tournament?.sportId

  const toggleOutcomesOpen = useCallback(
    (e) => {
      e.preventDefault()
      if (setIsOutcomesOpened) {
        setIsOutcomesOpened(!isOutcomesOpened)
      }
    },
    [isOutcomesOpened, setIsOutcomesOpened]
  )

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetLineFilter())
  }, [dispatch])

  const buttonBackProps = useMemo(
    () => ({
      link: `/line/${tournament?.sportId}`,
      title: tournament?.name,
      onButtonClickAction: resetLineFilter
    }),
    [resetLineFilter, tournament?.name, tournament?.sportId]
  )
  const eventTestId = getTebleBetsHeadTestId(tournament)

  return (
    <StyledLineTableHead variant={variant}>
      <StyledTableHeadColumnTitle align="flex-start">
        <ButtonBack
          link={isLinkHead ? buttonBackProps.link : null}
          testId={eventTestId}
          title={buttonBackProps.title || ''}
          onButtonClickAction={buttonBackProps.onButtonClickAction}
        >
          {/* <IconStarWithoutBackground // TODO handle favourite tournaments
          colorProps={{ name: EColorsNames.Primary, value: 40 }}
          size={14}
        /> */}
          {isDisplaySportIcon && (
            <StyledSportIcon>
              <SportIcon size={24} sport={sportCode as ESportsCodes} isActive />
            </StyledSportIcon>
          )}
          {tableEventHead?.withSportName && `${sportName}. `}
          <Marquee id={tournament?.id}>
            {tournament?.name || t('event')}
          </Marquee>
        </ButtonBack>
      </StyledTableHeadColumnTitle>

      <StyledTableHeadColumn hide={isShowOdds}>{t('w1')}</StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>Х</StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>{t('w2')}</StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>1X</StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>12</StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>X2</StyledTableHeadColumn>
      <StyledTableHeadDoubleColumn hide={isShowOdds}>
        {t('handicap')} 1
      </StyledTableHeadDoubleColumn>
      <StyledTableHeadDoubleColumn hide={isShowOdds}>
        {t('handicap')} 2
      </StyledTableHeadDoubleColumn>
      <StyledTableHeadColumn hide={isShowOdds}>
        {t('total')}
      </StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>
        {t('total over')}
      </StyledTableHeadColumn>
      <StyledTableHeadColumn hide={isShowOdds}>
        {t('total under')}
      </StyledTableHeadColumn>
      {isShowCount ? (
        <StyledLineTableHeadChevron>
          <StyledLineTableHeadChevronButton
            iconProps={{
              positionSide: EButtonIconPositionSides.Right,
              twist: isOutcomesOpened ? 180 : 0,
              size: 10
            }}
            icon={IconChevronDown}
            size={EButtonSizes.S}
            view={EButtonViews.SECONDARY}
            onClick={toggleOutcomesOpen}
          >
            {isShowCount}
          </StyledLineTableHeadChevronButton>
        </StyledLineTableHeadChevron>
      ) : (
        <StyledTableHeadColumn />
      )}
    </StyledLineTableHead>
  )
}
