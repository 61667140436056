/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'

import { RootState } from 'shared/types/store'

import { createReducer } from './reducers'

type ConfigureAppStoreParams = {
  preloadedState?: RootState
}

export function configureAppStore({ preloadedState }: ConfigureAppStoreParams) {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware]

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga
    })
  ]

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }),
      ...middlewares
    ],
    devTools: process.env.NODE_ENV !== 'production' && {
      trace: true,
      traceLimit: 25
    },
    enhancers,
    ...(!!preloadedState && { preloadedState })
  })

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store)
    })
  }

  return store
}
