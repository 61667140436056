import { IconLiveFilled } from 'shared/ui/Icon/General/IconLiveFilled'
import { IconLineFilled } from 'shared/ui/Icon/General/IconLineFilled'
import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import {
  selectTopLineEvents,
  selectTopLineEventsCurrentSportId,
  selectTopLineEventsLoading,
  selectTopLineEventsSports,
  selectTopLiveEventCurrentSportId,
  selectTopLiveEvents,
  selectTopLiveEventsLoading,
  selectTopLiveEventsSports,
  selectUpcomingEvents,
  selectUpcomingEventsCurrentSportId,
  selectUpcomingEventsLoading,
  selectUpcomingEventsSports
} from 'pages/page-main/MainPageContainer/selectors'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'

import {
  EMainPageEventsList,
  MainPageEventsConfiguration,
  EMainPageEventsRepresentation
} from './MainPageEvents.types'

export const mainPageEventsConfiguration: MainPageEventsConfiguration = {
  [EMainPageEventsList.TOP_LIVE]: {
    selectCurrentSport: selectTopLiveEventCurrentSportId,
    selectSports: selectTopLiveEventsSports,
    selectEvents: selectTopLiveEvents,
    selectLoading: selectTopLiveEventsLoading,
    setCurrentSport: mainPageContainerActions.selectTopLiveEventsSport,
    untranslatedTitle: 'mainPage topLive',
    icon: IconLiveFilled,
    representation: EMainPageEventsRepresentation.CAROUSEL,
    link: '/live'
  },
  [EMainPageEventsList.TOP_LINE]: {
    selectCurrentSport: selectTopLineEventsCurrentSportId,
    selectSports: selectTopLineEventsSports,
    selectEvents: selectTopLineEvents,
    selectLoading: selectTopLineEventsLoading,
    setCurrentSport: mainPageContainerActions.selectTopLineEventsSport,
    untranslatedTitle: 'mainPage topLine',
    icon: IconLineFilled,
    representation: EMainPageEventsRepresentation.CAROUSEL,
    link: '/line'
  },
  [EMainPageEventsList.UPCOMING]: {
    selectCurrentSport: selectUpcomingEventsCurrentSportId,
    selectSports: selectUpcomingEventsSports,
    selectEvents: selectUpcomingEvents,
    selectLoading: selectUpcomingEventsLoading,
    setCurrentSport: mainPageContainerActions.selectUpcomingEventsSport,
    untranslatedTitle: 'mainPage upcoming',
    representation: EMainPageEventsRepresentation.TABLE,
    link: '/live'
  }
}

export const EVENTS_TO_ONBOARDING_ATTRS_MAP = {
  [EMainPageEventsList.TOP_LINE]: EWelcomeOnboardingDataAttrs.TOP_LINE_SECTION,
  [EMainPageEventsList.TOP_LIVE]: EWelcomeOnboardingDataAttrs.TOP_LIVE_SECTION,
  [EMainPageEventsList.UPCOMING]: EWelcomeOnboardingDataAttrs.NEAREST_EVENTS
}
