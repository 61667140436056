import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getSelectedOutcomesGroup = (state: RootState) =>
  state.outcomesContainer.selectedOutcomesGroup ??
  initialState.selectedOutcomesGroup

export const selectOutcomesGroup = createSelector(
  [getSelectedOutcomesGroup],
  (selectedOutcomesGroup) => selectedOutcomesGroup
)
