import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportPistolShooting: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M5.12771 8.66675H18.8723C19.4951 8.66675 20 8.21902 20 7.66676C20 7.11451 19.4951 6.66675 18.8723 6.66675H5.12771C4.5049 6.66675 4 7.11451 4 7.66676C4 8.21902 4.5049 8.66675 5.12771 8.66675Z"
          fill={mainColor}
        />

        <path
          d="M19.1465 10H5.27647C4.80526 10 4.423 10.379 4.423 10.8461C4.423 11.3133 4.80526 11.6923 5.27647 11.6923H6.22778L4.11061 15.8514C3.94954 16.1682 3.96502 16.545 4.15288 16.8469C4.34071 17.1493 4.67303 17.3333 5.03089 17.3333H7.66989C8.05938 17.3333 8.415 17.1163 8.59003 16.772L9.6053 14.7772H11.7403C12.5838 14.7772 13.2702 14.0969 13.2702 13.2606V11.6923H19.1465C19.6177 11.6923 20 11.3133 20 10.8461C20 10.379 19.6177 10 19.1465 10ZM11.8475 13.2606C11.8475 13.3191 11.7992 13.3669 11.7403 13.3669H10.3233L11.059 11.922H11.8475V13.2606Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
