import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSkateboarding: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          clipRule="evenodd"
          d="M14.5038 4.9434C15.761 3.68618 17.7994 3.68618 19.0566 4.94341C20.3138 6.20063 20.3138 8.23899 19.0566 9.49621L9.49571 19.0571C8.23849 20.3143 6.20014 20.3143 4.94292 19.0571C3.68569 17.7999 3.68569 15.7615 4.94292 14.5043L14.5038 4.9434ZM16.0989 9.9502C15.9103 10.1388 15.6045 10.1388 15.4159 9.9502C15.2274 9.76162 15.2274 9.45586 15.4159 9.26728C15.6045 9.0787 15.9103 9.0787 16.0989 9.26728C16.2874 9.45586 16.2874 9.76162 16.0989 9.9502ZM16.7803 8.5856C16.9689 8.77418 17.2747 8.77418 17.4633 8.5856C17.6519 8.39702 17.6519 8.09126 17.4633 7.90268C17.2747 7.7141 16.9689 7.7141 16.7803 7.90268C16.5918 8.09126 16.5918 8.39702 16.7803 8.5856ZM8.58555 17.4632C8.39697 17.6518 8.09121 17.6518 7.90263 17.4632C7.71405 17.2746 7.71405 16.9689 7.90263 16.7803C8.09121 16.5917 8.39697 16.5917 8.58555 16.7803C8.77413 16.9689 8.77413 17.2746 8.58555 17.4632ZM9.26741 16.0986C9.456 16.2872 9.76175 16.2872 9.95033 16.0986C10.1389 15.91 10.1389 15.6043 9.95033 15.4157C9.76175 15.2271 9.456 15.2271 9.26741 15.4157C9.07883 15.6043 9.07883 15.91 9.26741 16.0986ZM16.0974 7.21981C15.9088 7.40839 15.603 7.40839 15.4145 7.21981C15.2259 7.03122 15.2259 6.72547 15.4145 6.53689C15.603 6.3483 15.9088 6.3483 16.0974 6.53689C16.286 6.72547 16.286 7.03122 16.0974 7.21981ZM7.90116 14.7328C8.08974 14.9214 8.39549 14.9214 8.58408 14.7328C8.77266 14.5443 8.77266 14.2385 8.58408 14.0499C8.39549 13.8613 8.08974 13.8613 7.90116 14.0499C7.71257 14.2385 7.71257 14.5443 7.90116 14.7328ZM14.7326 8.58436C14.544 8.77294 14.2383 8.77294 14.0497 8.58436C13.8611 8.39578 13.8611 8.09002 14.0497 7.90144C14.2383 7.71286 14.544 7.71286 14.7326 7.90144C14.9212 8.09002 14.9212 8.39578 14.7326 8.58436ZM6.53674 16.0974C6.72533 16.286 7.03108 16.286 7.21966 16.0974C7.40825 15.9088 7.40825 15.6031 7.21966 15.4145C7.03108 15.2259 6.72533 15.2259 6.53674 15.4145C6.34816 15.6031 6.34816 15.9088 6.53674 16.0974Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          d="M18.3893 10.2522C18.5411 10.1003 18.7874 10.1003 18.9392 10.2522C19.0911 10.4041 19.0911 10.6503 18.9392 10.8022L18.1257 11.6157C17.9738 11.7676 17.7276 11.7676 17.5757 11.6157C17.4239 11.4638 17.4239 11.2176 17.5757 11.0657L18.3893 10.2522Z"
          fill={mainColor}
        />
        <path
          d="M13.0881 5.15121C13.24 4.99934 13.4862 4.99934 13.6381 5.15121C13.79 5.30308 13.79 5.54931 13.6381 5.70118L12.8246 6.5147C12.6727 6.66657 12.4265 6.66657 12.2746 6.5147C12.1228 6.36283 12.1228 6.1166 12.2746 5.96473L13.0881 5.15121Z"
          fill={mainColor}
        />
        <path
          d="M10.9849 17.6563C11.1367 17.5044 11.383 17.5044 11.5348 17.6563C11.6867 17.8081 11.6867 18.0544 11.5348 18.2062L10.7213 19.0198C10.5694 19.1716 10.3232 19.1716 10.1713 19.0198C10.0195 18.8679 10.0195 18.6217 10.1713 18.4698L10.9849 17.6563Z"
          fill={mainColor}
        />
        <path
          d="M5.66094 12.5553C5.81281 12.4034 6.05904 12.4034 6.21091 12.5553C6.36278 12.7072 6.36278 12.9534 6.21091 13.1053L5.39739 13.9188C5.24552 14.0706 4.99929 14.0706 4.84742 13.9188C4.69555 13.7669 4.69555 13.5207 4.84742 13.3688L5.66094 12.5553Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
