import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import groupBy from 'lodash/groupBy'
import {
  CategoryMarketInfo,
  Event,
  EventProbabilitiesMarket,
  EventProbability,
  EventProbabilityTradingStatus,
  OutcomeType
} from 'betweb-openapi-axios'
import { getFullOutcomeName } from 'astra-core/utils/outcomes'

import { ProbabilityWithOutcome } from 'shared/lib/outcomes/types'
import { usePrevious } from 'hooks'
import { RootState } from 'shared/types/store'
import {
  EventOddProps,
  OddModes,
  UseOddHighlightType
} from 'pages/page-live-events/Line/Line.types'
import { selectIsOutcomeInBasket } from 'pages/page-live-events/Line/selectors'

export type TUseProbs = Dictionary<EventProbability>

export function useOutcomes(
  event: Event,
  market?: EventProbabilitiesMarket,
  orderedOutcomeTypes?: Array<OutcomeType>,
  categoryMarket?: CategoryMarketInfo,
  transformParamValue: (value: string) => string = (value) => value
) {
  return useMemo<Array<ProbabilityWithOutcome>>(() => {
    if (!market) return []
    const marketProbs = groupBy(market.probabilities, 'outcomeTypeId')

    return (orderedOutcomeTypes || []).reduce(
      (acc: Array<ProbabilityWithOutcome>, outcomeTypeData) => {
        const probs = marketProbs[outcomeTypeData.id] || []
        return [
          ...acc,
          ...probs
            .filter(
              (prob) =>
                prob?.tradingStatus === EventProbabilityTradingStatus.Trading
            )
            .map((prob) => {
              return {
                ...prob,
                outcomeTypeData: {
                  ...outcomeTypeData,
                  name: getFullOutcomeName(
                    { ...prob, outcomeTypeData },
                    event,
                    categoryMarket?.shortNamed,
                    transformParamValue
                  )
                }
              }
            })
        ]
      },
      []
    )
  }, [
    categoryMarket?.shortNamed,
    event,
    market,
    orderedOutcomeTypes,
    transformParamValue
  ])
}

const ODD_HIGHLIGHT_TIMEOUT = 2000

export function useOddHighlight(
  probability?: EventProbability
): UseOddHighlightType {
  const prevOdd = usePrevious(probability?.odd)
  const [mode, setMode] = useState<OddModes>(OddModes.Default)

  useEffect(() => {
    if (prevOdd && probability && prevOdd !== probability.odd) {
      if (prevOdd! < probability?.odd) {
        setMode(OddModes.Increase)
      } else if (prevOdd! > probability?.odd) {
        setMode(OddModes.Decrease)
      }
    }
  }, [prevOdd, probability])

  useEffect(() => {
    if (mode !== OddModes.Default) {
      const timerId = setTimeout(
        () => setMode(OddModes.Default),
        ODD_HIGHLIGHT_TIMEOUT
      )
      return () => clearTimeout(timerId)
    }
    return () => null
  }, [mode])

  return { mode, prevOdd }
}

// TODO: transfer to entity
export function useEventMode(
  eventProbability?: EventOddProps['eventProbability']
): OddModes {
  const { mode } = useOddHighlight(eventProbability)

  const isOutcomeInBasket = useSelector((state: RootState) =>
    selectIsOutcomeInBasket(state, {
      eventId: eventProbability?.eventId,
      outcomeTypeId: eventProbability?.outcomeTypeId,
      parameters: eventProbability?.parameters
    })
  )

  const outcomeMode = useMemo(
    () => (isOutcomeInBasket ? OddModes.Active : mode),
    [isOutcomeInBasket, mode]
  )

  return outcomeMode
}
