import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconPromotionsAndBonuses: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.76922 0.298096C8.33328 -0.0993652 7.66672 -0.0993652 7.23077 0.298096L6.20459 1.23376C5.95648 1.46002 5.62152 1.56555 5.28866 1.5224L3.88196 1.34003C3.28226 1.26233 2.72595 1.66583 2.61307 2.26044L2.37985 3.48889C2.31399 3.83575 2.09173 4.13269 1.77765 4.29346L0.622496 4.88458C0.0621928 5.17133 -0.16086 5.85767 0.123655 6.41949L0.66229 7.48309C0.826872 7.80798 0.826872 8.19202 0.66229 8.51691L0.123655 9.58051C-0.16086 10.1423 0.0621928 10.8287 0.622496 11.1154L1.77765 11.7065C2.09173 11.8673 2.31399 12.1642 2.37985 12.5111L2.61307 13.7396C2.72595 14.3342 3.28226 14.7377 3.88196 14.66L5.28866 14.4776C5.62152 14.4344 5.95648 14.54 6.20459 14.7662L7.23077 15.7019C7.66672 16.0994 8.33328 16.0994 8.76922 15.7019L9.79541 14.7662C10.0435 14.54 10.3785 14.4344 10.7113 14.4776L12.118 14.66C12.7177 14.7377 13.274 14.3342 13.3869 13.7396L13.6201 12.5111C13.686 12.1642 13.9083 11.8673 14.2223 11.7065L15.3775 11.1154C15.9378 10.8287 16.1609 10.1423 15.8763 9.58051L15.3377 8.51691C15.1731 8.19202 15.1731 7.80798 15.3377 7.48309L15.8763 6.41949C16.1609 5.85767 15.9378 5.17133 15.3775 4.88458L14.2223 4.29346C13.9083 4.13269 13.686 3.83575 13.6201 3.48889L13.3869 2.26044C13.274 1.66583 12.7177 1.26233 12.118 1.34003L10.7113 1.5224C10.3785 1.56555 10.0435 1.46002 9.79541 1.23376L8.76922 0.298096ZM10.5548 4.16797C11.0143 4.4743 11.1385 5.09515 10.8322 5.55469L6.83215 11.5547C6.52579 12.0142 5.90491 12.1384 5.4454 11.832C4.98587 11.5257 4.86169 10.9048 5.16803 10.4453L9.16803 4.44531C9.47439 3.98578 10.0953 3.86157 10.5548 4.16797ZM7.00009 5C7.00009 5.55231 6.55237 6 6.00009 6C5.44781 6 5.00009 5.55231 5.00009 5C5.00009 4.44769 5.44781 4 6.00009 4C6.55237 4 7.00009 4.44769 7.00009 5ZM11.0001 11C11.0001 11.5523 10.5524 12 10.0001 12C9.44781 12 9.00009 11.5523 9.00009 11C9.00009 10.4477 9.44781 10 10.0001 10C10.5524 10 11.0001 10.4477 11.0001 11Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
