import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSnowboarding: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M19.0108 4.98697C18.373 4.3505 17.5251 4 16.6233 4C15.7214 4 14.8735 4.3505 14.2353 4.98753L12.8186 6.40626L12.8017 6.39998L6.3846 12.8499L13.5217 15.2499C14.6415 14.1285 15.7408 12.9756 16.8377 11.8251L16.8378 11.825L16.8378 11.825L16.8378 11.825C17.758 10.8599 18.6765 9.89664 19.6039 8.95521L19.6043 8.95535L19.605 8.95417L19.615 8.94398L19.6112 8.94254C19.865 8.46359 19.9997 7.927 19.9997 7.36975C19.9996 6.46966 19.6485 5.62344 19.0108 4.98697ZM5.6443 13.5906L4.99382 14.242L12.1309 16.642L12.7814 15.9906L5.6443 13.5906ZM4 16.6302C4 16.0631 4.13952 15.5175 4.40216 15.0321L11.3918 17.3826L9.76432 19.0124C9.12602 19.6495 8.27818 20 7.37633 20C6.47448 20 5.62661 19.6494 4.9889 19.013C4.35118 18.3765 4 17.5303 4 16.6302ZM12.3367 4.80002C12.6018 4.80002 12.8167 5.01492 12.8167 5.28002C12.8167 5.54512 12.6018 5.76002 12.3367 5.76002H6.08267C5.81757 5.76002 5.60266 5.54512 5.60266 5.28002C5.60266 5.01492 5.81757 4.80002 6.08267 4.80002H12.3367ZM11.2145 6.87998C11.2145 6.61488 10.9996 6.39998 10.7345 6.39998H6.08358C5.81848 6.39998 5.60358 6.61488 5.60358 6.87998C5.60358 7.14507 5.81848 7.35998 6.08358 7.35998H10.7345C10.9996 7.35998 11.2145 7.14507 11.2145 6.87998ZM9.13116 8.00001C9.39626 8.00001 9.61117 8.21492 9.61117 8.48001C9.61117 8.74511 9.39626 8.96002 9.13116 8.96002H6.08335C5.81825 8.96002 5.60335 8.74511 5.60335 8.48001C5.60335 8.21492 5.81825 8.00001 6.08335 8.00001H9.13116Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
