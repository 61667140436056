import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { selectSportsCountersApi } from 'astra-core/containers/ConfigProvider'
import { ErrorType } from 'astra-core'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { actions } from './slice'
import { TFetchScheduledEventsPayload } from './types'
import {
  getFilterCurrentSportId,
  selectCalendarEventsPagination,
  selectFilterSelectInputPeriod
} from './selectors'

const SOURCE_KEY = 'calendarEvents'

export function* fetchScheduledEventsSaga(
  _action: PayloadAction<TFetchScheduledEventsPayload>
) {
  const currentSportId = yield select(getFilterCurrentSportId)
  const filterPeriod = yield select(selectFilterSelectInputPeriod)
  const pagination = yield select(selectCalendarEventsPagination)

  yield put(
    eventsProviderActions.fetchScheduledLiveEvents({
      reqData: { ...filterPeriod, ...pagination, sportId: currentSportId },
      sideEffects: eventsProviderSideEffects[SOURCE_KEY]
    })
  )
}

export function* fetchScheduledEventsCountersSaga() {
  try {
    const sportCountersAPI = yield select(selectSportsCountersApi)
    const filterPeriod = yield select(selectFilterSelectInputPeriod)

    const {
      data: { items }
    } = yield call(() =>
      sportCountersAPI.getScheduledSportsCounters({ ...filterPeriod })
    )

    yield put(actions.fetchScheduledEventsCountersSuccess({ items }))
  } catch (e) {
    yield put(
      actions.fetchScheduledEventsCountersError({
        type: ErrorType.RESPONSE_ERROR,
        message: (e as Error).message,
        title: '',
        code: ''
      })
    )
  }
}

export function* calendarEventsContainerSaga() {
  yield all([
    takeLatest(actions.fetchScheduledEvents.type, fetchScheduledEventsSaga),
    takeLatest(
      actions.fetchScheduledEventsCounters,
      fetchScheduledEventsCountersSaga
    ),
    takeLatest(actions.setCurrentSportIdFilter, fetchScheduledEventsSaga)
  ])
}
