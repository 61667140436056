import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBiplane: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_115946)">
          <path
            d="M10.5168 11.2461C9.63477 11.7727 9.74748 14.5903 10.2756 15.7316L14.1887 13.4724C13.5025 12.2839 12.1538 12.9737 11.6162 13.3729C11.0787 13.7722 11.3988 10.7194 10.5168 11.2461Z"
            fill={mainColor}
          />
          <path
            d="M8.08637 9.79058C7.20435 10.3173 7.31706 13.1348 7.84519 14.2762L8.19793 14.0725C8.07978 13.4926 8.02194 12.8386 8.03265 12.2298C8.04176 11.7117 8.10074 11.1916 8.23171 10.7484C8.30618 10.4964 8.41489 10.232 8.57801 10.0074C8.47461 9.76679 8.32419 9.64857 8.08637 9.79058Z"
            fill={mainColor}
          />
          <path
            d="M19.0651 8.8256C20.0053 10.1142 18.6153 10.9167 18.6153 10.9167L14.1887 13.4724M7.03784 17.601L10.2756 15.7316M10.2756 15.7316C9.74748 14.5903 9.63477 11.7727 10.5168 11.2461C11.3988 10.7194 11.0787 13.7722 11.6162 13.3729C12.1538 12.9737 13.5025 12.2839 14.1887 13.4724M10.2756 15.7316L14.1887 13.4724M7.84519 14.2762C7.31706 13.1348 7.20435 10.3173 8.08637 9.79058C8.32419 9.64857 8.47461 9.76679 8.57801 10.0074C8.41489 10.232 8.30618 10.4964 8.23171 10.7484C8.10074 11.1916 8.04176 11.7117 8.03265 12.2298C8.02194 12.8386 8.07978 13.4926 8.19793 14.0725L7.84519 14.2762ZM7.84519 14.2762L4.60742 16.1455M16.6347 7.37012C17.5749 8.65874 16.1849 9.46123 16.1849 9.46123L12.8336 11.3961"
            stroke={mainColor}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_115946">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
