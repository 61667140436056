import styled from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { IconStarWithoutBackground } from 'shared/ui/Icon/General/IconStarWithoutBackground'

export const StyledGroupedLineHeading = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.l};
  ${StyledIcon} {
    display: inline-block;
    margin-right: 8px;
  }

  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  padding: 4px 16px;
`

export const StyledIconStarWithoutBackground = styled(
  IconStarWithoutBackground
)`
  grid-area: fav;
`

export const StyledLineEvents = styled.div``
export const StyledLiveTable = styled.div``
