import React, { ComponentProps, FC, useCallback, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import range from 'lodash/range'
import { useTranslation } from 'react-i18next'
import { Event } from 'betweb-openapi-axios'
import { useGroupedEventsByGroupId } from 'astra-core/hooks/useEvents'

import {
  Button,
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/Button'
import { useCompleted } from 'hooks'
import { IconChevronRight } from 'shared/ui/Icon/General/IconChevronRight'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { EColorsNames } from 'shared/types/theme'
import { CarouselApi } from 'shared/ui/Carousel'
import {
  TableBetsHead,
  TableBetsRow
} from 'pages/page-live-events/Line/components/ui'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EventCardSkeleton } from 'pages/page-main/Main/components/EventCard/components'

import { EventCard } from '../EventCard'

import { SportsList, SportsListSkeleton } from './SportsList'
import { MainPageEventsContext } from './MainPageEvents.context'
import {
  EMainPageEventsRepresentation,
  MainPageEventsProps
} from './MainPageEvents.types'
import {
  EVENTS_TO_ONBOARDING_ATTRS_MAP,
  mainPageEventsConfiguration
} from './MainPageEvents.constants'
import { StyledSportsListWrapper } from './SportsList/SportsList.styled'
import {
  StyledEventsCarousel,
  StyledEventsTable,
  StyledMainPageEventsControlsWrapper,
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper
} from './MainPageEvents.styled'

export const MainPageEvents: FC<MainPageEventsProps> = ({
  mainPageEventsListType
}) => {
  const {
    icon: Icon,
    untranslatedTitle,
    selectLoading,
    selectEvents,
    representation,
    link
  } = mainPageEventsConfiguration[mainPageEventsListType]

  const { onboardingAttr } = useOnboardingAttr(
    EVENTS_TO_ONBOARDING_ATTRS_MAP[mainPageEventsListType],
    EOnboardingType.MAIN_PAGE_WELCOME
  )

  const { t } = useTranslation()
  const events = useSelector(selectEvents)
  const loading = useSelector(selectLoading)

  const loaded = useCompleted(!!loading)
  const showSkeleton = !loaded && events.length === 0
  const isEmpty = loaded && events.length === 0

  const carouselApi = useRef<CarouselApi>()

  const scrollToNext = useCallback(() => {
    carouselApi.current?.scrollToNext()
  }, [])

  const scrollToPrevious = useCallback(() => {
    carouselApi.current?.scrollToPrevious()
  }, [])

  if (isEmpty) {
    return null
  }

  return (
    <MainPageEventsContext.Provider value={{ mainPageEventsListType }}>
      <StyledMainPageEventsWrapper>
        <StyledMainPageEventsHeaderWrapper>
          {!!Icon && <Icon />}
          <StyledMainPageEventsHeader {...onboardingAttr}>
            {t(untranslatedTitle)}
          </StyledMainPageEventsHeader>

          <Button
            as={Link}
            size={EButtonSizes.S}
            to={link}
            view={EButtonViews.SECONDARY}
          >
            {t('mainPage goOver')}
          </Button>
        </StyledMainPageEventsHeaderWrapper>

        <StyledSportsListWrapper>
          {showSkeleton ? <SportsListSkeleton /> : <SportsList />}

          {representation === EMainPageEventsRepresentation.CAROUSEL && (
            <StyledMainPageEventsControlsWrapper>
              <Button
                iconProps={{
                  size: 16,
                  colorProps: { name: EColorsNames.Primary, value: 50 },
                  positionSide: EButtonIconPositionSides.Center
                }}
                icon={IconChevronLeft}
                size={EButtonSizes.S}
                view={EButtonViews.SECONDARY}
                onClick={scrollToPrevious}
              />
              <Button
                iconProps={{
                  size: 16,
                  colorProps: { name: EColorsNames.Primary, value: 50 },
                  positionSide: EButtonIconPositionSides.Center
                }}
                icon={IconChevronRight}
                size={EButtonSizes.S}
                view={EButtonViews.SECONDARY}
                onClick={scrollToNext}
              />
            </StyledMainPageEventsControlsWrapper>
          )}
        </StyledSportsListWrapper>
        {representation === EMainPageEventsRepresentation.CAROUSEL ? (
          <EventsCarousel
            events={events}
            showSkeleton={showSkeleton}
            onCarouselMounted={(api) => {
              carouselApi.current = api
            }}
          />
        ) : (
          <EventsTable events={events} />
        )}
      </StyledMainPageEventsWrapper>
    </MainPageEventsContext.Provider>
  )
}

type EventsCarouselProps = {
  events: Event[]
  showSkeleton: boolean
}

const EventsCarousel: FC<
  EventsCarouselProps & ComponentProps<typeof StyledEventsCarousel>
> = ({ events, showSkeleton, ...carouselProps }) => {
  return (
    <StyledEventsCarousel {...carouselProps}>
      {showSkeleton
        ? range(10).map((item) => <EventCardSkeleton key={item} />)
        : events.map((event) => <EventCard event={event} key={event.id} />)}
    </StyledEventsCarousel>
  )
}

interface EventsTableProps {
  events: Event[]
}

const EventsTable: FC<EventsTableProps> = ({ events }) => {
  const groupedEvents = useGroupedEventsByGroupId(events) // group events by tournament

  return (
    <StyledEventsTable>
      {groupedEvents.map((eventsGroup) => (
        <Fragment key={eventsGroup.id}>
          <TableBetsHead tournament={eventsGroup} />
          {eventsGroup.events.map((event) => (
            <TableBetsRow event={event} key={event.id} />
          ))}
        </Fragment>
      ))}
    </StyledEventsTable>
  )
}
