import React, { FC, useCallback, useRef, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { createPortal } from 'react-dom'

import { ITooltipProps } from './Tooltip.types'
import { StyledTooltip, StyledTooltipBody } from './Tooltip.styled'

type RefRectType = { left: number; top: number }

export const Tooltip: FC<ITooltipProps> = ({ children, title, isCommon }) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  const [maxTooltipWidth, setMaxTooltipWidth] = useState<number>(0)
  const [refRect, setRefRect] = useState<RefRectType>({ left: 0, top: 0 })
  const [width] = useWindowSize()
  const tooltipRef = useRef<HTMLDivElement>(null)

  const getMaxTooltipWidth = useCallback(
    (target) => {
      const rect: DOMRect = target.getBoundingClientRect()

      setMaxTooltipWidth(width - rect.left - 30)
      setRefRect({
        top: rect.top + rect.height + 6,
        left: rect.left
      })
    },
    [width]
  )

  const handleOnMouseEnter = (e) => {
    if (e?.currentTarget) {
      getMaxTooltipWidth(e.currentTarget)
      setIsShow(true)
    }
  }

  const handleOnMouseLeave = () => {
    setIsShow(false)
  }

  const handleOnWheel = () => {
    setIsShow(false)
  }

  return isCommon ? (
    <>{children}</>
  ) : (
    <StyledTooltip
      ref={tooltipRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onWheel={handleOnWheel}
    >
      {children}
      {isShow &&
        createPortal(
          <StyledTooltipBody maxWidth={maxTooltipWidth} rect={refRect}>
            {title}
          </StyledTooltipBody>,
          document.body
        )}
    </StyledTooltip>
  )
}
