import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import { getEvents } from 'astra-core/containers/EventsProvider'
import { getSports } from 'astra-core/containers/CommonDataProvider'
import values from 'lodash/values'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'

import { initialState } from './slice'

export const getLayoutSelectInputs = (state: RootState) =>
  state.calendarEventsContainer.layout.selectInputs ||
  initialState.layout.selectInputs

export const getFilterSelectInputPeriod = (state: RootState) =>
  state.calendarEventsContainer.filters.selectInputs.period ||
  initialState.filters.selectInputs.period

export const getFilterStream = (state: RootState) =>
  state.calendarEventsContainer.filters.isStream ??
  initialState.filters.isStream

export const getFilterInputSearch = (state: RootState) =>
  state.calendarEventsContainer.filters.inputSearch ||
  initialState.filters.inputSearch

export const getCalendarEventsIds = (state: RootState) =>
  state.calendarEventsContainer.data.eventsIds || initialState.data.eventsIds

export const getCalendarEventsFetchData = (state: RootState) =>
  state.calendarEventsContainer.data.fetchItems || initialState.data.fetchItems

export const getFilterCurrentSportId = (state: RootState) =>
  state.calendarEventsContainer.filters.currentSportId ||
  initialState.filters.currentSportId

export const selectLayoutSelectInputs = createSelector(
  [getLayoutSelectInputs],
  (selectInputs) => selectInputs
)

export const getCalendarHasMore = (state: RootState) =>
  state.calendarEventsContainer.data.hasMore || initialState.data.hasMore

export const getCalendarEventsPagination = (state: RootState) =>
  state.calendarEventsContainer.pagination || initialState.pagination

export const getCalendarEventsFetchItems = (state: RootState) =>
  state.calendarEventsContainer.data.fetchItems || initialState.data.fetchItems

/// TODO check to empty object
export const getCalendarCounters = (state: RootState) =>
  state.calendarEventsContainer.data.eventsCountersBySport ||
  initialState.data.eventsCountersBySport

export const getCalendarFilterSportId = (state: RootState) =>
  state.calendarEventsContainer.filters.currentSportId ||
  initialState.filters.currentSportId

export const selectCalendarCountersList = createSelector(
  [getSports, getCalendarCounters, getCalendarFilterSportId],
  (sports, calendarCounters, currentSportId) =>
    values(calendarCounters)
      .filter(({ count }) => count)
      .map((sport) => ({
        ...(sports[sport.id] || {}),
        ...sport,
        isActive: sport.id === currentSportId
      }))
)

export const selectCalendarEventsHasMore = createSelector(
  getCalendarHasMore,
  (hasMore) => hasMore
)

export const selectCalendarEventsPagination = createSelector(
  getCalendarEventsPagination,
  (pagination) => pagination
)

export const selectCalendarEventsFetchItems = createSelector(
  getCalendarEventsFetchItems,
  (fetchItems) => fetchItems
)

export const selectFilterStream = createSelector(
  [getFilterStream],
  (isStream) => isStream
)

export const selectFilterInputSearch = createSelector(
  [getFilterInputSearch],
  (inputSearch) => inputSearch
)

export const selectFilterSelectInputPeriod = createSelector(
  [getFilterSelectInputPeriod],
  (filter) => filter
)

export const selectCalendarEventsDataEntries = createSelector(
  [getCalendarEventsIds, getEvents, getFilterCurrentSportId],
  (eventsIds, events, currentSportId) => {
    if (eventsIds[currentSportId]) {
      return getValues(eventsIds[currentSportId], events)
    }

    return []
  }
)

export const selectCalendarEventsIsEmpty = createSelector(
  [selectCalendarEventsDataEntries],
  (calendarEvents) => isEmpty(calendarEvents)
)

export const selectCalendarEventsFetchData = createSelector(
  [getCalendarEventsFetchData],
  (fetchData) => fetchData
)
