import styled from 'styled-components'

type StyledTooltipBodyProps = {
  maxWidth: number
  rect: { left: number; top: number }
}

export const StyledTooltip = styled.div`
  position: relative;
`

export const StyledTooltipBody = styled.div<StyledTooltipBodyProps>`
  background: ${(p) => p.theme.colors.default.primary[70]};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.default.primary[10]};
  display: inline-block;
  font-size: ${(p) => p.theme.fonts.size.s};
  font-weight: ${(p) => p.theme.fonts.weight.normal};
  left: ${(p) => p.rect.left}px;
  line-height: 16px;
  max-width: ${(p) => p.maxWidth}px;
  padding: 2px 4px;
  position: absolute;
  top: ${(p) => p.rect.top}px;
  width: max-content;
  z-index: 1001;

  &::before {
    border: 6px solid transparent;
    border-bottom-color: ${(p) => p.theme.colors.default.primary[70]};
    bottom: 100%;
    content: '';
    display: inline-block;
    left: 2px;
    position: absolute;
    z-index: 1000;
  }
`
