import styled from 'styled-components'

import { SportIcon } from 'shared/ui/Icon/SportIcon/SportIcon'

import { StyledSportsListItemProps } from './SportsList.types'

export const StyledSportsListWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 0.25rem 1rem;
`

export const StyledSportsListItem = styled.div<StyledSportsListItemProps>`
  align-content: center;
  align-items: center;
  background-color: ${({ bgColor, isActive }) => (isActive ? bgColor : '')};
  border-radius: 0.25rem;
  color: ${(p) =>
    p.isActive
      ? p.theme.colors.default.primary[90]
      : p.theme.colors.default.primary[50]};
  column-gap: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.25rem 0.5rem;
`

export const StyledSportsListItemSkeleton = styled.div`
  align-items: center;
  background-color: ${(p) => p.theme.colors.default.primary[10]};
  border-radius: 0.25rem;
  column-gap: 0.25rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  height: 32px;
  margin-right: 0.25rem;
  padding: 0.25rem 0.5rem;
  width: 90px;
`

export const StyledSportsListItemLabel = styled.div`
  font-size: ${(p) => p.theme.fonts.size.m};
  white-space: nowrap;
`

export const StyledSportsListItemIcon = styled(
  SportIcon
)<StyledSportsListItemProps>`
  background-color: transparent;

  path {
    fill: ${(p) => !p.isActive && p.theme.colors.default.primary[50]};
  }
`

export const StyledSportsWrapper = styled.div`
  -ms-overflow-style: none;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
