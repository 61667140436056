import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledComponentOfUnauthorized = styled.div`
  align-items: center;
  display: flex;

  & > * {
    margin-left: 12px;
  }
`

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.custom.primary[4]};
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding: 4px 8px;

  &:hover {
    background: ${(props) => props.theme.colors.default.primary[10]};
  }

  &:active {
    background: ${(props) => props.theme.colors.default.primary[15]};
  }
`

export const StyledSignUp = styled(StyledButton)`
  background: linear-gradient(180deg, #6c7 0%, #40bf55 100%);
  color: ${(props) => props.theme.colors.fixed.primary[0]};

  &:hover {
    background: linear-gradient(180deg, #40bf55 0%, #394 100%);
  }

  &:active {
    background: linear-gradient(180deg, #394 0%, #267333 100%);
  }
`
