import styled from 'styled-components'

import { Input } from 'shared/ui/Input'

export const StyledBetCardElementWrapper = styled.div`
  position: relative;

  ${({ theme }) => `border-top: 1px solid ${theme.colors.custom.primary[4]};`}
`

export const StyledBetCardElementBorder = styled.div`
  ${({ theme }) =>
    `border-bottom: 1px solid ${theme.colors.custom.primary[4]};`}
`

export const StyledInputWrapper = styled.div`
  padding: 4px 16px;
  position: relative;
`

export const StyledSingleBetAmountWrapper = styled.div`
  width: 100%;
`

export const StyledSingleBetAmountInput = styled(Input)`
  height: 24px;
  line-height: 16px;
  text-align: right;
  width: 248px;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`
