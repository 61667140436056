import React, { FC, useCallback } from 'react'
import { selectAccountBonus } from 'astra-core/containers/AccountProvider'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  basketProviderActions,
  selectBasketBonusWallet
} from 'astra-core/containers/BasketProvider'
import { ETestIds } from 'astra-core/utils/testIds'

import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { Switch } from 'shared/ui/Switch'
import { ESwitchView } from 'shared/ui/Switch/types'

import {
  BasketBonusText,
  StyledBasketBonusWrapper,
  StyledGapRow
} from './BasketBonus.styled'

export const BasketBonus: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const bonus = useSelector(selectAccountBonus)
  const bonusWallet = useSelector(selectBasketBonusWallet)

  const isSwitchDisabled = bonus <= 0
  const handleChange = useCallback(() => {
    dispatch(basketProviderActions.setBonusWallet(!bonusWallet))
  }, [bonusWallet, dispatch])

  return (
    <StyledBasketBonusWrapper>
      <StyledGapRow gap={4}>
        <BasketBonusText>{t('use bonuses')}</BasketBonusText>
        <StyledGapRow gap={2}>
          <BasketBonusText>{bonus}</BasketBonusText>
          <IconBonus />
        </StyledGapRow>
      </StyledGapRow>
      <Switch
        active={bonusWallet}
        disabled={isSwitchDisabled}
        testId={ETestIds.TestBasketSwitch}
        view={ESwitchView.BASKET}
        onClick={handleChange}
      />
    </StyledBasketBonusWrapper>
  )
}
