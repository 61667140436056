import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchProviderActions,
  selectFetchItemsLoading,
  selectInputSearch
} from 'astra-core/containers/SearchProvider'
import { useHistory } from 'react-router-dom'
import { ETestIds } from 'astra-core/utils/testIds'

import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import { EInputSize } from 'shared/ui/Input'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'
import {
  StyledIconRightButton,
  StyledSearch,
  StyledSearchInput
} from 'widgets/FullRegistration/Search/Search.styled'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'
import { SearchList } from 'pages/page-main/Main/components/Search/SearchList'

import { SearchProps } from './Search.types'
import { StyledSearchWrapper, StyledWrapper } from './Search.styled'

export const Search = ({ isMainPage }: SearchProps) => {
  const [t] = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const valueSearch = useSelector(selectInputSearch) || ''
  const loading = useSelector(selectFetchItemsLoading)
  const { onboardingAttr: onboardingAttrFind } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.INPUT_FIND,
    EOnboardingType.MAIN_PAGE_WELCOME
  )
  useEffect(() => {
    return () => {
      dispatch(searchProviderActions.setSearchMain({ valueSearch: '' }))
    }
  }, [dispatch])

  const handlerInputSearch = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(searchProviderActions.setSearchMain({ valueSearch: value })),
        10
      ),
    [dispatch]
  )

  const debouncedChangeInputSearch = useCallback(
    (event) => handlerInputSearch(event.target.value),
    [handlerInputSearch]
  )

  const clearInputSearch = useCallback(
    () => dispatch(searchProviderActions.setSearchMain({ valueSearch: '' })),
    [dispatch]
  )

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(ERoutes.Search, { valueSearch })
    }
  }

  const searchWrapperBlock = useRef<HTMLDivElement>(null)

  const outsideClickSearch = useCallback(
    (e) => {
      if (!searchWrapperBlock.current?.contains(e.target)) {
        dispatch(searchProviderActions.setSearchMain({ valueSearch: '' }))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (valueSearch.length) {
      document.addEventListener('click', outsideClickSearch)
      return () => {
        document.removeEventListener('click', outsideClickSearch)
      }
    }
  }, [outsideClickSearch, valueSearch])

  return (
    <StyledSearchWrapper isMainPage={isMainPage}>
      <StyledSearch {...onboardingAttrFind}>
        <StyledSearchInput
          customSize={EInputSize.L}
          icon={IconSearch}
          id={ETestIds.TestSearchInput}
          placeholder={t('mainPage search placeholder')}
          rightPadding={28}
          value={valueSearch}
          onChange={debouncedChangeInputSearch}
          onKeyDown={handleKeyDown}
        />
        <StyledIconRightButton onClick={clearInputSearch}>
          {!!valueSearch.length &&
            (loading ? (
              <LoaderSpinner small />
            ) : (
              <IconClose
                colorProps={{ name: EColorsNames.Primary, value: 50 }}
                size={10}
              />
            ))}
        </StyledIconRightButton>
        <StyledWrapper ref={searchWrapperBlock}>
          <SearchList isMainPage={isMainPage} />
        </StyledWrapper>
      </StyledSearch>
    </StyledSearchWrapper>
  )
}
