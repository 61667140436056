import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportNetball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M15.1792 4.65677C15.1191 4.68977 15.0513 4.7109 14.9786 4.71675C13.5506 4.83179 12.0471 5.43857 10.878 6.62036C9.77332 7.73709 8.94315 9.39172 8.77758 11.6948C9.55563 11.924 10.4523 11.9934 11.4804 11.8056C11.6867 10.6014 12.2648 9.50801 13.1006 8.64294C14.296 7.40554 16.0207 6.63256 17.9533 6.65661C17.1886 5.80506 16.2439 5.11849 15.1792 4.65677Z"
          fill={mainColor}
        />
        <path
          d="M18.7606 7.72186C17.1094 7.48125 15.6019 7.92006 14.4694 8.76606C15.8134 9.33079 17.1601 10.6331 17.9693 12.2857C18.5507 13.4731 18.869 14.8665 18.7192 16.3468C19.5295 15.0956 20 13.6038 20 12.0021C20 10.4278 19.5455 8.95968 18.7606 7.72186Z"
          fill={mainColor}
        />
        <path
          d="M17.2896 18.0055C18.0581 16.0686 17.8009 14.216 17.0712 12.7257C16.2303 11.0082 14.7985 9.83641 13.6371 9.53685C13.0482 10.2121 12.6422 11.0256 12.4781 11.9068C12.9238 12.1385 13.2926 12.5191 13.5819 12.9601C13.9602 13.5367 14.2311 14.2574 14.3802 15.0199C14.6893 16.6009 14.4979 18.5616 13.4089 19.8805C14.881 19.6189 16.2138 18.9546 17.2896 18.0055Z"
          fill={mainColor}
        />
        <path
          d="M11.7389 20C11.7737 19.9609 11.8153 19.9266 11.8631 19.8991C13.1423 19.1629 13.6385 17.4966 13.4909 15.8584C10.529 17.1792 8.13804 17.0275 6.31761 16.2086C5.68575 15.9244 5.13035 15.5633 4.64905 15.1646C5.84222 17.9358 8.55575 19.8979 11.7389 20Z"
          fill={mainColor}
        />
        <path
          d="M4.07363 13.0923C4.66244 13.9105 5.54391 14.7638 6.72776 15.2964C8.29711 16.0023 10.4649 16.175 13.3136 14.8397C13.1788 14.3274 12.983 13.8703 12.7458 13.5089C12.4824 13.1074 12.1901 12.8552 11.9013 12.7418C10.4296 13.056 9.16457 12.9157 8.09817 12.5227C8.08799 12.5192 8.07796 12.5155 8.06809 12.5115C7.4256 12.271 6.85569 11.9388 6.35659 11.5595C5.50867 10.9151 4.86851 10.1386 4.41387 9.45479C4.14544 10.2549 4 11.1115 4 12.0021C4 12.3719 4.02508 12.7359 4.07363 13.0923Z"
          fill={mainColor}
        />
        <path
          d="M4.8937 8.32321C6.22534 5.75484 8.90779 4 12 4C12.3845 4 12.7627 4.02713 13.1327 4.07959C12.0694 4.4325 11.0388 5.03574 10.1672 5.91681C8.93369 7.16374 8.04076 8.94465 7.80756 11.3021C7.50296 11.1431 7.22135 10.9604 6.96155 10.763C5.97219 10.0111 5.29322 9.03835 4.8937 8.32321Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
