import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  MainOutcomeType,
  selectMainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'
import { useProbs } from 'astra-core/hooks/useEvents'
import { Event } from 'betweb-openapi-axios'

import { getMainOutcomeProbability } from 'shared/lib/outcomes'

export const useGetEventProbability = ({
  event,
  outcomeTypesName
}: {
  event: Event
  outcomeTypesName: Array<MainOutcomeType>
}) => {
  const mainOutcomeTypes = useSelector(selectMainOutcomeTypes)

  const probs = useProbs(event)

  const getOutcomeProbability = useCallback(
    (outcomeTypeName) =>
      getMainOutcomeProbability({
        probs,
        mainOutcomeTypes,
        mainOutcomeType: outcomeTypeName
      }),
    [mainOutcomeTypes, probs]
  )

  const eventProbability = useMemo(() => {
    const matchingMainOutcomeType = outcomeTypesName.find((outcomeTypeName) =>
      getOutcomeProbability(outcomeTypeName)
    )
    return getOutcomeProbability(matchingMainOutcomeType)
  }, [getOutcomeProbability, outcomeTypesName])

  return { eventProbability }
}
