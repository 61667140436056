import React, { FC, Fragment, memo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectSportsCountersList } from 'astra-core/containers/SportCountersProvider'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { RootState } from 'shared/types/store'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'

import {
  ISportsMenuListProps,
  SportsMenuItemProps
} from './SportMenuList.types.ts'
import {
  StyledSportMenuListSeparateTopLine,
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './SportsMenuList.styled'
import { isActiveSport, getMenuTabSportsCounterType } from './utils'

export const SportsMenuList: FC<ISportsMenuListProps> = ({
  currentTabMenu
}) => {
  const { pathname } = useLocation()
  const sportsCountersList = useSelector((state: RootState) =>
    selectSportsCountersList(state, getMenuTabSportsCounterType(currentTabMenu))
  )

  return (
    <StyledSportsMenuList>
      {sportsCountersList.map((sport, index) => {
        const isLastTopSport =
          sport.top &&
          !!sportsCountersList[index + 1] &&
          !sportsCountersList[index + 1].top

        return (
          <Fragment key={sport.id}>
            <SportsMenuItem
              active={isActiveSport(pathname, currentTabMenu, sport.id)}
              currentTabMenu={currentTabMenu}
              key={sport.id}
              sport={sport}
            >
              {sport.name}
            </SportsMenuItem>

            {isLastTopSport && <StyledSportMenuListSeparateTopLine />}
          </Fragment>
        )
      })}
    </StyledSportsMenuList>
  )
}

const SportsMenuItem: FC<SportsMenuItemProps> = memo(
  ({ sport, currentTabMenu, active }) => {
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
      dispatch(lineTournamentsContainerActions.resetLineFilter())
      dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: false }))
    }, [dispatch])

    return (
      <StyledSportsMenuItem
        $active={active}
        to={`/${currentTabMenu}/${sport.id}`}
        onClick={handleClick}
      >
        <SportIcon
          isActive={sport.top}
          sport={sport.code as ESportsCodes}
          withBackground
        />

        <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>

        <StyledSportsMenuItemCount $active={active}>
          {sport.count}
        </StyledSportsMenuItemCount>
      </StyledSportsMenuItem>
    )
  }
)
