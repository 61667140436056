import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventLiveTracker: FC<IconProps> = (props) => (
  <Icon size={12} {...props}>
    <svg fill="none" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M7 2.17071C8.16519 2.58254 9 3.69378 9 5C9 6.30622 8.16519 7.41746 7 7.82929V10H10C11.1046 10 12 9.10457 12 8V2C12 0.895431 11.1046 0 10 0H7V2.17071ZM5 0V2.17071C3.83481 2.58254 3 3.69378 3 5C3 6.30622 3.83481 7.41746 5 7.82929V10H2C0.895431 10 0 9.10457 0 8V2C0 0.89543 0.895431 0 2 0H5ZM7 5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
