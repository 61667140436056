import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

export const StyledEventCardWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[10]};
  border: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  border-radius: 0.5rem;
  cursor: pointer;
  display: grid;
  row-gap: 0.5rem;
  width: 280px;
`

export const StyledEventCardMetaWrapper = styled.div`
  display: grid;
`

export const StyledEventCardHeaderWrapper = styled.div`
  display: grid;
  padding: 18px 1rem 8px 1rem;
`

export const StyledEventCardHeader = styled.div`
  align-items: center;
  display: grid;
  font-size: ${(props) => props.theme.fonts.size.s};
  grid-template-columns: minmax(0, 1fr) auto;
  padding-bottom: 4px;
`

export const StyledEventCardName = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[50]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledEventCardCompetitor = styled.div<{
  isLongTeamName?: boolean
}>`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.custom.primary[11]};
    column-gap: 0.25rem;
    display: grid;
    font-size: ${props.theme.fonts.size[props.isLongTeamName ? 's' : 'm']};
    font-weight: ${props.theme.fonts.weight.semiBold};
    grid-template-columns: auto 1fr;
    height: 1.25rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    ${props.isLongTeamName &&
    css`
      ::after {
        background: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
          ? `linear-gradient(
        270deg,
        #262626 0%,
        rgba(38, 38, 38, 0.88) 59.9%,
        rgba(38, 38, 38, 0) 100%
      )`
          : `linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.88) 59.9%, rgba(255, 255, 255, 0) 100%);`};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 15px;
      }
    `}
  `}
`

export const StyledEventCardDate = styled.div`
  color: ${(props) => props.theme.colors.default.primary[50]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xxs};
`

export const AdditionalInfoWrapper = styled.div`
  padding: 0 0 0 1rem;
`

export const CurrentServerFlag = styled.div`
  background-color: ${(props) => props.theme.colors.default.green[50]};
  border-radius: 1px;
  flex-shrink: 0;
  height: 100%;
  width: 2px;
`
