import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportMoto: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M16.1958 10.7615C18.5972 10.7615 20.4819 13.0013 19.8891 15.5154C19.57 16.8563 18.4756 17.9534 17.1381 18.2733C16.819 18.3495 16.4998 18.38 16.1958 18.38C13.7958 18.38 11.9112 16.1402 12.5191 13.6261C12.7015 12.849 13.1727 12.1633 13.7792 11.6605L13.3232 10.8072C12.2151 11.63 11.516 12.9861 11.5297 14.5708V16.1554C11.5297 16.582 11.1954 16.9172 10.7698 16.9172H4.79963C4.37406 16.9172 4.0411 16.9172 4.03968 16.1554C4.03864 15.5949 4.03916 11.5512 4.0395 9.45241C4.03961 8.80846 4.6406 8.33224 5.27674 8.4322C5.74324 8.50551 6.2656 8.5674 6.69949 8.5674C9.07055 8.5674 9.98091 7.13513 10.3457 6.19044H9.79852C9.37453 6.19044 9.03857 5.83999 9.03857 5.42859C9.03857 5.00196 9.37453 4.66675 9.79852 4.66675H11.3184C11.3944 4.66675 13.1727 4.66675 14.7975 5.58096C16.6822 6.63231 17.6245 8.8569 17.6549 8.94833C17.7613 9.19212 17.7309 9.48162 17.5637 9.69494C17.3965 9.92349 17.1077 10.0301 16.8494 9.99967C16.5606 9.95396 16.2414 9.93873 15.8918 9.95396C15.4662 9.95396 15.0711 10.0301 14.6911 10.1368L15.1167 10.9291C15.1607 10.9139 15.2079 10.8834 15.2535 10.8834C15.5741 10.8072 15.8933 10.7615 16.1958 10.7615ZM18.3996 15.165C18.582 14.4336 18.43 13.7175 17.9877 13.1537C17.5485 12.6052 16.8934 12.2852 16.1942 12.2852C16.0894 12.2852 15.9678 12.3157 15.8598 12.3309L16.863 14.2051C17.0606 14.586 16.9238 15.0431 16.559 15.2412C16.4374 15.3021 16.3158 15.3326 16.1942 15.3326C15.9206 15.3326 15.6623 15.1955 15.527 14.9364L14.5087 13.0318C14.2655 13.3061 14.068 13.6261 13.992 13.9765C13.8096 14.7079 13.96 15.424 14.4023 15.9878C14.8431 16.5363 15.4966 16.8563 16.1958 16.8563C16.3934 16.8563 16.5924 16.8411 16.79 16.7801C17.5637 16.5973 18.2173 15.9421 18.3996 15.165Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
