import React, { memo } from 'react'

import { useHandleMarqueeWidth } from 'shared/ui/Marquee/hooks'

import { StyledTitleString } from './styled'
import { MarqueeComponentProps } from './types'

export const Marquee = memo(({ children, id }: MarqueeComponentProps) => {
  const { wrapperRef, marqueeWidth, stringRef } = useHandleMarqueeWidth()

  return (
    <StyledTitleString
      idKey={id || ''}
      marqueeWidth={marqueeWidth}
      ref={wrapperRef}
    >
      <span ref={stringRef}> {children}</span>
    </StyledTitleString>
  )
})
