import { useEffect, useRef, useState } from 'react'

import { useOnboardingItemContext } from 'widgets/onboarding/Onboarding.context'

import { getPositionData } from './utils'

export const useOnboardingContentCoords = () => {
  const onboardingContentRef = useRef<HTMLDivElement>(null)
  const [coords, setCoords] = useState<{ left: string; top: string }>()
  const { positionContent, itemNodeRectProps } = useOnboardingItemContext()

  useEffect(() => {
    if (itemNodeRectProps && onboardingContentRef.current && positionContent) {
      const blockCoords = getPositionData({
        position: positionContent,
        itemNodeRectProps,
        onboardingContentBlockProps: onboardingContentRef.current
      })

      setCoords(blockCoords)
    }
  }, [positionContent, itemNodeRectProps])

  return { coords, onboardingContentRef }
}
