import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useToggleEventFavourite } from 'astra-core/hooks'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

// import { IconStatistics } from 'shared/ui/Icon/General/IconStatistics'
import { IconLiveTracker } from 'shared/ui/Icon/General/IconLiveTracker'
// import { IconStreamFilled } from 'shared/ui/Icon/General/IconStreamFilled'
import { IconH2H } from 'shared/ui/Icon/General/IconH2H'
import { IconStar } from 'shared/ui/Icon/General/IconStar'
import { matchTrackerModel } from 'entities/match-tracker'
import { getEventBetradarSourceId } from 'shared/lib/events'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'

import { ICON_ACTION_PROPS } from './constants'
import {
  ActionFavouriteProps,
  ActionItemProps,
  ActionLiveTrackerProps,
  ActionStatisticsProps
} from './Actions.types'
import {
  StyledActionFavourite,
  StyledActions,
  StyledActionsItem
} from './Actions.styled'

export const ActionsList = memo(() => {
  const { event } = useContext(EventContext) as IEventContext

  return (
    <StyledActions>
      <ActionFavourite eventId={event.id} />
      {/* <ActionItem title="statistics" Icon={IconStatistics} /> */}

      {event.live ? (
        <>
          <ActionLiveTraker event={event} />
          {/* <ActionItem title="stream" Icon={IconStreamFilled} /> */}
        </>
      ) : (
        <>
          <ActionH2H event={event} />
        </>
      )}
    </StyledActions>
  )
})

const ActionItem: FC<ActionItemProps> = ({ title, Icon, ...props }) => {
  const [t] = useTranslation()

  return (
    <StyledActionsItem {...props}>
      <Icon {...ICON_ACTION_PROPS} />

      {t(title)}
    </StyledActionsItem>
  )
}

const ActionFavourite: FC<ActionFavouriteProps> = ({ eventId }) => {
  const [t] = useTranslation()
  const user = useSelector(selectAccountUser)

  const { isEventFavourite, toggleEventFavourite } =
    useToggleEventFavourite(eventId)

  const handleClick = useCallback(() => {
    if (user) {
      // TODO: if favourite functional is haven't for unauthorized users than move this condition useToggleEventFavourite in astra-core
      toggleEventFavourite()
    }
  }, [toggleEventFavourite, user])

  return (
    <StyledActionFavourite $isActive={isEventFavourite} onClick={handleClick}>
      <IconStar {...ICON_ACTION_PROPS} />

      {t(isEventFavourite ? 'in favorite' : 'favorite')}
    </StyledActionFavourite>
  )
}

const ActionH2H: FC<ActionStatisticsProps> = ({ event }) => {
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useCallback(
    () => window.open(`/headToHead/${eventSourceId}`, 'head2head'),
    [eventSourceId]
  )

  if (!eventSourceId) {
    return null
  }

  return <ActionItem Icon={IconH2H} title="H2H" onClick={handleClick} />
}

const ActionLiveTraker: FC<ActionLiveTrackerProps> = ({ event }) => {
  const dispatch = useDispatch()
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  useEffect(() => {
    dispatch(matchTrackerModel.liveMatchTrackerActions.clearCurrentMatch())
  }, [dispatch, event.id])

  const handleClick = useCallback(() => {
    if (eventSourceId) {
      dispatch(
        matchTrackerModel.liveMatchTrackerActions.setCurrentMatch({
          eventSourceId
        })
      )
    }
  }, [dispatch, eventSourceId])

  if (!eventSourceId) {
    return null
  }

  return (
    <ActionItem
      Icon={IconLiveTracker}
      title="live tracker"
      onClick={handleClick}
    />
  )
}
