import React, { MouseEvent, FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectOutcomeTypes,
  selectMainOutcomeTypes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { basketProviderActions } from 'astra-core/containers/BasketProvider'
import { useProbs } from 'astra-core/hooks/useEvents'
import { activeProbabilityStatus } from 'astra-core/containers/BasketProvider/constants'
import { getBetOutcomeId } from 'astra-core'

import { getMainOutcomeTypeProbability } from 'shared/lib/events'
import { useEventMode } from 'hooks'
import { RootState } from 'shared/types/store'

import {
  StyledEventCardProbabilities,
  StyledEventCardProbabilitiesItem,
  StyledEventCardProbabilitiesItemCode,
  StyledEventCardProbabilitiesItemValue,
  StyledEventCardProbabilitiesItemWrapper
} from './EventCardProbabilities.styled'
import {
  EventCardProbabilitiesProps,
  EventCardProbabilityCellProps
} from './EventCardProbabilities.types'
import { MAIN_TYPES } from './constants'

export const EventCardProbabilities: FC<EventCardProbabilitiesProps> = ({
  event
}) => {
  const sportData = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const typesMap = MAIN_TYPES[sportData?.code || ''] || MAIN_TYPES.default

  return (
    <StyledEventCardProbabilities>
      {typesMap.map((type) => (
        <ProbabilityCell
          cardMainOutcomeTypes={type}
          event={event}
          key={type[0]}
        />
      ))}
    </StyledEventCardProbabilities>
  )
}

const ProbabilityCell: FC<EventCardProbabilityCellProps> = ({
  cardMainOutcomeTypes,
  event
}) => {
  const dispatch = useDispatch()
  const mainOutcomeTypes = useSelector(selectMainOutcomeTypes)
  const outcomeTypes = useSelector(selectOutcomeTypes)
  const probs = useProbs(event)

  const getOutcomeProbability = useCallback(
    (outcomeTypeName) =>
      getMainOutcomeTypeProbability({
        outcomeType: outcomeTypeName,
        probs,
        mainOutcomeTypes,
        eventId: event.id
      }),
    [event.id, mainOutcomeTypes, probs]
  )

  const eventProbability = useMemo(() => {
    const matchingMainOutcomeType = cardMainOutcomeTypes.find(
      (outcomeTypeName) => getOutcomeProbability(outcomeTypeName)
    )

    return getOutcomeProbability(matchingMainOutcomeType)
  }, [getOutcomeProbability, cardMainOutcomeTypes])

  const handleAddOutcomeToBasket = useCallback(
    (clickEvent: MouseEvent) => {
      clickEvent.stopPropagation()

      if (
        eventProbability &&
        activeProbabilityStatus.includes(eventProbability.tradingStatus)
      ) {
        dispatch(
          basketProviderActions.addOutcomeToBasket({
            eventProbability,
            eventId: event.id
          })
        )
      }
    },
    [eventProbability, dispatch, event.id]
  )

  const outcomeMode = useEventMode(eventProbability)

  return (
    <StyledEventCardProbabilitiesItemWrapper>
      <StyledEventCardProbabilitiesItem
        key={getBetOutcomeId({
          eventId: event.id,
          outcomeTypeId: eventProbability.outcomeTypeId,
          parameters: eventProbability.parameters
        })}
        $mode={outcomeMode}
        onClick={handleAddOutcomeToBasket}
      >
        {!!eventProbability.outcomeTypeId && (
          <>
            <StyledEventCardProbabilitiesItemCode $mode={outcomeMode}>
              {outcomeTypes[eventProbability.outcomeTypeId]?.shortName}
            </StyledEventCardProbabilitiesItemCode>
            <StyledEventCardProbabilitiesItemValue $mode={outcomeMode}>
              {eventProbability.odd || '-'}
            </StyledEventCardProbabilitiesItemValue>
          </>
        )}
      </StyledEventCardProbabilitiesItem>
    </StyledEventCardProbabilitiesItemWrapper>
  )
}
