import { useEffect, useState } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

export const TIME_DATA_FORMAT = 'HH:mm'

export const useGetNowTime = (format: string): string => {
  const [date, setDate] = useState(dayjs().format(format))

  useEffect(() => {
    const timer = setInterval(() => setDate(dayjs().format(format)), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  return date
}
