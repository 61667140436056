import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportAmericanFootball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M19.5439 16.9866L19.6028 16.6335C19.6885 16.1192 19.3647 15.6254 18.8589 15.4989L16.0134 14.7875M19.5439 16.9866L19.2384 18.82C19.1532 19.331 18.6846 19.7055 18.1781 19.5967C15.0785 18.9306 16.855 16.3027 16.0134 14.7875M19.5439 16.9866L13.0879 16.0562M13.0879 14.0562L16.0134 14.7875"
          stroke={mainColor}
        />
        <path
          clipRule="evenodd"
          d="M17.8969 8.73981C15.9715 4.56993 11.8472 2.65935 7.33416 5.33331C3.50361 7.60288 3.65645 14.3044 5.49282 16.7562C5.58946 16.8852 5.70823 16.9989 5.86333 17.0429C6.09651 17.109 6.54375 17.1581 7.33414 17C8.02446 16.8619 8.48605 17.2957 9.02683 17.804C9.79163 18.5228 10.7148 19.3905 12.6675 19C16.0008 18.3333 15.4832 14.4472 15.3341 14C15.0008 13 15.6675 12.6666 17.0008 12.3333C17.4036 12.2326 17.7241 12.0653 17.9728 11.8782C18.5006 11.4813 18.5802 10.7875 18.4258 10.1435L14.4538 11.6712C14.0672 11.8199 13.6333 11.6271 13.4846 11.2405C13.3359 10.8539 13.5287 10.4199 13.9153 10.2712L17.8969 8.73981ZM12.3982 14.5742C12.3982 15.1265 11.9505 15.5742 11.3982 15.5742C10.846 15.5742 10.3982 15.1265 10.3982 14.5742C10.3982 14.0219 10.846 13.5742 11.3982 13.5742C11.9505 13.5742 12.3982 14.0219 12.3982 14.5742Z"
          fill={mainColor}
          fillRule="evenodd"
        />
      </>
    )}
  </SportIconWrapper>
)
