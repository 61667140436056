import React, { FC, useMemo, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectCountSystemCombinations,
  selectDisabledButtonExpressSystem,
  selectMakeBetErrorsBottom,
  selectMaxBetExpressSystem,
  selectSystemAmount,
  selectSystemSize
} from 'astra-core/containers/BasketProvider'
import { validateBasketInputValue } from 'astra-core'

import { ESelectView } from 'shared/ui/Select'

import {
  BasketButton,
  BasketError,
  BasketInput,
  BetCardElement,
  CommonBlockClearAll,
  CustomTopLayerAboveInput
} from '../../BasketCommon/Common'
import { StyledBetCardElementBorder } from '../../BasketCommon/Common.styled'

import {
  formatCountSystemCombinations,
  getSystemMaxWinSum
} from './SystemCombination'
import {
  StyledInputWrapper,
  StyledSelect
  // StyledSystemCalculatorButton
} from './System.styled'
import { SystemProps } from './System.types'

export const System: FC<SystemProps> = ({ outcomes }) => {
  const [t] = useTranslation()
  const basketInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const systemAmount = useSelector(selectSystemAmount)
  const systemSize = useSelector(selectSystemSize)
  const maxBetExpressSystem = useSelector(selectMaxBetExpressSystem)
  const errorsBottom = useSelector(selectMakeBetErrorsBottom)
  const disabledButton = useSelector(selectDisabledButtonExpressSystem)
  const combinations = useSelector(selectCountSystemCombinations)
  const isBonusWallet = useSelector(selectBasketBonusWallet)

  const formattedCountSystemCombinations = useMemo(
    () =>
      formatCountSystemCombinations({
        combinations,
        t
      }),
    [combinations, t]
  )

  const setOutcomeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        basketProviderActions.setSystemAmount({
          amount: validateBasketInputValue(e)
        })
      )
    },
    [dispatch]
  )

  const maxSystemWinSum = useMemo(() => {
    const amount = (systemAmount !== '' && systemAmount) || 0

    return (
      getSystemMaxWinSum(systemSize, +amount, outcomes) -
      +amount * +isBonusWallet
    )
  }, [systemAmount, systemSize, outcomes, isBonusWallet])

  const handleSelectSystemSize = useCallback(
    (selectedOption) => {
      dispatch(basketProviderActions.setSystemSize(selectedOption.value))
    },
    [dispatch]
  )

  const selectedSystemSize = useMemo(
    () =>
      formattedCountSystemCombinations.find(
        (combination) => combination.value === systemSize
      ),
    [formattedCountSystemCombinations, systemSize]
  )

  const makeInputFocus = useCallback(() => {
    if (basketInputRef?.current) {
      basketInputRef.current.focus()
    }
  }, [])

  return (
    <>
      <StyledBetCardElementBorder />
      {outcomes.map((outcome) => (
        <BetCardElement key={outcome.id} outcome={outcome} />
      ))}
      <CommonBlockClearAll />
      {/* <StyledSystemCalculatorButton>
        {t('system calculator')}
      </StyledSystemCalculatorButton> */}
      <StyledInputWrapper>
        <StyledSelect
          isSearchable={false}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          options={formattedCountSystemCombinations}
          placeholder={t('select')}
          value={selectedSystemSize}
          view={ESelectView.Basket}
          onChange={handleSelectSystemSize}
        />
      </StyledInputWrapper>

      <StyledInputWrapper>
        <CustomTopLayerAboveInput
          makeInputFocus={makeInputFocus}
          maxBet={maxBetExpressSystem}
          stakeAmount={systemAmount}
          winSum={maxSystemWinSum}
        />
        <BasketInput
          inputRef={basketInputRef}
          value={systemAmount}
          onChange={setOutcomeAmount}
        />
      </StyledInputWrapper>
      {errorsBottom.map((error) => (
        <BasketError error={error} key={error.code} />
      ))}
      <BasketButton disabledButton={disabledButton} />
    </>
  )
}
