import React, { FC, useContext, useMemo } from 'react'
import { Competitor } from 'betweb-openapi-axios'

// import { IconEmblem } from 'shared/ui/Icon/General/IconEmblem'

import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'

import { useEventBannerData } from '../../hooks'

import { EventDateTime, Stats } from './components'
import { LiveIndicator } from './components/LiveIndicator/LiveIndicator'
import { ActionsList } from './components/Actions/Actions'
import { SIZE_LONG_NAME } from './constants'
import {
  CurrentServerFlag,
  StyledBannerComment,
  StyledBannerCompetitor,
  StyledBannerCompetitors,
  StyledBannerCompetitorWrapper,
  StyledBannerContent,
  StyledBannerContentHead,
  StyledBannerScore,
  StyledEventBanner,
  StyledEventBannerWrapper
} from './EventBannerLarge.styled'
import { ScoreByPeriods } from './components/ScoreByPeriods'

export const EventBannerLarge: FC = () => {
  const { event } = useContext(EventContext) as IEventContext
  const { competitors, bannerScore, containerRef, currentServer } =
    useEventBannerData()

  const isLive = useMemo(() => event.live, [event.live])

  return (
    <StyledEventBannerWrapper ref={containerRef}>
      <StyledEventBanner>
        {/* <StyledTeamLogo>
          <IconEmblem size={64} />
        </StyledTeamLogo> */}

        <StyledBannerContent>
          <StyledBannerContentHead>
            {isLive && <LiveIndicator />}

            <EventDateTime />
          </StyledBannerContentHead>

          <EventCompetitorsBanner
            bannerScore={bannerScore}
            competitors={competitors}
            currentServer={currentServer}
          />

          {!!event.comments.length && (
            <StyledBannerComment>
              {event.comments.join(', ')}
            </StyledBannerComment>
          )}
          {isLive && (
            <>
              <ScoreByPeriods />
              <Stats eventId={event.id} />
            </>
          )}

          <ActionsList />
        </StyledBannerContent>

        {/* <StyledTeamLogo>
          <IconEmblem size={64} />
        </StyledTeamLogo> */}
      </StyledEventBanner>
    </StyledEventBannerWrapper>
  )
}

const EventCompetitorsBanner: FC<{
  competitors: {
    homeCompetitors: Competitor[]
    awayCompetitors: Competitor[]
  }
  bannerScore: string
  currentServer?: any
}> = ({ competitors, bannerScore, currentServer }) => {
  const isLongName =
    competitors.homeCompetitors[0]?.name.length >= SIZE_LONG_NAME ||
    competitors.awayCompetitors[0]?.name.length >= SIZE_LONG_NAME

  const hasCurrentServer = !!currentServer?.value

  return (
    <StyledBannerCompetitors>
      <StyledBannerCompetitorWrapper>
        <StyledBannerCompetitor isLongName={isLongName}>
          {competitors.homeCompetitors[0]?.name}
        </StyledBannerCompetitor>
        {hasCurrentServer && (
          <CurrentServerFlag $isActive={currentServer.value === '1'} />
        )}
      </StyledBannerCompetitorWrapper>

      <StyledBannerScore>{bannerScore}</StyledBannerScore>

      <StyledBannerCompetitorWrapper reverse>
        {hasCurrentServer && (
          <CurrentServerFlag $isActive={currentServer.value === '2'} />
        )}
        <StyledBannerCompetitor isLongName={isLongName}>
          {competitors.awayCompetitors[0]?.name}
        </StyledBannerCompetitor>
      </StyledBannerCompetitorWrapper>
    </StyledBannerCompetitors>
  )
}
