import { PayloadAction } from '@reduxjs/toolkit'
import { BannerList } from 'betweb-openapi-axios'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'
import { requestInitialState } from 'shared/lib/api'
import { groupEventItemsBySport } from 'shared/lib/events'

import {
  ContainerState,
  FetchTopSuccessPayload,
  SelectSportPayload
} from './types'

const REDUCER_KEY = 'mainPageContainer'

// The initial state of the SportCountersProvider container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  banners: {
    items: [],
    host: '',
    fetchItems: requestInitialState
  },
  topLineEvents: {
    eventsIdsBySport: {},
    currentSportId: 0,
    fetchItems: requestInitialState
  },
  topLiveEvents: {
    eventsIdsBySport: {},
    currentSportId: 0,
    fetchItems: requestInitialState
  },
  upcomingEvents: {
    eventsIdsBySport: {},
    currentSportId: 0,
    fetchItems: requestInitialState
  }
})

const mainPageContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    /**
     * Banners
     */
    fetchBanners(state) {
      state.banners.fetchItems.loading = true
    },
    fetchBannersSuccess(state, { payload }: PayloadAction<BannerList>) {
      state.banners.host = payload.host
      state.banners.items = payload.items
      state.banners.fetchItems.loading = false
    },
    fetchBannersError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.banners.fetchItems.loading = false
      state.banners.fetchItems.error = payload
    },
    /**
     * Top Live
     */
    selectTopLiveEventsSport(
      state,
      { payload }: PayloadAction<SelectSportPayload>
    ) {
      state.topLiveEvents.currentSportId = payload.sportId
    },
    fetchTopLiveEvents(state) {
      state.topLiveEvents.fetchItems.loading = true
    },
    fetchTopLiveEventsSuccess(
      state,
      { payload }: PayloadAction<FetchTopSuccessPayload>
    ) {
      const { items } = payload.resData
      const groupsBySport = groupEventItemsBySport(items)
      state.topLiveEvents.eventsIdsBySport = groupsBySport
      state.topLiveEvents.fetchItems.loading = false

      const firstSport = Object.keys(groupsBySport)[0]
      if (firstSport) {
        state.topLiveEvents.currentSportId = Number(firstSport)
      }
    },
    fetchTopLiveEventsError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.topLiveEvents.fetchItems.loading = false
      state.topLiveEvents.fetchItems.error = payload
    },
    /**
     * Top Live
     */
    selectTopLineEventsSport(
      state,
      { payload }: PayloadAction<SelectSportPayload>
    ) {
      state.topLineEvents.currentSportId = payload.sportId
    },
    fetchTopLineEvents(state) {
      state.topLineEvents.fetchItems.loading = true
    },
    fetchTopLineEventsSuccess(
      state,
      { payload }: PayloadAction<FetchTopSuccessPayload>
    ) {
      const { items } = payload.resData
      const groupsBySport = groupEventItemsBySport(items)
      state.topLineEvents.eventsIdsBySport = groupsBySport
      state.topLineEvents.fetchItems.loading = false

      const firstSport = Object.keys(groupsBySport)[0]
      if (firstSport) {
        state.topLineEvents.currentSportId = Number(firstSport)
      }
    },
    fetchTopLineEventsError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.topLineEvents.fetchItems.loading = false
      state.topLineEvents.fetchItems.error = payload
    },
    /**
     * Upcoming
     */
    selectUpcomingEventsSport(
      state,
      { payload }: PayloadAction<SelectSportPayload>
    ) {
      state.upcomingEvents.currentSportId = payload.sportId
    },
    fetchUpcomingEvents(state) {
      state.upcomingEvents.fetchItems.loading = true
    },
    fetchUpcomingEventsSuccess(
      state,
      { payload }: PayloadAction<FetchTopSuccessPayload>
    ) {
      const { items } = payload.resData
      const groupsBySport = groupEventItemsBySport(items)
      state.upcomingEvents.eventsIdsBySport = groupsBySport
      state.upcomingEvents.fetchItems.loading = false

      const firstSport = Object.keys(groupsBySport)[0]
      if (firstSport) {
        state.upcomingEvents.currentSportId = Number(firstSport)
      }
    },
    fetchUpcomingEventsError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.upcomingEvents.fetchItems.loading = false
      state.upcomingEvents.fetchItems.error = payload
    }
  }
})

export const {
  actions: mainPageContainerActions,
  reducer,
  name: sliceKey
} = mainPageContainerSlice
