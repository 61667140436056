import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getShowBasketOnSmallWindow = (state: RootState) =>
  state.rightBasketContainerState.layout.isShowBasketOnSmallWindow ??
  initialState.layout.isShowBasketOnSmallWindow

export const selectIsShowBasketOnSmallWindow = createSelector(
  getShowBasketOnSmallWindow,
  (isShow) => isShow
)
