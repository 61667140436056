import React, { FC, useMemo } from 'react'
import { MainOutcomeTypes } from 'astra-core/containers/CommonDataProvider'
import { getEventRowTestId } from 'astra-core/utils/testIds'
import { ParameterType } from 'betweb-openapi-axios'

import { useEventMode } from 'hooks'
import { getOutcomeParameterValue } from 'shared/lib/outcomes'
import { EventCoefficientTable as EventCoefficientTableEntity } from 'entities/event'
import { useHandleAddCoefficientToBasket } from 'features/event'

import { useGetEventProbability } from '../../lib/coefficient/get-event-probability'
import { EventRowCoefficientTotal } from '../coefficient-total'

import { IEventRowCoefficientProps } from './coefficient.types'
import { CoefficientTableCellStyled } from './coefficient.styled'

export const EventRowCoefficient: FC<IEventRowCoefficientProps> = ({
  mainOutcomeType,
  outcomeTypesName,
  event,
  ...props
}) => {
  const { eventProbability } = useGetEventProbability({
    event,
    outcomeTypesName
  })

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket({
    eventProbability,
    eventId: event.id,
    eventStatus: event.status
  })

  // @ts-ignore
  const outcomeMode = useEventMode({ ...eventProbability, eventId: event.id })
  const eventTestId = getEventRowTestId(event, mainOutcomeType)

  const parameterValue = useMemo(
    () =>
      getOutcomeParameterValue({
        eventProbability,
        parameterType: ParameterType.Value
      }),
    [eventProbability]
  )

  const isMainOutcomeTO = mainOutcomeType === MainOutcomeTypes.TO

  return (
    <>
      {isMainOutcomeTO && <EventRowCoefficientTotal event={event} />}

      <CoefficientTableCellStyled
        id={eventTestId}
        withoutBorder={isMainOutcomeTO}
        onClick={handleAddOutcomeToBasket}
      >
        <EventCoefficientTableEntity
          outcomeMode={outcomeMode}
          parameterValue={props.withParam && parameterValue}
          value={eventProbability?.odd}
        />
      </CoefficientTableCellStyled>
    </>
  )
}
