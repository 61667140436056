import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Event, MarketGroup } from 'betweb-openapi-axios'
import { selectMarketGroupsByEvent } from 'astra-core/containers/CommonDataProvider'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { selectOutcomesGroup } from 'containers/OutcomesContainer/selectors'

import { StyledTabItemWrapper } from './OutcomesGroupsTabs.styled'

type OutcomesFilterTabsProps = {
  event: Event
}

export const OutcomesGroupsTabs: FC<OutcomesFilterTabsProps> = ({ event }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const selectedGroup = useSelector(selectOutcomesGroup)
  const marketGroups = useSelector((state) =>
    selectMarketGroupsByEvent(state, event.id)
  )

  useEffect(() => {
    return () => {
      dispatch(outcomesContainerActions.setSelectedOutcomesGroup(null))
    }
  }, [dispatch])

  const handleSportTypeClick = useCallback(
    (group: MarketGroup | null) => () =>
      dispatch(outcomesContainerActions.setSelectedOutcomesGroup(group)),
    [dispatch]
  )

  return (
    <Tabs variant={2} combined>
      <StyledTabItemWrapper>
        <TabsItem
          active={!selectedGroup}
          handleTabChange={handleSportTypeClick(null)}
        >
          {t('all')}
        </TabsItem>
      </StyledTabItemWrapper>

      {marketGroups.map((group) => (
        <StyledTabItemWrapper key={group.id}>
          <TabsItem
            active={group.id === selectedGroup?.id}
            handleTabChange={handleSportTypeClick(group)}
          >
            {group?.name}
          </TabsItem>
        </StyledTabItemWrapper>
      ))}
    </Tabs>
  )
}
