import {
  Event,
  EventsApiGetScheduledLiveEventsRequest,
  PaginatedEventList,
  SportCounter
} from 'betweb-openapi-axios'

import { OptionType } from 'shared/ui/Select'
import { Pagination } from 'hooks/usePagination/usePagination.types'

export interface CalendarEventsContainerState {
  data: {
    eventsIds: Dictionary<Event['id'][]>
    fetchItems: RequestState
    eventsCountersBySport: Dictionary<SportCounter>
    fetchCountersItems: RequestState
    hasMore?: boolean
  }
  filters: {
    isStream: boolean
    selectInputs: {
      order: TSelectSortValue | null
      period: TFilterPeriod
    }
    inputSearch: string
    currentSportId: number
  }
  layout: {
    selectInputs: {
      order: TSelectSortValue
      period: TSelectSortValue
    }
  }
  pagination: Pagination
}

type TSelectSortValue = OptionType['value']

export type ContainerState = CalendarEventsContainerState

export enum ESelectInputsNames {
  Order = 'order',
  Period = 'period'
}

export type TFilterPeriod = Required<
  Pick<EventsApiGetScheduledLiveEventsRequest, 'scheduledFrom' & 'scheduledTo'>
>

export type TSetFilterInputSearchPayload = string

export type TFetchScheduledEventsSuccessPayload = {
  resData: PaginatedEventList
}

export type TFetchScheduledEventsPayload =
  EventsApiGetScheduledLiveEventsRequest
export type TSetSelectOrderPayload = OptionType
export type TSetSelectPeriodPayload = OptionType

export interface IFetchScheduledEventsCountersSuccessPayload {
  items: SportCounter[]
}

export interface SetCurrentSportIdFilterPayload {
  sportId: number
}
