import { OptionTypeBase, StylesConfig } from 'react-select'
import styled from 'styled-components'

import { ThemeContext } from 'shared/types/theme'

const BASE_ZINDEX = {
  zIndex: 10
}

export const StyledIndicatorsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 8px;
`

export function selectStylesDefault(
  themeContext: ThemeContext
): StylesConfig<OptionTypeBase, boolean> {
  return {
    container: (provided) => ({
      ...provided,
      ...BASE_ZINDEX,
      fontSize: themeContext.fonts.size.s
    }),
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 32,
        height: 32,
        cursor: 'pointer',
        boxShadow: 'none',
        borderColor: state.isFocused
          ? themeContext.colors.custom.primary[90]
          : themeContext.colors.custom.primary[8],
        borderRadius: 4,
        backgroundColor: themeContext.colors.custom.primary[10],

        '&:hover': {
          borderColor: state.isFocused
            ? themeContext.colors.custom.primary[90]
            : themeContext.colors.custom.primary[19]
        }
      }
    },
    singleValue: (provided) => ({
      ...provided,
      color: themeContext.colors.default.primary[90]
    }),
    indicatorsContainer: (provided) => ({
      ...provided
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: () => ({
      minWidth: 'max-content',
      position: 'absolute',
      top: 28,
      borderRadius: '4px',
      border: 'solid',
      borderWidth: '1px',
      borderColor: themeContext.colors.custom.primary[4],
      backgroundColor: themeContext.colors.custom.primary[10],
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)'
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? themeContext.colors.default.primary[10]
          : themeContext.colors.custom.primary[10],
        color: themeContext.colors.default.primary[90],
        cursor: 'pointer',
        height: 32,
        '&:hover': {
          backgroundColor: themeContext.colors.custom.primary[4]
        }
      }
    }
  }
}

export function selectStylesMini(
  themeContext: ThemeContext
): StylesConfig<OptionTypeBase, boolean> {
  return {
    container: (provided) => ({
      ...provided,
      ...BASE_ZINDEX,
      fontSize: themeContext.fonts.size.s
    }),
    control: (provided, state) => {
      return {
        ...provided,
        alignItems: 'stretch',
        minHeight: 24,
        height: 24,
        boxShadow: 'none',
        cursor: 'pointer',
        borderColor: state.isFocused
          ? themeContext.colors.custom.primary[90]
          : themeContext.colors.custom.primary[8],
        borderRadius: 4,
        backgroundColor: themeContext.colors.custom.primary[10],
        color: themeContext.colors.default.primary[50],

        '&:hover': {
          borderColor: state.isFocused
            ? themeContext.colors.custom.primary[90]
            : themeContext.colors.custom.primary[19]
        }
      }
    },
    valueContainer: (provided) => ({
      ...provided,
      fontSize: themeContext.fonts.size.s,
      padding: '0 4px 0 8px',
      height: '100%'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeContext.colors.default.primary[90]
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '100%'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
      padding: 0,
      paddingRight: 2,
      color: themeContext.colors.default.primary[50]
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: themeContext.colors.custom.primary[10],
      width: 'max-content',
      position: 'absolute',
      overflow: 'hidden',
      border: `1px solid ${themeContext.colors.custom.primary[4]}`,
      boxShadow: `0px 2px 4px ${themeContext.colors.default.shadow[0]}`
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? themeContext.colors.default.primary[10]
          : themeContext.colors.custom.primary[10],
        color: themeContext.colors.default.primary[90],
        cursor: 'pointer',
        padding: '9px 16px',
        minWidth: '198px',

        '&:hover': {
          backgroundColor: themeContext.colors.custom.primary[4]
        }
      }
    }
  }
}

export function selectStylesBasket(
  themeContext: ThemeContext
): StylesConfig<OptionTypeBase, boolean> {
  return {
    container: (provided) => ({
      ...provided,
      fontSize: themeContext.fonts.size.s
    }),
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 24,
        height: 24,
        cursor: 'pointer',
        boxShadow: 'none',
        borderRadius: 4,
        borderColor: state.isFocused
          ? themeContext.colors.custom.primary[90]
          : themeContext.colors.custom.primary[8],
        backgroundColor: themeContext.colors.custom.primary[10],
        color: themeContext.colors.default.primary[50],

        '&:hover': {
          borderColor: state.isFocused
            ? themeContext.colors.default.primary[90]
            : themeContext.colors.default.primary[20]
        }
      }
    },
    indicatorSeparator: () => ({
      display: 'none'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeContext.colors.default.primary[90]
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: themeContext.colors.custom.primary[10],
      borderRadius: '14px',
      zIndex: 9999
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      fontSize: themeContext.fonts.size.s
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? themeContext.colors.default.primary[5]
          : themeContext.colors.default.primary[0],
        color: themeContext.colors.default.primary[90],

        cursor: 'pointer',
        '&:hover': {
          backgroundColor: state.isSelected
            ? themeContext.colors.default.primary[5]
            : themeContext.colors.default.primary[10]
        }
      }
    }
  }
}

export function selectStylesResult(
  themeContext: ThemeContext
): StylesConfig<OptionTypeBase, boolean> {
  return {
    container: (provided) => ({
      ...provided,
      ...BASE_ZINDEX,
      fontSize: themeContext.fonts.size.s
    }),
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 24,
        height: 24,
        boxShadow: 'none',
        cursor: 'pointer',
        borderColor: state.isFocused
          ? themeContext.colors.custom.primary[58]
          : themeContext.colors.custom.primary[8],
        borderRadius: 4,
        backgroundColor: themeContext.colors.custom.primary[10],
        color: themeContext.colors.default.primary[50],

        '&:hover': {
          borderColor: state.isFocused
            ? themeContext.colors.custom.primary[90]
            : themeContext.colors.custom.primary[19]
        }
      }
    },
    valueContainer: (provided) => ({
      ...provided,
      fontSize: themeContext.fonts.size.s,
      padding: '0 4px 0 8px',
      height: '100%'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeContext.colors.default.primary[90]
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '100%'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
      padding: 0,
      paddingRight: 2,
      color: themeContext.colors.default.primary[50]
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: themeContext.colors.custom.primary[10],
      width: 'max-content',
      position: 'absolute'
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? themeContext.colors.default.primary[10]
          : themeContext.colors.custom.primary[10],
        color: themeContext.colors.default.primary[90],
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: themeContext.colors.custom.primary[4]
        }
      }
    }
  }
}
