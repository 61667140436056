import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectSportsCountersList } from 'astra-core/containers/SportCountersProvider'
import { useHistory } from 'react-router-dom'
import find from 'lodash/find'
import { Sport } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { getMenuTabSportsCounterType } from 'widgets/sports-menu/components/SportMenuList/utils'

export interface RedirectToSportProps {
  sportId?: Sport['id']
  currentTabMenu: ESportsMenuTabTypes
}

// TODO: used in mobile too. Transfer to Astra-Core

export const useRedirectToSport = ({
  sportId,
  currentTabMenu
}: RedirectToSportProps) => {
  const history = useHistory()

  const sportsCountersList = useSelector((state: RootState) =>
    selectSportsCountersList(state, getMenuTabSportsCounterType(currentTabMenu))
  )

  const redirectToSport = useCallback(() => {
    if (sportsCountersList.length) {
      history.replace(`/${currentTabMenu}/${sportsCountersList[0].id}`)
    }
  }, [currentTabMenu, history, sportsCountersList])

  useEffect(() => {
    if (!sportId || !find(sportsCountersList, ['id', sportId])) {
      redirectToSport()
    }
  }, [currentTabMenu, history, redirectToSport, sportId, sportsCountersList])
}
