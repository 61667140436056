import React from 'react'
import { useSelector } from 'react-redux'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  reducerLine,
  sliceKeyLine
} from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { lineTournamentsContainerSaga } from 'pages/page-line-tournament/LineTournamentsContainer/saga'
import { selectLineIsEmpty } from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { LineEvents } from 'pages/page-live-events/Line/Line'

import { LineProps } from './types'
import { useLineContainerData } from './hooks'

export function LineContainer(_props: LineProps) {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  const isEmpty = useSelector(selectLineIsEmpty)

  useLineContainerData()

  return isEmpty ? <LoaderSpinner /> : <LineEvents />
}
