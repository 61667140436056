import React, { FC, useContext } from 'react'

import { LayoutEventContext } from 'widgets/Layout/components/Event/context'

import { EventBannerLarge, EventBannerMini } from './components'

export const EventBanner: FC = () => {
  const { isEventBannerScrolled } = useContext(LayoutEventContext) || {}

  return isEventBannerScrolled ? <EventBannerMini /> : <EventBannerLarge />
}
