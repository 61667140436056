import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBadminton: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M17.2453 10.6797L19.6084 11.2088C19.8744 11.2688 20.0434 11.5298 19.9884 11.7958L19.4884 14.2698C19.4534 14.4458 19.3264 14.5889 19.1564 14.6459L10.8031 17.4369L9.26597 18.9741L9.26686 18.975L9.12085 19.121C8.55483 19.687 7.80081 20 6.99979 20C6.19877 20 5.44474 19.688 4.87873 19.121C3.70969 17.951 3.70969 16.0489 4.87873 14.8789L4.93188 14.8257L4.93099 14.8248L6.56201 13.1938L9.3531 4.84056C9.4121 4.66656 9.5601 4.53956 9.74111 4.50755L12.5692 4.00754C12.7052 3.98354 12.8452 4.01754 12.9552 4.09954C13.0652 4.18154 13.1382 4.30655 13.1522 4.44355L13.3822 6.48461L7.95606 13.7138L8.66908 14.4278L14.0392 7.27464L16.0563 7.50364C16.2873 7.52864 16.4703 7.71165 16.4963 7.94366L16.7103 9.83071L9.5641 15.3219L10.2771 16.0349L17.2453 10.6797Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
