import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconLive } from 'shared/ui/Icon/General/IconLive'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { StyledLiveIndicator } from './LiveIndicator.styled'

export const LiveIndicator: FC = () => {
  const [t] = useTranslation()

  return (
    <StyledLiveIndicator>
      <IconLive
        colorProps={{
          name: EColorsNames.Primary,
          type: EColorsTypes.FIXED,
          value: 0
        }}
        size={16}
      />
      {t('live')}
    </StyledLiveIndicator>
  )
}
