import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  EMakeBetStatus,
  selectBasketAppliedCoupon,
  selectBasketHeaderTab,
  selectBasketMakeBetStatus
} from 'astra-core/containers/BasketProvider'
import { useTheme } from 'styled-components'

import { BetsHistoryBasketContainer } from 'containers/BetsHistoryBasketContainer'
import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { IconPreloader } from 'shared/ui/Icon/General/IconPreloader'
import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { selectIsShowBasketOnSmallWindow } from 'containers/BasketContainer/selectors'
import { ERoutes } from 'shared/types/routes'
import { IconSuccessBonusCoupon } from 'shared/ui/Icon/General/IconSuccessBonusCoupon'

import { BasketHeader } from './BasketHeader/BasketHeader'
import { BasketBody } from './BasketBody/BasketBody'
import {
  BasketBodyTypes,
  EBasketHeaderTypes,
  MakeBetStatusBodyType
} from './Basket.types'
import {
  BetStatusSuccessLink,
  BetStatusSuccessText,
  BetStatusSuccessTitle,
  BetStatusSuccessWrapper,
  BetStatusWaitText,
  BetStatusWaitWrapper,
  StyledBasketWrapper
} from './Basket.styled'

const CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT = 1000

const basketBodyTypes: BasketBodyTypes = {
  [EBasketHeaderTypes.Coupon]: <BasketBody />,
  [EBasketHeaderTypes.History]: <BetsHistoryBasketContainer />
}

const makeBetStatusBody = (
  headerTab: EBasketHeaderTypes
): MakeBetStatusBodyType => ({
  [EMakeBetStatus.init]: (
    <>
      <BasketHeader />
      {basketBodyTypes[headerTab]}
    </>
  ),
  [EMakeBetStatus.loading]: <BetStatusWait />,
  [EMakeBetStatus.success]: <BetStatusSuccess />,
  [EMakeBetStatus.error]: <BetStatusError />
})

export const Basket: FC = () => {
  const headerTab = useSelector(selectBasketHeaderTab)
  const makeBetStatus = useSelector(selectBasketMakeBetStatus)
  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)
  const dispatch = useDispatch()

  // if we have small window and current making bet status is success, we should close basket window
  useEffect(() => {
    if (isShowBasketOnSmallWindow && makeBetStatus === EMakeBetStatus.success) {
      const timerId = setTimeout(
        () =>
          dispatch(
            rightBasketContainerState.toggleBasketOnSmallWindow({
              isShow: false
            })
          ),
        CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT
      )

      return () => clearTimeout(timerId)
    }

    return () => null
  }, [dispatch, isShowBasketOnSmallWindow, makeBetStatus])

  return (
    <StyledBasketWrapper>
      {makeBetStatusBody(headerTab)[makeBetStatus]}
    </StyledBasketWrapper>
  )
}

const BetStatusWait: FC = () => {
  const theme = useTheme()

  const [t] = useTranslation()

  return (
    <BetStatusWaitWrapper>
      <IconPreloader color={theme.colors.default.green[50]} />
      <BetStatusWaitText>{t('the bet is accepting')}</BetStatusWaitText>
    </BetStatusWaitWrapper>
  )
}

const BetStatusSuccess: FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)

  const historySelect = useMemo(
    () => ({
      betsHistory: () => history.push(ERoutes.BetsHistory)
    }),
    [history]
  )

  const icon = appliedCoupon ? (
    <IconSuccessBonusCoupon size={40} />
  ) : (
    <IconSuccess size={40} />
  )

  const title = appliedCoupon
    ? 'bonus coupon bet has been accepted'
    : 'your bet is accepted'

  return (
    <BetStatusSuccessWrapper>
      {icon}
      <BetStatusSuccessTitle isAppliedCoupon={!!appliedCoupon}>
        {t(title)}
      </BetStatusSuccessTitle>
      <BetStatusSuccessText>
        {t('the results can be found in')}
      </BetStatusSuccessText>
      <BetStatusSuccessLink onClick={historySelect.betsHistory}>
        {t('the history of bets')}
      </BetStatusSuccessLink>
    </BetStatusSuccessWrapper>
  )
}

// TODO make error window
const BetStatusError: FC = () => {
  return (
    <BetStatusWaitWrapper>
      <BetStatusWaitText>Error</BetStatusWaitText>
    </BetStatusWaitWrapper>
  )
}
