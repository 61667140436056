import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { Layout } from 'widgets/Layout'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'

import { lineTournamentsContainerActions } from './LineTournamentsContainer/slice'
import { LineTournamentsContainer } from './LineTournamentsContainer'
import { getFetchLineTournamentsReqData } from './LineTournamentsContainer/utils'

export const PageLineTournament = () => (
  <Layout>
    <LineTournamentsContainer />
  </Layout>
)

export const pageLineTournamentPrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLineTournaments(
      getFetchLineTournamentsReqData({
        sportId: getRouteParamAtIndex(req.params, 1)
      })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
}
