import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportCar: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M6.34376 6.68752C6.21942 6.68752 6.1002 6.63814 6.01233 6.5502L5.3873 5.9252C5.20423 5.74214 5.20423 5.44533 5.3873 5.2623C5.57036 5.07923 5.86717 5.07923 6.0502 5.2623L6.53792 5.75002H17.4621L17.9498 5.2623C18.1329 5.07923 18.4297 5.07923 18.6127 5.2623C18.7958 5.44536 18.7958 5.74217 18.6127 5.9252L17.9877 6.5502C17.8998 6.63814 17.7806 6.68752 17.6563 6.68752H15.2813V7.62502H16.25C16.4095 7.62502 16.5581 7.70611 16.6443 7.8403L17.9465 10.2991C19.1024 10.4425 20 11.4308 20 12.6249V15.5937C20 15.7712 19.8997 15.9336 19.7409 16.013L17.8659 16.9505C17.8008 16.983 17.729 17 17.6562 17H15.75C12.4 16.9505 12 16.9505 8.25 17H6.34375C6.27097 17 6.19919 16.983 6.13412 16.9505L4.25912 16.013C4.10031 15.9336 4 15.7713 4 15.5937V12.6249C4 11.4309 4.89759 10.4425 6.05349 10.2991L7.3557 7.8403C7.44195 7.70614 7.59051 7.62502 7.75001 7.62502H8.71876V6.68752H6.34376ZM14.3438 6.68752H9.65626V7.62502H14.3438V6.68752ZM6.09153 13.0201C6.13622 13.0486 7.20347 13.7187 8.71875 13.7187C8.97762 13.7187 9.1875 13.5088 9.1875 13.25C9.1875 12.9911 8.97762 12.7812 8.71875 12.7812C7.48819 12.7812 6.60331 12.2345 6.59453 12.2289C6.37644 12.0909 6.08759 12.155 5.94862 12.3728C5.80934 12.591 5.87331 12.8808 6.09153 13.0201ZM15.2812 13.7187C16.7965 13.7187 17.8638 13.0486 17.9085 13.0201C18.1267 12.8808 18.1914 12.5905 18.0521 12.3723C17.9128 12.1541 17.6237 12.0896 17.4055 12.2289C17.3967 12.2345 16.5118 12.7812 15.2812 12.7812C15.0224 12.7812 14.8125 12.9911 14.8125 13.25C14.8125 13.5088 15.0224 13.7187 15.2812 13.7187ZM17.6562 17.9375H15.75V18.4063C15.75 18.6651 15.9599 18.875 16.2188 18.875H19.5312C19.7901 18.875 20 18.6651 20 18.4063V16.9316L18.2851 17.789C18.0911 17.8861 17.8735 17.9375 17.6562 17.9375ZM4 16.9316L5.71463 17.7889C5.90891 17.8861 6.12647 17.9375 6.34375 17.9375H8.25V18.4062C8.25 18.6651 8.04012 18.875 7.78125 18.875H4.46875C4.20987 18.875 4 18.6651 4 18.4062V16.9316Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
