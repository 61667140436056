import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BetSaveAny } from 'betweb-openapi-axios'
import { useTheme } from 'styled-components'
import {
  basketProviderActions,
  selectBasketHeaderTab,
  selectBasketOutcomesLength,
  selectCoefficientStatusOnChanged
} from 'astra-core/containers/BasketProvider'
import { useLocation } from 'react-router-dom'
import { ETestIds } from 'astra-core/utils/testIds'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'

import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'
import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { selectIsShowBasketOnSmallWindow } from 'containers/BasketContainer/selectors'
import { Radio } from 'shared/ui/Radio'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { hiddenRightContainerRoutesList } from 'widgets/right-container/constants'

import { EBasketHeaderTypes } from '../Basket.types'

import {
  BasketHeaderItemCounter,
  BasketHeaderItemTitle,
  labelStyle,
  StyledBasketHeaderItemWrapper,
  StyledBasketHeaderWrapper,
  StyledCloseButtonWrapper,
  StyledIconClose,
  StyledInputRadioWrapper,
  StyledSettingRatesCoefficientChange,
  StyledSettingRatesHeader,
  StyledSettingRatesIcon,
  StyledSettingRatesTitle,
  StyledSettingRatesWrapper
} from './BasketHeader.styled'
import {
  BasketHeaderItemProps,
  BasketHeaderItemsLocale,
  BasketHeaderProps,
  SettingRatesProps
} from './BasketHeader.types'

const basketHeaderItemsLocale: BasketHeaderItemsLocale[] = [
  { name: EBasketHeaderTypes.Coupon, locale: 'just coupon' },
  { name: EBasketHeaderTypes.History, locale: 'history' }
]

const { Always, Never, Partly } = BetSaveAny

// TODO check delay when switching tab
export const BasketHeader: FC<BasketHeaderProps> = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)
  const isRouteWithPermanentHiddenBasket =
    hiddenRightContainerRoutesList.includes(pathname)

  const toggleShowBasket = useCallback(() => {
    dispatch(
      rightBasketContainerState.toggleBasketOnSmallWindow({ isShow: false })
    )
  }, [dispatch])

  return (
    <StyledBasketHeaderWrapper>
      {basketHeaderItemsLocale.map(({ name, locale }) => (
        <BasketHeaderItem key={name} locale={locale} name={name} />
      ))}

      {isShowBasketOnSmallWindow && (
        <StyledCloseButtonWrapper
          isRouteWithPermanentHiddenBasket={isRouteWithPermanentHiddenBasket}
          onClick={toggleShowBasket}
        >
          <StyledIconClose />
        </StyledCloseButtonWrapper>
      )}
    </StyledBasketHeaderWrapper>
  )
}

export const SettingRates: FC<SettingRatesProps> = memo(
  ({ toggleSettingRatesModal, rect }) => {
    const [t] = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const coefficientStatusOnChanged = useSelector(
      selectCoefficientStatusOnChanged
    )

    const changeCoefficientStatusOnChanged = useCallback(
      (status: BetSaveAny) => () => {
        dispatch(
          basketProviderActions.setCoefficientStatusOnChanged({ status })
        )
      },
      [dispatch]
    )

    const radioChecked = useMemo(
      () => (status: BetSaveAny) => status === coefficientStatusOnChanged,
      [coefficientStatusOnChanged]
    )

    return (
      <StyledSettingRatesWrapper top={rect.top}>
        <StyledSettingRatesHeader>
          <StyledSettingRatesTitle>
            {t('setting rates')}
          </StyledSettingRatesTitle>

          <StyledSettingRatesIcon onClick={toggleSettingRatesModal}>
            <IconClose size={10} />
          </StyledSettingRatesIcon>
        </StyledSettingRatesHeader>

        <StyledSettingRatesCoefficientChange>
          {t('accept bets with modified odds')}
        </StyledSettingRatesCoefficientChange>

        <StyledInputRadioWrapper>
          <Radio
            checked={radioChecked(Partly)}
            label={t('partly')}
            labelStyle={labelStyle(theme)}
            testId={ETestIds.TestBasketInputRadioPartly}
            onChange={changeCoefficientStatusOnChanged(Partly)}
          />

          <Radio
            checked={radioChecked(Always)}
            label={t('always')}
            labelStyle={labelStyle(theme)}
            testId={ETestIds.TestBasketInputRadioAlways}
            onChange={changeCoefficientStatusOnChanged(Always)}
          />

          <Radio
            checked={radioChecked(Never)}
            label={t('never')}
            labelStyle={labelStyle(theme)}
            testId={ETestIds.TestBasketInputRadioNever}
            onChange={changeCoefficientStatusOnChanged(Never)}
          />
        </StyledInputRadioWrapper>
      </StyledSettingRatesWrapper>
    )
  }
)

export const BasketHeaderItem: FC<BasketHeaderItemProps> = ({
  name,
  locale
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const headerTab = useSelector(selectBasketHeaderTab)
  const outcomesLength = useSelector(selectBasketOutcomesLength)

  const handleTabChange = useCallback(() => {
    dispatch(basketProviderActions.handleTabChange({ headerTab: name }))

    if (name === EBasketHeaderTypes.History) {
      dispatch(
        betsHistoryBasketContainerActions.setInitialStateBetsHistoryBasket()
      )
      dispatch(betsHistoryProviderActions.clearFilterBetsTypes())
    }
  }, [dispatch, name])

  return (
    <StyledBasketHeaderItemWrapper
      active={name === headerTab}
      onClick={handleTabChange}
    >
      <BasketHeaderItemTitle active={name === headerTab}>
        {t(locale)}
      </BasketHeaderItemTitle>
      {name === EBasketHeaderTypes.Coupon && (
        <BasketHeaderItemCounter>{outcomesLength}</BasketHeaderItemCounter>
      )}
    </StyledBasketHeaderItemWrapper>
  )
}
