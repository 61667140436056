import React from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { getCurrentLng } from 'astra-core'
import { useLoadLocaleDayjs } from 'astra-core/hooks/useLocaleDayjs'
import { AuthMessageProvider } from 'astra-core/containers/AuthMessageProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { useDisplayWelcomeScreen } from 'hooks/useDisplayWelcomeScreen'
import { useSetStorage } from 'hooks/useSetStorage'
import { PaymentModal } from 'pages/page-balance/Balance/components/Payment/components/PaymentModal'
import { OnboardingPortal } from 'widgets/onboarding' // Injecting static styles, such as fonts, etc
import { LoaderWelcome } from 'widgets/loader-welcome'

import {
  ThemeProvider,
  GlobalStyle,
  ErrorBoundary,
  AppRoutes
} from './providers'
import { useProviders } from './lib/providers'

import './styles/index.css'

const App = ({ routes }) => {
  useProviders()
  useSetStorage()
  useLoadLocaleDayjs()
  const config = useSelector(selectConfig)

  const [isLoaderDisplay] = useDisplayWelcomeScreen()

  return (
    <ThemeProvider>
      <GoogleReCaptchaProvider
        language={getCurrentLng()}
        reCaptchaKey={config.CAPTCHA_KEY}
      >
        <AuthMessageProvider>
          <LoaderWelcome isLoading={isLoaderDisplay} />
          <GlobalStyle />
          <PaymentModal />
          <Switch>
            <ErrorBoundary>
              <AppRoutes routes={routes} />
            </ErrorBoundary>
          </Switch>

          <OnboardingPortal />
        </AuthMessageProvider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  )
}

// eslint-disable-next-line import/no-default-export
export default App
