import React, { useReducer, memo, FC } from 'react'
import Lottie from 'react-lottie'

import {
  SPONSORS_LIST_HIGH_RESOLUTION,
  getLottieDefaultImgOptions,
  BANNER_HIGHT
} from './constants'
import {
  StyledSponsorsItemsWrapper,
  SponsorsItemWrapper,
  SponsorsItemLogo,
  SponsorsItemBG,
  SponsorsItemImage,
  SponsorsItemAdditionalImage
} from './SponsorsHighResolutionItems.styled'
import {
  ISponsorsHighResolutionItemsProps,
  ISponsorsItemProps
} from './SponsorsHighResolutionItems.types'

export const SponsorsHighResolutionItems: FC<
  ISponsorsHighResolutionItemsProps
> = ({ sponsorsWrapperWidth }) => (
  <StyledSponsorsItemsWrapper>
    {SPONSORS_LIST_HIGH_RESOLUTION(sponsorsWrapperWidth).map((sponsor) => (
      <SponsorsItem
        key={sponsor.name}
        sponsorsWrapperWidth={sponsorsWrapperWidth}
        {...sponsor}
      />
    ))}
  </StyledSponsorsItemsWrapper>
)

const SponsorsItem: FC<ISponsorsItemProps> = memo(
  ({ url, backgroundImg, mainImg, logoImg, additionalImg, itemStyles }) => {
    const [isAnimation, setIsAnimation] = useReducer((_, type) => type, false)

    return (
      <SponsorsItemWrapper
        to={url}
        onMouseEnter={() => setIsAnimation(true)}
        onMouseLeave={() => setIsAnimation(false)}
      >
        <SponsorsItemBG>{backgroundImg}</SponsorsItemBG>
        {additionalImg && (
          <SponsorsItemAdditionalImage itemStyles={itemStyles}>
            <Lottie
              height={BANNER_HIGHT}
              isStopped={!isAnimation}
              options={getLottieDefaultImgOptions(additionalImg)}
            />
          </SponsorsItemAdditionalImage>
        )}

        <SponsorsItemImage itemStyles={itemStyles}>
          <Lottie
            height={BANNER_HIGHT}
            isStopped={!isAnimation}
            options={getLottieDefaultImgOptions(mainImg)}
          />
        </SponsorsItemImage>

        <SponsorsItemLogo>{logoImg}</SponsorsItemLogo>
      </SponsorsItemWrapper>
    )
  }
)
