import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBulletShooting: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M15.7221 9.51093C15.5521 9.51093 15.4144 9.64868 15.4144 9.81862V13.6666C15.4144 14.8208 14.4754 15.7598 13.3212 15.7598H8.65979C7.73282 15.7598 6.94494 15.154 6.6706 14.3176C6.63243 14.2012 6.5168 14.1234 6.3966 14.1468C6.23626 14.1598 6.12346 14.1659 6.04652 14.1664C6.03245 14.1689 6.01816 14.1707 6.00368 14.1719L4.54216 14.2956C4.25043 14.3203 4 14.0901 4 13.7974V10.3223C4 10.0461 4.22386 9.82225 4.5 9.82225H5.96152C5.98901 9.82225 6.01598 9.82447 6.04227 9.82874C6.05244 9.82069 6.07099 9.81637 6.10108 9.81637H7.82223C8.24912 9.81637 8.59644 9.46905 8.59644 9.04216V8.88108C8.59644 8.71115 8.7342 8.57339 8.90413 8.57339H9.31041C9.46874 8.57339 9.5971 8.44504 9.5971 8.2867C9.5971 8.12836 9.72546 8 9.8838 8H10.2479C10.4063 8 10.5346 8.12836 10.5346 8.2867C10.5346 8.44504 10.663 8.57339 10.8213 8.57339H18.7758C18.9341 8.57339 19.0625 8.44504 19.0625 8.2867C19.0625 8.12836 19.1908 8 19.3492 8H19.6923C19.8622 8 20 8.13776 20 8.30769V9.20324C20 9.37317 19.8622 9.51093 19.6923 9.51093H15.7221ZM10.858 11.8318C10.7304 11.8318 10.619 11.9177 10.5729 12.0367C10.4911 12.2482 10.6347 12.5009 10.8614 12.5009H11.271C11.441 12.5009 11.5787 12.3631 11.5787 12.1932V12.1395C11.5787 11.9696 11.441 11.8318 11.271 11.8318H10.858ZM13.3212 14.8222C13.9584 14.8222 14.4768 14.3038 14.4768 13.6666L14.4769 12.1394C14.4769 11.9695 14.3391 11.8318 14.1692 11.8318H12.824C12.6541 11.8318 12.5163 11.9695 12.5163 12.1394V13.1307C12.5163 13.3006 12.3785 13.4384 12.2086 13.4384H9.42863C9.37648 13.4384 9.32536 13.4519 9.27902 13.4759C9.05592 13.591 8.81503 13.676 8.56172 13.7253L7.84609 13.8646C7.67531 13.8979 7.56301 14.0694 7.64685 14.2218C7.84349 14.5794 8.22372 14.8222 8.65979 14.8222H13.3212Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
