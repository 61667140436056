import React, { FC, memo, useMemo } from 'react'
import DatePickerLib, { ReactDatePickerProps } from 'react-datepicker'
import { useGetLocaleDayjs } from 'astra-core/hooks/useLocaleDayjs'
import { ETestIds } from 'astra-core/utils/testIds'

import { IDatePickerRange } from './types'
import { CustomButtonRange, CustomContainerRange } from './components'
import { StyledDatePicker } from './styled'

import 'react-datepicker/dist/react-datepicker.css'

/**
 onChange -> args was back ([startDate, endDate]) -> handler for click to cell with the date

 onChangeInputRange -> args was back ({startDate, endDate}) -> handler for input of dates

 onChangeSelect -> args was back ([startDate, endDate], value), where value is number of selected item -> handler for select

 selectValue -> value for show item of select
 */

export const DatePickerRange: FC<IDatePickerRange> = memo(
  ({
    onChange,
    onChangeInputRange,
    onCalendarClose,
    rangeCalendarDates,
    onChangeSelect,
    selectValue,
    onCalendarOpen,
    testId
  }) => {
    const CalendarContainer = useMemo(
      () => (props) =>
        (
          <CustomContainerRange
            rangeCalendarDates={rangeCalendarDates}
            selectValue={selectValue}
            onChangeInputRange={onChangeInputRange}
            onChangeSelect={onChangeSelect}
            {...props}
          />
        ),
      [onChangeInputRange, onChangeSelect, rangeCalendarDates, selectValue]
    )

    return (
      <DatePicker
        {...rangeCalendarDates}
        customInput={
          <CustomButtonRange {...rangeCalendarDates} onReset={onChange} />
        }
        calendarContainer={CalendarContainer}
        id={testId}
        monthsShown={2}
        selected={rangeCalendarDates?.startDate}
        onCalendarClose={onCalendarClose}
        onCalendarOpen={onCalendarOpen}
        onChange={onChange}
      />
    )
  }
)

export const DatePicker: FC<ReactDatePickerProps> = memo((props) => {
  const { weekdaysMin, months } = useGetLocaleDayjs()

  const lang: ReactDatePickerProps['locale'] = {
    localize: {
      day: (n) => weekdaysMin()[n],
      month: (n) => months()[n],
      ordinalNumber: () => {},
      era: () => {},
      quarter: () => {},
      dayPeriod: () => {}
    },
    formatLong: {
      date: () => 'mm/dd/yyyy',
      time: () => {},
      dateTime: () => {}
    }
  }

  return (
    <StyledDatePicker id={ETestIds.TestBetsHistoryDatePicker}>
      <DatePickerLib {...props} locale={lang} />
    </StyledDatePicker>
  )
})
