import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'

import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'

export const betsHistoryProviderSideEffects = {
  betsHistoryPage: {
    onSuccess: betsHistoryProviderActions.fetchHistoryBetsPageSuccess,
    onError: betsHistoryProviderActions.fetchHistoryBetsPageError
  },
  betsHistoryBasket: {
    onSuccess: betsHistoryBasketContainerActions.fetchHistoryBetsBasketSuccess,
    onError: betsHistoryBasketContainerActions.fetchHistoryBetsBasketError
  }
}
