import React from 'react'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { IconChevronUp } from 'shared/ui/Icon/General/IconChevronUp'

import { StyledInputCheckbox } from './styled'

export const Option = (props) => {
  return (
    <components.Option {...props}>
      <StyledInputCheckbox checked={props.isSelected} />
      {props.label}
    </components.Option>
  )
}

export const MultiValue = (props) => {
  const { t } = useTranslation()
  const value = props.getValue()

  // Only interested in rendering one/first multiValue
  if (props.index > 0 || !value.length) return null

  const labelText =
    value.length > 1
      ? t('selected types amount', { typesAmount: value.length })
      : value[0].label

  return <div className="option-label">{labelText}</div>
}

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <IconChevronUp isActive={props.selectProps.menuIsOpen} size={10} />
      ) : (
        <IconChevronDown size={10} />
      )}
    </components.DropdownIndicator>
  )
}
