import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSkating: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M14.9424 10.9082C16.595 11.6485 18.7125 12.5971 18.4686 13.6306C18.4032 13.9073 18.1622 14.1218 17.7887 14.285C17.5289 14.3986 17.1847 14.4714 16.7795 14.5141V15.2041H18.9648C19.5171 15.2041 19.9648 15.6518 19.9648 16.2041V16.4085C19.9648 16.5189 19.8753 16.6085 19.7648 16.6085H4.16484C4.05439 16.6085 3.96484 16.5189 3.96484 16.4085V16.2041C3.96484 15.6518 4.41256 15.2041 4.96484 15.2041H6.41602V14.0713C5.61768 13.8841 5.3919 12.8929 5.56782 11.3998C5.59533 11.1664 5.7014 10.8133 5.82633 10.3974L5.82633 10.3974C6.02934 9.72168 6.28218 8.88006 6.32877 8.11615C6.32877 7.5214 10.7887 7.16262 10.7887 7.56061C10.7887 9.02975 11.8005 9.47832 13.0283 10.0226C13.3016 10.1438 13.5857 10.2697 13.8716 10.4127C14.1757 10.5647 14.5457 10.7304 14.9424 10.9082ZM7.68968 15.2041H15.5059V14.5631C14.509 14.554 13.355 14.4634 12.2294 14.3749C11.8292 14.3435 11.4326 14.3123 11.048 14.2852C10.1732 14.2235 9.39825 14.2129 8.7017 14.2033H8.7017H8.70169H8.70169H8.70168C8.34473 14.1983 8.00836 14.1937 7.68968 14.1826V15.2041Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
