import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportHockey: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M14.5 15.7899L10.5419 5.11113C10.4148 4.76824 10.042 4.58434 9.6926 4.69215C9.31588 4.80839 9.11329 5.2164 9.24838 5.58678C10.4507 8.88306 11.6519 12.1797 12.8539 15.4761C12.9312 15.6881 13.0038 15.9232 13.0834 16.1812C13.4402 17.3371 13.9241 18.9049 14.9086 19.3027C15.121 19.3886 15.3108 19.4378 15.5028 19.4659C15.6948 19.4939 15.8722 19.5 16.0693 19.5H18.6052V19.4988C18.8954 19.4988 19.0371 19.4988 19.2052 19.4039C19.3549 19.3193 19.436 19.2043 19.5944 18.9798L19.6317 18.9269C19.7665 18.7363 19.8935 18.5128 19.9562 18.3019C20.0051 18.1373 20.017 17.974 19.9715 17.8241L19.9727 17.8238C19.9175 17.6426 19.7918 17.4991 19.5705 17.4166C19.4574 17.3744 19.3204 17.3509 19.1557 17.3509H15.7823C15.5907 17.3509 15.3928 17.2064 15.2103 17.0042C14.8874 16.6463 14.6352 16.1249 14.5 15.7899Z"
          fill={mainColor}
        />
        <path
          d="M9.5 15.7899L13.4581 5.11113C13.5852 4.76824 13.958 4.58434 14.3074 4.69215C14.6841 4.80839 14.8867 5.2164 14.7516 5.58678C13.5493 8.88306 12.3481 12.1797 11.1461 15.4761C11.0688 15.6881 10.9962 15.9232 10.9166 16.1812C10.5598 17.3371 10.0759 18.9049 9.09142 19.3027C8.87898 19.3886 8.68919 19.4378 8.49717 19.4659C8.3052 19.4939 8.12776 19.5 7.93066 19.5H5.39478V19.4988C5.1046 19.4988 4.96294 19.4988 4.79479 19.4039C4.6451 19.3193 4.564 19.2043 4.40564 18.9798L4.36828 18.9269C4.23349 18.7363 4.10647 18.5128 4.0438 18.3019C3.99486 18.1373 3.98298 17.974 4.0285 17.8241L4.02731 17.8238C4.08248 17.6426 4.2082 17.4991 4.42951 17.4166C4.54257 17.3744 4.67961 17.3509 4.84428 17.3509H8.21767C8.40926 17.3509 8.60721 17.2064 8.78965 17.0042C9.11257 16.6463 9.36477 16.1249 9.5 15.7899Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
