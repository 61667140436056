import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconInstagram: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.185.419A4.407 4.407 0 0 0 4.511 4.51c-.5.5-.808 1.003-1.039 1.594-.223.573-.375 1.227-.418 2.185C3.011 9.249 3 9.556 3 12s.01 2.75.054 3.71c.044.958.196 1.612.419 2.184A4.41 4.41 0 0 0 4.51 19.49c.5.5 1.002.809 1.593 1.038.573.223 1.227.375 2.185.419.96.043 1.267.054 3.71.054 2.445 0 2.751-.01 3.711-.054.958-.044 1.613-.196 2.185-.419a4.403 4.403 0 0 0 1.594-1.038c.5-.5.808-1.003 1.038-1.594.222-.573.374-1.227.419-2.185.043-.96.054-1.266.054-3.71s-.011-2.751-.054-3.711c-.045-.958-.197-1.612-.419-2.184A4.416 4.416 0 0 0 19.49 4.51c-.5-.5-1.002-.808-1.594-1.037-.574-.223-1.228-.375-2.186-.419-.96-.043-1.266-.054-3.711-.054h.003Zm-.807 1.622h.808c2.403 0 2.688.008 3.636.052.878.04 1.354.186 1.671.31.42.163.72.358 1.035.673.315.315.51.615.673 1.035.123.317.27.793.31 1.67.043.95.053 1.234.053 3.636s-.01 2.687-.053 3.636c-.04.877-.187 1.353-.31 1.67-.163.42-.358.72-.673 1.034-.315.315-.614.51-1.035.673-.316.124-.793.27-1.67.31-.95.043-1.234.053-3.637.053-2.404 0-2.688-.01-3.637-.053-.878-.04-1.354-.187-1.671-.31a2.787 2.787 0 0 1-1.035-.673 2.79 2.79 0 0 1-.674-1.035c-.123-.316-.27-.793-.31-1.67-.043-.949-.052-1.234-.052-3.637 0-2.404.01-2.687.052-3.636.04-.877.187-1.354.31-1.67.163-.42.359-.72.674-1.036.315-.315.615-.51 1.035-.673.317-.124.793-.27 1.67-.31.831-.038 1.153-.05 2.83-.051v.002Z"
        fill="url(#a)"
      />
      <path
        d="M16.785 6.094a1.08 1.08 0 1 0 0 2.161 1.08 1.08 0 0 0 0-2.161Z"
        fill="url(#b)"
      />
      <path
        clipRule="evenodd"
        d="M11.981 7.356a4.622 4.622 0 1 0 0 9.244 4.622 4.622 0 0 0 0-9.244ZM12 14.953a2.953 2.953 0 1 0 0-5.906 2.953 2.953 0 0 0 0 5.906Z"
        fill="url(#c)"
        fillRule="evenodd"
      />
      <path
        d="M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.185.419A4.407 4.407 0 0 0 4.511 4.51c-.5.5-.808 1.003-1.039 1.594-.223.573-.375 1.227-.418 2.185C3.011 9.249 3 9.556 3 12s.01 2.75.054 3.71c.044.958.196 1.612.419 2.184A4.41 4.41 0 0 0 4.51 19.49c.5.5 1.002.809 1.593 1.038.573.223 1.227.375 2.185.419.96.043 1.267.054 3.71.054 2.445 0 2.751-.01 3.711-.054.958-.044 1.613-.196 2.185-.419a4.403 4.403 0 0 0 1.594-1.038c.5-.5.808-1.003 1.038-1.594.222-.573.374-1.227.419-2.185.043-.96.054-1.266.054-3.71s-.011-2.751-.054-3.711c-.045-.958-.197-1.612-.419-2.184A4.416 4.416 0 0 0 19.49 4.51c-.5-.5-1.002-.808-1.594-1.037-.574-.223-1.228-.375-2.186-.419-.96-.043-1.266-.054-3.711-.054h.003Zm-.807 1.622h.808c2.403 0 2.688.008 3.636.052.878.04 1.354.186 1.671.31.42.163.72.358 1.035.673.315.315.51.615.673 1.035.123.317.27.793.31 1.67.043.95.053 1.234.053 3.636s-.01 2.687-.053 3.636c-.04.877-.187 1.353-.31 1.67-.163.42-.358.72-.673 1.034-.315.315-.614.51-1.035.673-.316.124-.793.27-1.67.31-.95.043-1.234.053-3.637.053-2.404 0-2.688-.01-3.637-.053-.878-.04-1.354-.187-1.671-.31a2.787 2.787 0 0 1-1.035-.673 2.79 2.79 0 0 1-.674-1.035c-.123-.316-.27-.793-.31-1.67-.043-.949-.052-1.234-.052-3.637 0-2.404.01-2.687.052-3.636.04-.877.187-1.354.31-1.67.163-.42.359-.72.674-1.036.315-.315.615-.51 1.035-.673.317-.124.793-.27 1.67-.31.831-.038 1.153-.05 2.83-.051v.002Z"
        fill="url(#d)"
      />
      <path
        d="M16.785 6.094a1.08 1.08 0 1 0 0 2.161 1.08 1.08 0 0 0 0-2.161Z"
        fill="url(#e)"
      />
      <path
        clipRule="evenodd"
        d="M11.981 7.356a4.622 4.622 0 1 0 0 9.244 4.622 4.622 0 0 0 0-9.244ZM12 14.953a2.953 2.953 0 1 0 0-5.906 2.953 2.953 0 0 0 0 5.906Z"
        fill="url(#f)"
        fillRule="evenodd"
      />
      <defs>
        <radialGradient
          cx={0}
          cy={0}
          gradientTransform="matrix(0 -17.8393 16.592 0 7.781 22.386)"
          gradientUnits="userSpaceOnUse"
          id="a"
          r={1}
        >
          <stop stopColor="#FD5" />
          <stop offset={0.1} stopColor="#FD5" />
          <stop offset={0.5} stopColor="#FF543E" />
          <stop offset={1} stopColor="#C837AB" />
        </radialGradient>
        <radialGradient
          cx={0}
          cy={0}
          gradientTransform="matrix(0 -17.8393 16.592 0 7.781 22.386)"
          gradientUnits="userSpaceOnUse"
          id="b"
          r={1}
        >
          <stop stopColor="#FD5" />
          <stop offset={0.1} stopColor="#FD5" />
          <stop offset={0.5} stopColor="#FF543E" />
          <stop offset={1} stopColor="#C837AB" />
        </radialGradient>
        <radialGradient
          cx={0}
          cy={0}
          gradientTransform="matrix(0 -17.8393 16.592 0 7.781 22.386)"
          gradientUnits="userSpaceOnUse"
          id="c"
          r={1}
        >
          <stop stopColor="#FD5" />
          <stop offset={0.1} stopColor="#FD5" />
          <stop offset={0.5} stopColor="#FF543E" />
          <stop offset={1} stopColor="#C837AB" />
        </radialGradient>
        <radialGradient
          cx={0}
          cy={0}
          gradientTransform="rotate(78.681 -2.629 2.14) scale(7.97425 32.8702)"
          gradientUnits="userSpaceOnUse"
          id="d"
          r={1}
        >
          <stop stopColor="#3771C8" />
          <stop offset={0.128} stopColor="#3771C8" />
          <stop offset={1} stopColor="#60F" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          cx={0}
          cy={0}
          gradientTransform="rotate(78.681 -2.629 2.14) scale(7.97425 32.8702)"
          gradientUnits="userSpaceOnUse"
          id="e"
          r={1}
        >
          <stop stopColor="#3771C8" />
          <stop offset={0.128} stopColor="#3771C8" />
          <stop offset={1} stopColor="#60F" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          cx={0}
          cy={0}
          gradientTransform="rotate(78.681 -2.629 2.14) scale(7.97425 32.8702)"
          gradientUnits="userSpaceOnUse"
          id="f"
          r={1}
        >
          <stop stopColor="#3771C8" />
          <stop offset={0.128} stopColor="#3771C8" />
          <stop offset={1} stopColor="#60F" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  </Icon>
)
