import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import {
  searchProviderActions,
  selectInputEventsSearch
} from 'astra-core/containers/SearchProvider'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EInputIconPositionSides, EInputView, Input } from 'shared/ui/Input'
import { RootState } from 'shared/types/store'

import { StyledInputSearch } from './InputSearch.styled'
import { IInputSearchProps } from './InputSearch.type'

export const InputSearch: FC<IInputSearchProps> = ({ eventId }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const valueSearch =
    useSelector((state: RootState) =>
      selectInputEventsSearch(state, eventId)
    ) || ''

  useEffect(() => {
    return () => {
      dispatch(searchProviderActions.deleteIdSearchQuery({ eventId }))
    }
  }, [dispatch, eventId])

  const onClickInputSearchClear = useCallback(
    () => dispatch(searchProviderActions.deleteIdSearchQuery({ eventId })),
    [dispatch, eventId]
  )

  const handlerInputSearch = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(
            searchProviderActions.setSearchQuery({
              eventId,
              value
            })
          ),
        10
      ),
    [dispatch, eventId]
  )

  const debouncedChangeInputSearch = useCallback(
    (event) => handlerInputSearch(event.target.value),
    [handlerInputSearch]
  )

  return (
    <StyledInputSearch>
      <Input
        iconProps={{
          positionSide: EInputIconPositionSides.Right,
          onClick: onClickInputSearchClear,
          hideIcon: !valueSearch.length
        }}
        icon={IconClose}
        placeholder={t('search by outcomes')}
        value={valueSearch}
        view={EInputView.SECONDARY}
        onChange={debouncedChangeInputSearch}
      />
    </StyledInputSearch>
  )
}
