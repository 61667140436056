import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBox: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M13.4399 10.1176C12.4375 10.1176 11.643 10.9819 11.7743 11.9771C8.66703 12.2617 6 9.85763 6 6.82353V5.88235C6 4.84276 6.8596 4 7.91997 4H14.6399C15.7002 4 16.5598 4.84276 16.5598 5.88235V9.11765C16.5598 9.66993 16.1121 10.1176 15.5598 10.1176H13.4399ZM17.9998 12C17.9998 11.4802 17.57 11.0588 17.0398 11.0588H13.4399C13.0235 11.0588 12.6881 11.4075 12.7225 11.8222C12.7516 12.1825 13.0611 12.4706 13.4399 12.4706C13.6247 12.471 13.739 12.4708 13.8148 12.4706C13.94 12.4703 13.9601 12.4703 14.0196 12.4737C14.8996 12.5245 15.5999 13.2423 15.5999 14.1176C15.5999 14.2476 15.4945 14.3529 15.3646 14.3529H14.8752C14.7452 14.3529 14.6399 14.2476 14.6399 14.1176C14.6399 13.7278 14.3175 13.4118 13.9199 13.4118H13.4399C12.9494 13.4118 12.5077 13.2043 12.2003 12.8745C10.3309 13.1463 8.47036 12.5753 7.10368 11.3662C6.73009 11.0357 6 11.2812 6 11.78V14.8235C6 15.8542 6.67598 16.7302 7.617 17.0484C7.57375 17.1282 7.54899 17.2172 7.54899 17.3138V19C7.54899 19.5523 7.99671 20 8.54899 20H14.7089C15.2611 20 15.7089 19.5523 15.7089 19V17.1741C16.9837 17.1182 17.9998 16.0872 17.9998 14.8235V12Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
