import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportWaterPolo: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M9.59951 10.528C8.91886 8.49933 8.76553 6.57 9.15286 4.81467C8.45887 5.25533 7.85155 5.86267 7.40423 6.61667C6.49692 8.16533 6.45025 9.98064 7.1309 11.5027L7.1909 11.4893C8.14488 11.2227 8.94553 10.882 9.60618 10.5413C9.59951 10.5354 9.59951 10.5353 9.59951 10.528ZM11.7015 4C11.0875 4.03333 10.4942 4.16667 9.93351 4.40733L9.88018 4.62133C9.43352 6.32333 9.54685 8.19933 10.2002 10.208C10.8742 9.80069 11.3341 9.42731 11.5948 9.22C11.6148 9.20667 11.6215 9.2 11.6415 9.18667C11.6348 9.16 11.6281 9.13333 11.6215 9.1C11.4748 8.37867 11.0875 6.48333 11.7015 4ZM12.1488 9.74069C12.1155 9.72736 12.0888 9.72064 12.0555 9.70731C12.0421 9.72064 12.0355 9.72736 12.0155 9.74069C11.4481 10.1947 9.96684 11.396 7.45156 12.1107C7.74489 12.598 8.12555 13.0453 8.57887 13.4253L8.81887 13.3587C10.5535 12.878 12.2281 11.8573 13.6894 10.4086C13.0094 10.0547 12.4621 9.85403 12.1488 9.74069ZM14.7107 4.728C13.9768 4.294 13.1827 4.06 12.3888 4L12.3688 4.07333C12.1355 4.98133 12.0488 5.802 12.0355 6.51667C14.6507 7.13733 16.332 8.272 17.3327 9.19333C17.2927 7.41133 16.352 5.69533 14.7107 4.728ZM16.3253 12.344C15.6181 11.65 14.924 11.136 14.2907 10.7487C12.8028 12.2773 11.0748 13.3653 9.27352 13.9127C9.28019 13.9194 9.28685 13.9193 9.29352 13.926C10.1402 14.4267 11.0741 14.6667 11.9955 14.6667C13.6901 14.6667 15.3447 13.8594 16.372 12.3907L16.3253 12.344ZM17.0994 9.88736C16.2187 9.01267 14.624 7.85867 12.0421 7.21067C12.0821 8.018 12.2088 8.63267 12.2755 8.966C12.2888 9.01267 12.2955 9.046 12.3021 9.086C12.3221 9.09267 12.3488 9.09933 12.3755 9.11267C13.0627 9.36 14.8707 10.0074 16.7253 11.8027C17.0187 11.2487 17.206 10.6613 17.286 10.074L17.0994 9.88736Z"
          fill={mainColor}
        />
        <path
          d="M4.33398 17C5.33398 17 5.33398 18 6.66732 18C8.00065 18 8.00065 17 9.33398 17C10.6673 17 10.6673 18 12.0007 18C13.334 18 13.334 17 14.6673 17C16.0007 17 16.0007 18 17.334 18C18.6673 18 18.6673 17 19.6673 17"
          stroke={mainColor}
        />
        <path
          d="M4.33398 18.6667C5.33398 18.6667 5.33398 19.6667 6.66732 19.6667C8.00065 19.6667 8.00065 18.6667 9.33398 18.6667C10.6673 18.6667 10.6673 19.6667 12.0007 19.6667C13.334 19.6667 13.334 18.6667 14.6673 18.6667C16.0007 18.6667 16.0007 19.6667 17.334 19.6667C18.6673 19.6667 18.6673 18.6667 19.6673 18.6667"
          stroke={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
