import React, { MouseEventHandler } from 'react'

import { IconProps } from 'shared/ui/Icon'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  view?: EInputView
  icon?: React.FC<IconProps> // components with icon
  fullWidth?: boolean
  customSize?: EInputSize
  inputRef?: React.RefObject<HTMLInputElement>
  iconProps?: InputIconProps // props of Icon component
  iconRight?: boolean
  rightPadding?: number
  testId?: string
}

export enum EInputView {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  THIRD = 'third',
  SEARCH = 'search',
  ERROR = 'error'
}

export enum EInputSize {
  M = '24',
  L = '32'
}

export enum EInputIconPositionSides {
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export interface InputIconProps extends IconProps {
  positionSide: EInputIconPositionSides
  onClick?: MouseEventHandler
  hideIcon?: boolean
}

export interface InputWrapperProps {
  customSize?: EInputSize
  iconRight?: boolean
}
