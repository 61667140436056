import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageLineTournamentLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageLineTournament,
  fallback
})

export { pageLineTournamentPrefetch } from './page'
