import React, { FC, memo } from 'react'

import {
  StyledCheckbox,
  StyledCheckboxInput,
  StyledCheckboxLabel,
  StyledCheckboxWrapper
} from './styled'
import { CheckboxProps, ICheckboxElement } from './types'

export const Checkbox: FC<CheckboxProps> = ({
  checked = false,
  label,
  disabled,
  ...props
}) => {
  return (
    <StyledCheckboxWrapper>
      <CheckboxElement checked={checked} disabled={disabled} {...props} />
      {!!label && <StyledCheckboxLabel>{label}</StyledCheckboxLabel>}
    </StyledCheckboxWrapper>
  )
}

export const CheckboxElement: FC<ICheckboxElement> = memo(
  ({ checked, disabled, className, ...props }) => (
    <StyledCheckbox checked={checked} className={className} disabled={disabled}>
      <Tick />
      <StyledCheckboxInput
        checked={checked}
        disabled={disabled}
        type="checkbox"
        {...props}
      />
    </StyledCheckbox>
  )
)

export const Tick = memo(() => (
  <svg
    fill="none"
    height="6"
    viewBox="0 0 10 6"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 2L4.42857 5L9 1" stroke="currentColor" />
  </svg>
))
