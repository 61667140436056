import { EventsApiGetEventsRequest } from 'betweb-openapi-axios'

import {
  LineTournamentFilterPeriod,
  TFormatLineTournamentsFiltersParams
} from './types'

interface GetFetchLineTournamentsReqDataParams {
  sportId: string | number
  top?: EventsApiGetEventsRequest['top']
  filterPeriod?: LineTournamentFilterPeriod
}

export const getFetchLineTournamentsReqData = ({
  sportId,
  top,
  filterPeriod
}: GetFetchLineTournamentsReqDataParams) => ({
  top,
  live: false,
  sportId: +sportId,
  ...filterPeriod
})

export const LINE_TOURNAMENTS_POLLING_INTERVAL = 5000

export const formatLineTournamentsFiltersData = ({
  isTop,
  period
}: TFormatLineTournamentsFiltersParams) => ({
  top: isTop,
  filterPeriod: period
})
