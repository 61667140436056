import { put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { actions } from './slice'
import { FetchEventPayload } from './types'

const SOURCE_KEY = 'event'

export function* fetchEventSaga(action: PayloadAction<FetchEventPayload>) {
  yield put(
    eventsProviderActions.fetchEvent({
      reqData: action.payload,
      sideEffects: eventsProviderSideEffects[SOURCE_KEY]
    })
  )
}

export function* eventContainerSaga() {
  yield takeLatest(actions.fetchEvent.type, fetchEventSaga)
}
