export enum EWelcomeOnboardingDataAttrs {
  HEADER_MENU_ITEM_IS_LINE = 'header_menu_item_is_line',
  INPUT_FIND = 'input_find',
  TOP_LIVE_SECTION = 'top_live_section',
  TOP_LINE_SECTION = 'top_line_section',
  NEAREST_EVENTS = 'nearest_events',
  SITE_SETTINGS = 'site_settings',
  FAVOURITES_EVENTS = 'favourites_events',
  NOTIFICATIONS = 'notifications',
  PERSONAL_ACCOUNT = 'personal_account',
  BALANCE = 'balance',
  BONUS = 'bonus',
  COUPON_SETTINGS = 'coupon_settings',
  MESSAGES = 'Messages'
}
