import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportGaelicFootball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M13.4653 4.13389C12.9903 4.04596 12.5005 4 12 4C11.5943 4 11.1957 4.03019 10.8063 4.08846C10.9597 4.2231 11.1251 4.37614 11.2933 4.54352C11.7851 5.03303 12.3352 5.67958 12.6632 6.37961C13.022 7.14552 13.2791 8.07775 13.2739 9.1616C13.2702 9.90815 13.1421 10.7194 12.8436 11.59C13.0157 12.0528 13.417 12.5994 14.034 13.0787C15.1668 11.3155 15.9675 7.64722 13.4653 4.13389Z"
          fill={mainColor}
        />
        <path
          d="M9.56752 4.37652C7.74867 4.95638 6.21232 6.16912 5.21734 7.75588C5.9741 7.28639 6.82385 6.9058 7.68363 6.62268C8.88472 6.22716 10.1488 6.00758 11.2658 6.01673C11.0583 5.74599 10.8234 5.48674 10.5878 5.25227C10.1406 4.80712 9.7248 4.48416 9.56752 4.37652Z"
          fill={mainColor}
        />
        <path
          d="M4.31329 9.77568C4.10931 10.4818 4 11.2282 4 12C4 12.2238 4.00919 12.4454 4.02721 12.6646C5.37108 10.9435 8.10278 8.58601 12.2526 8.6391C12.2006 8.04649 12.0573 7.51616 11.8658 7.05007C10.7786 6.93415 9.36997 7.12019 7.9964 7.5725C6.51693 8.05969 5.15295 8.8312 4.31329 9.77568Z"
          fill={mainColor}
        />
        <path
          d="M4.26976 14.0676C4.54196 15.0879 5.01077 16.0279 5.63313 16.8446C5.84366 15.8124 6.21838 14.9436 6.6994 14.219C7.25014 13.3892 7.9327 12.7596 8.64673 12.2912C9.78708 11.5433 11.0159 11.2009 11.9501 11.1074C12.1166 10.5857 12.2116 10.0962 12.2515 9.63932C7.77787 9.57935 5.12136 12.6344 4.26976 14.0676Z"
          fill={mainColor}
        />
        <path
          d="M6.49585 17.8056C7.92993 19.1656 9.86753 20 12 20C12.0188 20 12.0375 19.9999 12.0563 19.9998C10.8313 19.4565 9.79334 18.7605 8.96303 18.0254C8.10312 17.2641 7.44424 16.4407 7.03896 15.6807C6.7752 16.2851 6.58302 16.9883 6.49585 17.8056Z"
          fill={mainColor}
        />
        <path
          d="M14.1951 19.6951C15.1895 19.412 16.1049 18.9413 16.9007 18.3237C12.7082 18.0074 9.93154 15.5266 8.74338 13.4542C8.34997 13.7675 7.98 14.1436 7.65682 14.5923C7.87305 15.295 8.52344 16.3006 9.62591 17.2767C10.745 18.2675 12.2885 19.1891 14.1951 19.6951Z"
          fill={mainColor}
        />
        <path
          d="M17.9421 17.3566C18.5662 16.6647 19.0712 15.8632 19.4247 14.9845C18.4582 15.2298 17.5458 15.2791 16.7095 15.1839C15.6131 15.0591 14.6549 14.687 13.8821 14.1937C12.9844 13.6207 12.3085 12.8643 11.9761 12.1103C11.2982 12.1879 10.4258 12.4216 9.57646 12.8963C10.6736 14.8637 13.5135 17.4094 17.9421 17.3566Z"
          fill={mainColor}
        />
        <path
          d="M19.788 13.8372C19.9267 13.2473 20 12.6322 20 12C20 10.6262 19.6537 9.33328 19.0437 8.20376C19.0695 8.98213 18.9783 9.77021 18.8268 10.5142C18.5371 11.9375 18.0119 13.2758 17.5587 14.2291C18.2308 14.2243 18.9583 14.1113 19.7301 13.8529C19.7493 13.8465 19.7686 13.8413 19.788 13.8372Z"
          fill={mainColor}
        />
        <path
          d="M17.62 6.30649C16.8517 5.54811 15.9307 4.94408 14.9073 4.54469C16.8975 8.11479 16.0935 11.713 14.879 13.6126C15.352 13.8526 15.8917 14.0399 16.4873 14.1424C16.9433 13.2442 17.5399 11.8226 17.8469 10.3147C18.1399 8.8756 18.1523 7.4439 17.62 6.30649Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
