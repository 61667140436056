import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledEmptyPageFilterWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  line-height: 16px;
  width: 100%;
`

export const StyledEmptyPageFilterTitle = styled.div`
  line-height: 16px;
  margin-bottom: 8px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledEmptyPageFilterDescription = styled.div`
  line-height: 16px;
  margin-bottom: 8px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[17]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledEmptyPageFilterDescriptionButton = styled(Button)`
  height: 24px;
  line-height: 16px;

  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.normal};
    `}
`
