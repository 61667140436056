import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { getEventBackLink } from 'shared/lib/events'

export const useEventBackLink = (event) => {
  const history = useLocation<{ prevPath: string }>()

  const link = useMemo(() => {
    const prevPath =
      history.state?.prevPath === ERoutes.Calendar
        ? history.state?.prevPath
        : undefined

    return getEventBackLink(event, prevPath)
  }, [event, history.state?.prevPath])

  return [link]
}
