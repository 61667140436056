import React, {
  ElementRef,
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { EventStatus } from 'betweb-openapi-axios'
import { selectSport } from 'astra-core/containers/CommonDataProvider'

import { RootState } from 'shared/types/store'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { LayoutEventContext } from 'widgets/Layout/components/Event/context'
import { EventContext } from 'pages/page-live-events/Line/components/Event/Event.context'
import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'

import { Outcomes } from '../Outcomes/Outcomes'
import { OutcomesComponentType } from '../Outcomes/Outcomes.types'
import {
  StyledOutcomesHead,
  StyledOutcomesHeadRight,
  StyledOutcomesToggleDisplay
} from '../Outcomes/Outcomes.styled'
import { OutcomesGroupsTabs } from '../Outcomes/Filters/components/OutcomesFilterTabs'
import { OutcomesCategories } from '../Outcomes/Table'

import { EventBanner, InputSearch } from './components'
import { useEventBackLink } from './useEventBackLink'
import { IEventProps } from './Event.types'
import {
  StyledEventHead,
  StyledEventTitle,
  StyledEventWrapper,
  StyledHeadWrapper
} from './Event.styled'

export const Event: FC<IEventProps> = ({ event }) => {
  const [t] = useTranslation()

  const [redirectToBack] = useEventBackLink(event)
  const sport = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const isLoadingOutcomes = useMemo(
    () => event.status === EventStatus.Suspended,
    [event.status]
  )

  const [areAllOpened, setAllOpened] = useState(true)

  const outcomesRef = useRef<ElementRef<typeof OutcomesCategories>>(null)

  const openAll = useCallback(() => {
    setAllOpened(true)
    outcomesRef.current?.openAll()
  }, [])

  const closeAll = useCallback(() => {
    setAllOpened(false)
    outcomesRef.current?.closeAll()
  }, [])

  const { isEventBannerScrolled } = useContext(LayoutEventContext) || {}

  return (
    <EventContext.Provider value={{ event }}>
      <StyledEventWrapper>
        <StyledHeadWrapper isFixed={isEventBannerScrolled}>
          <StyledEventHead
            to={{
              pathname: redirectToBack,
              state: { maintainLineFilter: true }
            }}
          >
            <IconChevronLeft />

            <StyledEventTitle>
              {sport?.name}. {event.tournament.name}
            </StyledEventTitle>

            <BasketButtonSmallWindow />
          </StyledEventHead>

          <EventBanner />

          {/* <HotOdds event={event} /> */}

          <StyledOutcomesHead>
            <OutcomesGroupsTabs event={event} />

            <StyledOutcomesHeadRight>
              <InputSearch eventId={event.id} />

              <StyledOutcomesToggleDisplay
                onClick={() => (areAllOpened ? closeAll() : openAll())}
              >
                {t(areAllOpened ? 'collapse all' : 'expand all')}
              </StyledOutcomesToggleDisplay>
            </StyledOutcomesHeadRight>
          </StyledOutcomesHead>
        </StyledHeadWrapper>

        <Outcomes
          eventId={event.id}
          isLoading={isLoadingOutcomes}
          outcomesRef={outcomesRef}
          type={OutcomesComponentType.EVENT_PAGE}
        />
      </StyledEventWrapper>
    </EventContext.Provider>
  )
}
