import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ELoaderPointsMiniTypesSizes } from 'shared/ui/LoaderPointsMini'

import { StyledEventRowMessage } from './message.styled'

export const EventRowMessage: FC = () => {
  const [t] = useTranslation()

  return (
    <StyledEventRowMessage
      text={t('odds update')}
      type={ELoaderPointsMiniTypesSizes.M}
    />
  )
}
