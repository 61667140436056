import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSkiing: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M9.76866 4C8.50304 3.99722 7.97112 7.07998 7.96749 8.7146L7.96443 10.091L7.96093 10.091L7.94313 17.7097L7.94565 17.7097L7.94359 18.5892C7.94178 19.3646 8.54275 19.9966 9.28332 19.9982L10.1787 20.0001C10.9192 20.0016 11.5231 19.3721 11.525 18.5967L11.5326 15.3156L11.5195 15.3156L11.5306 10.5974L11.5447 10.5974L11.5488 8.72248C11.5525 7.08787 11.0343 4.00279 9.76866 4ZM19.0603 17.4591H19.4362C19.7476 17.4591 19.9999 17.6982 19.9999 17.9932C19.9999 18.2881 19.7475 18.5272 19.4362 18.5272H19.0603V19.0804C19.0603 19.3753 18.8079 19.6144 18.4966 19.6144C18.1852 19.6144 17.9328 19.3753 17.9328 19.0804V18.5272H17.557C17.2456 18.5272 16.9932 18.2881 16.9932 17.9932C16.9932 17.6982 17.2456 17.4591 17.557 17.4591H17.9328V9.20014C17.634 9.02201 17.4349 8.70695 17.4349 8.34849V5.68865C17.4349 5.13408 17.9112 4.68287 18.4966 4.68287C19.082 4.68287 19.5583 5.13408 19.5583 5.68865V8.34849C19.5583 8.70691 19.3591 9.02197 19.0603 9.20014V17.4591ZM6.06711 17.4592H6.44295C6.75433 17.4592 7.00671 17.6983 7.00671 17.9932C7.00671 18.2882 6.7543 18.5273 6.44295 18.5273H6.06711V19.0804C6.06711 19.3754 5.8147 19.6145 5.50336 19.6145C5.19201 19.6145 4.9396 19.3754 4.9396 19.0804V18.5273H4.56376C4.25241 18.5273 4 18.2882 4 17.9932C4 17.6983 4.25241 17.4592 4.56376 17.4592H4.9396V9.20016C4.64081 9.02204 4.44169 8.70698 4.44169 8.34851V5.68867C4.44169 5.1341 4.91795 4.68289 5.50336 4.68289C6.08876 4.68289 6.56506 5.1341 6.56506 5.68867V8.34851C6.56506 8.70694 6.36594 9.022 6.06711 9.20016V17.4592ZM12.7929 8.7146C12.7966 7.07998 13.3285 3.99722 14.5941 4C15.8597 4.00279 16.3779 7.08787 16.3743 8.72248L16.3701 10.5974L16.356 10.5974L16.345 15.3156L16.3586 15.3156L16.3509 18.5967C16.3491 19.3721 15.7452 20.0016 15.0046 20.0001L14.1093 19.9982C13.3687 19.9966 12.7677 19.3646 12.7695 18.5892L12.7716 17.7097L12.7686 17.7097L12.7864 10.091L12.7899 10.091L12.7929 8.7146Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
