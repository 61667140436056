import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportAthletics: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_5210_95154)">
          <path
            d="M16.6667 8.39266V8C16.6667 7.38044 16.24 6.86306 15.6667 6.71403V4.33334C15.6667 4.14909 15.5176 4 15.3333 4H14C13.8158 4 13.6667 4.14909 13.6667 4.33334V6.71403C13.0933 6.86309 12.6667 7.38047 12.6667 8V8.39266C12.177 8.59163 11.7235 8.86006 11.3186 9.18766C11.2573 8.63406 10.8605 8.18441 10.3333 8.04734V4.33334C10.3333 4.14909 10.1842 4 10 4H8.66666C8.48241 4 8.33331 4.14909 8.33331 4.33334V8.04738C7.76 8.19644 7.33331 8.71381 7.33331 9.33334V9.726C5.38134 10.5192 4 12.4333 4 14.6667C4 17.6074 6.39259 20 9.33334 20C10.8055 20 12.1401 19.4003 13.1061 18.4325C13.6 18.584 14.1238 18.6667 14.6667 18.6667C17.6074 18.6667 20 16.2741 20 13.3333C20 11.1 18.6187 9.18584 16.6667 8.39266ZM9.33334 18C7.49544 18 6 16.5046 6 14.6667C6 12.8288 7.49544 11.3333 9.33334 11.3333C9.46297 11.3333 9.58613 11.3571 9.71197 11.3716C9.47031 11.9796 9.33334 12.6402 9.33334 13.3333C9.33334 14.9641 10.0709 16.4243 11.2279 17.4033C10.6891 17.7776 10.0375 18 9.33334 18ZM12.4529 15.8065C11.7712 15.1957 11.3333 14.3185 11.3333 13.3333C11.3333 12.9313 11.416 12.5507 11.5471 12.1935C12.2288 12.8043 12.6667 13.6816 12.6667 14.6667C12.6667 15.0687 12.584 15.4493 12.4529 15.8065ZM14.6667 16.6667C14.537 16.6667 14.4139 16.6428 14.288 16.6284C14.5297 16.0203 14.6667 15.3598 14.6667 14.6667C14.6667 13.0359 13.9291 11.5757 12.7721 10.5966C13.3109 10.2224 13.9625 10 14.6667 10C16.5046 10 18 11.4954 18 13.3333C18 15.1712 16.5046 16.6667 14.6667 16.6667Z"
            fill={mainColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_5210_95154">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
