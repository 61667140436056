import React, { FC, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import iframeResize from 'iframe-resizer/js/iframeResizer.min'
import { accountProviderActions } from 'astra-core/containers/AccountProvider'
import {
  useGetPostAuthServiceMessage,
  EPostMessageIdEvent
} from 'astra-core/containers/AuthMessageProvider'

import { IIFrame } from './Auth.types'
import { LoaderSkeleton } from './components'
import {
  AuthWindow,
  IframeVisibilityControl,
  IframeWrapper
} from './UnauthorizedModal.styled'
import { useGetIframeData } from './hooks'

export const UnauthorizedModal: FC = () => {
  const dispatch = useDispatch()
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(true)

  const iframeData = useGetIframeData()

  const hideModalWindow = useCallback(() => {
    dispatch(accountProviderActions.setTypeAuthModal(null))
  }, [dispatch])

  const setSuccessfulLoading = useCallback(() => {
    setIsLoading(false)
    iframeResize(
      {
        log: false,
        checkOrigin: false
      },
      `#${iframeData.id}`
    )
  }, [iframeData])

  const backDropHandler = useCallback(
    (e) => {
      if (modalWrapperRef?.current === e.target) {
        hideModalWindow()
      }
    },
    [hideModalWindow]
  )

  useGetPostAuthServiceMessage(EPostMessageIdEvent.close, hideModalWindow)

  return (
    <AuthWindow ref={modalWrapperRef} onClick={backDropHandler}>
      <IframeWrapper>
        {isLoading && <LoaderSkeleton />}
        <IframeVisibilityControl isLoading={isLoading}>
          <IFrame setSuccessfulLoading={setSuccessfulLoading} />
        </IframeVisibilityControl>
      </IframeWrapper>
    </AuthWindow>
  )
}

const IFrame: FC<IIFrame> = ({ setSuccessfulLoading }) => {
  const dispatch = useDispatch()
  const { id, src, styles, typeAuthModal } = useGetIframeData()

  const handleAuthIFrameLoad = useCallback(() => {
    setSuccessfulLoading()

    try {
      const iframeHref = (document.getElementById(id) as any)?.contentWindow
        ?.location.pathname

      console.log(iframeHref)

      if (iframeHref.includes('success')) {
        /*
        Getting a user after the successful authorization in iFrame Auth Service.
        The successful authorization can be determined by the special page
        we are redirected to in case of success.
         */

        dispatch(accountProviderActions.getUser())
      }
    } catch (e) {
      // console.log('Auth iFrame error', e)
    }
  }, [dispatch, id, setSuccessfulLoading])

  const handleMessage = useCallback(
    ({ pageTitle }) => {
      if (pageTitle !== typeAuthModal) {
        dispatch(accountProviderActions.setTypeAuthModal(pageTitle))
      }
    },
    [dispatch, typeAuthModal]
  )

  useGetPostAuthServiceMessage<{ pageTitle: string }>(
    EPostMessageIdEvent.pageChange,
    handleMessage
  )

  return (
    <iframe
      frameBorder="0"
      id={id}
      src={src}
      style={styles}
      title="iframeAuth"
      onLoad={handleAuthIFrameLoad}
    />
  )
}
