import React, { FC, memo } from 'react'
import { ETestIds } from 'astra-core/utils/testIds'

import { IconLogo } from 'features/logo'

import { StyledLogo, StyledLogoWrap } from './Logo.styled'

export const Logo: FC = memo(() => (
  <StyledLogoWrap>
    <StyledLogo
      id={ETestIds.TestMainHeaderLogo}
      key="Redirect to Live"
      to="/"
      exact
    >
      <IconLogo />
    </StyledLogo>
  </StyledLogoWrap>
))
