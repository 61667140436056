import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BetType } from 'betweb-openapi-axios'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import {
  EBetStatusText,
  formatAmountLocaleString,
  getBetAmountFormatted,
  getBetOutcomeId,
  getFormatBetSystemType,
  getBetPreview
} from 'astra-core'
import {
  selectLoyaltyById,
  selectLoyaltyCurrencyRestrictionById
} from 'astra-core/containers/AccountProvider'

import { RootState } from 'shared/types/store'
import { formatDate } from 'shared/lib/events'
import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'
import { IconCoins } from 'shared/ui/Icon/General/IconCoins'
import { EColorsNames } from 'shared/types/theme'
import { selectBetsHistoryBasketsPagination } from 'containers/BetsHistoryBasketContainer/selectors'

import { BetCardHistory, CommonBlockUnderHead } from '../BasketCommon/Common'

import { selectBetsHistoryBasketValues } from './selectors'
import {
  BasketHistoryItemProps,
  BasketHistoryItemResultProps,
  TextAndNumberProps
} from './BasketHistoryBody.types'
import {
  StyledBasketHistoryBodyWrapper,
  StyledBasketHistoryItemBorder,
  StyledBasketHistoryItemHeaderBetTime,
  StyledBasketHistoryItemHeaderBetType,
  StyledBasketHistoryItemHeaderWrapper,
  StyledBetsStatus,
  StyledBasketHistoryItemResultWrapper,
  StyledBasketHistoryItemWrapper,
  StyledBetsInfo,
  StyledDataList,
  StyledNumberRightSide,
  StyledTextAndNumberWrapper,
  StyledTextLeftSide,
  StyledWideBorder,
  StyledBetsValue,
  StyledDataListTitle,
  StyledDataListText,
  StyledDataListEmpty
} from './BasketHistoryBody.styled'
import { PAGINATION_SETTINGS } from './constants'
import { BasketHistoryBonusCoupon } from './components'

export const BasketHistoryBody: FC = () => {
  const dispatch = useDispatch()

  // we reset our basket history and history filter then we change Basket Tab in "BasketHeaderItem" component
  const basketBets = useSelector(selectBetsHistoryBasketValues)

  const pagination = useSelector(selectBetsHistoryBasketsPagination)

  useEffect(() => {
    dispatch(
      betsHistoryBasketContainerActions.fetchHistoryBetsBasket({
        limit: pagination.limit,
        offset: pagination.offset
      })
    )
  }, [dispatch, pagination])

  return (
    <StyledBasketHistoryBodyWrapper>
      <CommonBlockUnderHead
        leftText="all types"
        rightText="all history"
        historyTab
      />

      <StyledDataList pagination={PAGINATION_SETTINGS}>
        {basketBets.length > 0 ? (
          basketBets.map((bet) => <BasketHistoryItem bet={bet} key={bet.id} />)
        ) : (
          <BasketDataListEmpty />
        )}
      </StyledDataList>
    </StyledBasketHistoryBodyWrapper>
  )
}

export const BasketHistoryItem: FC<BasketHistoryItemProps> = ({ bet }) => {
  const [t] = useTranslation()
  const { betType, timestamp, outcomes, coefficient, appliedCouponId } = bet
  const bonus = false

  const coefficientValue = coefficient ? +coefficient?.toFixed(2) : 0

  const systemType = getFormatBetSystemType(bet)

  return (
    <>
      <StyledBasketHistoryItemWrapper>
        <StyledBasketHistoryItemHeaderWrapper>
          <StyledBasketHistoryItemHeaderBetType>
            {t(betType.toLocaleLowerCase()).toUpperCase()} {systemType}
          </StyledBasketHistoryItemHeaderBetType>
          <StyledBasketHistoryItemHeaderBetTime>
            {formatDate(timestamp)}
          </StyledBasketHistoryItemHeaderBetTime>
        </StyledBasketHistoryItemHeaderWrapper>

        {outcomes.map((outcome) => {
          const { event, outcomeTypeId, parameters } = outcome
          const betOutcomeId = getBetOutcomeId({
            eventId: event?.id,
            outcomeTypeId,
            parameters
          })

          return <BetCardHistory key={betOutcomeId} outcome={outcome} />
        })}

        {betType !== BetType.Ordinar && (
          <>
            <StyledBasketHistoryItemBorder />
            <TextAndNumber
              number={coefficientValue}
              text={t('overall coefficient')}
            />
          </>
        )}

        {appliedCouponId && (
          <BasketHistoryBonusCoupon appliedCouponId={appliedCouponId} />
        )}

        {bonus && <TextAndNumber number={+'1,02'} text={t('bonus')} />}

        <BasketHistoryItemResult bet={bet} />
      </StyledBasketHistoryItemWrapper>

      <StyledWideBorder />
    </>
  )
}

const TextAndNumber: FC<TextAndNumberProps> = ({ text, number }) => (
  <StyledTextAndNumberWrapper>
    <StyledTextLeftSide>{text}</StyledTextLeftSide>
    <StyledNumberRightSide>{number}</StyledNumberRightSide>
  </StyledTextAndNumberWrapper>
)

const BasketHistoryItemResult: FC<BasketHistoryItemResultProps> = ({ bet }) => {
  const [t] = useTranslation()
  const { status, appliedCouponId } = bet
  const typeTheme = useSelector(selectUserSettingLayoutTheme)
  const currencyIcon = useGetCurrencyIcon()
  const couponLoyalty = useSelector((state: RootState) =>
    selectLoyaltyById(state, appliedCouponId)
  )
  const loyaltyCurrencyRestriction = useSelector((state: RootState) =>
    selectLoyaltyCurrencyRestrictionById(state, appliedCouponId)
  )

  const betAmountFormatted = getBetAmountFormatted({
    bet,
    couponLoyalty,
    currencyIcon,
    loyaltyCurrencyRestriction
  })

  const { isLongSumValue, isTwoLinesPreview } = getBetPreview({
    bet,
    betAmountFormatted
  })

  return (
    <StyledBasketHistoryItemResultWrapper status={status} typeTheme={typeTheme}>
      <StyledBetsInfo isTwoLines={isTwoLinesPreview}>
        {isLongSumValue && (
          <IconCoins
            colorProps={{ name: EColorsNames.Red, value: 90 }}
            size={10}
          />
        )}
        {!isLongSumValue && (
          <StyledBetsStatus>{t('bets amount')}</StyledBetsStatus>
        )}

        <StyledBetsValue>
          {formatAmountLocaleString({ value: bet.bet, currency: currencyIcon })}
        </StyledBetsValue>
      </StyledBetsInfo>

      <StyledBetsInfo isTwoLines={isTwoLinesPreview}>
        <StyledBetsStatus status={status}>
          {t(EBetStatusText[status])}
        </StyledBetsStatus>

        <StyledBetsValue>{betAmountFormatted}</StyledBetsValue>
      </StyledBetsInfo>
    </StyledBasketHistoryItemResultWrapper>
  )
}

const BasketDataListEmpty = () => {
  const [t] = useTranslation()
  return (
    <StyledDataListEmpty>
      <StyledDataListTitle>{t('no bets!')}</StyledDataListTitle>
      <StyledDataListText>{t('history bets is empty')}</StyledDataListText>
    </StyledDataListEmpty>
  )
}
