import React, { FC, memo } from 'react'
import { useTheme } from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

import { IIconsSportNameProps, ISportIconProps } from './SportIcon.types'
import { SPORTS_ICON_MAP } from './constants'
import { IconAllSports } from './components/IconAllSports'

export const SportIcon: FC<ISportIconProps> = memo(({ sport, ...props }) => {
  const SelectedIconSport: FC<IIconsSportNameProps> =
    SPORTS_ICON_MAP[sport!] ?? IconAllSports

  const theme = useTheme()

  return (
    <SelectedIconSport
      backgroundColor={theme.colors.custom.primary[4]}
      colorProps={{ name: EColorsNames.Primary, value: 70 }}
      size={24}
      sport={sport}
      {...props}
    />
  )
})
