import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportShortTrack: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M17.7014 14.034C18.5907 14.2757 19.0054 14.8403 19.1461 15.3981C19.6506 15.6399 20 16.1556 20 16.7514C20 17.5782 19.3273 18.2509 18.5005 18.2509H7.11981C6.86958 18.2509 6.66667 18.0481 6.66667 17.7977C6.66667 17.5475 6.86958 17.3446 7.11981 17.3446H8.02089V16.2783C7.97891 16.1585 7.93019 16.0409 7.87957 15.9188C7.64483 15.3526 7.36927 14.688 7.53594 13.2405C7.6604 12.8942 7.76263 11.1024 7.81052 10.2632C7.82074 10.0839 7.82849 9.94815 7.83345 9.87916C7.90168 8.92239 7.71265 7.04204 7.67483 6.66581C7.67096 6.62728 7.66867 6.60453 7.66841 6.60015C7.66611 6.56108 7.67424 6.50233 7.72617 6.44426C7.90571 6.24319 8.35241 5.74927 9.89309 5.74927C10.2577 5.74927 10.8704 5.75365 10.8704 5.75365L11.4816 5.77368C11.4816 5.77368 12.3009 5.87998 12.3043 6.05251L12.0441 7.82773C11.4345 12.2392 15.8315 13.4511 15.8315 13.4511C16.5405 13.7106 17.2383 13.908 17.7014 14.034ZM8.92715 17.3446H13.8009V16.5816L8.92715 16.5722V17.3446ZM14.7071 17.3446H18.5005C18.8275 17.3446 19.0937 17.0785 19.0937 16.7513C19.0937 16.6923 19.0847 16.6353 19.0687 16.5815H14.7071V17.3446ZM5.73333 8.66675C5.51242 8.66675 5.33333 8.84583 5.33333 9.06675C5.33333 9.28766 5.51242 9.46675 5.73333 9.46675H6.93333C7.15425 9.46675 7.33333 9.28766 7.33333 9.06675C7.33333 8.84583 7.15425 8.66675 6.93333 8.66675H5.73333ZM5.06667 10.0001C4.84575 10.0001 4.66667 10.1792 4.66667 10.4001C4.66667 10.621 4.84575 10.8001 5.06667 10.8001H6.93333C7.15425 10.8001 7.33333 10.621 7.33333 10.4001C7.33333 10.1792 7.15425 10.0001 6.93333 10.0001H5.06667ZM4 11.7334C4 11.5125 4.17909 11.3334 4.4 11.3334H6.93333C7.15425 11.3334 7.33333 11.5125 7.33333 11.7334C7.33333 11.9543 7.15425 12.1334 6.93333 12.1334H4.4C4.17909 12.1334 4 11.9543 4 11.7334Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
