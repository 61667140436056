import { ERoutes } from 'shared/types/routes'

export const FIXED_HEADER_ROUTES_LIST = [
  { path: ERoutes.Casino, locale: 'casino' },
  { path: ERoutes.CyberbetGames, locale: 'cyberbet' },
  { path: ERoutes.BetGames, locale: 'betgames' },
  { path: ERoutes.LiveGames, locale: 'livegames' },
  { path: ERoutes.SportGamesLobby, locale: 'sport games' },
  { path: ERoutes.TurboGames, locale: 'fast games' },
  { path: ERoutes.TwainGames, locale: 'twain' }
]
