import React, { ComponentProps, FC } from 'react'
import { Link } from 'react-router-dom'
import { getEventTestId } from 'astra-core/utils/testIds'

import { StyledEventTeamsNames } from 'entities/event'

import { useOpenEventPage } from '../lib'

export const EventRowOpenEventPage: FC<
  Pick<ComponentProps<typeof Link>, 'children'> &
    Pick<Parameters<typeof useOpenEventPage>[0], 'event'>
> = ({ children, event }) => {
  const link = useOpenEventPage({ event })
  const eventTestId = getEventTestId(event)

  return (
    <StyledEventTeamsNames id={eventTestId} to={link}>
      {children}
    </StyledEventTeamsNames>
  )
}
