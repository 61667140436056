import styled, { createGlobalStyle } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { IconClose } from 'shared/ui/Icon/General/IconClose'

export const StyledSuccessPageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

export const GlobalStyleHideLiveChatPatch = createGlobalStyle`
  #chat-widget-container {
    display: none;
  }
`

export const StyledSuccessPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
`

export const StyledSuccessPageTitle = styled.h2`
  color: ${(props) => props.theme.colors.custom.primary[66]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  margin: 16px 0 4px;
`

export const StyledSuccessPageSubtitle = styled.h3`
  color: ${(props) => props.theme.colors.default.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  margin: 4px 0 0;
  text-align: center;
`

export const StyledSuccessPageButton = styled(Button)`
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: auto;
  line-height: 16px;
  margin-top: 20px;
  padding: 4px 8px;
`

export const StyledSuccessPageModalHeaderWrapper = styled.div`
  position: absolute;
  right: 23px;
  top: 32px;
`

export const StyledSuccessPageModalHeaderExit = styled(IconClose)`
  cursor: pointer;
  margin-right: 20px;
`
