import styled, { css } from 'styled-components'

import { ESwitchView, SwitchProps } from './types'

export const StyledSwitchWrapper = styled.div`
  display: flex;
`
export const StyledSwitch = styled.div<SwitchProps>`
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: ${({ active }) => (active ? 'flex-end' : 'flex-start')};
  padding: 3px;
  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background-color: ${props.theme.colors.default.primary[10]};
      }
    `}
  position: relative;
  width: 28px;

  ${({ active, theme, view }) => css`
    ${view === ESwitchView.PRIMARY &&
    css`
      background-color: ${active
        ? theme.colors.custom.blue[0]
        : theme.colors.custom.primary[4]};
    `}

    ${view === ESwitchView.BASKET &&
    css`
      background-color: ${active
        ? theme.colors.custom.yellow[10]
        : theme.colors.custom.yellow[9]};
      border-radius: 14px;
      height: 20px;
      padding: 4px;
      width: 36px;
    `}
  `}
`

export const StyledSwitchPin = styled.div<SwitchProps>`
  border-radius: 5px;
  height: 10px;
  width: 10px;

  ${({ active, theme, view }) => css`
    ${view === ESwitchView.PRIMARY &&
    css`
      background-color: ${active
        ? theme.colors.fixed.primary[0]
        : theme.colors.custom.blue[1]};
    `}

    ${view === ESwitchView.BASKET &&
    css`
      background-color: ${active
        ? theme.colors.custom.primary[52]
        : theme.colors.custom.primary[71]};
      border-radius: 7px;
      height: 12px;
      width: 12px;
    `}
  `}
`

export const StyledSwitchLabel = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    margin-left: 4px;
  `}
`
