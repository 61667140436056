import React, { FC } from 'react'

import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'

import { TitleLinePageProps } from './TitleLinePage.types'
import {
  StyledHeaderWrapper,
  StyledTitle,
  StyledTitleTextWrapper,
  StyledTitleWrapper
} from './TitleLinePage.styled'

// TODO: Transfer to widget or entities
export const TitleLinePage: FC<TitleLinePageProps> = ({
  iconComponent,
  title,
  children
}) => {
  return (
    <StyledHeaderWrapper>
      <StyledTitleWrapper>
        <StyledTitleTextWrapper>
          {iconComponent}

          <StyledTitle>{title}</StyledTitle>
        </StyledTitleTextWrapper>

        <BasketButtonSmallWindow />
      </StyledTitleWrapper>

      {children}
    </StyledHeaderWrapper>
  )
}
