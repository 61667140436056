import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBobsled: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_5210_95380)">
          <path
            clipRule="evenodd"
            d="M11.8682 10.5742L10.6385 10.4298C10.9467 11.2374 11.8476 11.6493 12.6609 11.3485C13.4786 11.0456 13.8959 10.1371 13.5937 9.31966C13.2908 8.50165 12.3823 8.08435 11.5646 8.38688C11.0829 8.56511 10.7463 8.95534 10.6073 9.4113L11.9999 9.56328L12.0002 9.56371C12.0109 9.58053 12.3361 10.091 11.8682 10.5742ZM18.0504 14.2926H5.55284C4.47626 14.2926 3.64557 13.3863 4.15258 12.4364C4.52957 11.7311 5.04557 11.1655 5.54382 10.7335C6.35788 10.0286 8.03901 9.66231 9.1153 9.66231H10.0968V10.495C10.0968 11.2724 10.7293 11.9048 11.5067 11.9048H16.1593C16.9367 11.9048 17.5691 11.2724 17.5691 10.495V9.66231H18.0504C19.127 9.66231 20 10.5353 20 11.6119V12.343C20 13.4196 19.127 14.2926 18.0504 14.2926ZM17.9218 15.7117C18.1553 15.7117 18.3447 15.5225 18.3447 15.2887C18.3447 15.055 18.1553 14.8658 17.9218 14.8658H13.6922C13.4587 14.8658 13.2692 15.055 13.2692 15.2887C13.2692 15.5225 13.4587 15.7117 13.6922 15.7117H17.9218ZM9.88512 14.8658H5.65555C5.42207 14.8658 5.23258 15.055 5.23258 15.2888C5.23258 15.5225 5.42207 15.7117 5.65555 15.7117H9.88515C10.1186 15.7117 10.3081 15.5225 10.3081 15.2888C10.3081 15.055 10.1186 14.8658 9.88512 14.8658ZM14.1076 10.4298L15.3373 10.5742C15.8064 10.0898 15.4785 9.57811 15.4692 9.5636C15.4691 9.56339 15.469 9.56328 15.469 9.56328L14.0763 9.4113C14.2154 8.95534 14.552 8.56511 15.0336 8.38688C15.8514 8.08435 16.7599 8.50165 17.0627 9.31966C17.365 10.1371 16.9477 11.0456 16.1299 11.3485C15.3167 11.6493 14.4159 11.2374 14.1076 10.4298Z"
            fill={mainColor}
            fillRule="evenodd"
          />
        </g>

        <defs>
          <clipPath id="clip0_5210_95380">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
