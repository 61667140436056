import React, { FC, Fragment, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import uniqueId from 'lodash/uniqueId'

import { EEventStatisticsCodes } from 'widgets/event-statistics'
import { RootState } from 'shared/types/store'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'

import {
  StyledScoreByPeriod,
  StyledScoreByPeriods,
  StyledScoreByPeriodsDivider,
  StyledScoreByPeriodsItem
} from './ScoreByPeriods.styled'

export const ScoreByPeriods: FC = () => {
  const { event } = useContext(EventContext) as IEventContext
  const sport = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const scoreByPeriods = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.ScoresByPeriods
  )

  const gameScore = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.GameScore
  )

  const gameScoreValue = useMemo(() => {
    if (!gameScore?.value) {
      return null
    }
    let formattedGameScoreValue = gameScore?.value
    if (sport?.code === ESportsCodes.TENNIS) {
      formattedGameScoreValue = formattedGameScoreValue.replace(/50/g, 'A')
    }
    return formattedGameScoreValue
  }, [gameScore?.value, sport?.code])

  const splittedScoreByPeriods = useMemo(
    () =>
      scoreByPeriods?.value.split(',').map((item) => ({
        id: uniqueId(),
        value: item
      })),
    [scoreByPeriods]
  )

  if (!splittedScoreByPeriods) {
    return null
  }

  return (
    <StyledScoreByPeriods>
      {splittedScoreByPeriods.map((item, index, self) => {
        const isFirst = index === 0
        const isLast = index === self.length - 1
        return (
          <Fragment key={item.id}>
            <StyledScoreByPeriod>
              <StyledScoreByPeriodsItem
                isFirst={isFirst}
                isLast={isLast && !gameScoreValue}
              >
                {item.value}
              </StyledScoreByPeriodsItem>
            </StyledScoreByPeriod>
            {!isLast && <StyledScoreByPeriodsDivider />}
          </Fragment>
        )
      })}
      {!!gameScoreValue && (
        <>
          <StyledScoreByPeriodsDivider />
          <StyledScoreByPeriod>
            <StyledScoreByPeriodsItem isLast>
              {gameScoreValue}
            </StyledScoreByPeriodsItem>
          </StyledScoreByPeriod>
        </>
      )}
    </StyledScoreByPeriods>
  )
}
