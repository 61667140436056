import React, { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ETestIds } from 'astra-core/utils/testIds'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { IconLine } from 'shared/ui/Icon/General/IconLine'
import { IconLive } from 'shared/ui/Icon/General/IconLive'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'

import { ISportsMenuTabProps } from './SportsMenuTabs.types'

export const SportsMenuTabs: FC<ISportsMenuTabProps> = memo(
  ({ currentTabMenu, setCurrentTabMenu }) => {
    const [t] = useTranslation()

    const handleTabChange = useCallback(
      (type: ESportsMenuTabTypes) => {
        setCurrentTabMenu(type)
      },
      [setCurrentTabMenu]
    )

    return (
      <Tabs variant={4}>
        <TabsItem
          active={currentTabMenu === ESportsMenuTabTypes.LINE}
          handleTabChange={handleTabChange}
          icon={IconLine}
          testId={ETestIds.TestSportsMenuTabLine}
          type={ESportsMenuTabTypes.LINE}
        >
          {t('line')}
        </TabsItem>
        <TabsItem
          active={currentTabMenu === ESportsMenuTabTypes.LIVE}
          handleTabChange={handleTabChange}
          icon={IconLive}
          testId={ETestIds.TestSportsMenuTabLive}
          type={ESportsMenuTabTypes.LIVE}
        >
          {t('live')}
        </TabsItem>
      </Tabs>
    )
  }
)
