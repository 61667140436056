import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportModernPentathlon: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {() => (
      <>
        <g clipPath="url(#a)">
          <path
            d="M11.63 15.61a3.833 3.833 0 0 1-.091-.037c-.818-.34-1.605-.974-2.275-1.631a18.05 18.05 0 0 1-1.681-1.932c-.526-.693-.963-1.437-1.438-2.165l-.377-.583a17.05 17.05 0 0 0-1.61 2.33c-.437.775-.773 1.568-.866 2.26-.09.664.048 1.168.468 1.524.386.173.812.268 1.255.269l3.08.005 3.081-.005c.154 0 .306-.012.455-.034Z"
            fill="#F79838"
          />
          <path
            d="M2.3 13.718a3.9 3.9 0 0 0-.024.234c-.059-.12-.11-.246-.154-.376l-.957-2.882L.218 7.81a2.95 2.95 0 0 1-.023-1.77c.237-.486.686-.745 1.343-.83.693-.089 1.546.03 2.408.253.854.22 1.674.53 2.284.786.197.082.37.16.516.225l-.453.557c-.348.432-.69.87-1.047 1.294-.131.155-.315.378-.527.651-.423.545-.965 1.3-1.43 2.124-.462.817-.87 1.741-.988 2.62Z"
            fill="#C653FF"
          />
          <path
            d="M4.195 4.494c-.809-.209-1.688-.354-2.492-.304l2.109-1.513L6.307.9A3.072 3.072 0 0 1 7.741.35c.52.058.912.382 1.22.95.334.614.53 1.453.634 2.337.103.875.11 1.752.091 2.414a19.84 19.84 0 0 1-.02.503L9.013 6.3a96.488 96.488 0 0 1-2.397-.975 18.047 18.047 0 0 0-2.422-.832Z"
            fill="#40BF40"
          />
          <path
            d="M10.587 3.52c.11.941.117 1.87.098 2.56-.025.862-.093 1.724-.137 2.586l-.038.7a17.06 17.06 0 0 0 2.745-.855c.822-.34 1.576-.759 2.075-1.247.481-.47.683-.954.569-1.496a3.002 3.002 0 0 0-1.032-1.304l-2.49-1.786L9.882.901 9.879.898c.396.767.602 1.72.708 2.623Z"
            fill="#E03A3A"
          />
          <path
            d="m10.441 10.404-1.667.45-.672.179a17.056 17.056 0 0 0 1.86 2.194c.635.623 1.315 1.154 1.96 1.423.587.245 1.09.255 1.527-.015.272-.301.485-.66.617-1.06l.957-2.881.843-2.565c-.619.546-1.434.975-2.228 1.305-.875.363-1.766.623-2.435.793-.306.077-.567.136-.762.177Z"
            fill="#4E7FFF"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path d="M0 0h16v16H0z" fill="#fff" />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
