import styled, { css } from 'styled-components'

export const StyledContentTitle = styled.h2`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.xxl};
    font-weight: ${props.theme.fonts.weight.bold};
    margin: 0;
    padding: 16px 0 16px 16px;
  `}
`
