import React, { FC } from 'react'

import { EventCoefficientTotal } from 'entities/event'

import { useGetTotalValue } from '../../lib'

export const EventRowCoefficientTotal: FC<
  Pick<Parameters<typeof useGetTotalValue>[0], 'event'>
> = ({ event }) => {
  const value = useGetTotalValue({ event })

  return <EventCoefficientTotal value={value} />
}
