import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSynchronizedSwimming: FC<IIconsSportNameProps> = (
  props
) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_116044)">
          <path
            d="M4.33398 15.5635C5.33398 15.5635 5.33398 16.5635 6.66732 16.5635C8.00065 16.5635 8.00065 15.5635 9.33398 15.5635C10.6673 15.5635 10.6673 16.5635 12.0007 16.5635C13.334 16.5635 13.334 15.5635 14.6673 15.5635C16.0007 15.5635 16.0007 16.5635 17.334 16.5635C18.6673 16.5635 18.6673 15.5635 19.6673 15.5635"
            stroke={mainColor}
          />
          <path
            d="M6.66732 18.4404C5.33398 18.4404 5.33398 17.4404 4.33398 17.4404V19.6715H19.6673V17.4404C18.6673 17.4404 18.6673 18.4404 17.334 18.4404C16.0007 18.4404 16.0007 17.4404 14.6673 17.4404C13.334 17.4404 13.334 18.4404 12.0007 18.4404C10.6673 18.4404 10.6673 17.4404 9.33398 17.4404C8.00065 17.4404 8.00065 18.4404 6.66732 18.4404Z"
            fill={mainColor}
            stroke={mainColor}
          />
          <path
            clipRule="evenodd"
            d="M5.91797 13.89V5.66246C5.91797 5.34513 6.17521 5.08789 6.49253 5.08789C6.69273 5.08789 6.87849 5.19209 6.98285 5.36293L7.96976 6.97848C8.41526 7.70775 8.17956 8.6091 7.67582 9.29943C6.79591 10.5053 7.34213 12.5183 7.85498 13.8215C7.73246 13.9011 7.62972 13.9782 7.54896 14.0389L7.52964 14.0534C7.3539 14.1852 7.27052 14.2434 7.17761 14.2852C7.10375 14.3185 6.99427 14.3534 6.79631 14.3534C6.59627 14.3534 6.49067 14.3177 6.42891 14.2892C6.35641 14.2557 6.28602 14.2061 6.15543 14.0941C6.13598 14.0775 6.11479 14.0588 6.0918 14.0385C6.04238 13.9949 5.98464 13.944 5.91797 13.89Z"
            fill={mainColor}
            fillRule="evenodd"
          />
          <path
            d="M16.3045 9.88721L9.4034 9.88721C8.78201 9.88721 8.20508 9.92608 8.20508 10.6406C8.20508 11.3551 9.41539 14.4044 9.82104 14.4044H10.0207C10.1284 14.4044 10.1476 14.3191 10.1438 14.2114L10.0831 12.4769C10.0598 11.8118 10.4945 11.2348 11.1584 11.2812C11.561 11.3094 12.0709 11.3903 12.6852 11.5733C13.6673 11.8658 14.7386 11.9437 15.6036 11.3943L16.5651 10.7838C16.7055 10.6946 16.7906 10.5398 16.7906 10.3734C16.7906 10.1049 16.573 9.88721 16.3045 9.88721Z"
            fill={mainColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_116044">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
        <defs>
          <clipPath id="clip0_6396_116020">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
