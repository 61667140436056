import React, { FC } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { StyledTableCalendarDate } from '../TableCalendar.styled'
import { useDateToString } from '../utils'

import { TableCalendarHeadProps } from './TableCalendarHead.types'
import {
  StyledTableCalendarHead,
  StyledTableCalendarTime
} from './TableCalendarHead.styled'

export const TableCalendarHead: FC<TableCalendarHeadProps> = ({
  event,
  eventDate
}) => {
  const { checkDate } = useDateToString()

  return (
    <StyledTableCalendarHead>
      <StyledTableCalendarTime>{event.eventTime}</StyledTableCalendarTime>
      <StyledTableCalendarDate>
        {`${checkDate(eventDate)}${dayjs(eventDate).format('DD MMMM')}`}
      </StyledTableCalendarDate>
    </StyledTableCalendarHead>
  )
}
