import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledTournamentText = styled(Link)`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.custom.primary[13]};
    display: flex;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.regular};
    grid-area: text;
    line-height: 18px;
  `}
`

export const StyledTournamentAdditional = styled.span`
  color: ${(props) => props.theme.colors.custom.primary[14]};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding-left: 8px;
`

export const StyledTournament = styled.div`
  align-items: center;
  display: grid;
  font-size: ${(props) => props.theme.fonts.size.m};
  grid-template-areas: 'fav text';
  grid-template-columns: 24px 1fr 1fr;
  padding: 7px 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[4]};
  }
`

export const StyledLineWrapper = styled.div`
  margin-bottom: 10px;
`

export const StyledLineTable = styled.div`
  display: grid;
`

export const StyledGroupedLine = styled.div``

export const StyledTournamentTop = styled.span`
  background-color: ${(props) => props.theme.colors.default.blue[70]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.custom.primary[15]};
  font-size: ${(props) => props.theme.fonts.size.xxxxs};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: ${(props) => props.theme.fonts.size.l};
  margin-right: 4px;
  padding: 0 3px;
`

export const StyledLineTableTop = styled.div`
  position: relative;

  :not(:empty) {
    &::after {
      background-color: ${(props) => props.theme.colors.custom.primary[4]};
      content: ' ';
      height: 1px;
      margin: 0 0 0 24px;
      position: absolute;
      width: 100%;
    }
  }
`

export const StyledLineTournamentSport = styled.span`
  align-items: center;
  display: flex;
  grid-area: text;
  padding-right: 4px;
`

export const StyledLoadingIndicatorWrapper = styled.div`
  padding-top: 25%;
`
