import { ETestIds } from 'astra-core/utils/testIds'

export interface SwitchProps {
  active?: boolean
  label?: string
  onClick?: () => void
  disabled?: boolean
  testId?: ETestIds
  view?: ESwitchView
}

export enum ESwitchView {
  PRIMARY = 'primary',
  BASKET = 'basket'
}
