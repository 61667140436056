import styled from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'

export const StyledUserAccountBonus = styled.button`
  background-color: ${(props) => props.theme.colors.fixed.yellow[10]};
  border: none;
  ${columnGapPrefixes('2px')};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  line-height: 16px;
  outline: none;
  padding: 2px 2px 2px 6px;
`
