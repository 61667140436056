import styled, { css } from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

export const StyledEventDateTime = styled.div<{ isLive?: boolean }>`
  ${(props) => css`
    align-items: center;
    border-radius: 4px;
    color: ${props.theme.colors.default[EColorsNames.Primary][90]};
    display: flex;
    font-size: ${props.theme.fonts.size[props.isLive ? 'xxs' : 's']};
    font-weight: ${props.theme.fonts.weight.normal};
    gap: 8px;
    line-height: 16px;
    padding: 4px 0 4px 8px;
    position: relative;
    width: fit-content;

    &::after {
      background: ${(props) =>
        props.theme.colors.custom[EColorsNames.Primary][21]};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 1px;
    }
  `}
`

export const StyledEventDateTimeAdditional = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.custom[EColorsNames.Primary][17]};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.custom[EColorsNames.Primary][21]};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  margin-left: 8px;
  padding: 0 2px;
`

export const StyledAdditionalTime = styled.div`
  background-color: ${(p) => p.theme.colors.default[EColorsNames.Primary][5]};
  border-radius: 2px;
  color: ${(p) => p.theme.colors.default[EColorsNames.Primary][50]};
  font-size: ${(p) => p.theme.fonts.size.xs};
  line-height: 16px;
  padding: 0 2px;
`
