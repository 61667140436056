import styled from 'styled-components'

import { LoaderPointsMini } from 'shared/ui/LoaderPointsMini'

export const StyledEventRowMessage = styled(LoaderPointsMini)`
  position: absolute;
  right: calc(0% + 210px);
  top: calc(50% - 12px);
  z-index: 1;
`
