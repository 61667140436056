import { ERoutes } from 'shared/types/routes'

export const MAIN_HEADER_ROUTES_LIST = [
  { path: ERoutes.LineRedirect, locale: 'line' },
  { path: ERoutes.Live, locale: 'live' },
  { path: ERoutes.Calendar, locale: 'calendar' },
  { path: ERoutes.Statistics, locale: 'statistics' },
  { path: ERoutes.Results, locale: 'results' },
  { path: ERoutes.Promotions, locale: 'promotions and bonuses' }
]
