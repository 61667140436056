import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportShortHockey: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M12.2438 16.0426L16.6465 4L17.917 4.4329L17.812 4.75097C17.8088 4.76051 16.3124 9.29366 14.1945 15.7078C14.1198 15.9339 14.0497 16.1848 13.9728 16.4599C13.6282 17.693 13.1607 19.3652 12.2098 19.7896C12.0046 19.8812 11.8213 19.9337 11.6358 19.9636C11.4504 19.9935 11.279 20 11.0886 20H8.63918V19.9987C8.35889 19.9987 8.22205 19.9987 8.05963 19.8974C7.91505 19.8073 7.83671 19.6846 7.68375 19.4451L7.64766 19.3886C7.51746 19.1854 7.39477 18.947 7.33424 18.7221C7.28697 18.5465 7.27549 18.3722 7.31946 18.2124L7.31831 18.2121C7.3716 18.0188 7.49304 17.8657 7.7068 17.7777C7.81601 17.7327 7.94838 17.7077 8.10743 17.7077H11.0052C11.1902 17.7077 11.3814 17.5535 11.5577 17.3378C11.8696 16.956 12.1132 16.3999 12.2438 16.0426ZM11.1568 16.4501C11.1568 16.4942 11.1507 16.5379 11.1389 16.5811C11.1281 16.6203 11.1121 16.6587 11.0913 16.6962C10.9674 16.9187 10.6201 17.0951 10.1399 17.2013C9.72935 17.292 9.19896 17.347 8.62385 17.347C7.98956 17.347 7.4089 17.2798 6.98287 17.1711C6.43171 17.0305 6.09081 16.779 6.09081 16.4501H6.09205L6.09206 16.4183L6.092 16.3895L6.08895 16.3719L6.08398 16.3188V15.5879C6.31453 15.7871 6.63217 15.908 6.90274 15.9771C7.44947 16.1165 8.06228 16.163 8.62381 16.163C9.18535 16.163 9.79817 16.1165 10.3449 15.9771C10.6154 15.908 10.9329 15.7871 11.1634 15.5881C11.1628 15.7086 11.1609 15.8431 11.1591 15.9774L11.159 15.9839L11.159 15.9843C11.1579 16.0618 11.1568 16.1403 11.1568 16.4501ZM10.2696 14.2105C9.84234 14.1015 9.25998 14.0341 8.6238 14.0341C7.98764 14.0341 7.40529 14.1015 6.97804 14.2105C6.42565 14.3514 6.08398 14.6033 6.08398 14.9327C6.08398 15.2621 6.42565 15.5139 6.97804 15.6549C7.40529 15.7638 7.98764 15.8313 8.6238 15.8313C9.25998 15.8313 9.84234 15.7638 10.2696 15.6549C10.822 15.514 11.1636 15.2621 11.1636 14.9327C11.1636 14.6034 10.822 14.3514 10.2696 14.2105Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
