import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useDateToString = () => {
  const [t] = useTranslation()

  const checkDate = useCallback(
    (date: string) => {
      if (dayjs(date).isSame(dayjs().add(1, 'day'), 'day')) {
        return `${t('tomorrow')}, `
      }
      if (dayjs(date).isSame(dayjs(), 'day')) {
        return `${t('today')}, `
      }
      return ''
    },
    [t]
  )

  return {
    checkDate
  }
}
