import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { IconExit } from 'shared/ui/Icon/General/IconExit'
import {
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/Button'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { SideMenu } from 'shared/ui/SideMenu'

import { PersonalData } from './components/PersonalData'
import { PROFILE_MENU_ROUTES_LIST } from './routes'
import { getLogoutUrl } from './constants'
import { StyledButtonExit, StyledLkMenu } from './ProfileMenu.styled'

export const ProfileMenu = memo(() => {
  const [t] = useTranslation()

  const config = useSelector(selectConfig)
  const user = useSelector(selectAccountUser)

  return user ? (
    <StyledLkMenu>
      <PersonalData />

      <SideMenu routeList={PROFILE_MENU_ROUTES_LIST} />

      <form action={getLogoutUrl(config)} method="POST">
        <StyledButtonExit
          iconProps={{
            colorProps: {
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 17
            },
            positionSide: EButtonIconPositionSides.Left
          }}
          icon={IconExit}
          size={EButtonSizes.S}
          type="submit"
          view={EButtonViews.NONE}
        >
          {t('to exit')}
        </StyledButtonExit>
      </form>
    </StyledLkMenu>
  ) : null
})
