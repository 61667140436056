import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventH2H: FC<IconProps> = (props) => (
  <Icon size={12} {...props}>
    <svg fill="none" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H3L6 4L3 8H0V0Z" fill="currentColor" />
      <path d="M12 10L9 10L6 6L9 2L12 2L12 10Z" fill="currentColor" />
    </svg>
  </Icon>
)
