import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { IconTutorial } from 'shared/ui/Icon/General/IconTutorial'

import {
  IStyledNotification,
  IStyledNotificationText,
  IStyledNotificationTLink
} from './Notification.types'

export const StyledNotificationWrapper = styled.div`
  margin-bottom: 16px;
`

export const StyledNotification = styled.div<IStyledNotification>`
  ${(props) => css`
    align-items: center;
    background-color: ${props.theme.colors.custom[EColorsNames.Blue][4]};
    border-radius: 0.25rem;
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: auto 1fr auto;
    line-height: 16px;
    padding: 0.5rem;
  `}
`

export const StyledNotificationText = styled.span<IStyledNotificationText>`
  ${({
    theme,
    textColor: { name, value } = { name: EColorsNames.Primary, value: 30 }
  }) =>
    css`
      color: ${theme.colors.custom[name][value]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledNotificationLink = styled(Link)`
  ${({ theme }) =>
    css`
      border-bottom: 1.1px solid ${theme.colors.default.primary[1]};
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledIconTutorial = styled(IconTutorial)``

export const StyledIconClose = styled(IconClose)`
  align-self: center;
`

export const StyledNotificationModalWindowText = styled.span<IStyledNotificationTLink>`
  cursor: pointer;
  ${({
    theme,
    textColor: { name, value } = { name: EColorsNames.Primary, value: 90 }
  }) =>
    css`
      border-bottom: 1.1px solid ${theme.colors.default[name][value]};
      color: ${theme.colors.default[name][value]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`
