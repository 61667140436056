import React, { FC, memo } from 'react'

import {
  DEFAULT_COLOR_PROPS,
  DEFAULT_STYLED_ICON_BUTTON_PROPS
} from './constants'
import { IconProps } from './types'
import { StyledIcon, StyledIconButton } from './styled'

export const Icon: FC<IconProps> = memo(
  ({ children, buttonProps, testId, onClick, ...props }) => {
    if (buttonProps) {
      return (
        <StyledIconButton
          {...DEFAULT_STYLED_ICON_BUTTON_PROPS}
          {...(typeof buttonProps === 'object' && buttonProps)}
          onClick={onClick}
        >
          <StyledIcon colorProps={DEFAULT_COLOR_PROPS} size={16} {...props}>
            {children}
          </StyledIcon>
        </StyledIconButton>
      )
    } else {
      return (
        <StyledIcon
          colorProps={DEFAULT_COLOR_PROPS}
          id={testId}
          size={16}
          onClick={onClick}
          {...props}
        >
          {children}
        </StyledIcon>
      )
    }
  }
)
