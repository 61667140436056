import { useSelector } from 'react-redux'
import {
  MainOutcomeTypes,
  selectMainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'
import { useMemo } from 'react'
import { useProbs } from 'astra-core/hooks/useEvents'
import { Event, ParameterType } from 'betweb-openapi-axios'

import { getOutcomeParameterValue } from 'shared/lib/outcomes'

export const useGetTotalValue = ({ event }: { event: Event }) => {
  const mainOutcomeTypes = useSelector(selectMainOutcomeTypes)
  const probs = useProbs(event)

  return useMemo(
    () =>
      getOutcomeParameterValue({
        eventProbability: probs[mainOutcomeTypes[MainOutcomeTypes.TO]!],
        parameterType: ParameterType.Value
      }),
    [mainOutcomeTypes, probs]
  )
}
