interface GetFetchEventReqDataParams {
  eventId: string | number
}

export const getFetchEventReqData = ({
  eventId
}: GetFetchEventReqDataParams) => ({
  eventId: +eventId
})

export const LIVE_EVENT_POLLING_INTERVAL = 2000
export const LINE_EVENT_POLLING_INTERVAL = 3000
