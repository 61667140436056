import React, { ComponentProps, FC } from 'react'
import { getEventHomeAwayCompetitors } from 'astra-core'
import { Event } from 'betweb-openapi-axios'

import { EventTeamsNames as EventTeamsNamesEntity } from 'entities/event'
import { EventRowOpenEventPage } from 'features/event'
import { ETagTypes, Tag } from 'shared/ui/Tag'

import { useGetIsActivePlayer } from '../../lib/teams-names'

export const EventRowTeamsNames: FC<{ event: Event }> = ({ event }) => {
  const { homeCompetitors, awayCompetitors } =
    getEventHomeAwayCompetitors(event)

  const { isActiveHomeTeam, isActiveAwayTeam } = useGetIsActivePlayer({
    eventStatistics: event.statistics
  })

  const teamsList: ComponentProps<typeof EventTeamsNamesEntity>['teamsList'] = [
    {
      name: homeCompetitors.map((c) => c.name).join(', '),
      isActivePlayer: isActiveHomeTeam
    },
    {
      name: awayCompetitors.map((c) => c.name).join(', '),
      isActivePlayer: isActiveAwayTeam
    }
  ]

  return (
    <EventTeamsNamesEntity
      teamsWrapperComponent={(teams) => (
        <EventRowOpenEventPage event={event}>{teams}</EventRowOpenEventPage>
      )}
      tagComponent={event.top && <Tag type={ETagTypes.TOP} />}
      teamsList={teamsList}
    />
  )
}
