import { useLocation } from 'react-router-dom'
import { Event } from 'betweb-openapi-axios'

import { getEventLink } from 'shared/lib/events'
import { ERoutes } from 'shared/types/routes'

export const useOpenEventPage = ({ event }: { event: Event }) => {
  const location = useLocation()

  const link = getEventLink(event)

  if (location.pathname === ERoutes.Calendar) {
    return { pathname: link, state: { prevPath: location.pathname } }
  } else {
    return link
  }
}
