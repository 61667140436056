import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { selectBannersApi } from 'astra-core/containers/ConfigProvider'
import { selectCurrentLangCode } from 'astra-core/containers/LanguagesProvider'
import { ErrorType } from 'astra-core'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { mainPageContainerActions } from './slice'

function* handleFetchBanners() {
  try {
    const bannersApi = yield select(selectBannersApi)
    const currentLangCode = yield select(selectCurrentLangCode)

    const { data } = yield call(() =>
      bannersApi.getBanners({ locale: currentLangCode })
    )

    yield put(mainPageContainerActions.fetchBannersSuccess(data))
  } catch (e) {
    console.log('Error occurred while fetching bets statistics', e)
    yield put(
      mainPageContainerActions.fetchBannersError({
        type: ErrorType.RESPONSE_ERROR,
        message: (e as Error).message,
        code: '',
        title: ''
      })
    )
  }
}

function* handleFetchTopLiveEvents() {
  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        live: true,
        top: true
      },
      sideEffects: eventsProviderSideEffects.topLiveEvents
    })
  )
}

function* handleFetchTopLineEvents() {
  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        live: false,
        top: true
      },
      sideEffects: eventsProviderSideEffects.topLineEvents
    })
  )
}

function* handleFetchUpcomingEvents() {
  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        live: false,
        top: true
      },
      sideEffects: eventsProviderSideEffects.upcomingEvents
    })
  )
}

export function* mainPageContainerSaga() {
  yield all([
    takeLatest(mainPageContainerActions.fetchBanners, handleFetchBanners),
    takeLatest(
      mainPageContainerActions.fetchTopLiveEvents,
      handleFetchTopLiveEvents
    ),
    takeLatest(
      mainPageContainerActions.fetchTopLineEvents,
      handleFetchTopLineEvents
    ),
    takeLatest(
      mainPageContainerActions.fetchUpcomingEvents,
      handleFetchUpcomingEvents
    )
  ])
}
