import React, { FC } from 'react'

import { useOnboardingItemContext } from '../../Onboarding.context'

export const OnboardingMask: FC = () => {
  const { itemNodeRectProps } = useOnboardingItemContext()
  const { leftTopX, leftTopY, width, height } = itemNodeRectProps || {}

  return (
    <svg height="100%" role="none" width="100%">
      <defs>
        <mask id="hole">
          <rect fill="white" height="100%" width="100%" />

          {itemNodeRectProps && (
            <rect
              fill="black"
              height={height}
              rx="4"
              ry="4"
              width={width}
              x={leftTopX}
              y={leftTopY}
            />
          )}
        </mask>
      </defs>

      <rect
        fill="rgba(0, 0, 0, 0.65)"
        height="100%"
        mask="url(#hole)"
        width="100%"
      />
    </svg>
  )
}
