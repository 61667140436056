import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useTranslation } from 'react-i18next'

import { getAboutPageDocuments } from 'pages/page-about/AboutPage/constants'
import {
  menuListModel,
  StyledFooterBottomMenuListLink,
  StyledFooterBottomMenuListLinkA
} from 'entities/footer-of-page'

export const FooterOfPageMenuItems = () => {
  const [t] = useTranslation()
  const config = useSelector(selectConfig)
  const menuItems = menuListModel.useGetMenuList()
  const aboutPageDocumentsFromModel = getAboutPageDocuments()

  return (
    <>
      {menuItems.map(({ name, translatedName, path }) => (
        <StyledFooterBottomMenuListLink key={name} to={path}>
          {translatedName}
        </StyledFooterBottomMenuListLink>
      ))}

      {aboutPageDocumentsFromModel.map((document) => (
        <Fragment key={document.link + document.locale}>
          {document.to ? (
            <StyledFooterBottomMenuListLink to={document.to}>
              {t(document.locale)}
            </StyledFooterBottomMenuListLink>
          ) : (
            <StyledFooterBottomMenuListLinkA
              href={`${config.CDN_URL}/${config.RULES_CDN_FOLDER}/${document.link}`}
              target="_blank"
            >
              {t(document.locale)}
            </StyledFooterBottomMenuListLinkA>
          )}
        </Fragment>
      ))}
    </>
  )
}
