import { LayoutTheme } from 'betweb-openapi-axios'

export const MARQUEE_COLORS = {
  [LayoutTheme.Dark]: `background: linear-gradient(
    270deg,
    #262626 0%,
    rgba(38, 38, 38, 0.6) 59.9%,
    rgba(26, 26, 26, 0) 100%
  );`,
  [LayoutTheme.Light]: `background: linear-gradient(
    270deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(242, 242, 242, 0) 100%
  );`
}
