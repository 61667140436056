import React, { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { ERoutes } from 'shared/types/routes'
import { filterRoutes } from 'shared/lib/routes'
import { EMenuView } from 'widgets/header/Menu/Menu.types'

import { Additional } from './components'
import { FIXED_HEADER_ROUTES_LIST } from './routes'
import { StyledFixedHeader, StyledMenu } from './FixedHeader.styled'

export const FixedHeader: FC = memo(() => {
  const [t] = useTranslation()
  const config = useSelector(selectConfig)
  const isCasinoEnabled = !!config.CASINO_CLIENT_URL

  const menuItems = useMemo(
    () => [
      ...filterRoutes({
        routeList: FIXED_HEADER_ROUTES_LIST.filter((item) =>
          isCasinoEnabled ? true : item.path !== ERoutes.Casino
        ),
        t
      }),
      {
        path: ERoutes.TurboGamesAviator,
        name: 'Aviator',
        translatedName: t('aviator')
      }
    ],
    [isCasinoEnabled, t]
  )

  return (
    <StyledFixedHeader>
      <ul />
      <StyledMenu list={menuItems} view={EMenuView.DARK} />
      <Additional />
    </StyledFixedHeader>
  )
})
