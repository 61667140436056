import styled, { css } from 'styled-components'

export const StyledMainWrapper = styled.div`
  align-content: baseline;
  background-color: ${(props) => props.theme.colors.default.primary[0]};
  display: grid;
  position: relative;
`

export const StyledEventsSectionWrapper = styled.section`
  display: grid;
`

export const StyledMainSearchBlockWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  position: sticky;
  top: 0;
  z-index: 15;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[2]};
    `}
`
