import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBeachVolleyball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M9.9335 10.528C9.25285 8.49933 9.09952 6.57 9.48684 4.81467C8.79286 5.25533 8.18554 5.86267 7.73821 6.61667C6.8309 8.16533 6.78423 9.98064 7.46489 11.5027L7.52488 11.4893C8.47886 11.2227 9.27951 10.882 9.94017 10.5413C9.9335 10.5354 9.9335 10.5353 9.9335 10.528ZM12.0355 4C11.4215 4.03333 10.8281 4.16667 10.2675 4.40733L10.2142 4.62133C9.7675 6.32333 9.88083 8.19933 10.5342 10.208C11.2081 9.80069 11.6681 9.42731 11.9288 9.22C11.9488 9.20667 11.9555 9.2 11.9755 9.18667C11.9688 9.16 11.9621 9.13333 11.9555 9.1C11.8088 8.37867 11.4215 6.48333 12.0355 4ZM12.4828 9.74069C12.4494 9.72736 12.4228 9.72064 12.3895 9.70731C12.3761 9.72064 12.3695 9.72736 12.3495 9.74069C11.7821 10.1947 10.3008 11.396 7.78555 12.1107C8.07887 12.598 8.45953 13.0453 8.91286 13.4253L9.15285 13.3587C10.8875 12.878 12.5621 11.8573 14.0234 10.4086C13.3434 10.0547 12.7961 9.85403 12.4828 9.74069ZM15.0447 4.728C14.3107 4.294 13.5167 4.06 12.7228 4L12.7028 4.07333C12.4694 4.98133 12.3828 5.802 12.3695 6.51667C14.9847 7.13733 16.666 8.272 17.6667 9.19333C17.6267 7.41133 16.686 5.69533 15.0447 4.728ZM16.6593 12.344C15.952 11.65 15.258 11.136 14.6247 10.7487C13.1367 12.2773 11.4088 13.3653 9.60751 13.9127C9.61417 13.9194 9.62084 13.9193 9.62751 13.926C10.4742 14.4267 11.4081 14.6667 12.3294 14.6667C14.0241 14.6667 15.6787 13.8594 16.706 12.3907L16.6593 12.344ZM17.4333 9.88736C16.5527 9.01267 14.958 7.85867 12.3761 7.21067C12.4161 8.018 12.5428 8.63267 12.6094 8.966C12.6228 9.01267 12.6294 9.046 12.6361 9.086C12.6561 9.09267 12.6828 9.09933 12.7094 9.11267C13.3967 9.36 15.2047 10.0074 17.0593 11.8027C17.3526 11.2487 17.54 10.6613 17.62 10.074L17.4333 9.88736Z"
          fill={mainColor}
        />
        <ellipse
          cx="7.87474"
          cy="18.1882"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <ellipse
          cx="11.0622"
          cy="18.9167"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <path
          d="M4.125 18.1967C6.83588 16.1529 10.0788 16.6489 11.7183 17.791M13.3789 19.8999C13.3789 19.8999 12.7402 18.5941 11.7183 17.791M11.7183 17.791C13.0383 16.1671 17.0691 15.6561 19.8568 17.791"
          stroke={mainColor}
        />
        <ellipse
          cx="5.34154"
          cy="19.2532"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <circle cx="15.9646" cy="17.5954" fill={mainColor} r="0.433334" />
        <circle cx="16.8201" cy="19.0549" fill={mainColor} r="0.433333" />
        <ellipse
          cx="18.4783"
          cy="18.2532"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <circle cx="14.2497" cy="18.7532" fill={mainColor} r="0.433334" />
        <ellipse
          cx="8.95872"
          cy="19.6863"
          fill={mainColor}
          rx="0.433334"
          ry="0.433333"
        />
      </>
    )}
  </SportIconWrapper>
)
