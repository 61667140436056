import React, { FC, memo, useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectBasketOutcomesLength,
  selectDisabledButtonOrdinar,
  selectMakeBetErrorsBottom,
  selectOrdinarOutcomeErrors,
  selectOrdinarSingleAmount,
  selectOutcomeStakeAmount
} from 'astra-core/containers/BasketProvider'
import { activeEventStatus } from 'astra-core/containers/BasketProvider/constants'
import {
  getIsEventPending,
  validateBasketInputValue,
  formatAmount as formatAmountCore
} from 'astra-core'
import { ETestIds } from 'astra-core/utils/testIds'

import { RootState } from 'shared/types/store'

import {
  BasketOutcomeSingleError,
  BasketButton,
  BasketError,
  BetCardCoupon,
  CustomTopLayerAboveInput,
  CommonBlockClearAll,
  BasketInput
} from '../../BasketCommon/Common'
import { BasketBonusCoupons } from '../../BasketCommon/components'

import {
  StyledBetCardElementBorder,
  StyledBetCardElementWrapper,
  StyledInputWrapper,
  StyledSingleBetAmountInput,
  StyledSingleBetAmountWrapper
} from './Ordinar.styled'
import {
  BetCardElementProps,
  OrdinarProps,
  SingleBetAmountProps
} from './Ordinar.types'

export const Ordinar: FC<OrdinarProps> = ({ outcomes }) => {
  const disabledButton = useSelector(selectDisabledButtonOrdinar)
  const errorsBottom = useSelector(selectMakeBetErrorsBottom)
  const outcomesLength = useSelector(selectBasketOutcomesLength)

  return (
    <>
      {outcomes.map((outcome) => (
        <BetCardElement key={outcome.id} outcome={outcome} />
      ))}

      <StyledBetCardElementBorder />

      {outcomesLength > 1 && <CommonBlockClearAll />}

      <BasketBonusCoupons />

      {errorsBottom.map((error) => (
        <BasketError error={error} key={error.code} />
      ))}

      <BasketButton disabledButton={disabledButton} />
    </>
  )
}

const BetCardElement: FC<BetCardElementProps> = ({ outcome }) => {
  const {
    probability: { odd, tradingStatus },
    id: outcomeId,
    event: { status: eventStatus },
    status,
    maxBet
  } = outcome
  const dispatch = useDispatch()
  const basketInputRef = useRef<HTMLInputElement>(null)
  const outcomeErrors = useSelector(selectOrdinarOutcomeErrors)
  const stakeAmount = useSelector((state: RootState) =>
    selectOutcomeStakeAmount(state, outcomeId)
  )
  const isBonusWallet = useSelector(selectBasketBonusWallet)

  const isEventActive = useMemo(
    () => activeEventStatus.includes(eventStatus),
    [eventStatus]
  )

  const winSum = useMemo(
    () => (stakeAmount !== '' && +stakeAmount * (odd - +isBonusWallet)) || 0,
    [stakeAmount, odd, isBonusWallet]
  )

  const setOutcomeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        basketProviderActions.setOrdinarAmount({
          outcomeId,
          amount: validateBasketInputValue(e),
          maxBet
        })
      )
    },
    [dispatch, outcomeId, maxBet]
  )

  const isEventPending = useMemo(
    () => getIsEventPending(status, eventStatus, tradingStatus),
    [eventStatus, status, tradingStatus]
  )

  const outcomeError = useMemo(
    () => outcomeErrors[outcomeId]?.message,
    [outcomeErrors, outcomeId]
  )

  const makeInputFocus = useCallback(() => {
    if (basketInputRef?.current) {
      basketInputRef.current.focus()
    }
  }, [])

  return (
    <StyledBetCardElementWrapper>
      <BetCardCoupon outcome={outcome} />
      {isEventActive && !isEventPending && (
        <>
          <StyledInputWrapper>
            <CustomTopLayerAboveInput
              makeInputFocus={makeInputFocus}
              maxBet={maxBet}
              stakeAmount={stakeAmount}
              winSum={winSum}
            />
            <BasketInput
              inputRef={basketInputRef}
              testId={ETestIds.TestBasketBetCardInput}
              value={stakeAmount}
              onChange={setOutcomeAmount}
            />
          </StyledInputWrapper>
          {outcomeError && <BasketOutcomeSingleError message={outcomeError} />}
        </>
      )}
    </StyledBetCardElementWrapper>
  )
}

export const SingleBetAmount: FC<SingleBetAmountProps> = memo(() => {
  const dispatch = useDispatch()
  const singleAmount = useSelector(selectOrdinarSingleAmount)

  const setSingleAmountForEachOutcome = useCallback(
    (e) => {
      dispatch(
        basketProviderActions.setSingleAmountForEachOutcome({
          amount: validateBasketInputValue(e)
        })
      )
    },
    [dispatch]
  )

  return (
    <StyledSingleBetAmountWrapper>
      <StyledSingleBetAmountInput
        value={formatAmountCore({ value: singleAmount })}
        onChange={setSingleAmountForEachOutcome}
      />
    </StyledSingleBetAmountWrapper>
  )
})
