import React, { memo } from 'react'

import { FooterOfPage as FooterOfPageEntity } from 'entities/footer-of-page'
import { OpenInstagram, OpenSupport } from 'features/footer-of-page'
import { IconLogo } from 'features/logo'

import { FooterOfPageLogoItems, FooterOfPageMenuItems } from './ui'

export const FooterOfPage = memo(() => {
  return (
    <FooterOfPageEntity
      helpButtons={<OpenSupport />}
      logoItems={<FooterOfPageLogoItems />}
      logoMain={<IconLogo />}
      menuItems={<FooterOfPageMenuItems />}
      socialNetworksItems={<OpenInstagram />}
    />
  )
})
