import React, { ElementRef, FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventStatus } from 'betweb-openapi-axios'

import {
  EventRow as EventRowEntity,
  eventRowModel,
  CoefficientTableCellStyled,
  EventRowExpandButton,
  EventRowActions
} from 'entities/event'
import {
  getFullEventComment,
  OpenActionH2h,
  OpenActionMatchLiveTracker,
  ShowActionComment,
  useEventRowHandleHover,
  useExpandEventRow
} from 'features/event'
import { EventFavouritesStarButton } from 'widgets/event-favourites-star-button'
import {
  StyledOutcomesHead,
  StyledOutcomesHeadRight,
  StyledOutcomesToggleDisplay
} from 'pages/page-live-events/Line/components/Outcomes/Outcomes.styled'
import { OutcomesGroupsTabs } from 'pages/page-live-events/Line/components/Outcomes/Filters/components/OutcomesFilterTabs'
import { InputSearch } from 'pages/page-live-events/Line/components/Event/components'
import { Outcomes } from 'pages/page-live-events/Line/components/Outcomes/Outcomes'
import { OutcomesComponentType } from 'pages/page-live-events/Line/components/Outcomes/Outcomes.types'
import { OutcomesCategories } from 'pages/page-live-events/Line/components/Outcomes/Table'

import { EventRowTeamsNames } from '../teams-names'
import { EventRowCoefficient } from '../coefficient'
import { EventRowMessage } from '../message'
import { MiniStatistics } from '../mini-statistics'

import { ITableColumnHeaderListProps } from './event-row.types'

export const EventRow: FC<ITableColumnHeaderListProps> = ({ event }) => {
  const [t] = useTranslation()
  const [areAllOpened, setAllOpened] = useState(true)
  const outcomesRef = useRef<ElementRef<typeof OutcomesCategories>>(null)
  const isSuspended = event.status === EventStatus.Suspended

  const { toggleOutcomesOpen, isOutcomesOpened } = useExpandEventRow({
    isSuspended
  })

  const { handleMouseEnter, handleMouseLeave } = useEventRowHandleHover({
    eventId: event.id,
    isHoverAvailable: !isOutcomesOpened
  })

  const openAll = useCallback(() => {
    setAllOpened(true)
    outcomesRef?.current?.openAll()
  }, [outcomesRef])

  const closeAll = useCallback(() => {
    setAllOpened(false)
    outcomesRef?.current?.closeAll()
  }, [outcomesRef])

  return (
    <EventRowEntity
      coefficientsItems={
        <>
          {eventRowModel.COLUMNS_HEADERS_LIST.map((coefficientData) => (
            <EventRowCoefficient
              {...coefficientData}
              event={event}
              key={coefficientData.outcomeTypesName[0]}
            />
          ))}
        </>
      }
      expandedData={
        isOutcomesOpened && (
          <StyledOutcomesHead>
            <OutcomesGroupsTabs event={event} />

            <StyledOutcomesHeadRight>
              <InputSearch eventId={event.id} />

              <StyledOutcomesToggleDisplay
                onClick={() => (areAllOpened ? closeAll() : openAll())}
              >
                {t(areAllOpened ? 'collapse all' : 'expand all')}
              </StyledOutcomesToggleDisplay>
            </StyledOutcomesHeadRight>
          </StyledOutcomesHead>
        )
      }
      infoCellComponents={
        <>
          <EventRowTeamsNames event={event} />
          <MiniStatistics event={event} />
          <EventFavouritesStarButton eventId={event.id} size={14} />
          <EventRowActions
            lineComponents={
              <>
                <ShowActionComment
                  comment={getFullEventComment(event.comments)}
                />
                <OpenActionH2h event={event} />
              </>
            }
            liveComponents={
              <>
                <ShowActionComment
                  comment={getFullEventComment(event.comments)}
                />
                <OpenActionMatchLiveTracker event={event} />
              </>
            }
            isLive={event.live}
          />
        </>
      }
      lastCell={
        <CoefficientTableCellStyled>
          <EventRowExpandButton onClick={toggleOutcomesOpen}>
            {event.outcomesCount}
          </EventRowExpandButton>
        </CoefficientTableCellStyled>
      }
      outcomesComponent={
        isOutcomesOpened ? (
          <Outcomes
            eventId={event.id}
            isLoading={event.status === EventStatus.Suspended}
            outcomesRef={outcomesRef}
            type={OutcomesComponentType.EVENTS_LIST}
          />
        ) : null
      }
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      message={isSuspended && <EventRowMessage />}
    />
  )
}
