import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconClose: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 13L13 1" stroke="currentColor" />
      <path d="M1 0.999999L13 13" stroke="currentColor" />
    </svg>
  </Icon>
)
