import styled, { css } from 'styled-components'
import { ColumnType } from 'astrabet-templates-kit'

import { EColorsNames } from 'shared/types/theme'

import { StyledTableCellProps, StyledHeaderCellProps } from './OddsTable.types'

interface OddsTableProps {
  columns: ColumnType[]
  isPlain?: boolean
}

const columnTypeToWidth: Record<ColumnType, '1fr' | '48px'> = {
  label: '1fr',
  odd: '48px'
}

export const StyledOddsTable = styled.div<OddsTableProps>`
  ${(p) =>
    !p.isPlain &&
    css`
      display: grid;
      grid-template-columns: ${p.columns
        .map((c) => columnTypeToWidth[c])
        .join(' ')};
    `}
`

export const CoefficientTableCellStyled = styled.div<StyledTableCellProps>`
  font-family: Inter, sans-serif;
  font-size: 12px;
  transition: background-color 0.5s ease;

  ${({ isPlain, hideBorder }) =>
    !isPlain &&
    css`
      border-color: ${(props) =>
        props.theme.colors.custom[EColorsNames.Primary][36]};
      border-style: solid;
      border-width: 1px 0;
      margin: -1px 0 0 -1px;
      ${hideBorder &&
      css`
        border-bottom: none;
      `}
    `}
  ${({ isOdd }) =>
    isOdd &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
    `}
`

export const StyledTextCell = styled.div`
  color: ${(props) => props.theme.colors.custom[EColorsNames.Primary][32]};
  padding: 0.5rem;
`

export const StyledPlainHeaderCell = styled.div`
  font-weight: 500;
  padding: 0;
`

export const StyledHeaderCell = styled.div<StyledHeaderCellProps>`
  color: ${(p) => p.theme.colors.custom[EColorsNames.Primary][32]};
  font-family: Inter, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0.25rem 0.5rem;

  span {
    background-color: ${({ theme }) => theme.colors.default.yellow[30]};
    color: ${(props) => props.theme.colors.fixed.primary[90]};
  }

  ${(p) =>
    p.columns &&
    css`
      grid-column: 1 / ${p.columns + 1};
    `};
  ${(p) =>
    p.isOdd
      ? css`
          font-weight: 400;
          text-align: center;
        `
      : css`
          font-weight: 500;
          text-align: left;
        `};
`

export const StyledTableWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`

export const StyledTablePlainRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`

export const StyledPlainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`

export const StyledOddsDivider = styled.div`
  height: 8px;
`
