import React from 'react'
import { ETestIds } from 'astra-core/utils/testIds'

import { ERoutes } from 'shared/types/routes'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { useHandleRedirectList } from '../../hooks'

import { StyledIconPersonal } from './PersonalAccountButton.styled'

export const PersonalAccountButton = () => {
  const handleRedirectList = useHandleRedirectList()

  return (
    <StyledIconPersonal
      colorProps={{
        name: EColorsNames.Primary,
        type: EColorsTypes.CUSTOM,
        value: 7
      }}
      size={20}
      testId={ETestIds.TestMainHeaderIconPersonal}
      onClick={handleRedirectList(ERoutes.Profile)}
    />
  )
}
