import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { eventContainerActions } from 'widgets/EventContainer/slice'
import { EventContainer } from 'widgets/EventContainer'
import { getFetchEventReqData } from 'widgets/EventContainer/utils'

export const PageLiveEvent = () => (
  <Layout type={ELayoutsList.EVENT}>
    <EventContainer />
  </Layout>
)

export const pageLiveEventPrefetch = ({ store, req }) => {
  store.dispatch(
    eventContainerActions.fetchEvent(
      getFetchEventReqData({ eventId: getRouteParamAtIndex(req.params, 2) })
    )
  )
  store.dispatch(
    eventsProviderActions.fetchEventStatistics(
      getFetchEventReqData({ eventId: getRouteParamAtIndex(req.params, 2) })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLiveSportCounters())
}
