import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportVolleyball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M8.40025 13.792C7.37927 10.749 7.14928 7.855 7.73026 5.222C6.68929 5.883 5.77831 6.794 5.10732 7.925C3.74635 10.248 3.67635 12.971 4.69733 15.254L4.78733 15.234C6.2183 14.834 7.41927 14.323 8.41025 13.812C8.40025 13.803 8.40025 13.803 8.40025 13.792ZM11.5532 4C10.6322 4.05 9.74222 4.25 8.90124 4.611L8.82124 4.932C8.15125 7.485 8.32125 10.299 9.30123 13.312C10.3122 12.701 11.0022 12.141 11.3932 11.83C11.4232 11.81 11.4332 11.8 11.4632 11.78C11.4532 11.74 11.4432 11.7 11.4332 11.65C11.2132 10.568 10.6322 7.725 11.5532 4ZM12.2242 12.611C12.1742 12.591 12.1342 12.581 12.0842 12.561C12.0642 12.581 12.0542 12.591 12.0242 12.611C11.1732 13.292 8.95124 15.094 5.17832 16.166C5.61831 16.897 6.1893 17.568 6.86928 18.138L7.22927 18.038C9.83122 17.317 12.3431 15.786 14.5351 13.613C13.5151 13.082 12.6942 12.781 12.2242 12.611ZM16.067 5.092C14.9661 4.441 13.7751 4.09 12.5842 4L12.5542 4.11C12.2042 5.472 12.0742 6.703 12.0542 7.775C15.977 8.706 18.499 10.408 20 11.79C19.94 9.117 18.529 6.543 16.067 5.092ZM18.489 16.516C17.4281 15.475 16.387 14.704 15.4371 14.123C13.2051 16.416 10.6132 18.048 7.91126 18.869C7.92126 18.879 7.93126 18.879 7.94126 18.889C9.21123 19.64 10.6122 20 11.9942 20C14.5361 20 17.0181 18.789 18.559 16.586L18.489 16.516ZM19.65 12.831C18.329 11.519 15.937 9.788 12.0642 8.816C12.1242 10.027 12.3142 10.949 12.4142 11.449C12.4342 11.519 12.4442 11.569 12.4542 11.629C12.4842 11.639 12.5242 11.649 12.5642 11.669C13.5951 12.04 16.307 13.011 19.089 15.704C19.529 14.873 19.81 13.992 19.93 13.111L19.65 12.831Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
