import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import { getEvents } from 'astra-core/containers/EventsProvider'
import { getInputSearchLine } from 'astra-core/containers/SearchProvider'
import { getEventsBySearch } from 'astra-core/utils'
import isEqual from 'lodash/isEqual'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'

import { initialState } from './slice'

type SelectLiveEventsParams = {
  sportId: string
}

export const getLiveEventsIdsBySport = (
  state: RootState,
  params: SelectLiveEventsParams
) =>
  (state.liveContainer.eventsIdsBySport || initialState.eventsIdsBySport)[
    +params.sportId // TODO either add sportIdPrev here or add sceleton preloader to UI
  ] || []

export const getLiveFetchItems = (state: RootState) =>
  state.liveContainer.fetchItems || initialState.fetchItems

export const getLiveFilters = (state: RootState) =>
  state.liveContainer.filters ?? initialState.filters

export const getFilterIsTop = (state: RootState) =>
  state.liveContainer.filters.isTop ?? initialState.filters.isTop

export const getLayoutIsLoading = (state: RootState) =>
  state.liveContainer.layouts.isLoading ?? initialState.layouts.isLoading

export const selectLiveEvents = createSelector(
  [getLiveEventsIdsBySport, getEvents],
  (eventsIdsBySport, events) => getValues(eventsIdsBySport, events)
)

export const selectLiveEventsBySearch = createSelector(
  [selectLiveEvents, getInputSearchLine],
  (liveEvents, searchText) =>
    getEventsBySearch({
      events: liveEvents,
      searchText
    })
)

export const selectLiveIsEmpty = createSelector([getEvents], (events) =>
  isEmpty(events)
)

export const selectLiveFetchItems = createSelector(
  [getLiveFetchItems],
  (liveFetchItems) => liveFetchItems
)

export const selectFilterIsTop = createSelector(
  [getFilterIsTop],
  (isTop) => isTop
)

export const selectLayoutIsLoading = createSelector(
  [getLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectIsLiveFilterUsed = createSelector(
  [getLiveFilters],
  (filters) => !isEqual(filters, initialState.filters)
)
