import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportFreestyle: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M18.2398 4.19272C17.7757 4.36058 17.3542 4.62849 17.0053 4.97745L12.0459 9.93699C13.009 10.09 13.7784 10.8497 13.9459 11.8082L18.8909 6.86304C19.2399 6.51409 19.5078 6.09264 19.6756 5.62859C19.9989 4.73489 19.1335 3.86947 18.2398 4.19272Z"
          fill={mainColor}
        />
        <path
          d="M13.5109 12.2432C13.5337 11.1903 12.6616 10.3326 11.6036 10.3792L10.9897 10.9931L12.8753 12.8788L13.5109 12.2432Z"
          fill={mainColor}
        />
        <path
          d="M9.82308 12.1598L10.6598 11.3231L12.5454 13.2088L11.7087 14.0454L9.82308 12.1598Z"
          fill={mainColor}
        />
        <path
          d="M9.4931 12.4898L4.77002 17.213C4.44566 17.5374 4.25692 17.973 4.24213 18.4315C4.23677 18.5978 4.30046 18.7589 4.4181 18.8765L4.99216 19.4506C5.10979 19.5682 5.27087 19.6319 5.43713 19.6265C5.89561 19.6117 6.33128 19.423 6.65564 19.0986L11.3787 14.3754L9.4931 12.4898Z"
          fill={mainColor}
        />
        <path
          d="M11.3047 9.73559L6.85528 5.28612C6.51742 4.94827 6.11236 4.68517 5.66633 4.51388C4.7472 4.16089 3.84443 5.06366 4.19741 5.98279C4.36871 6.42882 4.63181 6.83389 4.96966 7.17174L9.41913 11.6212L11.3047 9.73559Z"
          fill={mainColor}
        />
        <path
          d="M12.2476 14.4496L17.1886 19.3907C17.5164 19.7185 17.9533 19.9145 18.4162 19.9415C18.5959 19.9519 18.7714 19.8851 18.8986 19.7579L19.4414 19.2151C19.5686 19.0879 19.6354 18.9123 19.625 18.7327C19.5981 18.2698 19.4021 17.8329 19.0742 17.505L14.1332 12.564L12.2476 14.4496Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
