import { ETestIds } from 'astra-core/utils/testIds'
import React, { CSSProperties } from 'react'

export interface RadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  label?: string
  labelStyle?: CSSProperties
  className?: string
  view?: ERatioView
  testId?: ETestIds
}

export enum ERatioView {
  DEFAULT = 'default',
  BASKET = 'basket'
}
