import { createSelector } from '@reduxjs/toolkit'
import { getEventById } from 'astra-core/containers/EventsProvider'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getEventFetchItem = (state: RootState) =>
  state.eventContainer.fetchItem || initialState.fetchItem

export const selectEvent = createSelector([getEventById], (event) => ({
  event,
  isEmpty: !event
}))

export const selectEventFetchItems = createSelector(
  [getEventFetchItem],
  (liveFetchItems) => liveFetchItems
)
