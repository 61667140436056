import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportWrestling: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M9.44909 4.6665H8.86466C8.7151 4.6665 8.64032 4.6665 8.58464 4.6939C8.53438 4.71863 8.49588 4.75539 8.46884 4.80444C8.43888 4.85879 8.43531 4.9356 8.42816 5.08921C8.40149 5.66249 8.32228 6.6951 8.08874 7.39519C7.9939 7.67951 7.82064 7.98478 7.62686 8.27051C7.85589 8.82963 8.10818 9.53445 8.32911 10.2812C8.66315 11.4103 8.93575 12.669 8.93575 13.6835C8.93575 15.2936 8.52778 17.5879 8.19855 19.3332H10.1145C10.4105 19.3332 10.5584 19.3332 10.6818 19.2816C10.7906 19.2361 10.8855 19.1626 10.9566 19.0685C11.0372 18.9618 11.074 18.8185 11.1476 18.5319L11.7516 16.1805C11.7797 16.0711 11.8783 15.9946 11.9912 15.9946C12.1025 15.9946 12.2001 16.0689 12.2297 16.1761L12.8858 18.5506C12.9632 18.8309 13.0019 18.9711 13.0827 19.0752C13.1541 19.1671 13.2482 19.2388 13.3557 19.283C13.4776 19.3332 13.6231 19.3332 13.9139 19.3332H15.8012C15.4719 17.5879 15.064 15.2936 15.064 13.6835C15.064 12.669 15.3366 11.4103 15.6706 10.2812C15.8915 9.53445 16.1438 8.82963 16.3729 8.27051C16.1791 7.98478 16.0058 7.67951 15.911 7.39519C15.6777 6.69594 15.6021 5.66497 15.5777 5.09128C15.5711 4.93686 15.5678 4.85965 15.538 4.80508C15.511 4.75581 15.4725 4.71891 15.4221 4.69404C15.3663 4.6665 15.2912 4.6665 15.141 4.6665H14.5508C14.4023 4.6665 14.328 4.6665 14.2725 4.69373C14.2224 4.71828 14.1839 4.75492 14.1568 4.80366C14.1267 4.85772 14.1228 4.93384 14.115 5.08606C14.0857 5.65802 14.0033 6.69163 13.7776 7.39519C13.5899 7.98034 13.0953 8.6218 12.6753 9.09235C12.4802 9.31096 12.3826 9.42027 12.2202 9.47715C12.0958 9.52071 11.9039 9.52071 11.7795 9.47715C11.6171 9.42027 11.5195 9.31096 11.3244 9.09235C10.9044 8.6218 10.4098 7.98034 10.2221 7.39519C9.99642 6.69166 9.91419 5.6581 9.88491 5.08613C9.87711 4.93388 9.87321 4.85776 9.84317 4.80368C9.8161 4.75494 9.77753 4.71829 9.72746 4.69374C9.67192 4.6665 9.59764 4.6665 9.44909 4.6665Z"
          fill={mainColor}
        />
        <path
          d="M16.8434 8.8929C16.6656 9.35716 16.4788 9.89926 16.3099 10.4704C15.9815 11.5802 15.7306 12.7657 15.7306 13.6835C15.7306 15.2661 16.1455 17.5646 16.4784 19.3256C16.6416 19.3158 16.7531 19.2936 16.8403 19.2429C16.9802 19.1616 17.0789 19.0429 17.1333 18.8905C17.1936 18.7213 17.15 18.4863 17.0626 18.0164C16.8335 16.7832 16.5523 14.9267 16.6221 13.5347C16.6787 12.4048 17.0028 10.8577 17.1934 10.0266C17.2443 9.80456 17.2697 9.69353 17.2647 9.60003C17.2598 9.50845 17.244 9.44397 17.2061 9.36045C17.1675 9.27517 17.0881 9.18191 16.9294 8.99539C16.9015 8.96256 16.8728 8.92835 16.8434 8.8929Z"
          fill={mainColor}
        />
        <path
          d="M7.07026 8.99539C7.09819 8.96256 7.12693 8.92835 7.15629 8.89291C7.33413 9.35716 7.52087 9.89926 7.68983 10.4704C8.01818 11.5802 8.26908 12.7657 8.26908 13.6835C8.26908 15.2661 7.85422 17.5646 7.52135 19.3256C7.35807 19.3158 7.24658 19.2936 7.1594 19.2429C7.01947 19.1616 6.92078 19.0429 6.86641 18.8905C6.80607 18.7213 6.84973 18.4863 6.93706 18.0164C7.16624 16.7832 7.44739 14.9267 7.37763 13.5347C7.321 12.4048 6.99688 10.8577 6.80634 10.0266C6.75543 9.80456 6.72997 9.69353 6.735 9.60003C6.73992 9.50845 6.75568 9.44397 6.79357 9.36045C6.83225 9.27517 6.91159 9.18191 7.07026 8.99539Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
