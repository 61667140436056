import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageLiveLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageLive,
  fallback
})

export { LiveScreenPrefetch } from './page'
