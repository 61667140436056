import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportGolf: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M6.84375 9.15625C6.84375 6.31309 9.15684 4 12 4C14.8432 4 17.1562 6.31309 17.1562 9.15625C17.1562 11.9994 14.8432 14.3125 12 14.3125C9.15684 14.3125 6.84375 11.9994 6.84375 9.15625ZM8.71875 9.15625C8.71875 9.41512 8.92863 9.625 9.1875 9.625C9.44637 9.625 9.65625 9.41512 9.65625 9.15625C9.65625 8.89738 9.44637 8.6875 9.1875 8.6875C8.92863 8.6875 8.71875 8.89738 8.71875 9.15625ZM9.79353 6.94978C9.61047 7.13284 9.61047 7.42963 9.79353 7.61269C9.97659 7.79575 10.2734 7.79575 10.4564 7.61269C10.6395 7.42963 10.6395 7.13281 10.4564 6.94978C10.2734 6.76672 9.97659 6.76672 9.79353 6.94978ZM10.7311 10.4252C10.9141 10.6083 11.2109 10.6083 11.394 10.4252C11.577 10.2422 11.577 9.94534 11.394 9.76228C11.2109 9.57922 10.9141 9.57922 10.7311 9.76228C10.548 9.94534 10.548 10.2421 10.7311 10.4252ZM11.6686 8.55019C11.8516 8.73325 12.1484 8.73325 12.3315 8.55019C12.5145 8.36716 12.5145 8.07034 12.3315 7.88728C12.1484 7.70422 11.8516 7.70422 11.6686 7.88728C11.4855 8.07034 11.4855 8.36713 11.6686 8.55019ZM12 15.25C12.9042 15.25 13.7629 15.0517 14.5353 14.6969C14.3083 14.9178 14.0344 15.2156 13.7625 15.588C12.998 16.6353 12.5694 17.8022 12.4847 19.0625H13.875C14.1339 19.0625 14.3438 19.2723 14.3438 19.5312C14.3438 19.7901 14.1339 20 13.875 20H10.125C9.86613 20 9.65625 19.7901 9.65625 19.5312C9.65625 19.2723 9.86613 19.0625 10.125 19.0625H11.5152C11.3642 16.8645 10.1799 15.3941 9.46484 14.6969C10.2372 15.0518 11.0958 15.25 12 15.25Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
