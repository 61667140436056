import React, {
  FC,
  useRef,
  useCallback,
  RefObject,
  useEffect,
  useMemo
} from 'react'

import { CarouselWrapper, CarouselItem } from './styled'
import { CarouselProps } from './types'

const LEFT_INTERSECTION_TOLERANCE = 40

/**
 * Pass children as array of ReactElements and define keys there
 */

export const Carousel: FC<CarouselProps> = ({
  children,
  className,
  onCarouselMounted,
  onIndexChange
}) => {
  const childrenArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  )
  const wrapperRef = useRef<HTMLDivElement>(null)
  const itemsRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => childrenArray.map(() => React.createRef()),
    [childrenArray]
  )
  const observer = useRef<IntersectionObserver | null>(null)
  const curIndex = useRef(0)

  const handleEntryIntersection = useCallback(
    (entry: IntersectionObserverEntry) => {
      const wrapperRect = wrapperRef.current?.getBoundingClientRect()

      const rect = entry.boundingClientRect
      if (wrapperRect && rect.x < wrapperRect?.left) {
        curIndex.current = Number(entry.target.getAttribute('data-index')) + 1
      } else if (
        wrapperRect &&
        Math.abs(rect.x - wrapperRect.x) < LEFT_INTERSECTION_TOLERANCE
      ) {
        curIndex.current = Number(entry.target.getAttribute('data-index'))
      }
      onIndexChange?.(curIndex.current)
    },
    [onIndexChange]
  )

  useEffect(() => {
    if (IntersectionObserver) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach(handleEntryIntersection)
        },
        {
          threshold: 1,
          rootMargin: '16px',
          root: wrapperRef.current
        }
      )
    }
  }, [handleEntryIntersection])

  useEffect(() => {
    itemsRefs.forEach((itemRef) => {
      if (itemRef.current && observer.current) {
        observer.current.observe(itemRef.current)
      }
    })
    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRefs.length])

  const scrollToIndex = useCallback(
    (index: number) => {
      if (wrapperRef.current && itemsRefs[index]?.current) {
        const itemRect = itemsRefs[index].current?.getBoundingClientRect()
        const wrapperRect = wrapperRef.current?.getBoundingClientRect()

        if (itemRect && wrapperRect) {
          const offset =
            itemRect.left - wrapperRect.left + wrapperRef.current.scrollLeft
          wrapperRef.current.scrollTo({ left: offset, behavior: 'smooth' })
        }
      }
    },
    [itemsRefs]
  )

  const scrollToNext = useCallback(() => {
    scrollToIndex(curIndex.current + 1)
  }, [scrollToIndex])

  const scrollToPrevious = useCallback(() => {
    scrollToIndex(curIndex.current - 1)
  }, [scrollToIndex])

  useEffect(() => {
    if (wrapperRef.current) {
      onCarouselMounted?.({
        scrollTo: scrollToIndex,
        scrollToNext,
        scrollToPrevious
      })
    }
  }, [onCarouselMounted, scrollToIndex, scrollToNext, scrollToPrevious])

  return (
    <CarouselWrapper className={className} ref={wrapperRef}>
      {childrenArray.map((child, index) => (
        // Due to typing error
        // @ts-ignore
        <CarouselItem data-index={index} key={child.key} ref={itemsRefs[index]}>
          {child}
        </CarouselItem>
      ))}
    </CarouselWrapper>
  )
}
