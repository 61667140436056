import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBonusCoupon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 14 12"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.25057 0.75V1.77083C5.25057 2.20039 5.59879 2.54861 6.02835 2.54861C6.4579 2.54861 6.80613 2.20039 6.80613 1.77083V0.75H11.6504C12.948 0.75 14 1.5335 14 2.5V9.5C14 10.4665 12.948 11.25 11.6504 11.25H6.80613V10.2785C6.80613 9.84898 6.4579 9.50076 6.02835 9.50076C5.59879 9.50076 5.25057 9.84898 5.25057 10.2785V11.25H2.34965C1.05197 11.25 0 10.4665 0 9.5V8.15833C0 7.83617 0.361722 7.58515 0.766849 7.47225C1.57775 7.24626 2.15385 6.67233 2.15385 6C2.15385 5.32767 1.57775 4.75374 0.766849 4.52775C0.361722 4.41485 0 4.16383 0 3.84167V2.5C0 1.5335 1.05197 0.75 2.34965 0.75H5.25057ZM5.25 5.02854C5.25 4.59898 5.59822 4.25076 6.02778 4.25076C6.45733 4.25076 6.80556 4.59898 6.80556 5.02854L6.80613 6.97298C6.80613 7.40254 6.4579 7.75076 6.02835 7.75076C5.59879 7.75076 5.25057 7.40254 5.25057 6.97298L5.25 5.02854Z"
        fill="url(#paint0_linear_31886_110327)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_31886_110327"
          x1="-7.15593e-07"
          x2="10.5315"
          y1="-0.100291"
          y2="20.8043"
        >
          <stop offset="0.0001" stopColor="#A184F9" />
          <stop offset="0.841126" stopColor="#805AC6" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
