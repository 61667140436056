import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportFootball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => (
  <SportIconWrapper sport={sport} {...props}>
    {({ mainColor }) => (
      <path
        d="M19.5806 9.37C21.3505 14.91 17.2707 20 12 20H11.99C8.5792 20 5.45937 17.87 4.41942 14.63C2.64952 9.09 6.7293 4 12 4H12.01C15.4198 4 18.5497 6.13 19.5806 9.37ZM18.9397 12.85C18.9597 12.67 18.8797 12.49 18.7397 12.39L17.0498 11.12C16.9198 11.02 16.7498 10.99 16.5898 11.04L15.4998 11.39C15.3498 11.44 15.2299 11.56 15.1799 11.71L14.3999 14.1C14.3499 14.25 14.3799 14.42 14.4699 14.55L15.1499 15.48C15.2399 15.61 15.3998 15.69 15.5598 15.69L17.6697 15.65C17.8497 15.65 18.0197 15.56 18.1097 15.4C18.5406 14.62 18.8306 13.76 18.9397 12.85ZM17.2297 7.74C17.2897 7.57 17.2497 7.37 17.1298 7.24C16.5198 6.58 15.7898 6.05 14.9699 5.66C14.7999 5.58 14.6099 5.6 14.4599 5.71L12.72 6.93C12.59 7.02 12.51 7.18 12.51 7.34V8.5C12.51 8.66 12.59 8.81 12.72 8.9L14.7399 10.37C14.8699 10.46 15.0399 10.49 15.1899 10.44L16.2798 10.09C16.4398 10.04 16.5598 9.92 16.6098 9.76L17.2297 7.74ZM14.4099 16.52C14.4599 16.37 14.4299 16.2 14.3399 16.07L13.6699 15.14C13.5799 15.01 13.42 14.93 13.26 14.93H10.7501C10.5901 14.93 10.4401 15.01 10.3501 15.14L9.67014 16.07C9.58014 16.2 9.55014 16.37 9.60014 16.52L10.2901 18.54C10.3401 18.71 10.4901 18.84 10.6601 18.87C11.0901 18.95 11.54 19 11.99 19C12.46 19 12.91 18.95 13.35 18.86C13.5199 18.83 13.6699 18.7 13.7199 18.53L14.4099 16.52ZM11.51 8.5V7.34C11.51 7.18 11.43 7.02 11.3 6.93L9.55014 5.71C9.40015 5.6 9.21016 5.58 9.04017 5.66C8.22022 6.05 7.49026 6.59 6.87929 7.24C6.7593 7.38 6.7193 7.56 6.76929 7.74L7.38926 9.75C7.43926 9.91 7.55925 10.03 7.71924 10.08L8.82918 10.44C8.97917 10.49 9.14916 10.46 9.27916 10.37L11.3 8.9C11.43 8.81 11.51 8.66 11.51 8.5ZM9.54014 14.55C9.63014 14.42 9.66014 14.25 9.61014 14.1L8.84018 11.71C8.79018 11.56 8.67019 11.44 8.5202 11.39L7.41026 11.03C7.25027 10.98 7.08028 11.01 6.95028 11.11L5.26038 12.38C5.12039 12.48 5.04039 12.66 5.06039 12.84C5.16038 13.74 5.45037 14.61 5.88034 15.4C5.97034 15.55 6.14033 15.65 6.32032 15.65L8.4502 15.69C8.61019 15.69 8.77018 15.61 8.86018 15.48L9.54014 14.55Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
