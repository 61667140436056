import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectBannersHost } from 'pages/page-main/MainPageContainer/selectors'
import { EPBTabs } from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/PromotionsAndBonuses.types'

import { StyledBanner, StyledBannersLink } from './Banners.styled'
import { BannerProps } from './Banners.types'

export const Banner: FC<BannerProps> = ({ banner }) => {
  const host = useSelector(selectBannersHost)
  const bannerUrl = useMemo(
    () => `${host}/${banner.imageId}`,
    [banner.imageId, host]
  )

  const [sourceLoaded, setSourceLoaded] = useState<string>('')

  const setImage = useCallback(() => setSourceLoaded(bannerUrl), [bannerUrl])

  useEffect(() => {
    const img = new Image()
    img.addEventListener('load', setImage, false)
    img.src = bannerUrl
    img.onerror = () => setSourceLoaded('')
    return () => {
      img.removeEventListener('load', setImage)
    }
  }, [bannerUrl, setImage])

  if (banner.link.includes('https')) {
    return (
      <a href={banner.link} rel="noreferrer" target="_blank">
        <StyledBanner imageUrl={sourceLoaded} />
      </a>
    )
  }

  return (
    <StyledBannersLink
      to={{
        pathname: banner.link,
        state: { currentTab: EPBTabs.PROMOTIONS }
      }}
      rel="noreferrer"
    >
      <StyledBanner imageUrl={sourceLoaded} />
    </StyledBannersLink>
  )
}

export const BannerSkeleton: FC = () => <StyledBanner imageUrl="" />
