import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBowShooting: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M19.3123 14.4288C19.3123 12.605 18.6627 10.8813 17.4737 9.52105L17.6989 9.29588C17.8941 9.10062 17.8941 8.78403 17.6989 8.58877L16.9167 7.80658L17.719 7.00423L18.4371 7.72234C18.7184 8.00359 19.1995 7.85978 19.2803 7.47032L19.8453 4.7458C19.9184 4.39345 19.6065 4.0816 19.2542 4.15468L16.5297 4.71975C16.1403 4.80052 15.9964 5.28164 16.2777 5.56288L16.9958 6.281L16.1935 7.08335L15.4113 6.30115C15.216 6.10589 14.8994 6.10589 14.7042 6.30115L14.479 6.52633C13.1187 5.33729 11.395 4.68776 9.57126 4.68776C7.5761 4.68776 5.70036 5.46472 4.28955 6.87554C4.11206 7.05302 4.86248 7.66623 4.86248 7.72988L10.2047 13.0721L8.6127 14.6641L7.68419 14.4673C7.51866 14.4322 7.3466 14.4832 7.22695 14.6029L4.6516 17.1783C4.37105 17.4588 4.51335 17.9387 4.90148 18.0209L5.79059 18.2094L5.97907 19.0985C6.06134 19.4867 6.54121 19.629 6.82175 19.3484L9.39707 16.7731C9.51673 16.6534 9.56774 16.4814 9.53265 16.3158L9.33582 15.3873L10.9278 13.7953L16.2701 19.1375C16.2701 19.1375 17.0032 19.8318 17.1245 19.7105C18.5353 18.2997 19.3123 16.4239 19.3123 14.4288ZM5.63198 7.05302C6.75529 6.18243 8.12696 5.71049 9.57123 5.71049C11.1216 5.71049 12.5883 6.25432 13.7539 7.25133L13.5554 7.44986C13.3601 7.64512 13.3601 7.9617 13.5554 8.15696L14.3376 8.93919L10.9279 12.349L5.63198 7.05302ZM16.947 18.368L11.6511 13.0721L15.0608 9.66235L15.843 10.4446C16.0383 10.6398 16.3549 10.6398 16.5502 10.4446L16.7487 10.2461C17.7457 11.4117 18.2895 12.8784 18.2895 14.4287C18.2895 15.873 17.8176 17.2447 16.947 18.368Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
