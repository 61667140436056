import { FC } from 'react'

import { EWelcomeOnboardingDataAttrs } from './typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'
import { TOnboardingMaskProps } from './components/OnboardingMask/OnboardingMask.types'

export type TOnboardingDataAttrsEnums = EWelcomeOnboardingDataAttrs

export enum EOnboardingType {
  MAIN_PAGE_WELCOME = 'main_page_welcome'
}

export enum EOnboardingContentPositions {
  TOP_LEFT = 'top_left',
  TOP_RIGHT = 'top_right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_RIGHT = 'bottom_right'
}

export interface IGetFormattedOnboardingAttr {
  attrType: TOnboardingDataAttrsEnums
  onboardingType: EOnboardingType
}

export type TOnboardingItem = IGetFormattedOnboardingAttr & {
  content: FC
  positionContent: EOnboardingContentPositions
  customGeometryParams?: TOnboardingMaskProps
}

export type TOnboardingList = Array<TOnboardingItem>
