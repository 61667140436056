import React, { FC } from 'react'

import {
  StyledEventOddInfo,
  CoefficientTableCellStyled
} from '../table/coefficient-table.styled'

export const EventCoefficientTotal: FC<{
  value: string | number | undefined
}> = ({ value }) => {
  return (
    <CoefficientTableCellStyled>
      {!!value && <StyledEventOddInfo>{value}</StyledEventOddInfo>}
    </CoefficientTableCellStyled>
  )
}
