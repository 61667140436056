import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'shared/ui/Switch'
import { ESelectView } from 'shared/ui/Select'
import {
  selectFilterIsTop,
  selectFilterPeriod
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'

import {
  getScheduleTime,
  OPTIONS_SELECT_PERIOD
} from '../LineHeaderFilters/constants'
import { SearchInput } from '../SearchInput'
import { getSelectedPeriodOptionValue } from '../../helpers'

import {
  StyledSelectPeriod,
  StyledTournamentsHeaderFilters
} from './TournamentsHeaderFilters.styled'

export const TournamentsHeaderFilters: FC = memo(() => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const isTop = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(selectFilterPeriod)

  const toggleTop = useCallback(() => {
    dispatch(lineTournamentsContainerActions.toggleFilterIsTop())
  }, [dispatch])

  const selectPeriodOptions = useMemo(() => OPTIONS_SELECT_PERIOD(t), [t])

  const selectPeriodHandle = useCallback(
    (option) => {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(option)
        })
      )
    },
    [dispatch]
  )

  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(selectPeriodOptions, filterPeriod),
    [filterPeriod, selectPeriodOptions]
  )

  return (
    <StyledTournamentsHeaderFilters>
      <Switch active={isTop} label="TOP" onClick={toggleTop} />

      <StyledSelectPeriod
        isSearchable={false}
        options={selectPeriodOptions}
        placeholder={t('select')}
        value={selectedPeriodValue}
        view={ESelectView.Mini}
        onChange={selectPeriodHandle}
      />

      <SearchInput isTournaments />
    </StyledTournamentsHeaderFilters>
  )
})
