import styled, { css } from 'styled-components'

export const StyledTableRow = styled.div<{
  isSuspended?: boolean
}>`
  display: grid;
  grid-template-columns:
    1fr repeat(6, 40px) repeat(2, 80px) repeat(3, minmax(max-content, 45px))
    68px;
  position: relative;
`

export const StyledTableRowOutcomes = styled(StyledTableRow)`
  ${(props) =>
    props.isSuspended &&
    css`
      & > *:not(:nth-child(1), :nth-child(2)) {
        opacity: 0.4;
      }

      * {
        cursor: auto;
      }
    `}
`

export const StyledEventRowInfoCell = styled.div`
  display: grid;
  grid-template-areas:
    'fav teams teams teams'
    'fav statistics statistics actions';
  grid-template-columns: 20px repeat(3, 1fr);
  min-height: 40px;
  padding: 3px 10px;
  width: 100%;
`

export const StyledLineTableRowWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};

  &:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[37]};
  }
`
