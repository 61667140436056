import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBeachHandball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          clipRule="evenodd"
          d="M11.9993 4C9.05383 4 6.66602 6.38781 6.66602 9.33333C6.66602 12.2788 9.05383 14.6667 11.9993 14.6667C14.9448 14.6667 17.3327 12.2788 17.3327 9.33333C17.3327 7.91885 16.7708 6.56229 15.7706 5.5621C14.7704 4.5619 13.4139 4 11.9993 4ZM11.9994 4.71108C12.548 4.71047 13.0923 4.80801 13.6065 4.99908L11.9994 5.84411L10.3923 4.99908C10.9065 4.80801 11.4508 4.71047 11.9994 4.71108ZM8.37146 6.47348C7.72668 7.28718 7.37621 8.29513 7.37709 9.33333V9.52059L8.68079 8.25718L8.37146 6.47348ZM8.16053 11.9028L9.94542 12.1624L10.7442 13.7802C9.68785 13.4801 8.77226 12.8148 8.16053 11.9028ZM9.97029 8.6779L10.7442 11.0625H10.7454H13.2533L14.0284 8.6779L11.9993 7.19997L9.97029 8.6779ZM13.2544 13.7802L14.0532 12.1624L15.8381 11.9028C15.2264 12.8148 14.3108 13.4801 13.2544 13.7802ZM15.6237 6.47348L15.3179 8.25718L16.6216 9.52059V9.33333C16.6214 8.2947 16.2697 7.28671 15.6237 6.47348Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <ellipse
          cx="7.87474"
          cy="18.1882"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <ellipse
          cx="11.0622"
          cy="18.9167"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <path
          d="M4.125 18.1967C6.83588 16.1529 10.0788 16.6489 11.7183 17.791M13.3789 19.8999C13.3789 19.8999 12.7402 18.5941 11.7183 17.791M11.7183 17.791C13.0383 16.1671 17.0691 15.6561 19.8568 17.791"
          stroke={mainColor}
        />
        <ellipse
          cx="5.34154"
          cy="19.2532"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <circle cx="15.9646" cy="17.5954" fill={mainColor} r="0.433334" />
        <circle cx="16.8201" cy="19.0549" fill={mainColor} r="0.433333" />
        <ellipse
          cx="18.4783"
          cy="18.2532"
          fill={mainColor}
          rx="0.433333"
          ry="0.433334"
        />
        <circle cx="14.2497" cy="18.7532" fill={mainColor} r="0.433334" />
        <ellipse
          cx="8.95872"
          cy="19.6863"
          fill={mainColor}
          rx="0.433334"
          ry="0.433333"
        />
      </>
    )}
  </SportIconWrapper>
)
