import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageLiveEventLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageLiveEvent,
  fallback
})

export { pageLiveEventPrefetch } from './page'
