import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Carousel } from 'shared/ui/Carousel'

import { StyledBannerProps, StyledBannerControlProps } from './Banners.types'

export const StyledBannerWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 0.5rem 0;
`

export const StyledBannersCarousel = styled(Carousel)`
  padding: 0 1rem;
`
export const StyledBannersLink = styled(Link)``

export const StyledBanner = styled.div<StyledBannerProps>`
  background: ${(p) =>
      p.imageUrl ? `url(${p.imageUrl})` : p.theme.colors.default.primary[10]}
    no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0.5rem;
  height: 144px;
  width: 370px;
`

export const StyledBannerControlsWrapper = styled.div`
  column-gap: 0.25rem;
  display: grid;
  grid-auto-columns: 24px;
  grid-auto-flow: column;
  grid-template-rows: 2px;
  padding: 0 1rem;
`

export const StyledBannerControl = styled.div<StyledBannerControlProps>`
  background-color: ${(p) =>
    p.isActive
      ? p.theme.colors.default.primary[90]
      : p.theme.colors.default.primary[10]};
`
