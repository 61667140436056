import styled from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { EColorsNames } from 'shared/types/theme'

export const StyledBannerStats = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledBannerStatsItem = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.custom[EColorsNames.Primary][20]};
  display: flex;
  flex-basis: 58px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  position: relative;

  ${StyledIcon} {
    color: ${(props) => props.theme.colors.default.primary[30]};
  }

  &::after {
    border: 1px solid ${(props) => props.theme.colors.default.primary[5]};
    bottom: 0;
    content: '';
    height: calc(100% - 16px);
    position: absolute;
    right: -1px;
    top: 8px;
  }

  &:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    &::after {
      display: none;
    }
  }
`

export const StyledBannerStatsItemText = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 16px;
  padding-top: 9px;
  white-space: nowrap;
`
