import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportFishing: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M14.446 8.24956C13.6818 7.35645 13.1596 6.34358 12.8263 5.39953C12.2351 5.6761 11.6774 5.99984 11.1814 6.37748L8.7995 5.88491C8.27817 5.7771 7.76586 6.10558 7.63936 6.62875L6.77865 10.1887C6.66202 10.671 6.91186 11.1671 7.36599 11.3549L8.2292 11.7119C8.09503 12.3375 8.00153 12.9585 7.93964 13.5474L4.75903 14.3213C3.83113 14.547 3.72453 15.84 4.60263 16.2183L7.00576 17.2536L7.95141 19.4048C8.33801 20.2842 9.60777 20.1603 9.82122 19.2223L10.4235 16.5755C10.8823 16.5511 11.3696 16.495 11.8728 16.3967L11.9201 16.5108C12.2179 17.2292 13.176 17.3442 13.6315 16.7162L14.5374 15.4673C15.3553 15.0381 16.1586 14.4611 16.8981 13.6965C17.7771 12.7876 18.4228 11.7616 18.8902 10.7142C16.924 10.417 15.4715 9.44796 14.446 8.24956ZM15.4338 7.40434C14.7703 6.62898 14.3177 5.73625 14.0327 4.91145C15.4535 4.416 16.9507 4.14294 18.1882 4.0125C19.1736 3.90863 20.0218 4.64161 19.9996 5.63225C19.9747 6.7371 19.8049 8.08696 19.3652 9.46519C17.5748 9.26959 16.314 8.43296 15.4338 7.40434ZM16.9126 7.68265C17.3876 7.68265 17.7727 7.30707 17.7727 6.84377C17.7727 6.38046 17.3876 6.00488 16.9126 6.00488C16.4376 6.00488 16.0525 6.38046 16.0525 6.84377C16.0525 7.30707 16.4376 7.68265 16.9126 7.68265Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
