export enum EPBTabs {
  PROMOTIONS = 'Promotions',
  BONUSES = 'Bonuses',
  BONUS_COUPONS = 'Bonus Coupons'
}

export type PBTabsType = EPBTabs

export interface IPromo {
  id: string
  src: {
    medium: string
    large: string
  }
  label: string
  value: string
  info: string
}
