import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportFormula1: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          clipRule="evenodd"
          d="M13.024 6.0835H15V4.5835H12.8291L13.024 6.0835ZM11.1709 4.5835H9V6.0835H10.976L11.1709 4.5835Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M14 7.80016C14 7.91062 13.9105 8.00016 13.8 8.00016H10.2C10.0895 8.00016 10 7.91062 10 7.80016V7.5335C10 7.42304 10.0895 7.3335 10.2 7.3335H13.8C13.9105 7.3335 14 7.42304 14 7.5335V7.80016Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          d="M9.66602 7C9.66602 6.44772 9.2183 6 8.66602 6C8.11373 6 7.66602 6.44772 7.66602 7H9.66602ZM7.66602 8.33333C7.66602 8.88562 8.11373 9.33333 8.66602 9.33333C9.2183 9.33333 9.66602 8.88562 9.66602 8.33333H7.66602ZM7.66602 7V8.33333H9.66602V7H7.66602Z"
          fill={mainColor}
        />
        <path
          clipRule="evenodd"
          d="M10.786 10.0668L11.4671 4.76973C11.4991 4.5203 11.7115 4.3335 11.963 4.3335H12.0357C12.2872 4.3335 12.4996 4.5203 12.5316 4.76973L13.2127 10.0668C13.2794 10.586 13.748 10.9573 14.2687 10.9036C14.8378 10.845 15.3327 11.2915 15.3327 11.8636V14.0002H14.7993C14.5193 14.0002 14.3793 14.0002 14.2724 14.0547C14.1783 14.1026 14.1018 14.1791 14.0538 14.2732C13.9993 14.3801 13.9993 14.5201 13.9993 14.8002V16.6624H9.99935V14.8002C9.99935 14.5201 9.99935 14.3801 9.94485 14.2732C9.89692 14.1791 9.82043 14.1026 9.72634 14.0547C9.61939 14.0002 9.47938 14.0002 9.19935 14.0002H8.66602V11.8636C8.66602 11.2915 9.16088 10.845 9.72996 10.9036C10.2507 10.9573 10.7193 10.586 10.786 10.0668Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.33398 14.6665C8.88627 14.6665 9.33398 15.1142 9.33398 15.6665V16.3332C9.33398 16.8855 8.88627 17.3332 8.33398 17.3332C7.7817 17.3332 7.33398 16.8855 7.33398 16.3332L7.33398 15.6665C7.33398 15.1142 7.7817 14.6665 8.33398 14.6665Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M15.666 14.6665C16.2183 14.6665 16.666 15.1142 16.666 15.6665V16.3332C16.666 16.8855 16.2183 17.3332 15.666 17.3332C15.1137 17.3332 14.666 16.8855 14.666 16.3332L14.666 15.6665C14.666 15.1142 15.1137 14.6665 15.666 14.6665Z"
          fill={mainColor}
          fillRule="evenodd"
        />
        <path
          d="M16.334 7C16.334 6.44772 15.8863 6 15.334 6C14.7817 6 14.334 6.44772 14.334 7H16.334ZM14.334 8.33333C14.334 8.88562 14.7817 9.33333 15.334 9.33333C15.8863 9.33333 16.334 8.88562 16.334 8.33333H14.334ZM14.334 7V8.33333H16.334V7H14.334Z"
          fill={mainColor}
        />
        <path
          clipRule="evenodd"
          d="M13.8 17.3335C13.9104 17.3335 13.9999 17.423 13.9999 17.5335C13.9999 17.8135 13.9999 17.9535 14.0544 18.0605C14.1024 18.1546 14.1789 18.2311 14.273 18.279C14.3799 18.3335 14.5199 18.3335 14.7999 18.3335H16.5333C16.6175 18.3335 16.689 18.3335 16.7508 18.332C16.8143 18.3305 16.8762 18.3559 16.9175 18.4042L17.7169 19.3367C17.8281 19.4664 17.736 19.6668 17.5651 19.6668H15.3333V18.9846H8.66667V19.6668H6.43476C6.2639 19.6668 6.17171 19.4664 6.2829 19.3367L7.08203 18.4042C7.1234 18.3559 7.18527 18.3305 7.24884 18.332C7.31068 18.3335 7.3823 18.3335 7.46662 18.3335H9.19995C9.47998 18.3335 9.61999 18.3335 9.72695 18.279C9.82103 18.2311 9.89752 18.1546 9.94545 18.0605C9.99995 17.9535 9.99995 17.8135 9.99995 17.5335C9.99995 17.423 10.0895 17.3335 10.2 17.3335H13.8Z"
          fill={mainColor}
          fillRule="evenodd"
        />
      </>
    )}
  </SportIconWrapper>
)
