import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { Layout } from 'widgets/Layout'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { useRedirectToSport } from 'hooks/useRedirectToSport'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'

export const PageLive = () => {
  useRedirectToSport({
    currentTabMenu: ESportsMenuTabTypes.LIVE
  })

  return (
    <Layout type={ELayoutsList.SPORT}>
      <LoaderSpinner />
    </Layout>
  )
}

export const LiveScreenPrefetch = ({ store }) => {
  store.dispatch(sportCountersProviderActions.fetchLiveSportCounters())
}
