import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isBrowser } from 'astra-core'

import { getOnboardingIsDisplay } from 'containers/LayoutContainer/selectors'

import { TOnboardingMaskProps } from './components/OnboardingMask/OnboardingMask.types'
import { getFormattedOnboardingAttr } from './utils'
import { IGetFormattedOnboardingAttr } from './Onboarding.types'
import { SCROLL_OFFSET } from './constants'

function getScrollParent(node: HTMLElement | null) {
  if (node === null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentElement)
  }
}

export const useGetOnboardingNodeItemClientRect = () => {
  return useCallback(
    (
      attrData?: IGetFormattedOnboardingAttr,
      toScroll?: boolean
    ): TOnboardingMaskProps | undefined => {
      if (isBrowser && attrData) {
        const itemNode = document.querySelector<HTMLDivElement>(
          `*[${getFormattedOnboardingAttr(attrData)}]`
        )
        if (itemNode) {
          if (toScroll) {
            const scrollParent = getScrollParent(itemNode)
            if (scrollParent) {
              const previousTop = itemNode.getBoundingClientRect().top
              if (previousTop > SCROLL_OFFSET) {
                scrollParent.scrollTo({
                  top: previousTop - SCROLL_OFFSET
                })
              }
            }
          }
          const { left, top, width, height } = itemNode.getBoundingClientRect()
          return {
            leftTopX: left,
            leftTopY: top,
            width,
            height
          }
        }
      }

      return undefined
    },
    []
  )
}

export const useOnboardingAttr = (
  attrType: IGetFormattedOnboardingAttr['attrType'],
  onboardingType: IGetFormattedOnboardingAttr['onboardingType']
) => {
  const result = getFormattedOnboardingAttr({ attrType, onboardingType })
  const attr = { [result]: true }
  const isDisplayOnboarding = useSelector(getOnboardingIsDisplay)

  return isDisplayOnboarding
    ? {
        onboardingAttr: attr,
        OnboardingAttrWrapper: ({ children, ...props }) => (
          <span {...attr} {...props}>
            {children}
          </span>
        )
      }
    : {
        OnboardingAttrWrapper: ({ children }) => children
      }
}
