import React, { FC } from 'react'

import { EventRow } from 'widgets/event-row'

import { TableBetsRowContext } from './context'
import { ITableBetsRowProps } from './TableBetsRow.types'

export const TableBetsRow: FC<ITableBetsRowProps> = ({ event }) => {
  return (
    <TableBetsRowContext.Provider value={{ event }}>
      <EventRow event={event} />
    </TableBetsRowContext.Provider>
  )
}
