import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectActiveLoyaltyWithoutAppliedCouponIds } from 'astra-core/containers/BasketProvider'
import { useGetCurrencyIcon } from 'astra-core'
import { useTranslation } from 'react-i18next'

import { IconBonusCoupon } from 'shared/ui/Icon/General/IconBonusCoupon'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'

import { IBasketSuitableBonusCouponProps } from './BasketSuitableBonusCoupons.types'
import {
  StyledBasketSuitableBonusCouponTitle,
  StyledBasketSuitableBonusCouponTitleWrapper,
  StyledBasketSuitableBonusCouponWrapper,
  StyledBasketSuitableBonusCouponsWrapper,
  StyledConditions
} from './BasketSuitableBonusCoupons.styled'

// The list of bonus coupons cannot be used because the terms of use have not been passed
export const BasketSuitableBonusCoupons = () => {
  const activeLoyaltyWithoutAppliedCouponIds = useSelector(
    selectActiveLoyaltyWithoutAppliedCouponIds
  )

  return activeLoyaltyWithoutAppliedCouponIds.length ? (
    <StyledBasketSuitableBonusCouponsWrapper>
      {activeLoyaltyWithoutAppliedCouponIds.map((coupon) => (
        <BasketSuitableBonusCoupon key={coupon.id} {...coupon} />
      ))}
    </StyledBasketSuitableBonusCouponsWrapper>
  ) : null
}

const BasketSuitableBonusCoupon: FC<IBasketSuitableBonusCouponProps> = ({
  possibleAmount,
  coupon: { description, amount }
}) => {
  const currencyIcon = useGetCurrencyIcon()
  const { t } = useTranslation()

  const couponTitle = getFormattedBonusCouponAmount(
    t('coupon for'),
    possibleAmount ?? amount,
    currencyIcon
  )

  const hintAttr = getHintAttrNameFormatted(description ?? '')

  return (
    <StyledBasketSuitableBonusCouponWrapper>
      <StyledBasketSuitableBonusCouponTitleWrapper>
        <IconBonusCoupon />
        <StyledBasketSuitableBonusCouponTitle>
          {couponTitle}
        </StyledBasketSuitableBonusCouponTitle>
      </StyledBasketSuitableBonusCouponTitleWrapper>

      <StyledConditions {...hintAttr}>{t('conditions')}</StyledConditions>
    </StyledBasketSuitableBonusCouponWrapper>
  )
}
