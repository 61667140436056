import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportRowingAcademic: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_115927)">
          <path
            d="M14.7621 15.5034L14.7621 14.0945H16.171C16.7781 14.0945 17.3603 14.3357 17.7895 14.7649L18.769 15.7444C19.4199 16.3953 19.4199 17.4506 18.769 18.1014C18.1181 18.7523 17.0629 18.7523 16.412 18.1014L15.4325 17.1219C15.0032 16.6927 14.7621 16.1105 14.7621 15.5034Z"
            fill={mainColor}
          />
          <path
            d="M6.5125 5.84493L14.7621 14.0945M6.5125 5.84493L5.691 6.66642M6.5125 5.84493L7.33398 5.02344M14.7621 14.0945V15.5034C14.7621 16.1105 15.0032 16.6927 15.4325 17.1219L16.412 18.1014C17.0629 18.7523 18.1181 18.7523 18.769 18.1014V18.1014C19.4199 17.4506 19.4199 16.3953 18.769 15.7444L17.7895 14.7649C17.3603 14.3357 16.7781 14.0945 16.171 14.0945H14.7621Z"
            stroke={mainColor}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            d="M9.23792 15.5034L9.23793 14.0945H7.82901C7.22195 14.0945 6.63975 14.3357 6.21049 14.7649L5.23099 15.7444C4.58011 16.3953 4.58011 17.4506 5.23099 18.1014C5.88186 18.7523 6.93714 18.7523 7.58801 18.1014L8.56751 17.1219C8.99677 16.6927 9.23792 16.1105 9.23792 15.5034Z"
            fill={mainColor}
          />
          <path
            d="M17.4875 5.84493L9.23793 14.0945M17.4875 5.84493L18.309 6.66642M17.4875 5.84493L16.666 5.02344M9.23793 14.0945V15.5034C9.23793 16.1105 8.99677 16.6927 8.56752 17.1219L7.58801 18.1014C6.93714 18.7523 5.88186 18.7523 5.23099 18.1014V18.1014C4.58011 17.4506 4.58011 16.3953 5.23099 15.7444L6.21049 14.7649C6.63975 14.3357 7.22195 14.0945 7.82901 14.0945H9.23793Z"
            stroke={mainColor}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_115927">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
