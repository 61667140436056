import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballShot: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg fill="none" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.63978 10.5837C3.89574 5.66559 5.44908 5.76289 8.28056 4.21397C11.1334 2.77244 8.81213 -1.18931 7.67656 0.348803C6.74068 1.61643 5.36385 2.63401 4.29935 3.22925C3.23485 3.8245 0.316221 3.96767 0.509123 4.97629C0.702024 5.98491 2.08547 7.29826 3.0112 9.95566C3.84114 12.3381 4.8992 12.2984 4.63978 10.5837Z"
          fill="currentColor"
        />
        <circle cx="10" cy="11.5" fill="currentColor" r="4.5" />
      </svg>
    </Icon>
  )
}
