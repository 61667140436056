import {
  selectCalendarEventsFetchItems,
  selectCalendarEventsHasMore,
  selectCalendarEventsPagination
} from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS = {
  setPaginationAction: calendarEventsContainerActions.setPagination,
  selectorHasMore: selectCalendarEventsHasMore,
  selectorPagination: selectCalendarEventsPagination,
  selectorRequestStatus: selectCalendarEventsFetchItems
} as DataListWithReduxPaginationProps['pagination']
