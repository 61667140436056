import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSkeleton: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_116086)">
          <path
            clipRule="evenodd"
            d="M7.94718 6.6942C7.53383 6.58089 7.10895 6.83185 6.99819 7.25475L6.79764 8.02048C6.68688 8.44338 6.93219 8.87807 7.34554 8.99138L8.09399 9.19656L7.69291 10.728L5.82178 10.215C5.6151 10.1584 5.40266 10.2839 5.34728 10.4953C5.2919 10.7068 5.41455 10.9241 5.62123 10.9808L16.4883 13.9598C16.8293 14.0533 17.1934 13.9831 17.4778 13.7691L19.1761 12.4909C19.3485 12.3611 19.3854 12.113 19.2586 11.9367C19.1318 11.7604 18.8893 11.7226 18.717 11.8523L17.0187 13.1305C16.9239 13.2018 16.8025 13.2252 16.6888 13.1941L15.1774 12.7797L15.5785 11.2483L16.327 11.4535C16.7403 11.5668 17.1652 11.3158 17.2759 10.8929L17.4765 10.1272C17.5873 9.70431 17.3419 9.26963 16.9286 9.15631L7.94718 6.6942ZM14.83 11.0431L8.84244 9.40173L8.44136 10.9332L14.429 12.5746L14.83 11.0431Z"
            fill={mainColor}
            fillRule="evenodd"
          />
          <path
            d="M4 19.5V12.9869C4 12.6576 4.31284 12.4182 4.63071 12.5043L19.6307 16.5668C19.8487 16.6258 20 16.8236 20 17.0494V19.5C20 19.7762 19.7761 20 19.5 20H4.5C4.22386 20 4 19.7762 4 19.5Z"
            fill={mainColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_116086">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
