import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { StyledIconSvgFromServer } from 'shared/ui/Icon/IconSvgFromServer.styled'

export const StyledFooterWrapper = styled.footer`
  background: ${(props) => props.theme.colors.custom.primary[2]};
  border-top: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 16px 0;
`

export const StyledFooterTop = styled.div`
  align-items: center;
  column-gap: 40px;
  display: flex;
  justify-content: flex-end;
  margin: 0 16px;
  padding-bottom: 12px;

  ${StyledIconSvgFromServer} {
    margin-right: auto;
  }
`

export const StyledFooterTopSocialNetworkWrapper = styled.div`
  align-items: center;
  column-gap: 4px;
  display: flex;

  & > * {
    cursor: pointer;
  }
`

export const StyledFooterBottom = styled.div`
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  color: ${(props) => props.theme.colors.default.primary[70]};
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 12px;
  margin: 0 16px;
  padding-top: 10px;
`

export const StyledFooterBottomYear = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  margin-right: 20px;
`

export const StyledFooterBottomMenuList = styled.nav`
  align-items: center;
  display: flex;
  justify-content: start;
  list-style: none;
  width: 100%;
`

export const StyledFooterBottomLogoList = styled.div`
  align-items: center;
  column-gap: 16px;
  display: flex;
  margin-left: auto;
`

export const StyledFooterLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  width: 100%;
`

export const StyledFooterTopHelpSupport = styled.div`
  align-items: center;
  display: flex;

  & > :first-child {
    margin-right: 10px;
  }
`

export const StyledFooterBottomSecurity = styled.div``

export const StyledFooterBottomMenuListLinkA = styled.a`
  color: ${(props) => props.theme.colors.default.primary[70]};
  margin-right: 14px;
`

export const StyledFooterBottomMenuListLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.default.primary[70]};
  margin-right: 14px;
`
