import React, { FC } from 'react'

import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { LoaderPointsMiniProps } from './types'
import { StyledLoaderPointsMini, StyledLoaderPointsMiniText } from './styled'

export const LoaderPointsMini: FC<LoaderPointsMiniProps> = (props) => (
  <StyledLoaderPointsMini {...props}>
    <LoaderPoints
      settingsAnimation={{
        timeStep: 0.3,
        timeAfterEnd: 1.7,
        scaleActivePoint: 1
      }}
      count={3}
      margin={2}
      size={3}
    />

    {!!props.text && (
      <StyledLoaderPointsMiniText>{props.text}</StyledLoaderPointsMiniText>
    )}
  </StyledLoaderPointsMini>
)
