import React from 'react'
import { useSelector } from 'react-redux'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { LiveTable } from '../Line/Live'

import { selectLiveIsEmpty } from './selectors'
import { useLiveContainerData } from './hooks'
import { reducerLive, sliceKeyLive } from './slice'
import { liveContainerSaga } from './saga'
import { LiveProps } from './types'

export function LiveContainer(_props: LiveProps) {
  useInjectReducer({ key: sliceKeyLive, reducer: reducerLive })
  useInjectSaga({ key: sliceKeyLive, saga: liveContainerSaga })
  const isEmpty = useSelector(selectLiveIsEmpty)

  useLiveContainerData()

  return isEmpty ? <LoaderSpinner /> : <LiveTable />
}
