import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportHandball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          clipRule="evenodd"
          d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4182 7.58172 20 12 20C16.4182 20 20 16.4182 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34314C16.1566 4.84286 14.1218 4 12 4ZM12.0001 5.06662C12.823 5.0657 13.6394 5.21202 14.4108 5.49862L12.0001 6.76617L9.58943 5.49862C10.3608 5.21202 11.1772 5.0657 12.0001 5.06662ZM6.55817 7.71022C5.59099 8.93078 5.0653 10.4427 5.06662 12V12.2809L7.02217 10.3858L6.55817 7.71022ZM6.24178 15.8542L8.91911 16.2436L10.1173 18.6702C8.53275 18.2202 7.15937 17.2222 6.24178 15.8542ZM8.95642 11.0168L10.1173 14.5938H10.1191H13.8809L15.0435 11.0168L12 8.79996L8.95642 11.0168ZM13.8826 18.6702L15.0808 16.2436L17.7582 15.8542C16.8406 17.2222 15.4672 18.2202 13.8826 18.6702ZM17.4365 7.71022L16.9778 10.3858L18.9334 12.2809V12C18.9331 10.4421 18.4055 8.93006 17.4365 7.71022Z"
          fill={mainColor}
          fillRule="evenodd"
        />

        <path
          clipRule="evenodd"
          d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4182 7.58172 20 12 20C16.4182 20 20 16.4182 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34314C16.1566 4.84286 14.1218 4 12 4ZM12.0001 5.06662C12.823 5.0657 13.6394 5.21202 14.4108 5.49862L12.0001 6.76617L9.58943 5.49862C10.3608 5.21202 11.1772 5.0657 12.0001 5.06662ZM6.55817 7.71022C5.59099 8.93078 5.0653 10.4427 5.06662 12V12.2809L7.02217 10.3858L6.55817 7.71022ZM6.24178 15.8542L8.91911 16.2436L10.1173 18.6702C8.53275 18.2202 7.15937 17.2222 6.24178 15.8542ZM8.95642 11.0168L10.1173 14.5938H10.1191H13.8809L15.0435 11.0168L12 8.79996L8.95642 11.0168ZM13.8826 18.6702L15.0808 16.2436L17.7582 15.8542C16.8406 17.2222 15.4672 18.2202 13.8826 18.6702ZM17.4365 7.71022L16.9778 10.3858L18.9334 12.2809V12C18.9331 10.4421 18.4055 8.93006 17.4365 7.71022Z"
          fill={mainColor}
          fillRule="evenodd"
        />
      </>
    )}
  </SportIconWrapper>
)
