import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ERoutes } from 'shared/types/routes'
import { IconSvgFromServer } from 'shared/ui/Icon/IconSvgFromServer'

const CASINO_LOGO_URL = 'casino-logo.svg'

export const useIconLogoUrl = () => {
  const casinoMatch = useRouteMatch(ERoutes.Casino)
  const config = useSelector(selectConfig)

  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  return {
    url: `${config.CDN_URL}/logo/${
      config.CLIENT_TYPE
    }/${typeTheme.toLowerCase()}/${
      casinoMatch ? CASINO_LOGO_URL : config.LOGO_ICON_URL
    }`,
    height: casinoMatch ? 14 : 16
  }
}

export const IconLogo: FC = () => {
  const { url, height } = useIconLogoUrl()

  return (
    <IconLogoStyled
      parseFileFromServerProps={{
        url
      }}
      height={height}
    />
  )
}

export const IconLogoStyled = styled(IconSvgFromServer)`
  max-width: 240px;
  overflow: hidden;
`
