import styled from 'styled-components'

import { IIframeVisibilityControl, IIframeWrapper } from './Auth.types'

export const AuthWindow = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`

export const IframeWrapper = styled.div<IIframeWrapper>`
  background-color: ${({ theme }) => theme.colors.custom.primary[10]};
  border-radius: 8px;
  height: fit-content;
  left: 50%;
  margin-right: 12px;
  margin-top: 6px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  z-index: 50;
`

export const IframeVisibilityControl = styled.div<IIframeVisibilityControl>`
  ${(props) =>
    props.isLoading
      ? `
        height: 0;
        overflow: hidden;
        visibility: hidden;
      `
      : ''};
`

export const StyledButtonClose = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: absolute;
  right: 32px;
  top: 23px;
`
