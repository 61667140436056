import { Event } from 'betweb-openapi-axios'
import { useDispatch } from 'react-redux'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'

import { useHoverCapture } from 'hooks'

const EVENT_HOVER_CAPTURE_DURATION = 500

export const useEventRowHandleHover = ({
  eventId,
  isHoverAvailable
}: { eventId: Event['id'] } & Pick<
  Parameters<typeof useHoverCapture>[0],
  'isHoverAvailable'
>) => {
  const dispatch = useDispatch()

  return useHoverCapture({
    onHoverCaptured: () => {
      dispatch(eventsProviderActions.fetchEventProbabilities({ eventId }))
      dispatch(eventsProviderActions.fetchEventStatistics({ eventId }))
    },
    isHoverAvailable,
    captureDuration: EVENT_HOVER_CAPTURE_DURATION
  })
}
