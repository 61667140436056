import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import { useInterval, usePrevious } from 'hooks'
import {
  selectEventsLayoutIsLoading,
  selectFilterIsTop,
  selectFilterPeriod
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'

import { getFetchLineReqData, LINE_POLLING_INTERVAL } from './utils'
import { LineParams } from './types'

export const useLineContainerData = () => {
  const dispatch = useDispatch()
  const { tournamentId } = useParams<LineParams>()
  const tournamentIdPrev = usePrevious(tournamentId)
  const isLayoutLoading = useSelector(selectEventsLayoutIsLoading)
  const top = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(selectFilterPeriod)

  const filters = useMemo(() => ({ top, filterPeriod }), [filterPeriod, top])
  const filtersPrev = usePrevious(filters)

  const fetchLine = useCallback(() => {
    if (tournamentId) {
      dispatch(
        lineTournamentsContainerActions.fetchLine(
          getFetchLineReqData({ tournamentId })
        )
      )
    }
  }, [dispatch, tournamentId])

  useEffect(() => {
    /* To handle fetch on changed filters */

    if (!isEqual(filtersPrev, filters)) {
      fetchLine()
    }
  }, [fetchLine, filters, filtersPrev])

  useEffect(() => {
    /* To handle fetch on sport id change */

    if (!!tournamentIdPrev && tournamentIdPrev !== tournamentId) {
      fetchLine()
    }
  }, [tournamentIdPrev, tournamentId, fetchLine])

  const intervalDelay = isLayoutLoading ? null : LINE_POLLING_INTERVAL

  useInterval(fetchLine, intervalDelay, true)
}

export const useLineTournamentContainerData = ({ tournamentId }) => {
  const dispatch = useDispatch()
  const isLayoutLoading = useSelector(selectEventsLayoutIsLoading)

  const fetchLine = useCallback(() => {
    if (tournamentId) {
      dispatch(
        lineTournamentsContainerActions.fetchLine(
          getFetchLineReqData({ tournamentId })
        )
      )
    }
  }, [dispatch, tournamentId])

  const intervalDelay = isLayoutLoading ? null : LINE_POLLING_INTERVAL

  useInterval(fetchLine, intervalDelay, true)
}
