import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportCybersport: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M15.0413 12C15.6012 12 16.0551 11.5523 16.0551 11C16.0551 10.4477 15.6012 10 15.0413 10C14.4813 10 14.0274 10.4477 14.0274 11C14.0274 11.5523 14.4813 12 15.0413 12Z"
          fill={mainColor}
        />
        <path
          clipRule="evenodd"
          d="M4.01007 15.6891L4.73566 7.81645C4.83051 6.78736 5.69377 6 6.72722 6H17.2724C18.3058 6 19.1691 6.78736 19.264 7.81645L19.9895 15.6891C20.0623 16.4788 19.6409 17.2315 18.9297 17.5823C18.3958 17.8456 17.7697 17.8456 17.2357 17.5823L12.8844 15.4363C12.3268 15.1612 11.6729 15.1612 11.1152 15.4363L6.76389 17.5823C6.22993 17.8456 5.60386 17.8456 5.0699 17.5823C4.35873 17.2315 3.9373 16.4788 4.01007 15.6891ZM8.95835 8.5C9.23831 8.5 9.46526 8.72386 9.46526 9V10.5H10.986C11.2659 10.5 11.4929 10.7239 11.4929 11C11.4929 11.2761 11.2659 11.5 10.986 11.5H9.46526V13C9.46526 13.2761 9.23831 13.5 8.95835 13.5C8.67839 13.5 8.45144 13.2761 8.45144 13V11.5H6.93071C6.65076 11.5 6.4238 11.2761 6.4238 11C6.4238 10.7239 6.65076 10.5 6.93071 10.5H8.45144V9C8.45144 8.72386 8.67839 8.5 8.95835 8.5ZM17.0689 11C17.0689 12.1046 16.1611 13 15.0413 13C13.9214 13 13.0136 12.1046 13.0136 11C13.0136 9.89543 13.9214 9 15.0413 9C16.1611 9 17.0689 9.89543 17.0689 11Z"
          fill={mainColor}
          fillRule="evenodd"
        />
      </>
    )}
  </SportIconWrapper>
)
