import {
  selectBasketOutcomesLength,
  selectExpressRate
} from 'astra-core/containers/BasketProvider'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { useLocation } from 'react-router-dom'

import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { hiddenRightContainerRoutesList } from 'widgets/right-container/constants'

import {
  StyledBasketButtonSmallWindowCoefficient,
  StyledBasketButtonSmallWindowSize,
  StyledBasketButtonSmallWindowText,
  StyledBasketButtonSmallWindowWrapper
} from './BasketButtonSmallWindow.styled'

export const BasketButtonSmallWindow = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const expressRate = useSelector(selectExpressRate)
  const outcomesLength = useSelector(selectBasketOutcomesLength)
  const isRouteWithPermanentHiddenBasket =
    hiddenRightContainerRoutesList.includes(pathname)

  const user = useSelector(selectAccountUser)

  const toggleShowBasket = useCallback(() => {
    dispatch(
      rightBasketContainerState.toggleBasketOnSmallWindow({ isShow: true })
    )
  }, [dispatch])

  return user ? (
    <StyledBasketButtonSmallWindowWrapper
      isRouteWithPermanentHiddenBasket={isRouteWithPermanentHiddenBasket}
      onClick={toggleShowBasket}
    >
      <StyledBasketButtonSmallWindowText>
        {t('just coupon')}
      </StyledBasketButtonSmallWindowText>
      {!!outcomesLength && (
        <StyledBasketButtonSmallWindowSize>
          {outcomesLength}
        </StyledBasketButtonSmallWindowSize>
      )}
      {!!outcomesLength && (
        <StyledBasketButtonSmallWindowCoefficient>
          {expressRate.toFixed(2)}
        </StyledBasketButtonSmallWindowCoefficient>
      )}
    </StyledBasketButtonSmallWindowWrapper>
  ) : null
}
