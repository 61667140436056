import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconHomepageNotifications: FC<
  IconProps & { isNewNotifications?: boolean }
> = ({ isNewNotifications, ...props }) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 26 24"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.4892 20.5 9.07046 20.1058 7.84101 19.4147L3.5 20.5L4.58525 16.159C3.89416 14.9295 3.5 13.5108 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM16.5 10C16.5 9.44772 16.0523 9 15.5 9H8.5C7.94772 9 7.5 9.44772 7.5 10C7.5 10.5523 7.94772 11 8.5 11L15.5 11C16.0523 11 16.5 10.5523 16.5 10ZM14.5 14C14.5 13.4477 14.0523 13 13.5 13H8.5C7.94772 13 7.5 13.4477 7.5 14C7.5 14.5523 7.94772 15 8.5 15H13.5C14.0523 15 14.5 14.5523 14.5 14Z"
        fill="#B3B3B3"
        fillRule="evenodd"
      />
      {isNewNotifications && <circle cx="21" cy="8" r="4" strokeWidth="2" />}
    </svg>
  </Icon>
)
