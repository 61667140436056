import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutProps } from 'widgets/Layout/Layout.types'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { Auth } from 'widgets/Auth/Auth'
import { Header } from 'widgets/header'

import { StyledContentTitle } from '../Common'

import { StyledContentAbout, StyledLayoutAbout } from './About.styled'

export const LayoutAbout: FC<LayoutProps> = memo(({ pageTitle, children }) => {
  const [t] = useTranslation()

  return (
    <StyledLayoutAbout>
      <Auth />

      <Header />

      <NotificationsPanel />

      <StyledContentAbout>
        {pageTitle?.text && (
          <StyledContentTitle {...pageTitle}>
            {t(pageTitle.text)}
          </StyledContentTitle>
        )}
        {children}
      </StyledContentAbout>
    </StyledLayoutAbout>
  )
})
