import React from 'react'

import { Layout } from 'widgets/Layout'
import { useCalendarEventsContainer } from 'containers/CalendarEventsContainer'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'

import { CalendarEvents } from './CalendarEvents'

export const PageCalendar = () => {
  useCalendarEventsContainer()

  return (
    <Layout type={ELayoutsList.CALENDAR}>
      <CalendarEvents />
    </Layout>
  )
}

export const pageCalendarPrefetch = ({ store }) => {
  store.dispatch(calendarEventsContainerActions.fetchScheduledEvents({}))
}
