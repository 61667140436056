import styled from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'

export const StyledMatchActions = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
  flex-wrap: nowrap;
  grid-area: actions;
  justify-content: flex-end;

  ${StyledIcon} {
    opacity: 0.7;

    &:hover {
      opacity: 0.95;
    }
  }
`
