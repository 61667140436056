import React, { FC, useEffect, useState } from 'react'

import {
  IParseFileFromServerProps,
  parseFileFromServer
} from 'shared/lib/@system'

import { StyledIconSvgFromServer } from './IconSvgFromServer.styled'

interface IIconSvgFromServerProps {
  parseFileFromServerProps: Pick<IParseFileFromServerProps, 'url'>
  height?: number
  className?: string
}

export const IconSvgFromServer: FC<IIconSvgFromServerProps> = ({
  parseFileFromServerProps,
  height = 16,
  className
}) => {
  const [svg, setSvg] = useState('')

  useEffect(() => {
    const getSvg = async (url: string) => {
      try {
        const svgString = await parseFileFromServer({
          url,
          contentTypeResponse: 'image/svg+xml',
          options: { mode: 'cors' }
        })

        setSvg(svgString)
      } catch (e) {
        console.log('An error occurred while fetching an svg', e)
      }
    }

    getSvg(parseFileFromServerProps.url)
  }, [parseFileFromServerProps.url])

  const isError = svg.includes('<Error><Code>')

  if (isError) {
    console.error(svg)
  }

  return svg ? (
    <StyledIconSvgFromServer
      dangerouslySetInnerHTML={{
        __html: isError ? 'Remote icon error' : svg
      }}
      className={className}
      height={height}
    />
  ) : null
}
