import get from 'lodash/get'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  Competitor,
  Event,
  Tournament,
  Sport,
  BetEvent,
  EventSourceType,
  EventProbabilityTradingStatus,
  EventProbability
} from 'betweb-openapi-axios'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import {
  CommonDataProviderState,
  MainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'

import { toIdsArray } from 'shared/lib/@reduxjs'
import { TUseProbs } from 'hooks'

export function getEventHomeAwayCompetitors(event: Event): {
  homeCompetitors: Array<Competitor>
  awayCompetitors: Array<Competitor>
} {
  return {
    homeCompetitors: event.competitors.filter((competitor) =>
      event.homeCompetitorIds?.includes(competitor.id)
    ),
    awayCompetitors: event.competitors.filter(
      (competitor) => !event.homeCompetitorIds?.includes(competitor.id)
    )
  }
}

export function getBetEventHomeAwayCompetitors(event: BetEvent) {
  return {
    homeCompetitors: event.competitors.filter((competitor) => competitor.home),
    awayCompetitors: event.competitors.filter((competitor) => !competitor.home)
  }
}

export function getEventLink(event?: Event): string {
  return !event
    ? ''
    : event.live
    ? `/live/${event.tournament.sportId}/${event.id}`
    : `/line/${event.tournament.sportId}/${event.tournament.id}/${event.id}`
}

export function getLineTournamentLink(tournament?: Tournament): string {
  return !tournament ? '' : `/line/${tournament.sportId}/${tournament.id}`
}

export function getEventBackLink(event, prevPath?): string {
  if (event) {
    if (prevPath) return prevPath

    if (event.live) return `/live/${event.tournament.sportId}`

    return getLineTournamentLink(event.tournament)
  } else {
    return ''
  }
}

export function getEventSport(sports, event?: Event): Sport {
  return get(sports, `${event?.tournament.sportId}`)
}

export function formatDate(date: string): string {
  const format = {
    en: 'MMMM DD [at] HH:mm',
    ru: 'DD.MM.YY, HH:mm'
  }
  const currentFormat = format[dayjs().locale()]
  return dayjs(date).format(currentFormat)
}

export function getLastYearEvent(date: string): string | null {
  const islastYear = Number(dayjs(date).format('YYYY')) < Number(dayjs().year())
  return islastYear ? dayjs(date).format('YYYY') : null
}

export const groupEventItemsBySport = (items: Event[]) =>
  mapValues(
    groupBy(items, (item) => item.tournament.sportId),
    (events) => toIdsArray(events)
  )

export const getCompetitorsNames = (competitors: Competitor[]) =>
  competitors.map((c) => c.name).join(', ')

export const getEventBetradarSourceId = (event: Event) =>
  event.sources
    ?.find((source) => source.sourceType === EventSourceType.Betradar)
    ?.sourceEventId.split(':')[2]

export const getMainOutcomeTypeProbability = ({
  outcomeType,
  probs,
  mainOutcomeTypes,
  eventId
}: {
  outcomeType: MainOutcomeTypes
  probs: TUseProbs
  mainOutcomeTypes: CommonDataProviderState['entries']['mainOutcomeTypes']
  eventId: Event['id']
}): EventProbability & { eventId: number } => {
  const probability = probs[mainOutcomeTypes[outcomeType]!]
  const outcomeTypeId = mainOutcomeTypes[outcomeType]!

  return probability
    ? {
        ...probability,
        eventId
      }
    : {
        odd: 0,
        outcomeTypeId,
        parameters: [],
        suspended: true,
        tradingStatus: EventProbabilityTradingStatus.Suspended,
        eventId
      }
}
