import React, { FC, Suspense } from 'react'
// eslint-disable-next-line react/no-deprecated
import { hydrateRoot } from 'react-dom/client'
// TODO: Switch to hydrate func fo React v18
import { loadableReady } from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { useSSR } from 'react-i18next'

import './app/lib/i18n'
import { configureAppStore } from 'app/lib/store'
import { routes } from 'pages/model'

import App from './app/app'

/*
Redux Store Init
Statically injected reducers are pre-filled with initial data here,
while initial states of dynamically injected reducers are pre-filled
on behalf of each particular slice.
 */
const store = configureAppStore({
  // @ts-ignore
  preloadedState: window.__PRELOADED_STATE__
})

const AppContainer: FC = () => {
  useSSR(window.initialI18nStore, window.initialLanguage)

  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <Provider store={store}>
        <BrowserRouter>
          <App routes={routes} />
        </BrowserRouter>
      </Provider>
    </Suspense>
  )
}

// Load all components needed before rendering
loadableReady().then(() => {
  hydrateRoot(document.getElementById('root'), <AppContainer />)
})

if (module.hot) {
  module.hot.accept()
}
