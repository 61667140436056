import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { currentYearConstants } from '../..'

import { IFooterOfPageProps } from './footer-of-page.types'
import {
  StyledFooterBottom,
  StyledFooterBottomLogoList,
  StyledFooterBottomMenuList,
  StyledFooterBottomYear,
  StyledFooterLogoWrapper,
  StyledFooterTop,
  StyledFooterTopSocialNetworkWrapper,
  StyledFooterWrapper
} from './footer-of-page.styled'

export const FooterOfPage: FC<IFooterOfPageProps> = memo(
  ({ menuItems, logoItems, logoMain, socialNetworksItems, helpButtons }) => {
    const [t] = useTranslation()

    return (
      <StyledFooterWrapper>
        <StyledFooterTop>
          {logoMain}

          {helpButtons}

          <StyledFooterTopSocialNetworkWrapper>
            {socialNetworksItems}
          </StyledFooterTopSocialNetworkWrapper>
        </StyledFooterTop>

        <StyledFooterBottom>
          <StyledFooterBottomMenuList>{menuItems}</StyledFooterBottomMenuList>

          <StyledFooterLogoWrapper>
            <StyledFooterBottomYear>
              {`${t('footer app name')}, ${currentYearConstants.CURRENT_YEAR}`}
            </StyledFooterBottomYear>

            <StyledFooterBottomLogoList>{logoItems}</StyledFooterBottomLogoList>
          </StyledFooterLogoWrapper>
        </StyledFooterBottom>
      </StyledFooterWrapper>
    )
  }
)
