import loadable from '@loadable/component'

import { fallback } from '../model'

export const PageLineEventLazy = loadable(() => import('./page'), {
  resolveComponent: (components) => components.PageLineEvent,
  fallback
})

export { pageLineEventPrefetch } from './page'
