import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportSkiJumping: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_116020)">
          <path
            clipRule="evenodd"
            d="M11.3731 4.88378C10.5108 4.38339 8.93372 6.27694 8.28742 7.39064L7.74304 8.32871L7.74045 8.3272L4.72755 13.5177L4.72975 13.5189L4.38204 14.1179C4.0754 14.6462 4.23644 15.3141 4.74104 15.6068L5.3511 15.9608C5.8557 16.2535 6.51564 16.0619 6.82228 15.5336L8.11982 13.2983L8.11044 13.2929L9.97637 10.0783L9.9862 10.084L10.7275 8.8066C11.3738 7.6929 12.2354 5.38416 11.3731 4.88378ZM14.5502 14.5351L14.3238 14.4044L17.1969 9.42802C17.4389 9.42461 17.6685 9.30405 17.7931 9.08808L18.7184 7.48543C18.9114 7.15129 18.7814 6.71374 18.4286 6.51009C18.0759 6.30644 17.6319 6.41262 17.439 6.74677L16.5137 8.34941C16.389 8.5654 16.3994 8.8245 16.5175 9.03579L13.6444 14.0121L13.418 13.8814C13.2304 13.7731 12.9951 13.8293 12.8925 14.007C12.7899 14.1848 12.8588 14.4166 13.0464 14.525L13.2728 14.6557L13.0804 14.989C12.9778 15.1667 13.0467 15.3986 13.2343 15.5069C13.4219 15.6152 13.6572 15.5589 13.7598 15.3812L13.9522 15.0479L14.1787 15.1787C14.3663 15.287 14.6015 15.2307 14.7041 15.053C14.8067 14.8753 14.7378 14.6434 14.5502 14.5351ZM11.5788 9.2914C12.2251 8.1777 13.8021 6.28414 14.6644 6.78453C15.5267 7.28492 14.6651 9.59366 14.0188 10.7074L13.2775 11.9848L13.2683 11.9794L11.4025 15.1936L11.4116 15.1988L10.1141 17.4342C9.80746 17.9624 9.14752 18.154 8.64292 17.8613L8.03286 17.5074C7.52826 17.2146 7.36722 16.5467 7.67386 16.0185L8.02163 15.4194L8.01971 15.4183L11.0326 10.2278L11.0347 10.229L11.5788 9.2914Z"
            fill={mainColor}
            fillRule="evenodd"
          />
          <path
            d="M18.4614 13.9464C17.5752 15.3871 15.0498 18.326 8.8945 19.791L19.6957 19.791C19.8091 19.791 19.8998 19.6968 19.8955 19.5835L19.6814 13.8854C19.6773 13.7779 19.5891 13.6929 19.4815 13.6929H18.9043C18.7226 13.6929 18.5566 13.7916 18.4614 13.9464Z"
            fill={mainColor}
            stroke={mainColor}
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_116020">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
