import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'

import { MainPageContainer } from './MainPageContainer'

export const PageMain = () => (
  <Layout type={ELayoutsList.MAIN}>
    <MainPageContainer />
  </Layout>
)

export const pageMainPrefetch = ({ store }) => {
  store.dispatch(mainPageContainerActions.fetchTopLiveEvents())
  store.dispatch(mainPageContainerActions.fetchTopLineEvents())
  store.dispatch(mainPageContainerActions.fetchUpcomingEvents())
}
