import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportWeightlifting: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M5.875 8.25V10.5938H5.40625C4.62959 10.5938 4 11.2233 4 12C4 12.7767 4.62959 13.4062 5.40625 13.4062H5.875V15.75C5.875 16.0091 6.08466 16.2188 6.34375 16.2188H8.25C8.50909 16.2188 8.71875 16.0091 8.71875 15.75V13.3333H15.2812V15.75C15.2812 16.0091 15.4909 16.2188 15.75 16.2188H17.6562C17.9153 16.2188 18.125 16.0091 18.125 15.75V13.4062H18.5938C19.3704 13.4062 20 12.7767 20 12C20 11.2233 19.3704 10.5938 18.5938 10.5938H18.125V8.25C18.125 7.99091 17.9153 7.78125 17.6562 7.78125H15.75C15.4909 7.78125 15.2812 7.99091 15.2812 8.25V10.6667H8.71875V8.25C8.71875 7.99091 8.50909 7.78125 8.25 7.78125H6.34375C6.08466 7.78125 5.875 7.99091 5.875 8.25Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
