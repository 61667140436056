import styled, { keyframes } from 'styled-components'

import { ELoaderSkeletonBlockView } from './LoaderSkeleton.types'

export const StyledLoaderSkeleton = styled.div`
  padding: 25px 33px;
`

export const StyledLoaderSkeletonTitle = styled.h2`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.l};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 19px;
  padding-bottom: 25px;
`

const animationStyledLoaderSkeletonBlock = keyframes`
  0% {
    left: -110px;
  }
  60%,
  100% {
    left: calc(100% + 110px);
  }
`

export const StyledLoaderSkeletonBlock = styled.div<{
  view: ELoaderSkeletonBlockView
  marginBottom?: number
  width?: number
}>`
  background: ${(props) => props.theme.colors.default.primary[10]};
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  &::before {
    -moz-animation: 3s ${animationStyledLoaderSkeletonBlock} infinite;
    -o-animation: 3s ${animationStyledLoaderSkeletonBlock} infinite;
    -webkit-animation: 3s ${animationStyledLoaderSkeletonBlock} infinite;
    animation: 3s ${animationStyledLoaderSkeletonBlock} infinite;
    background: linear-gradient(
      to right,
      transparent 0%,
      ${(props) => props.theme.colors.default.primary[5]} 50%,
      transparent 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: -110px;
    position: absolute;
    top: 0;
    width: 110px;
  }

  ${(props) => `
  ${props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ''}}
  
    ${
      props.view === ELoaderSkeletonBlockView.Input
        ? `
            height: 32px;
            width: 100%;
          `
        : ''
    }
  
    ${
      props.view === ELoaderSkeletonBlockView.Text
        ? `
            height: 16px;
            width: 30%;
          `
        : ''
    }
    
    ${props.width ? `width: ${props.width}px` : ''}
  `}
`
