import styled from 'styled-components'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'

export const StyledBasketBonusWrapper = styled.div`
  display: grid;
  font-size: ${(props) => props.theme.fonts.size.s};
  grid-template-columns: 1fr auto;
  padding: 4px 16px 8px;
`

export const StyledGapRow = styled.div<{ gap: number }>`
  align-items: center;
  display: flex;
  gap: ${(props) => props.gap}px;
`

export const BasketBonusText = styled.span`
  color: ${(props) =>
    props.theme.colors[EColorsTypes.DEFAULT][EColorsNames.Primary][90]};
  ${({ theme }) =>
    `
      font-weight: ${theme.fonts.weight.normal};
    `};
`
