import { TFunction } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { OptionType } from 'shared/ui/Select'

export const INITIAL_FILTER_PERIOD_ID = '1'

export const OPTIONS_SELECT_PERIOD = (
  t?: TFunction<'translation'>
): OptionType[] => [
  {
    id: INITIAL_FILTER_PERIOD_ID,
    label: t?.('all time') || '',
    value: ''
  },
  {
    id: '2',
    label: t?.('today') || '',
    value: dayjs().endOf('day').format(),
    additionalLabel: dayjs().format('D MMMM')
  },
  {
    id: '3',
    label: t?.('tomorrow') || '',
    value: 'tomorrow',
    scheduledFrom: dayjs().add(1, 'day').startOf('day').format(),
    scheduledTo: dayjs().add(1, 'day').endOf('day').format()
  },
  {
    id: '4',
    label: t?.('day after tomorrow') || '',
    value: 'day after tomorrow',
    scheduledFrom: dayjs().add(2, 'day').startOf('day').format(),
    scheduledTo: dayjs().add(2, 'day').endOf('day').format(),
    styles: {
      isBottomBorder: true
    }
  },
  {
    id: '5',
    label: t?.('for 2 hours') || '',
    value: dayjs().add(2, 'hour').format()
  },
  {
    id: '6',
    label: t?.('for 4 hours') || '',
    value: dayjs().add(4, 'hour').format()
  },
  {
    id: '7',
    label: t?.('for 8 hours') || '',
    value: dayjs().add(8, 'hour').format()
  },
  {
    id: '8',
    label: t?.('for 12 hours') || '',
    value: dayjs().add(12, 'hour').format()
  },
  {
    id: '9',
    label: t?.('per 24 hours') || '',
    value: dayjs().add(1, 'day').format(),
    styles: {
      isBottomBorder: true
    }
  },
  {
    id: '10',
    label: t?.('for 2 days') || '',
    value: dayjs().add(2, 'day').format()
  },
  {
    id: '11',
    label: t?.('for 3 days') || '',
    value: dayjs().add(3, 'day').format()
  },
  {
    id: '12',
    label: t?.('by week') || '',
    value: dayjs().add(1, 'week').format()
  }
]

export const getScheduleTime = (
  option: OptionType
): {
  scheduledFrom: OptionType['scheduledFrom']
  scheduledTo: OptionType['scheduledTo']
  id: OptionType['id']
} => {
  const scheduledFrom =
    option.scheduledFrom ?? (option.value ? dayjs().format() : '')
  const scheduledTo = option.scheduledTo ?? option.value

  return { scheduledFrom, scheduledTo, id: option.id }
}
