import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useInterval, usePrevious } from 'hooks'

import { selectFilterIsTop, selectLayoutIsLoading } from './selectors'
import { getFetchLiveReqData, LIVE_POLLING_INTERVAL } from './utils'
import { LiveParams } from './types'
import { liveContainerActions } from './slice'

export const useLiveContainerData = () => {
  const { sportId } = useParams<LiveParams>()
  const dispatch = useDispatch()
  const top = useSelector(selectFilterIsTop)
  const isLayoutLoading = useSelector(selectLayoutIsLoading)
  const sportIdPrev = usePrevious(sportId)
  const topPrev = usePrevious(top)

  const fetchLive = useCallback(() => {
    if (sportId) {
      dispatch(
        liveContainerActions.fetchLive(getFetchLiveReqData({ sportId, top }))
      )
    }
  }, [dispatch, sportId, top])

  useEffect(() => {
    /* To handle fetch on toggle TOP */

    if (top !== topPrev) {
      fetchLive()
    }
  }, [fetchLive, top, topPrev])

  useEffect(() => {
    /* To handle fetch on sport id change */

    if (!!sportIdPrev && sportIdPrev !== sportId) {
      fetchLive()
    }
  }, [sportIdPrev, sportId, fetchLive, top, topPrev])

  const intervalDelay = isLayoutLoading ? null : LIVE_POLLING_INTERVAL

  useInterval(fetchLive, intervalDelay, true)
}

export const useLiveData = ({ sportId }) => {
  const dispatch = useDispatch()

  const fetchLive = useCallback(() => {
    if (sportId) {
      dispatch(liveContainerActions.fetchLive(getFetchLiveReqData({ sportId })))
    }
  }, [dispatch, sportId])

  useInterval(fetchLive, LIVE_POLLING_INTERVAL, true)
}
