import React, { forwardRef } from 'react'

import { getCompetitorsNames } from 'shared/lib/events'

import {
  CurrentServerFlag,
  StyledEventCardCompetitor
} from '../../EventCard.styled'

import { EventCardCompetitorProps } from './EventCardCompetitor.types'

export const EventCardCompetitor = forwardRef<
  HTMLDivElement,
  EventCardCompetitorProps
>(({ competitors, isServer, isLongTeamName }, ref) => (
  <StyledEventCardCompetitor isLongTeamName={!!isLongTeamName} ref={ref}>
    {isServer && <CurrentServerFlag />}
    {/* <IconEmblem size={16} /> */}
    {getCompetitorsNames(competitors)}
  </StyledEventCardCompetitor>
))
