import React from 'react'

import { ERoutes } from 'shared/types/routes'
import { ETurboGamesIds } from 'pages/page-games-turbo/TurboGames/types'

import {
  AviatorBackground,
  AviatorLogo,
  AviatrixBackground,
  AviatrixLogo,
  BetGamesBackground,
  BetGamesLogo,
  FastGamesBackground,
  FastGamesLogo,
  LiveGamesBackground,
  LiveGamesLogo,
  SportGamesBackground,
  SportGamesLogo,
  TwainsportBackground,
  TwainsportLogo
} from './SvgImages'
import * as aviatorMainImg from './JsonImages/aviator_small.json'
import * as betGamesMainImg from './JsonImages/betgames_small.json'
import * as twainsportMainImg from './JsonImages/twainsport_small.json'
import * as sportGamesMainImg from './JsonImages/sport_games_small.json'
import * as liveGamesMainImg from './JsonImages/live_games_small.json'
import * as fastGamesMainImg from './JsonImages/fast_game_small.json'
import * as aviatrixMainImg from './JsonImages/aviatrix_small.json'
import * as sportGamesAdditionalImg from './JsonImages/bgsportgames.json'
import { ISponsorsItemProps } from './SponsorsLowerResolutionItems.types'

export const getLottieDefaultImgOptions = (animationData) => ({
  loop: false,
  autoplay: false,
  animationData
})

export const BANNER_HIGHT = '108px'

export const SPONSORS_LIST_LOWER_RESOLUTION: Array<ISponsorsItemProps> = [
  {
    url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
    name: 'Aviator',
    backgroundImg: <AviatorBackground />,
    mainImg: aviatorMainImg,
    logoImg: <AviatorLogo />
  },
  {
    url: ERoutes.BetGames,
    name: 'BetGames',
    backgroundImg: <BetGamesBackground />,
    mainImg: betGamesMainImg,
    logoImg: <BetGamesLogo />,
    itemStyles: {
      bottomPosition: '-3px'
    }
  },
  {
    url: ERoutes.TwainGames,
    name: 'TwainGames',
    backgroundImg: <TwainsportBackground />,
    mainImg: twainsportMainImg,
    logoImg: <TwainsportLogo />,
    itemStyles: {
      bottomPosition: '-5px'
    }
  },
  {
    url: ERoutes.SportGamesLobby,
    name: 'SportGamesLobby',
    backgroundImg: <SportGamesBackground />,
    mainImg: sportGamesMainImg,
    logoImg: <SportGamesLogo />,
    additionalImg: sportGamesAdditionalImg,
    itemStyles: {
      bottomPosition: '-5px'
    }
  },
  {
    url: ERoutes.LiveGames,
    name: 'LiveGames',
    backgroundImg: <LiveGamesBackground />,
    mainImg: liveGamesMainImg,
    logoImg: <LiveGamesLogo />,
    itemStyles: {
      bottomPosition: '-5px'
    }
  },
  {
    url: ERoutes.TurboGames,
    name: 'TurboGames',
    backgroundImg: <FastGamesBackground />,
    mainImg: fastGamesMainImg,
    logoImg: <FastGamesLogo />,
    itemStyles: {
      bottomPosition: '-5px'
    }
  },
  {
    url: ERoutes.SportGamesLobby,
    name: 'Aviatrix',
    backgroundImg: <AviatrixBackground />,
    mainImg: aviatrixMainImg,
    logoImg: <AviatrixLogo />,
    itemStyles: {
      bottomPosition: '-5px'
    }
  }
]
