import React, { FC, memo } from 'react'

import { Auth } from 'widgets/Auth/Auth'
import { RightBasketContainer } from 'containers/BasketContainer'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { Header } from 'widgets/header'
import { CalendarMenu } from 'widgets/calendar-menu'
import { FooterOfPage } from 'widgets/footer-of-page'

import { LayoutProps } from '../../Layout.types'

import { StyledContent, StyledLayout } from './Calendar.styled'

export const LayoutCalendar: FC<LayoutProps> = memo(({ children }) => (
  <StyledLayout>
    <Auth />

    <Header />

    <CalendarMenu />

    <NotificationsPanel />

    <StyledContent>
      {children}
      <FooterOfPage />
    </StyledContent>

    <RightBasketContainer />
  </StyledLayout>
))
