import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'
import {
  BasketOutcome,
  BetEvent,
  BetOutcomeStatus,
  BetType
} from 'betweb-openapi-axios'
import {
  IBasketError,
  basketProviderActions,
  EBetTypes,
  EMakeBetStatus,
  selectBasketBetTypeTab,
  selectBasketHeaderTab,
  selectBasketMakeBetStatus,
  selectBasketOutcomesLength,
  selectDependentEventGroupsLength,
  selectExpressRate,
  selectIsDependedHighlightedOutcome,
  selectIsDependentOutcomes,
  selectOutcomeType,
  selectBasketAppliedCoupon
} from 'astra-core/containers/BasketProvider'
import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'
import {
  formatAmount as formatAmountCore,
  getIsEventPending,
  getIsEventEnded,
  formatAmountLocaleString
} from 'astra-core'
import {
  useBasketAvailableWidth,
  useContainerDimensions,
  useGetCurrencyIcon
} from 'astra-core/hooks'
import {
  selectCurrencyMaxPayout,
  selectCurrencyMinBet,
  selectOutcomeCategoryById,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useTheme } from 'styled-components'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { ETestIds } from 'astra-core/utils/testIds'

import {
  formatDate,
  getBetEventHomeAwayCompetitors,
  getEventHomeAwayCompetitors
} from 'shared/lib/events'
import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'
import { RootState } from 'shared/types/store'
import { OddModes } from 'pages/page-live-events/Line/Line.types'
import { useOddHighlight } from 'hooks'
import { IconSortAsc } from 'shared/ui/Icon/General/IconSortAsc'
import { IconBin } from 'shared/ui/Icon/General/IconBin'
import { IconDependentOutcomes } from 'shared/ui/Icon/General/IconDependentOutcomes'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { ERoutes } from 'shared/types/routes'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { IconExtraHint } from 'shared/ui/Icon/General/IconExtraHint'
import { EButtonViews } from 'shared/ui/Button'
import {
  getEventStatisticScoreByPeriod,
  getEventStatisticScoreCurrent
} from 'shared/lib/statistics'
import { IconInfoCircle } from 'shared/ui/Icon/General/IconInfoCircle'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { Tooltip } from 'shared/ui/Tooltip'
import { SelectBetTypes } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Filter/components'

import { EBasketHeaderTypes } from '../Basket.types'
import { SingleBetAmount } from '../BasketBody/Ordinar/Ordinar'
import { BASKET_ERROR_ACTIONS } from '../constants'

import { BasketBetCardHistoryStatistic } from './components'
import {
  BASKET_INPUT_WIDTH_WITHOUT_EMPTY_SPACE,
  getIconArrowColorProps,
  iconCoefficientChangeColorProps,
  showIconCoefficientChangeList
} from './constants'
import {
  BasketButtonProps,
  BasketErrorBottomProps,
  BasketInputProps,
  BasketOutcomeSingleErrorProps,
  BetCardCouponProps,
  BetCardElementProps,
  BetCardHistoryProps,
  CommonBlockUnderHeadProps,
  HoverMaxBetTextProps,
  HoverTextProps,
  ICustomTopLayerAboveInput,
  InputMaxBetProps,
  MapNewBetListItemsParams,
  MaxBetHoverTextProps,
  PossibleWinningSumProps,
  TotalRateProps
} from './Common.types'
import {
  SingleBetAmountWrapper,
  StyledBasketButtonClearAll,
  StyledBasketButtonSingleBetSize,
  StyledBasketButtonWrapper,
  StyledBasketErrorAction,
  StyledBasketErrorText,
  StyledBasketErrorWrapper,
  StyledBasketInput,
  StyledBetCardCouponWrapper,
  StyledBetCardElementBorder,
  StyledBetCardElementWrapper,
  StyledBetCardEventPending,
  StyledBetCardEventUnavailableText,
  StyledBetCardHistoryLeftSide,
  StyledBetCardHistoryWrapper,
  StyledBetCardIcon,
  StyledBetCardLeftSide,
  StyledBetCardOdd,
  StyledBetCardOddWrapper,
  StyledBetCardRightSide,
  StyledBetCardWrapper,
  StyledBetPrevOdd,
  StyledButton,
  StyledButtonText,
  StyledButtonWrapper,
  StyledCleanBasketWrapper,
  StyledCommonBlockClearAllButtonsWrapper,
  StyledCommonBlockClearAllWrapper,
  StyledCustomTopLayerAboveInputWrapper,
  StyledCustomTopLayerInputValue,
  StyledCustomTopLayerInputValueWrapper,
  StyledCustomTopLayerMaxBetWrapper,
  StyledCustomTopLayerWinSum,
  StyledEventType,
  StyledEventTypeWrapper,
  StyledHoverMaxBetText,
  StyledHoverMaxBetTextTriangle,
  StyledHoverMaxBetTextWrapper,
  StyledHoverText,
  StyledHoverTextWrapper,
  StyledIconArrow,
  StyledIconDependentOutcomes,
  StyledIconExtraHintWrapper,
  StyledIconWrapper,
  StyledInfoIconWrapper,
  StyledInputMaxBet,
  StyledLink,
  StyledLoaderEventPendingContainer,
  StyledLoaderEventPendingWrapper,
  StyledMaxBetHoverText,
  StyledMaxBetHoverTextWrapper,
  StyledMaxBetHoverTriangle,
  StyledPossibleWinningSum,
  StyledPossibleWinningSumText,
  StyledPossibleWinningSumWrapper,
  StyledRemoveText,
  StyledTeamName,
  StyledText,
  StyledTime,
  StyledTotalRate,
  StyledTotalRateBonusSum,
  StyledTotalRateBonusWrapper,
  StyledTotalRateWrapper
} from './Common.styled'
import { BasketBonus } from './components/BasketBonus'

export const CommonBlockUnderHead: FC<CommonBlockUnderHeadProps> = memo(
  ({ historyTab, leftText, rightText, wideBorder = false }) => {
    const [t] = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const headerTab = useSelector(selectBasketHeaderTab)

    const historySelect = useMemo(
      () => ({
        betsHistory: () => {
          dispatch(
            betsHistoryBasketContainerActions.setInitialStateBetsHistoryBasket()
          )
          dispatch(betsHistoryProviderActions.clearFilterBetsTypes())

          return history.push(ERoutes.BetsHistory)
        }
      }),
      [dispatch, history]
    )

    const deleteBasket = useCallback(() => {
      dispatch(basketProviderActions.deleteBasket())
    }, [dispatch])

    const leftHeaderTabClick = useCallback(
      () =>
        headerTab === EBasketHeaderTypes.History
          ? historySelect.betsHistory()
          : deleteBasket(),
      [headerTab, historySelect, deleteBasket]
    )

    return (
      <StyledCleanBasketWrapper wideBorder={wideBorder}>
        {historyTab && !!leftText && <SelectBetTypes />}
        <StyledButton oneRightButton={!leftText} onClick={leftHeaderTabClick}>
          <StyledText>{t(rightText)}</StyledText>
        </StyledButton>
      </StyledCleanBasketWrapper>
    )
  }
)
const RightPartViewByBetType = ({ toggleSingleBetPanel }) => {
  const [t] = useTranslation()
  const expressRate = useSelector(selectExpressRate)

  return {
    [EBetTypes.Ordinar]: (
      <StyledBasketButtonSingleBetSize
        id={ETestIds.TestBasketButtonSingleBetSize}
        view={EButtonViews.SECONDARY}
        onClick={toggleSingleBetPanel}
      >
        {t('a single bet size')}
      </StyledBasketButtonSingleBetSize>
    ),
    [EBetTypes.Express]: <TotalRate coefficient={expressRate} />,
    [EBetTypes.System]: null
  }
}

export const CommonBlockClearAll = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [isShownSingleBetPanel, setIsShownSingleBetPanel] = useState(false)
  const betTypeTab = useSelector(selectBasketBetTypeTab)

  const toggleSingleBetPanel = useCallback(() => {
    setIsShownSingleBetPanel((prev) => !prev)
  }, [])

  const deleteBasket = useCallback(() => {
    dispatch(basketProviderActions.deleteBasket())
  }, [dispatch])

  return (
    <StyledCommonBlockClearAllWrapper
      isShownSingleBetPanel={isShownSingleBetPanel}
    >
      <StyledCommonBlockClearAllButtonsWrapper>
        <StyledBasketButtonClearAll
          id={ETestIds.TestBasketButtonClearAll}
          view={EButtonViews.SECONDARY}
          onClick={deleteBasket}
        >
          {t('clear all')}
        </StyledBasketButtonClearAll>
        {RightPartViewByBetType({ toggleSingleBetPanel })[betTypeTab]}
      </StyledCommonBlockClearAllButtonsWrapper>

      {isShownSingleBetPanel && (
        <SingleBetAmountWrapper>
          <SingleBetAmount />
        </SingleBetAmountWrapper>
      )}
    </StyledCommonBlockClearAllWrapper>
  )
}

export const BetCardCoupon: FC<BetCardCouponProps> = memo(({ outcome }) => {
  const [t] = useTranslation()
  const [isHover, setIsHover] = useState<boolean>(false)
  const { event, probability, id: outcomeId, status } = outcome
  const { tradingStatus, odd } = probability
  const { status: eventStatus, id: eventId } = event
  const outcomeType = useSelector((state: RootState) =>
    selectOutcomeType(state, probability.outcomeTypeId)
  )
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcomeType?.categoryId)
  )
  const isShowIconDependentOutcomes = useSelector((state: RootState) =>
    selectIsDependentOutcomes(state, { status: eventStatus, id: eventId })
  )
  const dependentEventGroups = useSelector(selectDependentEventGroupsLength)
  const isDependedHighlightedOutcome = useSelector((state: RootState) =>
    selectIsDependedHighlightedOutcome(state, eventId)
  )

  const { name: sportName } =
    useSelector((state: RootState) =>
      selectSport(state, event.tournament.sportId)
    ) || {}

  const { mode, prevOdd } = useOddHighlight(probability)

  const dispatch = useDispatch()

  const isEventEnded = useMemo(
    () => getIsEventEnded(eventStatus, tradingStatus),
    [eventStatus, tradingStatus]
  )

  const isEventPending = useMemo(
    () => getIsEventPending(status, eventStatus, tradingStatus),
    [eventStatus, status, tradingStatus]
  )

  const toggleIsHover = useCallback(
    (flag: boolean) => {
      if (!isEventEnded) {
        setIsHover(flag)
      }
    },
    [isEventEnded]
  )

  const onMouseEnter = () => toggleIsHover(true)
  const onMouseLeave = () => toggleIsHover(false)

  const deleteBasketOutcome = useCallback(() => {
    dispatch(basketProviderActions.deleteBasketOutcome({ outcomeId }))
  }, [dispatch, outcomeId])

  const showPrevOdd = useMemo(() => odd !== prevOdd, [odd, prevOdd])

  const competitors = useMemo(() => {
    const { homeCompetitors, awayCompetitors } = getEventHomeAwayCompetitors(
      event!
    )

    return [...homeCompetitors, ...awayCompetitors]
  }, [event])

  const highlightDependentOutcomes = useCallback(() => {
    if (dependentEventGroups) {
      dispatch(basketProviderActions.highlightDependentOutcomes({ eventId }))
    }
  }, [dispatch, eventId, dependentEventGroups])

  const doNotHighlightDependentOutcomes = useCallback(() => {
    if (dependentEventGroups) {
      dispatch(basketProviderActions.doNotHighlightDependentOutcomes())
    }
  }, [dispatch, dependentEventGroups])

  const basketOutcomeName = useMemo(() => {
    return outcomeType
      ? getBasketOutcomeName({
          probWithOutcome: {
            ...probability,
            outcomeTypeData: outcomeType
          },
          event,
          outcomeCategory
        })
      : ''
  }, [event, outcomeCategory, outcomeType, probability])

  return (
    <>
      <StyledBetCardCouponWrapper
        isDependedHighlightedOutcome={isDependedHighlightedOutcome}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Tooltip title={`${sportName}. ${event.tournament.name}`}>
          <StyledBetCardLeftSide
            isEventEnded={isEventEnded}
            isEventPending={isEventPending}
          >
            {competitors.map((competitor) => (
              <StyledTeamName key={competitor.id}>
                {competitor.name}
              </StyledTeamName>
            ))}
            <StyledEventTypeWrapper>
              <IconInfoCircle
                colorProps={{
                  type: EColorsTypes.CUSTOM,
                  name: EColorsNames.Primary,
                  value: 9
                }}
              />
              <StyledEventType>{basketOutcomeName}</StyledEventType>
            </StyledEventTypeWrapper>
          </StyledBetCardLeftSide>
        </Tooltip>

        <StyledBetCardRightSide>
          <StyledBetCardWrapper>
            {showIconCoefficientChangeList.includes(mode) && (
              <IconSortAsc
                colorProps={iconCoefficientChangeColorProps[mode]}
                twist={mode === OddModes.Decrease ? 180 : 0}
              />
            )}
            <StyledBetCardOddWrapper
              isEventEnded={isEventEnded}
              isEventPending={isEventPending}
            >
              <StyledBetCardOdd $mode={mode}>{odd}</StyledBetCardOdd>
              {showPrevOdd && <StyledBetPrevOdd>{prevOdd}</StyledBetPrevOdd>}
            </StyledBetCardOddWrapper>
            {isHover && (
              <StyledBetCardIcon
                id={ETestIds.TestBasketBetCardHoverIcon}
                onClick={deleteBasketOutcome}
              >
                <IconBin />
              </StyledBetCardIcon>
            )}
          </StyledBetCardWrapper>
          {isShowIconDependentOutcomes && (
            <StyledIconDependentOutcomes
              onMouseEnter={highlightDependentOutcomes}
              onMouseLeave={doNotHighlightDependentOutcomes}
            >
              <IconDependentOutcomes />
            </StyledIconDependentOutcomes>
          )}
        </StyledBetCardRightSide>
      </StyledBetCardCouponWrapper>

      {isEventEnded ? (
        <StyledBetCardEventUnavailableText>
          <Trans
            components={[<StyledRemoveText onClick={deleteBasketOutcome} />]}
            i18nKey={ALL_BASKET_ERROR_MESSAGES.EVENT_IS_OVER_DELETE}
            t={t}
          />
        </StyledBetCardEventUnavailableText>
      ) : isEventPending ? (
        <StyledBetCardEventPending>
          <LoaderEventPending />
        </StyledBetCardEventPending>
      ) : null}
    </>
  )
})

export const CustomTopLayerAboveInput: FC<ICustomTopLayerAboveInput> = ({
  makeInputFocus,
  stakeAmount,
  winSum,
  maxBet
}) => {
  const currencyIcon = useGetCurrencyIcon()
  const [isShownHint, setIsShownHint] = useReducer((_, type) => type, false)
  const maxPayout = useSelector(selectCurrencyMaxPayout)
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)

  const inputValue = useMemo(() => {
    const value = stakeAmount || 0

    return appliedCoupon
      ? ''
      : formatAmountCore({ value, currency: currencyIcon })
  }, [currencyIcon, stakeAmount, appliedCoupon])

  const [isShownHintIcon, setMaxBetBlockWidth, measuredRef] =
    useBasketAvailableWidth(inputValue, BASKET_INPUT_WIDTH_WITHOUT_EMPTY_SPACE)

  const sumWin = useMemo(() => {
    const sum = winSum > maxPayout ? maxPayout : winSum
    const value = appliedCoupon ? stakeAmount : sum.toFixed(2)

    return formatAmountCore({
      value,
      currency: currencyIcon
    })
  }, [currencyIcon, winSum, maxPayout, stakeAmount, appliedCoupon])

  return (
    <>
      {isShownHint && <HoverMaxBetText maxBet={maxBet} />}
      <StyledCustomTopLayerAboveInputWrapper
        betTypeTab={betTypeTab}
        isAppliedCoupon={!!appliedCoupon}
        onClick={makeInputFocus}
      >
        <StyledCustomTopLayerMaxBetWrapper>
          {isShownHintIcon ? (
            <StyledIconExtraHintWrapper
              onMouseEnter={() => setIsShownHint(true)}
              onMouseLeave={() => setIsShownHint(false)}
            >
              <IconExtraHint
                colorProps={{
                  type: EColorsTypes.DEFAULT,
                  name: EColorsNames.Primary,
                  value: 30
                }}
              />
            </StyledIconExtraHintWrapper>
          ) : (
            <InputMaxBet
              maxBet={maxBet}
              setMaxBetBlockWidth={setMaxBetBlockWidth}
            />
          )}
        </StyledCustomTopLayerMaxBetWrapper>

        <StyledCustomTopLayerInputValueWrapper ref={measuredRef}>
          <StyledCustomTopLayerInputValue>
            {inputValue}
          </StyledCustomTopLayerInputValue>
          {!!winSum && (
            <StyledCustomTopLayerWinSum isAppliedCoupon={!!appliedCoupon}>
              <StyledIconArrow
                colorProps={getIconArrowColorProps(!!appliedCoupon)}
              />
              {sumWin}
            </StyledCustomTopLayerWinSum>
          )}
        </StyledCustomTopLayerInputValueWrapper>
      </StyledCustomTopLayerAboveInputWrapper>
    </>
  )
}

export const LoaderEventPending = memo(() => {
  const { colors } = useTheme()

  return (
    <StyledLoaderEventPendingWrapper>
      <StyledLoaderEventPendingContainer>
        <LoaderPoints
          colors={{
            start: colors[EColorsTypes.DEFAULT].primary[90],
            end: colors[EColorsTypes.CUSTOM].primary[26]
          }}
          settingsAnimation={{
            timeAfterEnd: 2.4,
            scaleActivePoint: 1
          }}
          count={3}
          margin={2}
          size={4}
        />
      </StyledLoaderEventPendingContainer>
    </StyledLoaderEventPendingWrapper>
  )
})

export const BetCardHistory: FC<BetCardHistoryProps> = ({ outcome }) => {
  const { coefficient, status, event, live: isOutcomeOfLive } = outcome
  const { basicStatistics } = outcome.event || {}
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcome.outcomeCategoryId)
  )
  // TODO team names etc.
  const { homeCompetitors, awayCompetitors } = getBetEventHomeAwayCompetitors(
    event as BetEvent
  )

  const date = event?.eventDate ? formatDate(event?.eventDate) : ''

  const outcomeTypeData = useSelector((state: RootState) =>
    selectOutcomeType(state, outcome.outcomeTypeId)
  )

  const basketOutcomeName = useMemo(
    () =>
      getBasketOutcomeName({
        probWithOutcome: { ...outcome, outcomeTypeData: outcomeTypeData! },
        event: event!,
        isBetOutcome: true,
        outcomeCategory
      }),
    [event, outcome, outcomeCategory, outcomeTypeData]
  )

  const isDisplayStatistic =
    isOutcomeOfLive || (!isOutcomeOfLive && status === BetOutcomeStatus.Win)

  const scoresByPeriod = getEventStatisticScoreByPeriod(
    basicStatistics?.scoresByPeriod
  )
  const currentScore = getEventStatisticScoreCurrent(
    basicStatistics?.currentScore
  )

  const hintAttrDescription = useMemo(
    () =>
      getHintAttrNameFormatted(outcome.recalculationReason?.message || '') ||
      {},
    [outcome.recalculationReason?.message]
  )

  return (
    <StyledBetCardHistoryWrapper>
      <StyledBetCardHistoryLeftSide>
        <StyledTeamName>{homeCompetitors[0]?.name}</StyledTeamName>
        <StyledTeamName>{awayCompetitors[0]?.name}</StyledTeamName>
        <StyledEventType>{basketOutcomeName}</StyledEventType>

        {isDisplayStatistic ? (
          <BasketBetCardHistoryStatistic
            currentScore={currentScore}
            scoresByPeriod={scoresByPeriod}
          />
        ) : (
          <StyledTime>{date}</StyledTime>
        )}
      </StyledBetCardHistoryLeftSide>

      <StyledBetCardRightSide>
        {outcome.recalculationReason?.id && (
          <StyledInfoIconWrapper {...hintAttrDescription}>
            <IconInfoCircle
              colorProps={{
                type: EColorsTypes.FIXED,
                name: EColorsNames.Yellow,
                value: 49
              }}
            />
          </StyledInfoIconWrapper>
        )}
        <StyledBetCardOdd>{coefficient}</StyledBetCardOdd>
      </StyledBetCardRightSide>
    </StyledBetCardHistoryWrapper>
  )
}

// TODO we will take this value from back end in the future
const MAX_WIN_SUM = 100000000

export const PossibleWinningSum: FC<PossibleWinningSumProps> = memo(
  ({ winSum }) => {
    const [t] = useTranslation()
    const currencyIcon = useGetCurrencyIcon()

    const possibleWinSum = useMemo(
      () =>
        formatAmountLocaleString({
          value: winSum < MAX_WIN_SUM ? winSum : MAX_WIN_SUM
        }),
      [winSum]
    )

    return (
      <StyledPossibleWinningSumWrapper>
        <StyledPossibleWinningSumText>
          {t('possibility gain full')} ({currencyIcon})
        </StyledPossibleWinningSumText>
        <StyledPossibleWinningSum>{possibleWinSum}</StyledPossibleWinningSum>
      </StyledPossibleWinningSumWrapper>
    )
  }
)

export const BasketButton: FC<BasketButtonProps> = memo(
  ({ disabledButton }) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const makeBetStatus = useSelector(selectBasketMakeBetStatus)
    const outcomesLength = useSelector(selectBasketOutcomesLength)
    const appliedCoupon = useSelector(selectBasketAppliedCoupon)

    const makeBet = useCallback(() => {
      dispatch(basketProviderActions.makeBet({}))
    }, [dispatch])

    const isLoading = useMemo(
      () => EMakeBetStatus.loading === makeBetStatus,
      [makeBetStatus]
    )

    const isAddedExtraSpace = useMemo(
      () => outcomesLength === 1,
      [outcomesLength]
    )

    return (
      <>
        <BasketBonus />

        <StyledButtonWrapper
          disabled={disabledButton}
          id={ETestIds.TestBasketButtonMakeABet}
          isAddedExtraSpace={isAddedExtraSpace}
          isAppliedCoupon={!!appliedCoupon}
          onClick={makeBet}
        >
          {isLoading ? (
            <StyledBasketButtonWrapper>
              <LoaderPoints />
            </StyledBasketButtonWrapper>
          ) : (
            <StyledButtonText
              disabled={disabledButton}
              isAppliedCoupon={!!appliedCoupon}
            >
              {t('make a bet')}
            </StyledButtonText>
          )}
        </StyledButtonWrapper>
      </>
    )
  }
)

// TODO add real data
export const TotalRate: FC<TotalRateProps> = memo(({ coefficient = 0 }) => {
  const bonus = false

  return (
    <StyledTotalRateWrapper>
      {bonus && (
        <StyledTotalRateBonusWrapper>
          <StyledTotalRateBonusSum>BONUS 1.02</StyledTotalRateBonusSum>
        </StyledTotalRateBonusWrapper>
      )}
      <StyledTotalRate>{coefficient.toFixed(2)}</StyledTotalRate>
    </StyledTotalRateWrapper>
  )
})

export const BetCardElement: FC<BetCardElementProps> = memo(({ outcome }) => (
  <StyledBetCardElementWrapper>
    <BetCardCoupon outcome={outcome} />
    <StyledBetCardElementBorder />
  </StyledBetCardElementWrapper>
))

export const HoverText: FC<HoverTextProps> = ({ text }) => (
  <StyledHoverTextWrapper>
    <StyledHoverText>{text}</StyledHoverText>
  </StyledHoverTextWrapper>
)

export const BasketOutcomeSingleError: FC<BasketOutcomeSingleErrorProps> = ({
  message
}) => {
  const [t] = useTranslation()

  return (
    <StyledBasketErrorWrapper>
      <StyledIconWrapper>
        <IconInfoCircleFilled />
      </StyledIconWrapper>
      <StyledBasketErrorText>{t(message)}</StyledBasketErrorText>
    </StyledBasketErrorWrapper>
  )
}

const getBasketErrorTrans = (message: IBasketError['message']) => {
  if (message === ALL_BASKET_ERROR_MESSAGES.BETS_INSUFFICIENT_BALANCE_CODE) {
    return BASKET_ERROR_ACTIONS[message]?.map(({ id, action }) => (
      <StyledLink key={id} to={action} />
    ))
  } else if (message === ALL_BASKET_ERROR_MESSAGES.CLIENT_BETS_BLOCK) {
    return BASKET_ERROR_ACTIONS[message]?.map(({ id, action }) => (
      <StyledBasketErrorAction key={id} onClick={action} />
    ))
  } else {
    return []
  }
}

export const BasketError: FC<BasketErrorBottomProps> = ({ error }) => {
  const [t] = useTranslation()
  const { message } = error

  return (
    <StyledBasketErrorWrapper>
      <StyledIconWrapper>
        <IconInfoCircleFilled />
      </StyledIconWrapper>
      <StyledBasketErrorText>
        <Trans
          components={getBasketErrorTrans(message)}
          i18nKey={message}
          t={t}
        />
      </StyledBasketErrorText>
    </StyledBasketErrorWrapper>
  )
}

export const BasketInput: FC<BasketInputProps> = ({
  value,
  inputRef,
  testId,
  onChange
}) => {
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)

  return (
    <StyledBasketInput
      betTypeTab={betTypeTab}
      disabled={!!appliedCoupon}
      id={testId}
      inputRef={inputRef}
      isAppliedCoupon={!!appliedCoupon}
      value={value}
      onChange={onChange}
    />
  )
}

export const InputMaxBet: FC<InputMaxBetProps> = ({
  maxBet,
  setMaxBetBlockWidth
}) => {
  const minBet = useSelector(selectCurrencyMinBet)
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)

  const [dimensions, inputMaxBetRef] = useContainerDimensions()

  useEffect(() => {
    if (dimensions?.width) {
      setMaxBetBlockWidth(dimensions.width)
    }
  }, [dimensions, setMaxBetBlockWidth])

  const minMaxBet = useMemo(() => {
    return appliedCoupon
      ? ''
      : `${formatAmountLocaleString({
          value: minBet
        })} - ${formatAmountLocaleString({
          value: maxBet
        })}`
  }, [minBet, maxBet, appliedCoupon])

  return <StyledInputMaxBet ref={inputMaxBetRef}>{minMaxBet}</StyledInputMaxBet>
}

export const MaxBetHoverText: FC<MaxBetHoverTextProps> = ({ text }) => (
  <StyledMaxBetHoverTextWrapper>
    <StyledMaxBetHoverTriangle />
    <StyledMaxBetHoverText>{text}</StyledMaxBetHoverText>
  </StyledMaxBetHoverTextWrapper>
)

const transformOutcomes = (outcomes: BasketOutcome[]) =>
  outcomes.map((outcome) => ({
    outcomeId: outcome.id,
    coefficient: outcome.probability.odd
  }))

export const mapNewBetListItems = ({
  outcomes,
  systemSize,
  betAmount,
  betType
}: MapNewBetListItemsParams) => [
  {
    outcomes: transformOutcomes(outcomes),
    systemSize,
    bet: betAmount,
    betType: BetType[betType]
  }
]

export const HoverMaxBetText: FC<HoverMaxBetTextProps> = ({ maxBet }) => {
  const minBet = useSelector(selectCurrencyMinBet)

  const minMaxBet = useMemo(
    () =>
      `${formatAmountLocaleString({
        value: minBet
      })} - ${formatAmountCore({
        value: maxBet
      })}`,
    [minBet, maxBet]
  )

  return (
    <StyledHoverMaxBetTextWrapper>
      <StyledHoverMaxBetText>{minMaxBet}</StyledHoverMaxBetText>
      <StyledHoverMaxBetTextTriangle />
    </StyledHoverMaxBetTextWrapper>
  )
}
