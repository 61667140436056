import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledEventWrapper = styled.div`
  padding: 16px 0 0;
`

export const StyledEventHead = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  cursor: pointer;
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: 16px 1fr max-content;
  padding: 4px 8px 16px 16px;
`

export const StyledEventTitle = styled.span`
  font-size: ${(props) => props.theme.fonts.size.s};
  line-height: 16px;
`

export const StyledHeadWrapper = styled.div.attrs(
  (props: { isFixed?: boolean }) => props
)`
  ${(props) => css`
    background-color: ${props.theme.colors.default.primary[0]};
    border-bottom: 1px solid;
    border-color: ${props.theme.colors.default.primary[5]};

    ${props.isFixed &&
    css`
      padding-top: 16px;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 99;
    `};
  `}
`
