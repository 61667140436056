import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconStatFootballYellowCard: FC<IconProps> = (props) => (
  <Icon size={16} {...props}>
    <svg fill="none" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#FFDB66" height="14" rx="2" width="10" x="0.5" />
    </svg>
  </Icon>
)
