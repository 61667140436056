import React from 'react'

import { ERoutes } from 'shared/types/routes'
import { ETurboGamesIds } from 'pages/page-games-turbo/TurboGames/types'

import { ESponsorsName } from '../constants'

import {
  AviatorBackground,
  AviatorLogo,
  AviatrixBackground,
  AviatrixLogo,
  BetGamesBackground,
  BetGamesLogo,
  FastGamesBackground,
  FastGamesLogo,
  LiveGamesBackground,
  LiveGamesLogo,
  SportGamesBackground,
  SportGamesLogo,
  TwainsportBackground,
  TwainsportLogo
} from './SvgImages'
import * as aviatorMainImg from './JsonImages/aviator.json'
import * as aviatorMainMiddleImg from './JsonImages/aviator_middle.json'
import * as betGamesMainImg from './JsonImages/betgames.json'
import * as twainsportMainImg from './JsonImages/twainsport.json'
import * as sportGamesMainImg from './JsonImages/sportgames.json'
import * as liveGamesMainImg from './JsonImages/livegames.json'
import * as fastGamesMainImg from './JsonImages/fastgames.json'
import * as fastGamesMainMiddleImg from './JsonImages/fastgames_middle.json'
import * as aviatrixMainBigImg from './JsonImages/aviatrix.json'
import * as aviatrixMainMiddleImg from './JsonImages/aviatrix_middle.json'
import * as sportGamesAdditionalImg from './JsonImages/bgsportgames.json'

export const getLottieDefaultImgOptions = (animationData) => ({
  loop: false,
  autoplay: false,
  animationData
})

export const BANNER_HIGHT = '72px'

export const SPONSORS_LIST_HIGH_RESOLUTION = (sponsorsWrapperWidth: number) => {
  const isWideScreen = sponsorsWrapperWidth > 1150

  return [
    {
      url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
      name: ESponsorsName.AVIATOR,
      backgroundImg: <AviatorBackground />,
      mainImg: isWideScreen ? aviatorMainImg : aviatorMainMiddleImg,
      logoImg: <AviatorLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '7px' : '10px'
      }
    },
    {
      url: ERoutes.BetGames,
      name: ESponsorsName.BET_GAMES,
      backgroundImg: <BetGamesBackground />,
      mainImg: betGamesMainImg,
      logoImg: <BetGamesLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '7px' : '10px',
        rightPosition: '-10px'
      }
    },
    {
      url: ERoutes.TwainGames,
      name: ESponsorsName.TWAIN_SPORT,
      backgroundImg: <TwainsportBackground />,
      mainImg: twainsportMainImg,
      logoImg: <TwainsportLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '10px' : '15px',
        rightPosition: '-10px'
      }
    },
    {
      url: ERoutes.SportGamesLobby,
      name: ESponsorsName.SPORT_GAMES,
      backgroundImg: <SportGamesBackground />,
      mainImg: sportGamesMainImg,
      logoImg: <SportGamesLogo />,
      additionalImg: sportGamesAdditionalImg,
      itemStyles: {
        topPosition: isWideScreen ? '10px' : '12px',
        topAdditionalImgPosition: isWideScreen ? '0' : '-10px'
      }
    },
    {
      url: ERoutes.LiveGames,
      name: ESponsorsName.LIVE_GAMES_37,
      backgroundImg: <LiveGamesBackground />,
      mainImg: liveGamesMainImg,
      logoImg: <LiveGamesLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '10px' : '12px'
      }
    },
    {
      url: ERoutes.TurboGames,
      name: ESponsorsName.FAST_GAMES,
      backgroundImg: <FastGamesBackground />,
      mainImg: isWideScreen ? fastGamesMainImg : fastGamesMainMiddleImg,

      logoImg: <FastGamesLogo />
    },
    {
      url: ERoutes.SportGamesLobby,
      name: ESponsorsName.AVIATRIX,
      backgroundImg: <AviatrixBackground />,
      mainImg: isWideScreen ? aviatrixMainBigImg : aviatrixMainMiddleImg,
      logoImg: <AviatrixLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '7px' : '10px'
      }
    }
  ]
}
