import React, { FC, useMemo } from 'react'
import { getColor } from 'astra-core/utils/sportColors'
import { useSelector } from 'react-redux'
import { LayoutTheme } from 'betweb-openapi-axios'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'

import { Icon } from 'shared/ui/Icon/Icon'

import { ISportIconWrapperProps } from './SportIcon.types'

export const SportIconWrapper: FC<ISportIconWrapperProps> = ({
  children,
  withBackground,
  isActive,
  sport,
  backgroundColor,
  ...props
}) => {
  const typeTheme = useSelector(selectUserSettingLayoutTheme)
  const { main, background } = getColor(sport, typeTheme)

  const mainColor = useMemo(
    () => (isActive ? main : 'currentColor'),
    [main, isActive]
  )

  const mainBackgroundColor = useMemo(() => {
    if (typeTheme === LayoutTheme.Light) {
      return isActive ? background : backgroundColor
    } else {
      return backgroundColor
    }
  }, [typeTheme, isActive, background, backgroundColor])

  return (
    <Icon {...props}>
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        {withBackground && (
          <SportIconBackground fillColor={mainBackgroundColor} />
        )}

        {children({ mainColor })}
      </svg>
    </Icon>
  )
}

const SportIconBackground = ({ fillColor }) => (
  <path
    d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
    fill={fillColor}
  />
)
