import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import { ContainerState, FetchEventPayload } from './types'

const REDUCER_KEY = 'eventContainer'

// The initial state of the EventContainer container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  fetchItem: requestInitialState
})

const eventContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchEvent(state, _action: PayloadAction<FetchEventPayload>) {
      state.fetchItem.loading = true
      state.fetchItem.error = null
    },
    fetchEventSuccess(state) {
      state.fetchItem.loading = false
      state.fetchItem.error = null
    },
    fetchEventError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItem.loading = false
      state.fetchItem.error = action.payload
    }
  }
})

export const {
  actions,
  actions: eventContainerActions,
  reducer,
  name: sliceKey
} = eventContainerSlice
