import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportBasketball: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M19.85 10.44C19.24 10.54 18.75 10.77 18.35 11.11C18.11 11.32 17.9 11.61 17.71 11.92C18.46 12.81 19.06 13.83 19.45 14.92C19.8 14.02 20 13.03 20 12C20 11.47 19.95 10.94 19.85 10.44ZM16.21 5.2C16.14 6.83 15.89 8.42 15.48 9.92C16.03 10.29 16.54 10.71 17 11.16C17.21 10.86 17.44 10.58 17.7 10.36C18.22 9.91 18.84 9.61 19.59 9.48C19 7.69 17.79 6.17 16.21 5.2ZM17.24 12.92C16.73 14.31 16.48 16.25 16.35 18.71C17.34 18.08 18.17 17.23 18.79 16.23C18.52 15.02 17.98 13.91 17.24 12.92ZM15.15 10.91C14.39 13.2 13.25 15.28 11.74 16.96C10.84 17.95 9.86 18.76 8.83 19.35C9.8 19.77 10.88 20 12 20C13.19 20 14.32 19.74 15.33 19.28C15.46 16.1 15.77 13.71 16.5 12.07C16.09 11.64 15.64 11.26 15.15 10.91ZM12 4C10.94 4 9.93 4.21 9 4.59C11.2 5.36 12.54 6.79 12.55 8.51C12.55 8.52 12.55 8.52 12.55 8.52C13.26 8.75 13.94 9.04 14.58 9.38C14.97 7.88 15.19 6.3 15.23 4.68C14.24 4.24 13.15 4 12 4ZM12.38 9.52C11.79 11.37 9.45 13.38 4.42 14.54C5 16.31 6.2 17.8 7.75 18.77C8.9 18.23 10 17.39 11 16.29C12.47 14.66 13.56 12.62 14.28 10.36C13.69 10.02 13.05 9.74 12.38 9.52ZM7.71 5.25C6.37 6.1 5.3 7.34 4.66 8.81C6 8.28 7.47 7.98 9.02 7.98C9.88 7.98 10.71 8.08 11.51 8.25C11.33 6.9 9.88 5.76 7.71 5.25ZM9.02 8.98C7.28 8.98 5.65 9.39 4.23 10.1C4.08 10.71 4 11.34 4 12C4 12.54 4.05 13.06 4.15 13.57C8.8 12.52 10.92 10.76 11.42 9.26C10.65 9.08 9.85 8.98 9.02 8.98Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
