import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportTableTennis: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <path
          d="M4.29311 17.26C3.90311 17.65 3.90311 18.2801 4.29311 18.6701L5.33311 19.7101C5.52211 19.9001 5.78311 20.0001 6.04311 20.0001C6.29311 20.0001 6.55311 19.9001 6.74311 19.7101L8.71311 17.74L6.26311 15.29L4.29311 17.26Z"
          fill={mainColor}
        />
        <path
          d="M18.2829 5.72C17.1329 4.58 15.633 4 14.133 4C12.633 4 11.133 4.58 9.98299 5.72L7.06299 8.64C5.43299 10.28 5.40299 12.9 6.97299 14.58V14.59L9.42299 17.04V17.03C10.243 17.8 11.283 18.19 12.3329 18.19C13.433 18.19 14.522 17.78 15.3629 16.94L18.2829 14.02C20.572 11.73 20.572 8.01 18.2829 5.72Z"
          fill={mainColor}
        />
        <path
          d="M5.983 8.02002C7.088 8.02002 7.983 7.12502 7.984 6.02102C7.984 4.91702 7.089 4.02102 5.984 4.02002C4.88 4.02002 4 4.91502 4 6.01902C4 7.12402 4.879 8.02002 5.983 8.02002Z"
          fill={mainColor}
        />
      </>
    )}
  </SportIconWrapper>
)
