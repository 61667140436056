import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportFieldHockey: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_6396_115626)">
          <ellipse
            cx="8.36263"
            cy="13.3333"
            fill={mainColor}
            rx="1.33333"
            ry="1.33333"
          />
          <path
            d="M17.326 4.54106L16.36 4.28224C16.36 4.28224 13.167 14.9104 12.8919 15.7589C12.6169 16.6075 12.2291 17.6818 11.5517 17.4885C10.8742 17.2951 11.4731 15.8861 10.9142 15.5914C10.3552 15.2968 9.48763 15.7644 9.28117 16.5342C9.0747 17.304 9.79621 19.4185 11.5517 19.4708C13.3071 19.523 13.8463 18.1308 14.1826 17.1614C14.519 16.192 17.326 4.54106 17.326 4.54106Z"
            fill={mainColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6396_115626">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
