import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballCorner: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg fill="none" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.5 1L5.5 14"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M6.5 2L12.3456 5.11765C13.0515 5.49412 13.0515 6.50588 12.3456 6.88235L6.5 10V2Z"
          fill="currentColor"
        />
        <path
          d="M1.5 15H9.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </Icon>
  )
}
