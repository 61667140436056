import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportLacrosse: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <>
        <g clipPath="url(#clip0_5210_95257)">
          <path
            clipRule="evenodd"
            d="M13.2687 4.00894C14.9362 3.86651 16.7988 5.4705 16.9983 5.64716C17.2056 5.8095 19.0886 7.32355 19.2638 8.95499C19.33 9.57039 19.157 10.125 18.7506 10.6036C17.9861 11.5025 17.0814 11.8756 16.2053 12.2365L16.2053 12.2365C15.3687 12.5814 14.5784 12.9072 13.9939 13.7032C12.8698 15.2316 11.2505 15.671 10.0899 15.7701C10.0829 15.7708 10.076 15.7712 10.0691 15.7717C10.0623 15.7722 10.0554 15.7727 10.0484 15.7733C8.80958 16.8392 7.75195 18.0194 7.05419 18.8611C6.56935 19.446 5.67185 19.515 5.13089 18.9797C4.64856 18.5023 4.64032 17.7263 5.1124 17.2405L8.10813 14.1579C8.08006 13.9768 8.07933 13.7674 8.08006 13.553C8.08293 12.1413 8.57183 10.8977 9.49279 9.9571C10.1845 9.25089 10.3737 8.42518 10.5739 7.5509L10.5744 7.54894C10.783 6.63369 10.9996 5.6868 11.7639 4.78795C12.1595 4.3225 12.666 4.06041 13.2687 4.00894ZM14.9299 15.5018C15.4322 15.0038 16.1015 14.7297 16.8122 14.7297C17.5237 14.7297 18.1921 15.0038 18.6953 15.5018C19.1984 15.9996 19.4753 16.6605 19.4753 17.3645C19.4753 18.0686 19.1984 18.731 18.6953 19.2282C18.1921 19.7253 17.5237 20 16.8122 20C16.1015 20 15.4322 19.7253 14.9299 19.2282C14.4267 18.731 14.1499 18.0686 14.1499 17.3645C14.1499 16.6605 14.4267 15.9996 14.9299 15.5018Z"
            fill={mainColor}
            fillRule="evenodd"
          />
        </g>

        <defs>
          <clipPath id="clip0_5210_95257">
            <rect
              fill="white"
              height="16"
              transform="translate(4 4)"
              width="16"
            />
          </clipPath>
        </defs>
      </>
    )}
  </SportIconWrapper>
)
