import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballShotOnGoal: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg fill="none" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5 1H1.5V13"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle cx="7.5" cy="7" fill="currentColor" r="4" />
      </svg>
    </Icon>
  )
}
