import { MainOutcomeTypes } from 'astra-core/containers/CommonDataProvider'

import { ITableColumnHeaderProps } from 'widgets/event-row'

export const COLUMNS_HEADERS_LIST: ITableColumnHeaderProps[] = [
  { outcomeTypesName: ['W1', 'W1_X2'], mainOutcomeType: MainOutcomeTypes.W1 },
  { outcomeTypesName: ['WX'], mainOutcomeType: MainOutcomeTypes.WX },
  { outcomeTypesName: ['W2', 'W2_X2'], mainOutcomeType: MainOutcomeTypes.W2 },
  { outcomeTypesName: ['W1X'], mainOutcomeType: MainOutcomeTypes.W1X },
  { outcomeTypesName: ['W12'], mainOutcomeType: MainOutcomeTypes.W12 },
  { outcomeTypesName: ['WX2'], mainOutcomeType: MainOutcomeTypes.WX2 },
  {
    outcomeTypesName: ['HC1'],
    mainOutcomeType: MainOutcomeTypes.HC1,
    withParam: true,
    isDoubleCell: true
  },
  {
    outcomeTypesName: ['HC2'],
    mainOutcomeType: MainOutcomeTypes.HC2,
    withParam: true,
    isDoubleCell: true
  },
  { outcomeTypesName: ['TO'], mainOutcomeType: MainOutcomeTypes.TO },
  { outcomeTypesName: ['TU'], mainOutcomeType: MainOutcomeTypes.TU }
]
