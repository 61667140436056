import React, { FC, useCallback, useMemo } from 'react'
import { OddsTableProps } from 'astrabet-templates-kit'
import { EventProbability, ParameterType } from 'betweb-openapi-axios'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { getOutcomeTestId } from 'astra-core/utils/testIds'

import {
  StyledOddsTable,
  CoefficientTableCellStyled,
  StyledTableWrapper,
  StyledHeaderCell,
  StyledTextCell,
  StyledTablePlainRow,
  StyledPlainWrapper,
  StyledPlainHeaderCell
} from './OddsTable.styled'
import { OddsDivider } from './OddsDivider'
import { TableCellProps } from './OddsTable.types'

const getArrayDepth = (value) =>
  Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0

const wrapTitle = (title: OddsTableProps['title']): string[][] =>
  getArrayDepth(title) === 2
    ? (title as string[][])
    : wrapTitle([title] as string | string[] | string[][])

const getOutcomeId = ({ outcomeTypeId, parameters = [] }: EventProbability) =>
  `${outcomeTypeId}-${sortBy(parameters, ['type'])
    .map((p) => p.value)
    .join('-')}`

export const OddsTable: FC<OddsTableProps> = ({
  rows,
  columns,
  title,
  withoutDivider,
  withoutTitle,
  probabilities,
  isPlain,
  isLast,
  ...props
}) => {
  const formatTitle = useCallback(
    (title?: string) => {
      const probsParams = keyBy(
        probabilities.map((p) => p.parameters).flat(),
        'type'
      )

      const result = Object.values(ParameterType).reduce((res, cur) => {
        res = res.replaceAll(`{$${cur}}`, probsParams[cur]?.value ?? '')
        return res
      }, title ?? '')

      return result
        .replaceAll(
          '{$competitor1}',
          props.competitors.homeCompetitors.map((c) => c.name).join(', ')
        )
        .replaceAll(
          '{$competitor2}',
          props.competitors.awayCompetitors.map((c) => c.name).join(', ')
        )
    },
    [
      probabilities,
      props.competitors.awayCompetitors,
      props.competitors.homeCompetitors
    ]
  )

  const formattedTitles = useMemo(
    () =>
      wrapTitle(title)
        .filter((titlesGroup) => titlesGroup !== undefined)
        .map((titlesGroup) =>
          titlesGroup
            .filter((title) => title !== undefined)
            .map((title) => formatTitle(title))
        ),
    [formatTitle, title]
  )

  if (!rows.length) {
    return null
  }

  return (
    <>
      {!withoutDivider && <OddsDivider />}
      <StyledTableWrapper>
        <StyledOddsTable columns={columns} isPlain={isPlain}>
          {!withoutTitle && !!formattedTitles.length
            ? formattedTitles.filter(Boolean).map((formattedTitle) =>
                formattedTitle.length
                  ? isPlain
                    ? formattedTitle[0] && (
                        <StyledPlainHeaderCell>
                          <>{formattedTitle[0]}</>
                        </StyledPlainHeaderCell>
                      )
                    : columns.map((column, index, array) => (
                        <StyledHeaderCell
                          dangerouslySetInnerHTML={{
                            __html: formattedTitle[index]
                          }}
                          isFirstInRow={index === 0}
                          isLastInRow={index === array.length - 1}
                          isOdd={index > 0 && column === 'odd'}
                        />
                      ))
                  : null
              )
            : null}
          <PlainWrapper isPlain={isPlain}>
            {rows.map((row, rowIndex) => {
              const key = row
                .map((item) => {
                  if (typeof item === 'string') {
                    return item
                  } else if (item) {
                    return getOutcomeId(item)
                  } else {
                    return rowIndex
                  }
                })
                .join('-')
              return isPlain ? (
                <StyledTablePlainRow key={key}>
                  {columns.map((column, columnIndex) => {
                    const cellValue = row[columnIndex]

                    const key =
                      typeof cellValue === 'string'
                        ? cellValue
                        : cellValue
                        ? getOutcomeId(cellValue)
                        : columnIndex

                    if (column === 'label') {
                      return null
                    }

                    return (
                      <TableCell
                        data={cellValue}
                        hideBorder={isLast && rowIndex === rows.length - 1}
                        isOdd={column === 'odd'}
                        key={key}
                        isPlain
                        {...props}
                      />
                    )
                  })}
                </StyledTablePlainRow>
              ) : (
                <React.Fragment key={key}>
                  {columns.map((column, index) => {
                    const cellValue = row[index]

                    const key =
                      typeof cellValue === 'string'
                        ? cellValue
                        : cellValue
                        ? getOutcomeId(cellValue)
                        : index

                    return (
                      <TableCell
                        data={cellValue}
                        hideBorder={isLast && rowIndex === rows.length - 1}
                        isOdd={column === 'odd'}
                        key={key}
                        {...props}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
          </PlainWrapper>
        </StyledOddsTable>
      </StyledTableWrapper>
    </>
  )
}

const PlainWrapper: FC<{ isPlain?: boolean }> = ({
  children,
  isPlain = false
}) => {
  return isPlain ? (
    <StyledPlainWrapper>{children}</StyledPlainWrapper>
  ) : (
    <>{children}</>
  )
}

const TableCell: FC<TableCellProps> = ({
  data,
  renderOdd,
  tournamentId,
  ...props
}) => {
  const marketOddTestId = getOutcomeTestId(
    data,
    tournamentId,
    props.categoryMarket.marketId
  )

  if (!data) {
    return <CoefficientTableCellStyled {...props} />
  }

  if (props.isOdd && typeof data !== 'string') {
    return (
      <CoefficientTableCellStyled id={marketOddTestId} {...props}>
        {renderOdd?.(data)}
      </CoefficientTableCellStyled>
    )
  }

  return (
    <CoefficientTableCellStyled {...props}>
      <StyledTextCell>{data}</StyledTextCell>
    </CoefficientTableCellStyled>
  )
}
