import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportDarts: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M15.8004 6.23186V7.35421L15.0339 8.12069C13.8668 7.14375 12.3631 6.55565 10.722 6.55565C7.00953 6.55565 4 9.56516 4 13.2776C4 16.99 7.00953 19.9995 10.722 19.9995C14.4344 19.9995 17.4439 16.99 17.4439 13.2776C17.4439 11.6365 16.8559 10.1328 15.8789 8.9657L16.6458 8.19886H17.7674C17.847 8.19886 17.9233 8.16725 17.9796 8.11099L19.7696 6.32097C19.9586 6.13198 19.8247 5.80884 19.5575 5.80884H18.4905C18.3248 5.80884 18.1905 5.67453 18.1905 5.50884V4.44184C18.1905 4.17457 17.8673 4.04072 17.6783 4.2297L15.8883 6.01972C15.832 6.07599 15.8004 6.15229 15.8004 6.23186ZM13.1201 10.0345C12.4498 9.53804 11.6202 9.24442 10.722 9.24442C8.4945 9.24442 6.68879 11.0501 6.68879 13.2776C6.68879 15.505 8.4945 17.3107 10.722 17.3107C12.9494 17.3107 14.7551 15.505 14.7551 13.2776C14.7551 12.3794 14.4615 11.5498 13.9651 10.8795L13.4296 11.415C13.7944 11.9444 14.008 12.5861 14.008 13.2776C14.008 15.0926 12.5367 16.5639 10.7217 16.5639C8.90676 16.5639 7.43544 15.0926 7.43544 13.2776C7.43544 11.4627 8.90676 9.99134 10.7217 9.99134C11.4134 9.99134 12.0552 10.205 12.5846 10.57L13.1201 10.0345ZM11.2678 11.8868C11.0987 11.8204 10.9145 11.7839 10.7217 11.7839C9.89675 11.7839 9.22796 12.4526 9.22796 13.2776C9.22796 14.1026 9.89675 14.7714 10.7217 14.7714C11.5467 14.7714 12.2155 14.1026 12.2155 13.2776C12.2155 13.085 12.1791 12.901 12.1127 12.7319L11.6165 13.2281C11.6174 13.2445 11.6178 13.261 11.6178 13.2776C11.6178 13.7726 11.2166 14.1739 10.7216 14.1739C10.2266 14.1739 9.8253 13.7726 9.8253 13.2776C9.8253 12.7826 10.2266 12.3814 10.7216 12.3814C10.7384 12.3814 10.7552 12.3818 10.7719 12.3827L11.2678 11.8868Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
