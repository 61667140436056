import { ETestIds } from 'astra-core/utils/testIds'

import { IRoute } from 'shared/types/routes'

export enum EMenuView {
  DARK = 'dark',
  LIGHT = 'light'
}

export interface MenuProps {
  list: IRoute[]
  view: EMenuView
  className?: string
}

export interface MenuItemProps {
  route: any
  isActive: boolean
  view: EMenuView
  testId?: string // ETestIds.TestMainHeaderMenu-<ITEM_NAME>
}

export interface StyledMenuItemProps {
  view: EMenuView
  $isActive: boolean
  $isParticular?: boolean
  testId?: ETestIds
}
