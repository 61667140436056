import styled, { css } from 'styled-components'

import { HINT_ATTR_DATA } from 'constants/styles/components/hintAttr/constants'

export const StyledBasketSuitableBonusCouponsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 14px;
  padding: 0 16px;
`

export const StyledBasketSuitableBonusCouponWrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  padding: 8px 8px 8px 9px;

  ${HINT_ATTR_DATA.body} {
    margin-bottom: 45px;
    white-space: normal;
    margin-left: -100px;
  }

  ${HINT_ATTR_DATA.arrow} {
    display: none;
  }

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.blue[16]};
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledBasketSuitableBonusCouponTitleWrapper = styled.div`
  display: flex;
  gap: 5px;
`

export const StyledBasketSuitableBonusCouponTitle = styled.p`
  ${({ theme }) =>
    css`
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledConditions = styled.div``
