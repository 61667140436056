import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLiveFilled: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6C0 3.79086 1.79086 2 4 2H20C22.2091 2 24 3.79086 24 6V18C24 20.2091 22.2091 22 20 22H4C1.79086 22 0 20.2091 0 18V6Z"
        fill="#FF3333"
      />
      <path
        clipRule="evenodd"
        d="M17.2807 16.3053C16.897 16.7026 16.9081 17.3357 17.3053 17.7193C17.7026 18.103 18.3357 18.0919 18.7193 17.6947C21.7602 14.5457 21.7602 9.45428 18.7193 6.30534C18.3357 5.90806 17.7026 5.89701 17.3053 6.28066C16.9081 6.66431 16.897 7.29738 17.2807 7.69466C19.5731 10.0686 19.5731 13.9314 17.2807 16.3053ZM6.69466 6.28066C7.09194 6.66431 7.10299 7.29738 6.71934 7.69466C4.42689 10.0686 4.42689 13.9314 6.71934 16.3053C7.10299 16.7026 7.09194 17.3357 6.69466 17.7193C6.29738 18.103 5.66431 18.0919 5.28066 17.6947C2.23978 14.5457 2.23978 9.45428 5.28066 6.30534C5.66431 5.90806 6.29738 5.89701 6.69466 6.28066ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM14.7054 8.28066C14.3081 8.66431 14.297 9.29738 14.6807 9.69466C15.9065 10.964 15.9065 13.036 14.6807 14.3053C14.297 14.7026 14.3081 15.3357 14.7054 15.7193C15.1027 16.103 15.7357 16.0919 16.1194 15.6947C18.0936 13.6503 18.0936 10.3497 16.1194 8.30534C15.7357 7.90806 15.1027 7.89701 14.7054 8.28066ZM9.21933 14.3053C9.60297 14.7026 9.59192 15.3357 9.19464 15.7193C8.79736 16.103 8.16429 16.0919 7.78064 15.6947C5.80643 13.6503 5.80643 10.3497 7.78064 8.30534C8.16429 7.90806 8.79736 7.89701 9.19464 8.28066C9.59192 8.66431 9.60297 9.29738 9.21933 9.69466C7.99354 10.964 7.99354 13.036 9.21933 14.3053Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
