import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LayoutTheme } from 'betweb-openapi-axios'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'

import { Radio } from 'shared/ui/Radio'

import {
  StyledSettingItem,
  StyledSettingItemContentRadio,
  StyledSettingItemIcon,
  StyledSettingItemLabel,
  StyledSettingItemTitle
} from '../../Settings.styled'

export const SettingTheme = () => {
  const [t] = useTranslation()
  const { onSetUserSetting } = useSetUserSetting()
  const themeType = useSelector(selectUserSettingLayoutTheme)

  const radioValue = useMemo(
    () => ({
      light: themeType === LayoutTheme.Light,
      dark: themeType === LayoutTheme.Dark
    }),
    [themeType]
  )

  const handleClickTheme = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>, themeType: LayoutTheme) => {
      e.preventDefault()

      onSetUserSetting({
        layoutTheme: themeType
      })
    },
    [onSetUserSetting]
  )

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>{t('site theme')}</StyledSettingItemTitle>

      <StyledSettingItemContentRadio>
        <StyledSettingItemLabel
          onClick={(e) => handleClickTheme(e, LayoutTheme.Light)}
        >
          <StyledSettingItemIcon
            $src={require('assets/images/settings/allThemes/setting site theme light.png')}
          />

          <Radio
            checked={radioValue.light}
            label={t('light theme by single word')}
          />
        </StyledSettingItemLabel>

        <StyledSettingItemLabel
          onClick={(e) => handleClickTheme(e, LayoutTheme.Dark)}
        >
          <StyledSettingItemIcon
            $src={require('assets/images/settings/allThemes/setting site theme dark.png')}
          />

          <Radio
            checked={radioValue.dark}
            label={t('dark theme by single word')}
          />
        </StyledSettingItemLabel>
      </StyledSettingItemContentRadio>
    </StyledSettingItem>
  )
}
