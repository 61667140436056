import styled, { css } from 'styled-components'

const BASKET_BONUS_COUPON_HEIGHT = 32

export const StyledBasketBonusCouponsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 16px;
`

export const StyledBasketBonusCouponWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  user-select: none;
`

export const StyledRadioWrapper = styled.div<{ isActive: boolean }>`
  align-items: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  height: ${BASKET_BONUS_COUPON_HEIGHT}px;
  justify-content: space-between;
  line-height: 16px;
  padding: 0 8px;
  width: 232px;
  ${({ theme, isActive }) =>
    css`
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};

      ${isActive
        ? css`
            background-color: ${theme.colors.custom.blue[19]};
          `
        : css`
            background-color: ${theme.colors.custom.blue[16]};
          `}
    `}
`

export const StyledBasketBonusCouponEmptySpace = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
`

export const StyledBasketCouponRightSide = styled.div<{ isActive: boolean }>`
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  height: ${BASKET_BONUS_COUPON_HEIGHT}px;
  position: relative;
  width: 16px;

  ${({ theme, isActive }) =>
    css`
      ${isActive
        ? css`
            background-color: ${theme.colors.custom.blue[19]};
            margin-right: -5px;
            margin-top: -1.5px;
            transform: rotate(-12deg);
          `
        : css`
            background-color: ${theme.colors.custom.blue[16]};
          `}
    `}
`

export const StyledBasketBonusCouponFirstCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: 229px;
  position: absolute;
  top: -1.5px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponSecondCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: 229px;
  position: absolute;
  top: 8px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponThirdCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: 229px;
  position: absolute;
  top: 18px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponFourthCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: 229px;
  position: absolute;
  top: 27px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponFirstRightCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: -3px;
  position: absolute;
  top: -1.5px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponSecondRightCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: -3px;
  position: absolute;
  top: 8px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponThirdRightCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: -3px;
  position: absolute;
  top: 18px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponFourthRightCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  left: -3px;
  position: absolute;
  top: 27px;
  width: 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketBonusCouponRightBigCircle = styled.div`
  border-radius: 50%;
  height: 12px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 12px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[0]};
    `}
`
