import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportCricket: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M8.00038 6.00261C8.00038 7.10655 7.10443 8.00151 5.99948 8.00151C4.89654 8.00151 4.00059 7.10555 4.00059 6.00061C4.00159 4.89667 4.89654 4.00171 6.00048 4.00171H6.00148C7.10543 4.00171 8.00138 4.89767 8.00038 6.00261ZM17.5196 4.27273L19.7297 6.48162C20.1367 6.8886 20.0777 7.60756 19.5977 8.08653L10.9096 16.7741C10.6436 17.039 10.3046 17.1761 9.98558 17.1761C9.72857 17.1761 9.48557 17.0881 9.30356 16.9061L8.93556 16.5381L5.63952 19.8329C5.52852 19.9449 5.38251 19.9999 5.23751 19.9999C5.09251 19.9999 4.94751 19.9449 4.83651 19.8329L4.1665 19.164C3.9445 18.942 3.9445 18.582 4.1665 18.36L7.46254 15.0642L7.09454 14.6962C6.68753 14.2902 6.74753 13.5703 7.22654 13.0912L15.9136 4.40472C16.1796 4.13974 16.5186 4.00274 16.8377 4.00274C17.0947 4.00274 17.3376 4.09174 17.5196 4.27273Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
