import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryCollapsedBets
} from 'astra-core/containers/BetsHistoryProvider'
import { ETestIds } from 'astra-core/utils/testIds'

import { StyledFilterTextButton } from '../../Filter.styled'

export const ButtonToggleCollapse = () => {
  const { isCollapsedAll } = useSelector(selectBetsHistoryCollapsedBets)
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const handleCollapse = useCallback(
    () => dispatch(betsHistoryProviderActions.toggleCollapseAllBets()),
    [dispatch]
  )

  const buttonToggleCollapseText = useMemo(
    () => t(isCollapsedAll ? 'expand all' : 'collapse all'),
    [isCollapsedAll, t]
  )

  return (
    <StyledFilterTextButton
      id={ETestIds.TestBetsHistoryFilterTextButton}
      onClick={handleCollapse}
    >
      {buttonToggleCollapseText}
    </StyledFilterTextButton>
  )
}
