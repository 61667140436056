import React from 'react'
import { useTranslation } from 'react-i18next'
import { ETestIds } from 'astra-core/utils/testIds'

import { ERoutes } from 'shared/types/routes'
import { EButtonSizes, EButtonViews } from 'shared/ui/Button'

import { useHandleRedirectList } from '../../hooks'

import { StyledButtonHistory } from './HistoryBetsButton.styled'

export const HistoryBetsButton = () => {
  const [t] = useTranslation()
  const handleRedirectList = useHandleRedirectList()

  return (
    <StyledButtonHistory
      id={ETestIds.TestMainHeaderBetsHistoryButton}
      size={EButtonSizes.S}
      view={EButtonViews.SECONDARY}
      onClick={handleRedirectList(ERoutes.BetsHistory)}
    >
      {t('history bets')}
    </StyledButtonHistory>
  )
}
