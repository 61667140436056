import React, { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import {
  ESportsCodes,
  selectSport,
  selectSports
} from 'astra-core/containers/CommonDataProvider'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { useGroupedTournaments } from 'astra-core/hooks/useEvents'
import { ETestIds } from 'astra-core/utils/testIds'

import { getLineTournamentLink } from 'shared/lib/events'
import { RootState } from 'shared/types/store'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import {
  selectLayoutIsLoading,
  selectLineTournaments,
  selectLineTournamentsLength
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { LineTournamentsParams } from 'pages/page-line-tournament/LineTournamentsContainer/types'

import { ClearResultSearch, LineHeader } from '../ui'
import { ELineHeaderTypes } from '../ui/LineHeader/LineHeader.types'
import { EmptyPageFilter } from '../EmptyPageFilter'

import {
  LineGroupedTournamentsProps,
  LineTournamentProps
} from './LineTournaments.types'
import {
  StyledGroupedLine,
  StyledLineTable,
  StyledLineTableTop,
  StyledLineTournamentSport,
  StyledLineWrapper,
  StyledLoadingIndicatorWrapper,
  StyledTournament,
  StyledTournamentAdditional,
  StyledTournamentText,
  StyledTournamentTop
} from './LineTournaments.styled'

const LineTournamentSport: FC<LineTournamentProps> = ({ tournament }) => {
  const { sportId } = tournament
  const { code, code: sportCode } =
    useSelector((state: RootState) => selectSport(state, +sportId)) || {}

  const Icon = useMemo(
    () => <SportIcon sport={sportCode as ESportsCodes} isActive />,
    [sportCode]
  )

  return (
    <StyledLineTournamentSport>
      {Icon} {code}.
    </StyledLineTournamentSport>
  )
}

export const LineTournaments: FC = () => {
  const { sportId } = useParams<LineTournamentsParams>()
  const sports = useSelector(selectSports)
  const dispatch = useDispatch()
  const searchText = useSelector(selectInputSearchLine)
  const tournaments = useSelector((state: RootState) =>
    selectLineTournaments(state, { sportId })
  )
  const isLayoutLoading = useSelector(selectLayoutIsLoading)
  const lineTournamentsLength = useSelector((state: RootState) =>
    selectLineTournamentsLength(state, { sportId })
  )

  const groupedTournaments = useGroupedTournaments(tournaments, sports)

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetLineFilter())
  }, [dispatch])

  return (
    <StyledLineWrapper>
      <LineHeader type={ELineHeaderTypes.TOURNAMENTS} />

      {isLayoutLoading && !lineTournamentsLength ? (
        <StyledLoadingIndicatorWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorWrapper>
      ) : lineTournamentsLength ? (
        groupedTournaments.map((group) => (
          <LineGroupedTournaments key={group.name} tournamentsGroup={group} />
        ))
      ) : searchText ? (
        <ClearResultSearch />
      ) : (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </StyledLineWrapper>
  )
}

export const LineGroupedTournaments: FC<LineGroupedTournamentsProps> = ({
  tournamentsGroup,
  showSport
}) => {
  const tournaments = groupBy(tournamentsGroup.tournaments, 'top')

  const topTournaments = tournaments.true || []
  const otherTournaments = tournaments.false || []

  return (
    <StyledGroupedLine>
      <StyledLineTable id={ETestIds.TestLineTable}>
        <StyledLineTableTop>
          {topTournaments.map((tournament) => (
            <LineTournament
              key={tournament.id}
              showSport={showSport}
              tournament={tournament}
            />
          ))}
        </StyledLineTableTop>

        {otherTournaments.map((tournament) => (
          <LineTournament
            key={tournament.id}
            showSport={showSport}
            tournament={tournament}
          />
        ))}
      </StyledLineTable>
    </StyledGroupedLine>
  )
}

export const LineTournament: FC<LineTournamentProps> = ({
  tournament,
  showSport
}) => {
  const tournamentLink = useMemo(
    () => getLineTournamentLink(tournament),
    [tournament]
  )

  return (
    <StyledTournament>
      {/* <StyledIconStarWithoutBackground // TODO handle favourite tournaments
        colorProps={{ name: EColorsNames.Primary, value: 40 }}
        size={14}
      /> */}

      <StyledTournamentText to={tournamentLink}>
        {tournament.top && <StyledTournamentTop>TOP</StyledTournamentTop>}
        {!!showSport && <LineTournamentSport tournament={tournament} />}
        {tournament.name}

        <StyledTournamentAdditional>
          {tournament.lineEventCount}
        </StyledTournamentAdditional>
      </StyledTournamentText>
    </StyledTournament>
  )
}
