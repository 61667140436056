import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import {
  IconSportMMA,
  IconSportGaelicFootball,
  IconSportShortHockey,
  IconSportBeachHandball,
  IconSportBox,
  IconAllSports,
  IconSportFootball,
  IconSportBasketball,
  IconSportTennis,
  IconSportHockey,
  IconSportVolleyball,
  IconSportCybersport,
  IconSportBaseball,
  IconSportTableTennis,
  IconSportBadminton,
  IconSportDarts,
  IconSportVelo,
  IconSportCricket,
  IconSportCurling,
  IconSportRugby,
  IconSportSoftball,
  IconSportBowShooting,
  IconSportPistolShooting,
  IconSportBiathlon,
  IconSportFishing,
  IconSportSkiing,
  IconSportSleigh,
  IconSportCar,
  IconSportSwimming,
  IconSportFloorball,
  IconSportHorseRacing,
  IconSportWindsurf,
  IconSportHandball,
  IconSportFieldHockey,
  IconSportRowing,
  IconSportFigureSkating,
  IconSportBilliards,
  IconSportAthletics,
  IconSportWeightlifting,
  IconSportLacrosse,
  IconSportSnowboarding,
  IconSportMoto,
  IconSportSkating,
  IconSportMixedMartialArts,
  IconSportSepaktakrau,
  IconSportBobsled,
  IconSportSquash,
  IconSportBasketball3vs3,
  IconSportWaterPolo,
  IconSportAmericanFootball,
  IconSportHockeyWithBall,
  IconSportCyberbasketball,
  IconSportCybertennis,
  IconSportCyberfootball,
  IconSportCyberhockey,
  IconSportMiniFootball,
  IconSportPesapallo,
  IconSportBeachVolleyball,
  IconSportBeachFootball,
  IconSportFormula1,
  IconSportBalls,
  IconSportChess,
  IconSportGolf,
  IconSportBowling,
  IconSportWrestling,
  IconSportRowingAcademic,
  IconSportBiplane,
  IconSportKabaddi,
  IconSportTriathlon,
  IconSportFreestyle,
  IconSportNetball,
  IconSportDiving,
  IconSportSkiJumping,
  IconSportSynchronizedSwimming,
  IconSportSkateboarding,
  IconSportSkeleton,
  IconSportModernPentathlon,
  IconSportSpeedway,
  IconSportBulletShooting,
  IconSportShortTrack,
  IconSportRunning,
  IconSportTeqball
} from './components'

export const SPORTS_ICON_MAP = {
  0: IconAllSports,
  [ESportsCodes.FOOTBALL]: IconSportFootball,
  [ESportsCodes.TENNIS]: IconSportTennis,
  [ESportsCodes.BASKETBALL]: IconSportBasketball,
  [ESportsCodes.ICE_HOCKEY]: IconSportHockey,
  [ESportsCodes.VOLLEYBALL]: IconSportVolleyball,
  [ESportsCodes.CYBER_SPORT]: IconSportCybersport,
  [ESportsCodes.MIXED_MARTIAL_ARTS]: IconSportMixedMartialArts,
  [ESportsCodes.BOX]: IconSportBox,
  [ESportsCodes.BASEBALL]: IconSportBaseball,
  [ESportsCodes.BASKETBALL_3X3]: IconSportBasketball3vs3,
  [ESportsCodes.TABLE_TENNIS]: IconSportTableTennis,
  [ESportsCodes.SHORT_HOCKEY]: IconSportShortHockey,
  [ESportsCodes.HANDBALL]: IconSportHandball,
  [ESportsCodes.BILLIARDS]: IconSportBilliards,
  [ESportsCodes.WATER_POLO]: IconSportWaterPolo,
  [ESportsCodes.BIATHLON]: IconSportBiathlon,
  [ESportsCodes.CURLING]: IconSportCurling,
  [ESportsCodes.BADMINTON]: IconSportBadminton,
  [ESportsCodes.AUSTRALIAN_FOOTBALL]: IconSportFootball,
  [ESportsCodes.AMERICAN_FOOTBALL]: IconSportAmericanFootball,
  [ESportsCodes.DARTS]: IconSportDarts,
  [ESportsCodes.FIELD_HOCKEY]: IconSportFieldHockey,
  [ESportsCodes.HOCKEY_WITH_BALL]: IconSportHockeyWithBall,
  [ESportsCodes.CYCLING]: IconSportVelo,
  [ESportsCodes.CYBER_BASKETBALL]: IconSportCyberbasketball,
  [ESportsCodes.CYBER_TENNIS]: IconSportCybertennis,
  [ESportsCodes.CYBER_FOOTBALL]: IconSportCyberfootball,
  [ESportsCodes.CYBER_HOCKEY]: IconSportCyberhockey,
  [ESportsCodes.CRICKET]: IconSportCricket,
  [ESportsCodes.MINI_FOOTBALL]: IconSportMiniFootball,
  [ESportsCodes.PESAPALLO]: IconSportPesapallo,
  [ESportsCodes.BEACH_VOLLEYBALL]: IconSportBeachVolleyball,
  [ESportsCodes.BEACH_HANDBALL]: IconSportBeachHandball,
  [ESportsCodes.BEACH_SOCCER]: IconSportBeachFootball,
  [ESportsCodes.RUGBY]: IconSportRugby,
  [ESportsCodes.RUGBY_7]: IconSportRugby,
  [ESportsCodes.RUGBY_UNION]: IconSportRugby,
  [ESportsCodes.RUGBY_LEAGUE]: IconSportRugby,
  [ESportsCodes.SEPAK_TAKRAW]: IconSportSepaktakrau,
  [ESportsCodes.FLOORBALL]: IconSportFloorball,
  [ESportsCodes.FORMULA_1]: IconSportFormula1,
  [ESportsCodes.BALLS]: IconSportBalls,
  [ESportsCodes.FUTSAL]: IconSportMiniFootball,
  [ESportsCodes.CHESS]: IconSportChess,
  [ESportsCodes.SHORT_TRACK]: IconSportShortTrack,
  [ESportsCodes.DTM]: IconSportCar,
  [ESportsCodes.NASCAR]: IconSportCar,
  [ESportsCodes.BOBSLED]: IconSportBobsled,
  [ESportsCodes.BOWLING]: IconSportBowling,
  [ESportsCodes.FREESTYLE_WRESTLING]: IconSportWrestling,
  [ESportsCodes.GOLF]: IconSportGolf,
  [ESportsCodes.ALPINE_SKIING]: IconSportSkiing,
  [ESportsCodes.ROWING_ACADEMIC]: IconSportRowingAcademic,
  [ESportsCodes.ROWING_ON_KAYAKS_AND_CANOES]: IconSportRowing,
  [ESportsCodes.GRECOROMAN_WRESTLING]: IconSportWrestling,
  [ESportsCodes.GAELIC_FOOTBALL]: IconSportGaelicFootball,
  [ESportsCodes.DUATHLON]: IconSportBiplane,
  [ESportsCodes.KABBADI]: IconSportKabaddi,
  [ESportsCodes.HORSEBACK_RIDING]: IconSportHorseRacing,
  [ESportsCodes.SKATING]: IconSportSkating,
  [ESportsCodes.LACROSSE]: IconSportLacrosse,
  [ESportsCodes.ATHLETICS]: IconSportRunning,
  [ESportsCodes.SKIING]: IconSportSkiing,
  [ESportsCodes.NORDIC_COMBINED]: IconSportBiplane,
  [ESportsCodes.MOTORCYCLE_RACING]: IconSportMoto,
  [ESportsCodes.NETBALL]: IconSportNetball,
  [ESportsCodes.SAILING]: IconSportWindsurf,
  [ESportsCodes.SWIMMING]: IconSportSwimming,
  [ESportsCodes.DIVING]: IconSportDiving,
  [ESportsCodes.SKI_JUMPING]: IconSportSkiJumping,
  [ESportsCodes.BULLET_SHOOTING]: IconSportBulletShooting,
  [ESportsCodes.RALLY]: IconSportCar,
  [ESportsCodes.FISHING]: IconSportFishing,
  [ESportsCodes.SLED]: IconSportSleigh,
  [ESportsCodes.SYNCHRONIZED_SWIMMING]: IconSportSynchronizedSwimming,
  [ESportsCodes.HORSE_RACING]: IconSportHorseRacing,
  [ESportsCodes.SQUASH]: IconSportSquash,
  [ESportsCodes.SKATEBOARDING]: IconSportSkateboarding,
  [ESportsCodes.SKELETON]: IconSportSkeleton,
  [ESportsCodes.SNOWBOARDING]: IconSportSnowboarding,
  [ESportsCodes.MODERN_PENTATHLON]: IconSportModernPentathlon,
  [ESportsCodes.SOFTBALL]: IconSportSoftball,
  [ESportsCodes.SPEEDWAY]: IconSportSpeedway,
  [ESportsCodes.GYMNASTICS]: IconSportAthletics,
  [ESportsCodes.ARCHERY]: IconSportBowShooting,
  [ESportsCodes.PISTOL_SHOOTING]: IconSportPistolShooting,
  [ESportsCodes.TRIATHLON]: IconSportTriathlon,
  [ESportsCodes.WEIGHTLIFTING]: IconSportWeightlifting,
  [ESportsCodes.FIGURE_SKATING]: IconSportFigureSkating,
  [ESportsCodes.FREESTYLE]: IconSportFreestyle,
  [ESportsCodes.MMA]: IconSportMMA,
  [ESportsCodes.TEQBOL]: IconSportTeqball
}
