import { IconBalance } from 'shared/ui/Icon/General/IconBalance'
import { IconBetsHistory } from 'shared/ui/Icon/General/IconBetsHistory'
import { IconNotification } from 'shared/ui/Icon/General/IconNotification'
// import { IconFAQ } from 'shared/ui/Icon/General/IconFAQ'
// import { IconNotification } from 'shared/ui/Icon/General/IconNotification'
import { IconProfile } from 'shared/ui/Icon/General/IconProfile'
import { IconPromotionsAndBonuses } from 'shared/ui/Icon/General/IconPromotionsAndBonuses'
// import { IconSupportPage } from 'shared/ui/Icon/General/IconSupportPage'
import { ERoutes } from 'shared/types/routes'
import { IRouteTranslate } from 'shared/lib/routes'

export const PROFILE_MENU_ROUTES_LIST: IRouteTranslate[] = [
  { path: ERoutes.Profile, locale: 'profile', icon: IconProfile },
  { path: ERoutes.ProfileBalance, locale: 'balance', icon: IconBalance },
  { path: ERoutes.BetsHistory, locale: 'history bets', icon: IconBetsHistory },
  {
    path: ERoutes.Notifications,
    locale: 'notifications',
    icon: IconNotification
  },
  {
    path: ERoutes.PromotionsAndBonuses,
    locale: 'promotions and bonuses',
    icon: IconPromotionsAndBonuses
  }
  // {
  //   path: ERoutes.FAQ,
  //   locale: 'FAQ',
  //   icon: IconFAQ
  // }
  // {
  //   path: ERoutes.SupportPage,
  //   locale: 'support',
  //   icon: IconSupportPage
  // }
]
