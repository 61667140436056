import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconClearResultSearch } from 'shared/ui/Icon/General/IconClearResultSearch'

import {
  StyledClearResultSearch,
  StyledClearResultSearchIcon,
  StyledClearResultSearchText,
  StyledClearResultSearchTextNotResults
} from './ClearResult.styled'
import { ClearResultSearchProps } from './ClearResultSearch.types'

export const ClearResultSearch = memo(
  ({ isMainPage }: ClearResultSearchProps) => {
    const { t } = useTranslation()
    return (
      <StyledClearResultSearch isMainPage={isMainPage}>
        <StyledClearResultSearchIcon>
          <IconClearResultSearch size={64} />
        </StyledClearResultSearchIcon>
        <StyledClearResultSearchTextNotResults>
          {t('search has not given any results')}
        </StyledClearResultSearchTextNotResults>
        <StyledClearResultSearchText
          dangerouslySetInnerHTML={{ __html: t('try other keywords') }}
        />
      </StyledClearResultSearch>
    )
  }
)
