import styled from 'styled-components'

export const StyledFilter = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: space-between;
  margin-top: 16px;
`

export const StyledFilterWrap = styled.div`
  align-items: center;
  display: flex;
`

export const StyledFilterList = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(6, 0fr);
`

export const StyledFilterTextButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(p) => p.theme.colors.default.primary[70]};
  cursor: pointer;
  outline: none;

  &:not(:last-child) {
    margin-right: 16px;
  }
`
