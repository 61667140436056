import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventInfo: FC<IconProps> = (props) => (
  <Icon size={12} {...props}>
    <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 2C5.44772 2 5 2.44772 5 3C5 3.55228 5.44772 4 6 4C6.55228 4 7 3.55228 7 3C7 2.44772 6.55228 2 6 2ZM6 5C5.44772 5 5 5.44772 5 6V9C5 9.55228 5.44772 10 6 10C6.55228 10 7 9.55228 7 9V6C7 5.44772 6.55228 5 6 5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
