import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGroupedCalendarEvents } from 'astra-core/hooks/useEvents'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  selectCalendarEventsDataEntries,
  selectCalendarEventsIsEmpty,
  selectCalendarEventsPagination,
  selectFilterSelectInputPeriod
} from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { EColorsNames } from 'shared/types/theme'
import {
  Filters,
  TableCalendar
} from 'pages/page-calendar/CalendarEvents/components'

import {
  StyledCalendarEvents,
  StyledDataList,
  StyledIconCalendar
} from './CalendarEvents.styled'
import { PAGINATION_SETTINGS } from './constants'

const IconCalendar = (
  <StyledIconCalendar
    colorProps={{ name: EColorsNames.Blue, value: 50 }}
    size={24}
  />
)

export const CalendarEvents = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const filterPeriod = useSelector(selectFilterSelectInputPeriod)
  const isCalendarEventsEmpty = useSelector(selectCalendarEventsIsEmpty)
  const events = useSelector(selectCalendarEventsDataEntries)
  const groupedEvents = useGroupedCalendarEvents(events)
  const pagination = useSelector(selectCalendarEventsPagination)

  useEffect(() => {
    dispatch(
      calendarEventsContainerActions.fetchScheduledEvents({
        ...filterPeriod,
        limit: pagination.limit,
        offset: pagination.offset
      })
    )
  }, [dispatch, filterPeriod, pagination.limit, pagination.offset])

  return isCalendarEventsEmpty ? (
    <LoaderSpinner />
  ) : (
    <StyledCalendarEvents>
      <TitleLinePage
        iconComponent={IconCalendar}
        title={t('mainPage upcoming')}
      >
        <Filters />
      </TitleLinePage>

      <StyledDataList pagination={PAGINATION_SETTINGS}>
        <TableCalendar groupedEvents={groupedEvents} />
      </StyledDataList>
    </StyledCalendarEvents>
  )
}
