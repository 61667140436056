import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectIsShowBasketOnSmallWindow } from 'containers/BasketContainer/selectors'
import { LiveMatchTracker } from 'widgets/live-match-tracker'

import { Basket } from './Basket/Basket'
import { StyledRightContainerWrapper } from './right-container.styled'
import { hiddenRightContainerRoutesList } from './constants'

export const RightContainer: FC = () => {
  const { pathname } = useLocation()
  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)
  const isRouteWithPermanentHiddenBasket =
    hiddenRightContainerRoutesList.includes(pathname)

  return (
    <StyledRightContainerWrapper
      isRouteWithPermanentHiddenBasket={isRouteWithPermanentHiddenBasket}
      isShowBasketOnSmallWindow={isShowBasketOnSmallWindow}
    >
      <LiveMatchTracker />
      <Basket />
    </StyledRightContainerWrapper>
  )
}
