import { PayloadAction } from '@reduxjs/toolkit'
import { MarketGroup } from 'betweb-openapi-axios'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'

import { ContainerState } from './types'

const REDUCER_KEY = 'outcomesContainer'

export const initialState: ContainerState = {
  selectedOutcomesGroup: null
}

const outcomesContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setSelectedOutcomesGroup(
      state,
      { payload }: PayloadAction<MarketGroup | null>
    ) {
      state.selectedOutcomesGroup = payload
    }
  }
})

export const {
  actions: outcomesContainerActions,
  reducer,
  name: sliceKey
} = outcomesContainerSlice
