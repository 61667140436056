import styled from 'styled-components'

import { StyledContent, StyledLayout } from '../Sport/Sport.styled'

export const StyledLayoutProfile = styled(StyledLayout)`
  grid-template-areas:
    'header header'
    'profile-menu content';
  grid-template-columns: 240px 1fr;
`

export const StyledContentProfile = styled(StyledContent)`
  grid-template-rows: auto minmax(0, 1fr);
  justify-self: center;
  max-width: 1400px;
  overflow: hidden;
  width: 100%;
`
