import {
  CategoryMarketInfo,
  Event,
  MarketCategory,
  EventProbability
} from 'betweb-openapi-axios'

export interface PanelHandle {
  open: () => void
  close: () => void
}

export interface PanelProps {
  title?: string
}

export enum OutcomesComponentType {
  EVENT_PAGE = 'event page',
  EVENTS_LIST = 'events list'
}

export interface OutcomesProps {
  eventId: Event['id']
  type: OutcomesComponentType
  isLoading?: boolean
  outcomesRef?: React.RefObject<OutcomeCategoriesHandle>
}

export interface OutcomeCategoriesHandle {
  openAll: () => void
  closeAll: () => void
}

export interface OutcomesCategoriesProps {
  eventId: Event['id']
  columnsCount?: number
}

export interface MemoizedMasonryProps extends OutcomesCategoriesProps {
  marketCategories: MarketCategory[]
}

export interface OutcomesCategoryProps {
  eventId: Event['id']
  category: MarketCategory
}

export interface OutcomesTableProps {
  eventId: Event['id']
  market: CategoryMarketInfo
  isFirst: boolean
  isLast: boolean
  isMerged: boolean
}

export interface OutcomeOddProps {
  eventProbability: EventProbability & { eventId: Event['id'] }
  withParam?: boolean
}
