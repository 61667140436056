import React, { FC, useCallback } from 'react'
import { CompetitorStatistics, Event } from 'betweb-openapi-axios'
import { selectEventStatistics } from 'astra-core/containers/EventsProvider'
import { useSelector } from 'react-redux'

import { RootState } from 'shared/types/store'
import {
  IconStatFootballCorner,
  IconStatFootballFouls,
  IconStatFootballShot,
  IconStatFootballShotOnGoal,
  IconStatFootballYellowCard
} from 'shared/ui/Icon/SportStatsIcon/Football'

import {
  StyledBannerStats,
  StyledBannerStatsItem,
  StyledBannerStatsItemText
} from '../../Stats.styled'

import type { StatisticsItems } from '../../Stats.types'

const statisticsItems: StatisticsItems<CompetitorStatistics> = [
  {
    field: 'corners',
    Icon: IconStatFootballCorner
  },
  {
    field: 'yellowCards',
    Icon: IconStatFootballYellowCard
  },
  {
    field: 'shotsOffTarget',
    Icon: IconStatFootballShot
  },
  {
    field: 'shotsOnTarget',
    Icon: IconStatFootballShotOnGoal
  },
  {
    field: 'freeKicks',
    Icon: IconStatFootballFouls
  }
]

export const StatsFootball: FC<{ eventId: Event['id'] }> = ({ eventId }) => {
  const eventStatistics = useSelector((state: RootState) =>
    selectEventStatistics(state, eventId)
  )

  const getStatisticsItem = useCallback(
    (field: keyof CompetitorStatistics) =>
      eventStatistics.homeStatistics &&
      eventStatistics.awayStatistics &&
      `${eventStatistics.homeStatistics[field] ?? 0} – ${
        eventStatistics.awayStatistics[field] ?? 0
      }`,
    [eventStatistics]
  )

  if (!eventStatistics) {
    return null
  }

  return (
    <StyledBannerStats>
      {statisticsItems.map(({ field, Icon }) => (
        <StyledBannerStatsItem key={field}>
          <Icon />

          <StyledBannerStatsItemText>
            {getStatisticsItem(field)}
          </StyledBannerStatsItemText>
        </StyledBannerStatsItem>
      ))}
    </StyledBannerStats>
  )
}
