import styled from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

export const STYLED_EVENT_BANNER_MINI_HEIGHT = 40

export const StyledEventBannerMini = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.theme.colors.custom[EColorsNames.Yellow][1]};
  border-radius: 8px;
  display: flex;
  height: ${STYLED_EVENT_BANNER_MINI_HEIGHT}px;
  margin: 0 8px 8px;
  padding: 8px;
  position: sticky;
  top: 2px;
  width: calc(100% - 8px);
  z-index: 1000;
`

export const StyledEventBannerMiniCompetitors = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: flex;
  gap: 6px;
`

export const StyledBannerMiniCompetitorWrapper = styled.div<{
  reverse?: boolean
}>`
  display: flex;
  gap: 4px;
  justify-content: ${(p) => (p.reverse ? 'flex-start' : 'flex-end')};
  line-height: 1;
`

export const CurrentServerFlag = styled.div<{ $isActive?: boolean }>`
  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.default.green[50] : 'transparent'};
  border-radius: 1px;
  width: 2px;
`

export const StyledEventBannerMiniCompetitor = styled.h3`
  display: inline-block;
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 20px;
`
export const StyledEventBannerMiniLiveWrapper = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.default[EColorsNames.Red][100]};
  border-radius: 4px;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
`

export const StyledEventBannerWhiteSpace = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.theme.colors.custom[EColorsNames.Primary][20]};
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0 16px 0 8px;
  padding: 0 8px;
`

export const StyledEventBannerScore = styled.span`
  color: ${(props) => props.theme.colors.default.primary[80]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 15px;
  padding-right: 8px;
  position: relative;

  &::after {
    background: ${(props) =>
      props.theme.colors.default[EColorsNames.Primary][5]};
    content: '';
    display: block;
    height: calc(100% + 4px);
    position: absolute;
    right: 0;
    top: -2px;
    width: 1px;
  }
`

export const StyledEventBannerScoreByPeriods = styled.span`
  color: ${(props) => props.theme.colors.default.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.xs};
  margin-left: 8px;
  padding-right: 8px;
  position: relative;
`

export const StyledScoresWrapper = styled.div`
  align-items: center;
  display: flex;
`
