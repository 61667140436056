import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { Button } from 'shared/ui/Button'
import { StyledTableRow } from 'widgets/event-row'

import { ETableHeadVariant } from './TableBetsHead.types'

export const StyledLineTableHead = styled(StyledTableRow)<{
  variant: ETableHeadVariant
}>`
  background-color: ${({ theme }) => theme.colors.custom.primary[2]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.custom.primary[4]};
  position: sticky;
  top: 0;
  z-index: 5;
  ${(props) => css`
    ${props.variant === ETableHeadVariant.ONE &&
    css`
      background-color: ${({ theme }) => theme.colors.custom.primary[2]};
    `};

    ${props.variant === ETableHeadVariant.TWO &&
    css`
      background-color: ${({ theme }) => theme.colors.custom.primary[65]};
    `};
  `}
`

export const StyledTableHeadColumn = styled.div<{
  align?: string
  hide?: boolean
}>`
  align-items: center;
  color: ${(props) => props.theme.colors.custom.primary[9]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  justify-content: ${(props) => props.align ?? 'center'};
  line-height: 16px;
  min-width: 40px;
  padding: 4px;
  visibility: ${(props) => props.hide && 'hidden'};
`

export const StyledTableHeadColumnTitle = styled(StyledTableHeadColumn)`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  gap: 4px;
  min-width: 300px;
  position: relative;

  ${StyledIcon} {
    margin-right: 6px;
  }
`

export const StyledTableHeadDoubleColumn = styled(StyledTableHeadColumn)`
  min-width: 80px;
`

export const StyledSportIcon = styled.span`
  height: 16px;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
  width: 16px;

  ${StyledIcon} {
    left: -4px;
    position: absolute;
    top: -4px;
  }
`

export const StyledLineTableHeadChevron = styled.div`
  align-items: center;
  display: flex;
`

export const StyledLineTableHeadChevronButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.custom.primary[8]};
  color: ${({ theme }) => theme.colors.default.primary[70]};
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: 16px;
  line-height: 16px;
  margin: auto 13px auto auto;
`
