import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsTypes
} from 'astra-core/containers/BetsHistoryProvider'
import { ETestIds } from 'astra-core/utils/testIds'

import { SelectCheckbox } from 'shared/ui/SelectCheckbox'

import { BET_TYPES_OPTIONS } from './constants'

export const SelectBetTypes = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const betsTypes = useSelector(selectBetsHistoryBetsTypes)

  const optionsSelectCheckbox = useMemo(() => BET_TYPES_OPTIONS(t), [t])

  const handleBetsTypesChange = useCallback(
    (value) => {
      dispatch(betsHistoryProviderActions.setFilterBetsTypes(value))
    },
    [dispatch]
  )

  return (
    <SelectCheckbox
      id={ETestIds.TestBetsHistoryBetsTypeCheckbox}
      isSearchable={false}
      options={optionsSelectCheckbox}
      value={betsTypes}
      onChange={handleBetsTypesChange}
    />
  )
}
