import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'shared/ui/Switch'
import { selectFilterIsTop } from 'pages/page-live-events/LiveContainer/selectors'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'

import { SearchInput } from '../SearchInput'

import { StyledLiveHeaderFilters } from './LiveHeaderFilters.styled'

export const LiveHeaderFilters: FC = memo(() => {
  const dispatch = useDispatch()
  const isTop = useSelector(selectFilterIsTop)
  // const [isActiveIconStream, toggleIsActiveIconStream] = useToggleState(false)

  const toggleFilterTop = useCallback(
    () => dispatch(liveContainerActions.toggleFilterIsTop()),
    [dispatch]
  )

  return (
    <StyledLiveHeaderFilters>
      <Switch active={isTop} label="TOP" onClick={toggleFilterTop} />

      {/* <TabsTimeEvents /> */}

      {/* <IconStreamFilled */}
      {/*  buttonProps={{ */}
      {/*    isActiveState: isActiveIconStream */}
      {/*  }} */}
      {/*  colorProps={{ name: EColorsNames.Primary, value: 30 }} */}
      {/*  size={14} */}
      {/*  onClick={toggleIsActiveIconStream} */}
      {/* /> */}

      <SearchInput isTournaments />
    </StyledLiveHeaderFilters>
  )
})
