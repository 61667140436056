/**
 *
 * EventContainer
 *
 */

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useInterval } from 'hooks'
import { RootState } from 'shared/types/store'
import { Event } from 'pages/page-live-events/Line/components/Event/Event'

import { actions, reducer, sliceKey } from './slice'
import { selectEvent } from './selectors'
import { eventContainerSaga } from './saga'
import {
  getFetchEventReqData,
  LINE_EVENT_POLLING_INTERVAL,
  LIVE_EVENT_POLLING_INTERVAL
} from './utils'
import { EventParams, EventProps } from './types'

export function EventContainer(_props: EventProps) {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: eventContainerSaga })

  const { eventId } = useParams<EventParams>()

  const { event, isEmpty } = useSelector((state: RootState) =>
    selectEvent(state, { eventId: +eventId })
  )

  const dispatch = useDispatch()

  const fetchEvent = useCallback(() => {
    if (eventId) {
      dispatch(actions.fetchEvent(getFetchEventReqData({ eventId })))
    }
  }, [dispatch, eventId])

  useInterval(
    fetchEvent,
    event
      ? event.live
        ? LIVE_EVENT_POLLING_INTERVAL
        : LINE_EVENT_POLLING_INTERVAL
      : null,
    true
  )

  return !isEmpty ? <Event event={event!} /> : <LoaderSpinner />
}
