export type TComponentColorProps = {
  name: EColorsNames
  value: number
  type?: EColorsTypes
}

export enum EColorsTypes {
  CUSTOM = 'custom',
  FIXED = 'fixed',
  DEFAULT = 'default'
}

export enum EColorsNames {
  Primary = 'primary',
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
  Blue = 'blue',
  Brown = 'brown',
  Shadow = 'shadow'
}

type Colors<T extends Partial<EColorsNames>> = {
  [ColorProp in T]: Record<number, `${`#` | `rgb`}${string}`>
}

export type TBaseColorsTheme = {
  [EColorsTypes.DEFAULT]: Colors<Exclude<EColorsNames, EColorsNames.Brown>>
  [EColorsTypes.CUSTOM]: Colors<Exclude<EColorsNames, EColorsNames.Shadow>>
}

export type TFixedColorsTheme = {
  [EColorsTypes.FIXED]: Colors<
    Exclude<EColorsNames, EColorsNames.Brown | EColorsNames.Shadow>
  >
}

export type TThemeColors = TBaseColorsTheme & TFixedColorsTheme
