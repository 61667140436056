import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconSportChess: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        clipRule="evenodd"
        d="M15.2078 8.94441H8.79115C8.20143 8.94441 7.72171 8.46469 7.72171 7.87496V5.12496C7.72171 4.87196 7.92704 4.66663 8.18004 4.66663H9.70782C9.96082 4.66663 10.1662 4.87196 10.1662 5.12496V6.19441H10.7773V5.12496C10.7773 4.87196 10.9826 4.66663 11.2356 4.66663H12.7634C13.0164 4.66663 13.2217 4.87196 13.2217 5.12496V6.19441H13.8328V5.12496C13.8328 4.87196 14.0382 4.66663 14.2912 4.66663H15.8189C16.0719 4.66663 16.2773 4.87196 16.2773 5.12496V7.87496C16.2773 8.46469 15.7975 8.94441 15.2078 8.94441ZM14.5603 9.86111C14.377 11.4072 14.4992 12.9656 14.9148 14.4444H9.08477C9.50644 12.9656 9.62866 11.4011 9.43922 9.86111H14.5603ZM7.11119 16.8889V16.4306C7.11119 15.8408 7.59091 15.3611 8.18064 15.3611H15.8195C16.4092 15.3611 16.889 15.8408 16.889 16.4306V16.8889H17.0415C17.6312 16.8889 18.1109 17.3686 18.1109 17.9583V18.875C18.1109 19.128 17.9056 19.3333 17.6526 19.3333H6.34701C6.09401 19.3333 5.88867 19.128 5.88867 18.875V17.9583C5.88867 17.3686 6.36839 16.8889 6.95812 16.8889H7.11119Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    )}
  </SportIconWrapper>
)
