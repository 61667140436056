import styled, { css } from 'styled-components'

import {
  StyledModalContainerProps,
  StyledModalProps,
  ViewComponentType
} from './types'

export const StyledModal = styled.div<StyledModalProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.default.shadow[1]};

  /* TODO figure out is it really needed */
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 102;
`

export const StyledModalContainer = styled.div<StyledModalContainerProps>`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 16px;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 1px;
  }

  ${({ view, theme }) => {
    switch (view) {
      case ViewComponentType.FULL: {
        return css`
          height: 100%;
        `
      }
      default:
      case ViewComponentType.CUSTOM: {
        return css`
          border-radius: 16px;
        `
      }
      case ViewComponentType.PAYMENT: {
        return css`
          border-radius: 8px;
        `
      }
      case ViewComponentType.NOTIFICATION: {
        return css`
          border-radius: 8px;
          height: 312px;
          width: 400px;
        `
      }
      case ViewComponentType.FIRST: {
        return css`
          background-color: ${theme.colors.custom.primary[10]};
          border-radius: 8px;
        `
      }
    }
  }}
`
