import React, { FC } from 'react'

import { SportIconWrapper } from '../SportIconWrapper'
import { IIconsSportNameProps } from '../SportIcon.types'

export const IconAllSports: FC<IIconsSportNameProps> = (props) => (
  <SportIconWrapper {...props}>
    {({ mainColor }) => (
      <path
        d="M11.992 4C7.576 4 4 7.584 4 12C4 16.416 7.576 20 11.992 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 11.992 4ZM17.536 8.8H15.176C14.92 7.8 14.552 6.84 14.072 5.952C15.544 6.456 16.768 7.48 17.536 8.8ZM12 5.632C12.664 6.592 13.184 7.656 13.528 8.8H10.472C10.816 7.656 11.336 6.592 12 5.632ZM5.808 13.6C5.68 13.088 5.6 12.552 5.6 12C5.6 11.448 5.68 10.912 5.808 10.4H8.512C8.448 10.928 8.4 11.456 8.4 12C8.4 12.544 8.448 13.072 8.512 13.6H5.808ZM6.464 15.2H8.824C9.08 16.2 9.448 17.16 9.928 18.048C8.456 17.544 7.232 16.528 6.464 15.2ZM8.824 8.8H6.464C7.232 7.472 8.456 6.456 9.928 5.952C9.448 6.84 9.08 7.8 8.824 8.8ZM12 18.368C11.336 17.408 10.816 16.344 10.472 15.2H13.528C13.184 16.344 12.664 17.408 12 18.368ZM13.872 13.6H10.128C10.056 13.072 10 12.544 10 12C10 11.456 10.056 10.92 10.128 10.4H13.872C13.944 10.92 14 11.456 14 12C14 12.544 13.944 13.072 13.872 13.6ZM14.072 18.048C14.552 17.16 14.92 16.2 15.176 15.2H17.536C16.768 16.52 15.544 17.544 14.072 18.048ZM15.488 13.6C15.552 13.072 15.6 12.544 15.6 12C15.6 11.456 15.552 10.928 15.488 10.4H18.192C18.32 10.912 18.4 11.448 18.4 12C18.4 12.552 18.32 13.088 18.192 13.6H15.488Z"
        fill={mainColor}
      />
    )}
  </SportIconWrapper>
)
